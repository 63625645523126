const TextField = (props) => {
    const {
        onClick,
        onChange,
        style,
        className,
        label,
        labelClass,
        placeholder,
        leftElement,
        leftElementClickHandler,
        rightElement,
        rightElementClickHandler,
        inputClass,
        type,
        value,
        validEmail,
        emailError,
    } = props

    return (
        <span onClick={onClick} style={style} className={`font-notoSans text-white ${className || ''}`}>
            {
                label &&
                <div className={`mb-2 ${labelClass || ''}`}>
                    <label htmlFor={label} >
                        {label}
                    </label>
                </div>
            }

            <div className="relative">
                {!!leftElement &&
                    <span onClick={leftElementClickHandler || ''} className="absolute inset-y-0 left-0 flex items-center">
                        <span type="submit" className="p-1 focus:outline-none focus:shadow-outline">
                            {leftElement}
                        </span>
                    </span>
                }
                {
                    type === 'textarea'
                        ? <textarea value={value} onChange={onChange} id={label} type="text" name="q" className={`h-[88px] w-full text-sm text-white bg-lightbg px-5 py-3 focus:outline-none ${inputClass}`} placeholder={placeholder} autoComplete="off" />
                        : <input value={value} onChange={onChange} id={label} type={type || 'text'} name="q" className={`h-[48px] w-full text-sm text-white bg-lightbg px-5 focus:outline-none ${inputClass}`} placeholder={placeholder} autoComplete="off" min={props.min} max={props.max} />

                }
                {!!rightElement &&
                    <span onClick={rightElementClickHandler || ''} className="absolute inset-y-0 right-0 flex items-center">
                        <span type="submit" className="p-0 focus:outline-none focus:shadow-outline">
                            {rightElement}
                        </span>
                    </span>
                }
            </div>
            {!validEmail && emailError}
        </span>
    )
}

export default TextField