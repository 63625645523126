import { DoubleArrowLeft } from "components/icons/DoubleArrowleft";
import { LeftArrow } from "components/icons/LeftArrow";
import React, { useEffect, useState } from "react";
import { getActivity } from "api/apiCalls";
import { Grid } from "@mui/material";

const Pagination = ({ RenderComponent, pageLimit }) => {
  const [activityData, setActivityData] = useState([]);

  const [activityDataLimit, setActivityDataLimit] = useState("");

  const [totalActivityData, setTotalActivityData] = useState("");

  const [pages] = useState(Math.round(activityData.length / activityDataLimit));
  const lastPage = Math.round(totalActivityData / activityDataLimit) + 1;
  const lastPage1 = Math.round(totalActivityData / activityDataLimit);

  // console.log(lastPage)
  const [currentPage, setCurrentPage] = useState(1);

  const fetchActivity = async (page, perPage, order) => {
    const getActivityResponse = await getActivity(page, perPage, order);
    const data =
      getActivityResponse &&
      getActivityResponse.data &&
      getActivityResponse.data.list
        ? getActivityResponse.data.list
        : [];
    const dataLimit =
      getActivityResponse &&
      getActivityResponse.data &&
      getActivityResponse.data.perPage
        ? getActivityResponse.data.perPage
        : "";
    const totalDataCount =
      getActivityResponse &&
      getActivityResponse.data &&
      getActivityResponse.data.count
        ? getActivityResponse.data.count
        : "";
    setActivityData(data);
    setActivityDataLimit(dataLimit);
    setTotalActivityData(totalDataCount);
  };

  function goToNextPage() {
    if (currentPage >= lastPage) {
      setCurrentPage(lastPage);
    } else {
      setCurrentPage((page) => page + 1);
    }
  }

  function goToPreviousPage() {
    setCurrentPage((page) => page - 1);
  }
  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }
  function changePageToFirst(event) {
    const pageNumber = 1;
    setCurrentPage(pageNumber);
  }
  function changePageToLast(event) {
    // const pageNumber = Number(event.target.textContent);
    setCurrentPage(lastPage);
  }

  const getPaginatedData = () => {
    return activityData;
  };

  const getPaginationGroup = () => {
    let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
    const pageArray = [];
    for (let i = 1; i <= pageLimit; i++) {
      let pageNum = start + i;
      if (pageNum <= lastPage) {
        pageArray.push(pageNum);
      }
    }
    return pageArray;
    // return new Array(pageLimit).fill().map((_, idx) => start + idx + 1);
  };

  const tableHeader = ["Event", "Item", "Price", "From", "To", "Date"];
  const order = "ASC";
  useEffect(() => {
    fetchActivity(currentPage, activityDataLimit, order);
    getPaginatedData();
  }, [currentPage, order]);
  return (
    <div>
      <div className="bg-[#1B1E21] rounded-xl overflow-x-scroll p-5 px-6 hidden xl:block">
        <div className="min-w-full overflow-x-scroll">
          <div className="rounded-t-xl grid grid-cols-11 gap-2">
            {tableHeader.map((title) => {
              return (
                <div
                  className={`text-sm font-medium text-white opacity-80 py-6 text-left ${
                    title === "Event"
                      ? "col-span-2 ml-4"
                      : title === "Item"
                      ? "col-span-3 pl-5 mr-2"
                      : title === "Price"
                      ? "price mr-2"
                      : title === "From"
                      ? "fromTab col-span-2 mr-2"
                      : title === "To"
                      ? "toTab col-span-2 mr-2"
                      : title === "Date"
                      ? "dateTab col-span-1"
                      : ""
                  }`}
                >
                  {title}
                </div>
              );
            })}
          </div>
        </div>
        <div className="pb-2.5">
          <div className="pb-2.5">
            {getPaginatedData().map((d, idx) => (
              <RenderComponent key={idx} item={d} />
            ))}
          </div>
          <div className="flex items-center justify-center rounded-b-xl">
            {/* previous button */}
            <button
              style={{
                margin: "0px 10px",
                padding: "10px",
                backgroundColor: "#1B1E21",
              }}
              onClick={changePageToFirst}
              className={`${
                currentPage === 1 ? "disabled cursor-none " : "cursor-pointer"
              }`}
            >
              <DoubleArrowLeft className="fill-white opacity-80" />
            </button>
            <button
              style={{ margin: "0px 10px" }}
              onClick={goToPreviousPage}
              className={`prev border-none ${
                currentPage === 1 ? "disabled cursor-none " : "cursor-pointer"
              }`}
            >
              <LeftArrow className="fill-white opacity-80" />
            </button>

            {/* show page numbers */}
            {getPaginationGroup().map((item, index) => (
              <button
                style={{ height: "30px", width: "30px", margin: "6px" }}
                key={index}
                onClick={changePage}
                className={`relative rounded-md text-opacity-80 text-sm ${
                  currentPage === item
                    ? "activeButton text-black rounded-md cursor-none"
                    : "text-white cursor-pointer"
                }`}
              >
                <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                  {item}
                </span>
              </button>
            ))}

            {/* next button */}
            <button
              style={{ margin: "0px 10px" }}
              onClick={goToNextPage}
              className={`border-none ${
                currentPage === lastPage
                  ? "disabled cursor-none "
                  : "cursor-pointer"
              }`}
            >
              <LeftArrow className="fill-white rotate-180 opacity-80" />
            </button>
            <button
              style={{ margin: "0px 10px" }}
              onClick={changePageToLast}
              className={`border-none ${
                currentPage === lastPage
                  ? "disabled cursor-none "
                  : "cursor-pointer"
              }`}
            >
              <DoubleArrowLeft className="fill-white rotate-180 opacity-80" />
            </button>
          </div>
        </div>
      </div>
      <div className="xl:hidden block">
        <div className="pb-2.5">
          <div className="pb-2.5 grid grid-cols-1 sm:grid-cols-2 gap-6">
            {getPaginatedData().map((d, idx) => (
              <div
                key={idx}
                className="card-item min-w-[99%] sm:min-w-[48%] lg:min-w-[24%]"
              >
                <RenderComponent key={idx} item={d} />
              </div>
            ))}
          </div>
          <div className="flex items-center justify-center rounded-b-xl">
            {/* previous button */}
            <button
              style={{
                margin: "4px",
                padding: "10px",
                backgroundColor: "#1B1E21",
              }}
              onClick={changePageToFirst}
              className={`rounded-md ${
                currentPage === 1 ? "disabled cursor-none " : "cursor-pointer"
              }`}
            >
              <DoubleArrowLeft className="fill-white opacity-80" />
            </button>
            <button
              style={{
                margin: "",
                padding: "10.5px 11.5px",
                backgroundColor: "#1B1E21",
              }}
              onClick={goToPreviousPage}
              className={`rounded-md ${
                currentPage === 1 ? "disabled cursor-none " : "cursor-pointer"
              }`}
            >
              <LeftArrow className="fill-white opacity-80" />
            </button>

            {/* show page numbers */}
            {getPaginationGroup().map((item, index) => (
              <button
                style={{ height: "30px", width: "30px", margin: "6px" }}
                key={index}
                onClick={changePage}
                className={`relative rounded-md text-opacity-80 text-sm ${
                  currentPage === item
                    ? "activeButton text-black rounded-md cursor-none"
                    : "text-white cursor-pointer"
                }`}
              >
                <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                  {item}
                </span>
              </button>
            ))}

            {/* next button */}
            <button
              style={{
                margin: "",
                padding: "10.5px 11.5px",
                backgroundColor: "#1B1E21",
              }}
              onClick={goToNextPage}
              className={`rounded-md border-none ${
                currentPage === pages
                  ? "disabled cursor-none "
                  : "cursor-pointer"
              }`}
            >
              <LeftArrow className="fill-white rotate-180 opacity-80" />
            </button>
            <button
              style={{
                margin: "4px",
                padding: "10px",
                backgroundColor: "#1B1E21",
              }}
              onClick={changePageToLast}
              className={`rounded-md border-none ${
                currentPage === pages
                  ? "disabled cursor-none "
                  : "cursor-pointer"
              }`}
            >
              <DoubleArrowLeft className="fill-white rotate-180 opacity-80" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
