import Verified from "components/icons/Verified";
import { Text12Regular } from "components/elements/Text";
import { useNavigate } from "react-router-dom";

const NFTCollectionCard = (props) => {
  const navigate = useNavigate();
  const {
    collection,
    style,
    className,
    userIsVerified,
    collectionWrapClass,
    collectionItemClass,
    loading,
  } = props;

  if (loading) {
    return (
      <div
        className={`flex flex-col gap-2 w-full ${className || ""}`}
        style={style}
      >
        <div
          className={`bg-gray-800 animate-pulse py-3 px-[14px] w-full rounded-[30px] ${
            collectionWrapClass || ""
          }`}
        >
          <div id="collection-foreground" className="h-[168px] xs2:h-[240px]" />
        </div>
        <div className="bg-gray-800 animate-pulse h-4 w-24 rounded" />
      </div>
    );
  }

  const nftImages = collection?.nfts?.map((item) =>
    !!item.secondaryMediaS3Key && item.secondaryMediaS3Key.signedUrl
      ? item.secondaryMediaS3Key.signedUrl
      : null
  );
  const collectionImagesForDisplay = [...nftImages]
    .sort(() => 0.5 - Math.random())
    .slice(0, 4);

  return !collection || !collection.id ? null : (
    <>
      <div
        className={`flex flex-col gap-2 w-full ${className || ""}`}
        style={style}
      >
        <div
          id="collection-background"
          className={`bg-dark1 py-3 px-[14px] w-full rounded-[30px] ${
            collectionWrapClass || ""
          }`}
        >
          <div id="collection-foreground" className="flex flex-wrap gap-1">
            {collectionImagesForDisplay.map((item, idx) => (
              <div
                key={idx}
                style={{ backgroundImage: `url(${item})` }}
                className={`rounded-[25px] bg-no-repeat bg-cover bg-center bg-baseDark ${
                  collectionItemClass ||
                  "w-[84px] h-[84px] xs2:w-[120px] xs2:h-[120px]"
                }`}
              />
            ))}
          </div>
        </div>
        <div
          id="collection-info"
          className="flex items-center px-8 justify-center md:justify-start"
        >
          {!!collection.name && (
            <div>
              <Text12Regular>
                <span className="capitalize">{collection.name}</span>
              </Text12Regular>
            </div>
          )}
          {!!userIsVerified && (
            <div>
              <Verified />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default NFTCollectionCard;
