import Discord from "components/icons/Discord"
import Facebook from "components/icons/Facebook"
import Instagram from "components/icons/Instagram"
import Telegram2 from "components/icons/Telegram2"
import Twitter from "components/icons/Twitter"

const FloatingSocial = (props) => {
    const { caption, active } = props
    return <>
        <div 
        className="h-[328px] 
        min-w-[50px] 
        max-w-[50px] 
        hidden 
        xl:flex 
        flex-col 
        justify-around 
        items-center 
        fixed 
        top-[264px]
        right-[77px]
        py-8 
        rounded-3xl 
        bg-lightbg1 
        gap-9
        z-[1]">
            <a target="_blank" href="https://www.instagram.com/wearevalores/"><Instagram className="fill-white cursor-pointer w-[25px] h-[25px]" /></a>
            <a target="_blank" href="https://discord.gg/2D3c7Ehjvk"><Discord className="fill-white cursor-pointer w-[25px] h-[25px]" /></a>
            <a target="_blank" href="https://twitter.com/wearevalores"><Twitter className="fill-white cursor-pointer w-[25px] h-[25px]" /></a>
            <a target="_blank" href="https://www.facebook.com/wearevalores"><Facebook className="fill-white cursor-pointer w-[25px] h-[25px]" /></a>
            <a target="_blank" href="https://t.me/wearevalores"><Telegram2 className="fill-white cursor-pointer w-[25px] h-[25px]" /></a>
        </div>
    </>
}

export default FloatingSocial
