import { Text18Bold } from "components/elements/Text";
import Close from "components/icons/Close";
import { $t } from "components/utils/Translation";

const PlaceBidModal = (props) => {
  const { visible, toggle } = props;
  let classNames = ` fixed -translate-y-1/2 -translate-x-1/2 top-1/2 left-1/2 z-20  `; // position
  classNames += ` w-[80%] h-[60%] sm:w-[600px] sm:h-[525px] `; // dimensions
  classNames += ` bg-black shadow-['0px 4px 4px rgba(0, 0, 0, 0.25)'] `; // colors
  classNames += ` flex flex-col gap-1 overflow-hidden rounded-xl `; // display

  if (!visible) return null;

  return (
    <>
      <div
        id="connect-wallet-overlay"
        className="fixed inset-0 bg-darkbg1 z-10"
        onClick={() => toggle(!visible)}
      />
      <div id="place-bid" className={classNames}>
        <div className="flex">
          <Text18Bold className="self-center">
            {$t("_PLACE_A_BID_", "Place a Bid")}
          </Text18Bold>
          <Close className="fill-white self-end" />
        </div>
      </div>
    </>
  );
};

export default PlaceBidModal;
