import {
  getSignatureTexts,
  registerUser,
  userLoginWithToken,
} from "api/apiCalls";
import WalletConnectionProviderModal from "components/blocks/walletConnectionProviderModal/WalletConnectionProviderModal";
import { customToast } from "components/utils/generic";
import { AppContext, HttpHeaderParam } from "contexts/AppContext";
import { useContext, useEffect } from "react";
import { useMoralis, useChain } from "react-moralis";
import { Flip, ToastContainer } from "react-toastify";
import ValoresRouter from "Routes";
import jwt_decode from "jwt-decode";
import { signLogin } from "common/function";
import MetaTags from "components/MetaTags";
import { ALLOWED_CHAINS } from "common/constants";

function App() {
  const { account, provider, enableWeb3, chainId } = useMoralis();
  const {
    setShowProviderPopup,
    signatureTexts,
    setsignatureTexts,
    setuserData,
    userData,
    langCode,
    setIsLoginReInitiate,
  } = useContext(AppContext);

  const setupSignatureTexts = async () => {
    const texts = await getSignatureTexts();
    setsignatureTexts(texts && texts.data ? texts.data : null);
  };

  const updateUserData = async (user, token) => {
    if (user?.id) {
      enableWeb3();
      setuserData(user);
      if (token) localStorage.setItem(HttpHeaderParam.BearerToken, token || "");
      setShowProviderPopup(false);
    } else {
      setuserData({});
    }
  };

  useEffect(() => {
    if (!account) {
      customToast(`No wallet is connected`, { toastId: "WALLET_DISCONNECTED" });
      return;
    }
    setShowProviderPopup(false);
    customToast(`Connected to ${account}`, { toastId: "WALLET_CONNECTED" });
    registerUser(account);
  }, [account, setShowProviderPopup]);

  useEffect(() => {
    if (!signatureTexts) {
      setupSignatureTexts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loginWithSign = async () => {
    const userResponse = await signLogin(
      account,
      signatureTexts,
      provider,
      userData
    );
    if (userResponse.statusCode > 201) {
      return customToast(userResponse.message, {
        toastId: "LOGIN_ERROR",
        type: "error",
      });
    }
    if (
      userResponse.data &&
      userResponse.data.user &&
      userResponse.data.user.id
    ) {
      localStorage.setItem(
        HttpHeaderParam.BearerToken,
        userResponse.data.token || ""
      );
      setIsLoginReInitiate((prev) => ({ ...prev, isSuccess: true }));
      updateUserData(userResponse.data.user, userResponse.data.token);
    }
  };

  async function connectWallet(chainId) {
    const token = localStorage.getItem(HttpHeaderParam.BearerToken);
    const decodedToken = token ? jwt_decode(token) : {};
    const walletAddress = decodedToken?.walletAddress || "";
    const storedChainId = decodedToken?.chainId || "";
    const isValidToken = decodedToken?.exp * 1000 > new Date().getTime();
    if (
      walletAddress &&
      walletAddress?.toLowerCase() === account?.toLowerCase() &&
      isValidToken &&
      storedChainId === chainId
    ) {
      const loginResponse = await userLoginWithToken();
      updateUserData(loginResponse?.data, token);
    } else {
      loginWithSign();
    }
  }
  const { switchNetwork } = useChain();

  useEffect(() => {
    localStorage.setItem(HttpHeaderParam.XChainId, chainId);
    if (account && provider && chainId) {
      const allowedChainIds = Object.keys(ALLOWED_CHAINS);
      if (!allowedChainIds.includes(chainId))
        return switchNetwork(allowedChainIds[0]);
      connectWallet(chainId);
      setShowProviderPopup(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, provider, chainId]);

  return (
    <>
      <MetaTags language={langCode} />
      <ValoresRouter />
      <ToastContainer
        position="top-left"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        draggable={false}
        pauseOnHover
        transition={Flip}
        // limit={3}
      />
      <WalletConnectionProviderModal />
    </>
  );
}

export default App;
