import ProfileDefaultIcon from "components/icons/ProfileDefaultIcon"
import ProfileIcon from "components/icons/ProfileIcon"
import Verified from "components/icons/Verified"
import { shortWalletAddress } from "components/utils/generic"
import { celebrityImages } from "components/utils/mockData"
import { useNavigate } from "react-router-dom"
import { Text12Regular, Text18Bold, Text24Black } from "./Text"

const ProfileCard = (props) => {
    const navigate = useNavigate()
    const {style, rank, walletBalance, chainUnit, widthClass, heightClass, profile} = props
    const imageUrl = !!profile && profile.profileImage && profile.profileImage.signedUrl ? profile.profileImage.signedUrl : ''
    const profileId = !!profile && profile.id ? profile.id : ''
    const userIsVerified = !!profile && !!profile.verfied
    const userName = !!profile 
        ? profile.profileName ? profile.profileName : shortWalletAddress(profile.walletAddress)
        : ''
    return !profile || !profile.id ? null : <>
        <div 
            style={style}
            className={`flex bg-dark2 justify-between rounded-3xl items-end p-3 md:p-[26px] ${heightClass || 'md:min-h-[103px] h-auto'} ${widthClass || 'w-[200px] sm:w-[255px] lg:w-[370px]'} `}
        >
            <div className="flex gap-2 md:gap-4 items-end">
                <div className="bg-no-repeat bg-cover bg-center bg-[#676F7D] flex justify-center items-center" 
                style={{backgroundImage: `url(${imageUrl})`, width:50, height: 50, borderRadius: 6}} 
                >{!imageUrl && <ProfileDefaultIcon/>}</div>
                <div className="flex flex-col">
                    <Text12Regular>{walletBalance} {chainUnit}</Text12Regular>
                    <div className="flex cursor-pointer" onClick={() => navigate(celebrityImages.includes(imageUrl) ? '' : `/profile/${profileId}`)}>
                        <Text18Bold style={{overflowWrap: 'anywhere'}}>{userName}</Text18Bold>
                        {!!userIsVerified && <Verified />}
                    </div>
                </div>
            </div>
            <Text24Black>#{rank}</Text24Black>

            

        </div>
    </>
}

export default ProfileCard