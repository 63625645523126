export const ValoresShortIcon = () => {
    return (
        <>
            <svg width="21" height="14" viewBox="0 0 21 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.9928 0L13.8486 2.16247L11.7452 6.16705L10.5 8.56979L9.23798 6.16705L7.13462 2.16247L5.99038 0H0L7.33654 14H13.6466L21 0H14.9928Z" fill="#00FF8B" />
            </svg>

        </>
    )
}
