import AuctionCard from "components/elements/AuctionCard";
import { ButtonTertiarySmall } from "components/elements/Button";
import IconButton from "components/elements/IconButton";
import NFTCollectionCard from "components/elements/NFTCollectionCard";
import ProfileCard from "components/elements/ProfileCard";
import { Text26Bold } from "components/elements/Text";
import ChevronLeft from "components/icons/ChevronLeft";
import ChevronRight from "components/icons/ChevronRight";
import { $t } from "components/utils/Translation";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import NFTCardNew from "components/elements/NFTCardNew";
import NFTBidCardNew from "components/elements/NFTBidCardNew";

const LoadingScrollableContent = ({ type }) => {
  switch (type.toUpperCase()) {
    case "NFT":
      return [1, 2, 3, 4]?.map((idx) => (
        <div
          key={idx}
          className="card-item min-w-[99%] sm:min-w-[49%] lg:min-w-[23.5%] xl:min-w-[24.2%]"
        >
          <NFTCardNew
            loading={true}
            wrapperStyle={{ scrollSnapAlign: "start" }}
          />
        </div>
      ));
    case "COLLECTIONS":
      return [1, 2, 3, 4]?.map((idx) => (
        <div
          key={idx}
          className="card-item min-w-[99%] sm:min-w-[48%] lg:min-w-[24%]"
        >
          <NFTCollectionCard
            loading={true}
            collectionItemClass={`w-[49%] pb-[49%]`}
            style={{ scrollSnapAlign: "start" }}
          />
        </div>
      ));
    case "BIDS":
      return [1, 2, 3, 4]?.map((idx) => (
        <div
          key={idx}
          className="card-item min-w-[99%] sm:min-w-[49%] lg:min-w-[23.1%] xl:min-w-[24.1%]"
        >
          <NFTBidCardNew
            loading={true}
            wrapperStyle={{ scrollSnapAlign: "start" }}
          />
        </div>
      ));
    case "LIVEAUCTION":
      return (
        <div className="min-w-[99%]">
          <AuctionCard
            loading={true}
            wrapperStyle={{ scrollSnapAlign: "start" }}
          />
        </div>
      );
    default:
      return null;
  }
};

const ScrollContent = ({ items, type, sellers }) => {
  if (!type) return;
  switch (type.toUpperCase()) {
    case "NFT":
      return items?.map((item, idx) => (
        <div
          key={idx}
          className="card-item min-w-[99%] sm:min-w-[49%] lg:min-w-[23.5%] xl:min-w-[24.2%]"
        >
          <NFTCardNew nft={item} wrapperStyle={{ scrollSnapAlign: "start" }} />
        </div>
      ));
    case "COLLECTIONS":
      return items?.map((item, idx) => (
        <div
          key={idx}
          className="card-item min-w-[99%] sm:min-w-[48%] lg:min-w-[24%]"
        >
          <NFTCollectionCard
            collection={item}
            collectionItemClass={`w-[49%] pb-[49%]`}
            style={{ scrollSnapAlign: "start" }}
            userName="Imapoki"
            userIsVerified={true}
          />
        </div>
      ));
    case "BIDS":
      return items?.map((item, idx) => (
        <div
          key={idx}
          className="card-item min-w-[99%] sm:min-w-[49%] lg:min-w-[23.1%] xl:min-w-[24.1%]"
        >
          <NFTBidCardNew
            bids={item}
            wrapperStyle={{ scrollSnapAlign: "start" }}
            nftImageUrl={item}
            userName={`Imapoki`}
            userIsVerified={true}
            name="Epizon Akatsuki Dance"
            price="0.12389121"
            priceUnit="ETH"
            likeCount="9.1K"
            isLikedByUser={true}
            bidStats={{
              count: "323",
              highestBid: "0.08",
              highestBidUnit: "ETH",
            }}
          />
        </div>
      ));
    case "PROFILES":
      let profilesForDisplay = [];
      let profilePool = [...sellers];
      const setCount = 6;
      // const noOfProfilesToDisplay = window.screen.width >= 768 ? profilesForDisplay.push(profilePool.splice(0, setCount)) : profilesForDisplay.push(profilePool.splice(0, setCountMobile))

      while (profilePool.length)
        profilesForDisplay.push(profilePool.splice(0, setCount));

      return profilesForDisplay.map((item, idx) => (
        <div key={idx} className="min-w-full flex flex-wrap content-start">
          {item.map((element, index) => (
            <ProfileCard
              profile={element}
              key={index}
              widthClass="min-w-[99%] sm:min-w-[49%] lg:min-w-[32%]"
              heightClass="max-h-[103px] h-auto"
              style={{
                scrollSnapAlign: "start",
                marginRight: "1%",
                marginTop: "1%",
              }}
              rank={index + idx * setCount + 1}
              userName="Imapoki "
              userIsVerified={true}
              imageUrl={element}
              walletBalance="1.23"
              chainUnit="ETH"
            />
          ))}
        </div>
      ));

    case "LIVEAUCTION":
      const nftsWithValidSales = items.filter((nft) =>
        nft.sales.some((sale) => new Date(sale.expiry) > new Date())
      );

      return nftsWithValidSales.map((item, idx) => (
        <div key={idx} className="min-w-[99%]">
          <AuctionCard
            nftInfo={item}
            wrapperStyle={{ scrollSnapAlign: "start" }}
          />
        </div>
      ));
    default:
      return null;
  }
};

const Trending = (props) => {
  const {
    type,
    caption,
    buttonText,
    leftIcon,
    rightIcon,
    isArtist,
    items,
    sellers,
    buyers,
    isLoading,
    ...rest
  } = props;

  const cardsContainer = useRef(null);
  const navigate = useNavigate();
  const prev = () => {
    if (!cardsContainer || !cardsContainer.current) return;
    cardsContainer.current.scrollBy({
      top: 0,
      left: -1 * cardsContainer.current.clientWidth,
      behavior: "smooth",
    });
  };
  const next = () => {
    if (!cardsContainer || !cardsContainer.current) return;
    cardsContainer.current.scrollBy({
      top: 0,
      left: cardsContainer.current.clientWidth,
      behavior: "smooth",
    });
  };

  const NavArrows = () => {
    return (
      <div className="flex justify-between gap-4">
        <IconButton
          onClick={prev}
          className="w-[48px] h-[48px] rounded-full flex items-center justify-center"
          icon={leftIcon || <ChevronLeft className="fill-teritary2" />}
        />
        <IconButton
          onClick={next}
          className="w-[48px] h-[48px] rounded-full flex items-center justify-center"
          icon={rightIcon || <ChevronRight className="fill-teritary2" />}
        />
      </div>
    );
  };

  const showHomePageSection = () => {
    if (type === "LIVEAUCTION") {
      return (
        items.filter((nft) =>
          nft.sales.some((sale) => new Date(sale.expiry) > new Date())
        ).length || isLoading
      );
    }
    return items?.length || isLoading;
  };

  return (
    <>
      {showHomePageSection() ? (
        <div {...rest} className="flex flex-col flex-1 gap-12">
          <div
            id="trending-nfts-header"
            className="flex flex-col sm:flex-row items-center justify-between gap-8"
          >
            <div
              className="md:hidden self-center md:self-start sm:self-center cursor-pointer lg:cursor-none"
              onClick={() => navigate("/explore")}
            >
              {!!caption ? (
                caption
              ) : (
                <Text26Bold className="capitalize">Trending</Text26Bold>
              )}
            </div>
            <div className="hidden md:flex self-center md:self-start sm:self-center">
              {!!caption ? (
                caption
              ) : (
                <Text26Bold className="capitalize">Trending</Text26Bold>
              )}
            </div>
            <div className="hidden md:flex flex-row flex-wrap gap-4 w-full sm:w-auto justify-between items-center">
              <ButtonTertiarySmall
                textClass={"text-teritary2 hover:text-teritary1"}
                className="h-[36px]"
                onClick={() =>
                  navigate(
                    `${
                      type.toUpperCase() === "COLLECTIONS"
                        ? "/collection"
                        : type.toUpperCase() === "PROFILES"
                        ? "/topSellers"
                        : "/explore"
                    }`
                  )
                }
                text={buttonText || $t("_MORE_", "More")}
              />
              {!isLoading && <NavArrows />}
            </div>
          </div>
          <div
            ref={cardsContainer}
            id="trending-nfts-cards"
            className="flex Indicators overflow-x-scroll gap-[3%] sm:gap-[2%] md:gap-[2%] lg:gap-[2%] xl:gap-[1%] pb-8 scrollable-container"
            style={{ scrollSnapType: "x mandatory" }}
          >
            {isLoading ? (
              <LoadingScrollableContent {...{ type }} />
            ) : (
              <ScrollContent {...{ items, type, sellers }} />
            )}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Trending;
