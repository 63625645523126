const ChevronLeft = (props) => {
    const { style, className } = props
    

    return (
        <svg style={style} className={className} width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0.910774 0.410749C1.23621 0.0853125 1.76385 0.0853125 2.08929 0.410749L7.08929 5.41075C7.41472 5.73619 7.41472 6.26382 7.08929 6.58926L2.08929 11.5893C1.76385 11.9147 1.23621 11.9147 0.910774 11.5893C0.585337 11.2638 0.585337 10.7362 0.910774 10.4108L5.32152 6.00001L0.910774 1.58926C0.585337 1.26382 0.585337 0.736186 0.910774 0.410749Z"   />
        </svg>
    )
}

export default ChevronLeft
