const RemoveIcon = (props) => {
    const {className} = props
    return (
        <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0007 2.50016C5.85851 2.50016 2.50065 5.85803 2.50065 10.0002C2.50065 14.1423 5.85851 17.5002 10.0007 17.5002C14.1428 17.5002 17.5006 14.1423 17.5006 10.0002C17.5006 5.85803 14.1428 2.50016 10.0007 2.50016ZM0.833984 10.0002C0.833984 4.93755 4.93804 0.833496 10.0007 0.833496C15.0633 0.833496 19.1673 4.93755 19.1673 10.0002C19.1673 15.0628 15.0633 19.1668 10.0007 19.1668C4.93804 19.1668 0.833984 15.0628 0.833984 10.0002Z" fill="#707274" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0886 6.91058C13.414 7.23602 13.414 7.76366 13.0886 8.08909L8.0886 13.0891C7.76317 13.4145 7.23553 13.4145 6.91009 13.0891C6.58466 12.7637 6.58466 12.236 6.91009 11.9106L11.9101 6.91058C12.2355 6.58514 12.7632 6.58514 13.0886 6.91058Z" fill="#707274" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.91009 6.91058C7.23553 6.58514 7.76317 6.58514 8.0886 6.91058L13.0886 11.9106C13.414 12.236 13.414 12.7637 13.0886 13.0891C12.7632 13.4145 12.2355 13.4145 11.9101 13.0891L6.91009 8.08909C6.58466 7.76366 6.58466 7.23602 6.91009 6.91058Z" fill="#707274" />
        </svg>

) 
}

export default RemoveIcon