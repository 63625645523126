const Filter = (props) => {
    const { style, className } = props

    return <>
        <svg style={style} className={className} width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0.910703 1.14926C1.04725 0.854955 1.3422 0.666656 1.66663 0.666656H18.3333C18.6577 0.666656 18.9527 0.854955 19.0892 1.14926C19.2258 1.44356 19.1791 1.79036 18.9696 2.03809L12.5 9.68845V16.5C12.5 16.7888 12.3504 17.057 12.1047 17.2089C11.8591 17.3607 11.5523 17.3745 11.294 17.2453L7.96062 15.5787C7.6783 15.4375 7.49997 15.149 7.49997 14.8333V9.68845L1.03033 2.03809C0.820828 1.79036 0.774155 1.44356 0.910703 1.14926ZM3.46272 2.33332L8.96961 8.84522C9.09683 8.99566 9.16663 9.1863 9.16663 9.38332V14.3183L10.8333 15.1516V9.38332C10.8333 9.1863 10.9031 8.99566 11.0303 8.84522L16.5372 2.33332H3.46272Z" fill="#161719" />
        </svg>
    </>
}

export default Filter
