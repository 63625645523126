// import React f
import { Grid } from "@mui/material";
import { CaptionWhite } from "components/elements/Caption";
import SearchField from "components/elements/SearchField";
import { Text14Regular } from "components/elements/Text";
import { SampleUrls } from "components/utils/mockData";
import { $t } from "components/utils/Translation";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { AppContext } from "contexts/AppContext";
import { useContext } from "react";
import NFTCollectionCardNew from "components/elements/NFTCollectionCardNew";

export const emptyFilter = {
    categories: [],
    chains: [],
    saleType: [],
    collection: "",
    sortBy: "",
    minPrice: 0,
    maxPrice: 0,
    createdBy: 0, // id
    ownedBy: 0, // id
    likedBy: 0, // id
};
const Collection = (props) => {
    const { collections } = useContext(AppContext);
    const [autoSearch, setautoSearch] = useState(true);
      const nftCategory = [
        "Trending",
        "Top",
        "Art",
        "Collectibles",
        "Domain Names",
        "Music",
        "Photography",
        "Sports",
        "Trading Cards",
        "Utility",
        "Virtual Worlds",
      ];
    const [activeCategory, setActiveCategory] = useState("Trending")
    return (
        <>
            <div className="pt-[98px] flex flex-col w-full">
                <div className="flex justify-center md:justify-between items-center mb-5 md:mb-3">
                <CaptionWhite className="opacity-95 uppercase font-bold text-[26px]">
                    {$t("_COLLECTIONS_", "Collections")}
                </CaptionWhite>
                <div className="hidden md:block w-[350px]">
                    <SearchField
                        placeholder="Search by NFT name / collection / address"
                        iconClass="fill-white2"
                        auto={autoSearch}
                        className="flex-1"
                        // onClick={(response) => handleSearchResponse(response)}
                    />
                    </div>
                </div>
                <div className='flex justify-center md:justify-start'>
                    <Text14Regular className="uppercase">
                        {collections.length}{collections.length <= 1 ? ' Result' : ' Results'}
                    </Text14Regular>
                   
                </div>

                <div className="hidden xl:flex justify-around w-full py-2 mt-8 px-3">
                    {nftCategory.map((category) => {
                        return (
                            <div className={`flex text-sm font-notoSans font-bold px-3 py-2 whitespace-nowrap ${activeCategory === category ? `bg-primary2 hover:bg-primary1 text-black rounded-2xl flex justify-center items-center` : ' text-[#E3E3E3]'}`} onClick={() => setActiveCategory(category)}>
                                {category}
                            </div>
                        )
                    })}
                </div>
                <div className="xl:hidden flex flex-nowrap w-full overflow-scroll py-2 mt-8">
                    {nftCategory.map((category) => {
                        return (
                            <div className={`flex text-sm font-notoSans font-bold px-4 py-2  whitespace-nowrap ${activeCategory === category ? `bg-primary2 text-black rounded-2xl flex justify-center items-center` : ' text-[#E3E3E3]'}`} onClick={() => setActiveCategory(category)}>
                                {category}
                            </div>
                        )
                    })}
                </div>
                <div className="flex item-center justify-center flex- mt-16 w-full gap-10 relative">
                    <div
                        id="content"
                        className="flex flex-col md:w-full gap-[1.25rem]"
                    >
                        <Grid   
                            container
                            columnSpacing={''}
                            className="overflow-y-scroll scrollable-container"
                            style={{ maxHeight: 1440 }}
                        >
                            {collections?.map((item, id) => {
                                return (
                                    <div key={id}><NFTCollectionCardNew collection={item}
                                    collectionItemClass={`w-[48%] pb-[49%]`}
                                    style={{ scrollSnapAlign: "start" }}
                                    collectionImages={SampleUrls}
                                    userName="Imapoki"
                                    userIsVerified={true} /></div>
                            )}) }
                        </Grid>
                    </div>
                </div>
                <div className="hidden md:flex justify-center mt-6 cursor-pointer"><Text14Regular className='text-primary2'>{$t('_LOAD_MORE_', 'Load More')}</Text14Regular></div>
            </div>
        </>
    );
};

export default Collection;
