import Close from "components/icons/Close"
import { useEffect, useState } from "react"
import { Text12Light, Text16Light, Text24Bold } from "./Text"

const InfoModal = (props) => {
    const {visible, toggle, caption, content, captionClassNames} = props
    let classNames = ` fixed -translate-y-1/2 -translate-x-1/2 top-1/2 left-1/2 z-20 ` // position
    classNames += ` w-[80%] h-[60%] sm:w-[600px] sm:h-[225px] ` // dimensions
    classNames += ` bg-black shadow-['0px 4px 4px rgba(0, 0, 0, 0.25)'] ` // colors
    classNames += ` flex flex-col gap-1 overflow-hidden rounded-xl ` // display
    classNames += ` items-center justify-center p-8 ` // extras

    const[show, setShow] = useState(!!visible)

    useEffect(() => {
        setShow(visible)
    }, [visible])
    
    return !show ? null : <>
        <div id="information-overlay" className="fixed inset-0 bg-darkbg1 z-10" onClick={() => toggle(!show)} />
        <div id="information" className={classNames}>
            <Close className="fill-white cursor-pointer absolute top-4 right-4 " onClick={() => toggle(!show)}/>
            {
                !!caption && <Text24Bold className={`text-grey2 underline ${captionClassNames || ''}`}>{caption}</Text24Bold>
            }
            {
                typeof content === 'string' 
                ?   <Text16Light className="text-white">{content}</Text16Light>
                :   content 
            }
        </div>
    </>
}

export default InfoModal
