const BarChart = (props) => {
    const { style, className } = props

    return (
        <svg style={style} className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M15 7.5C15.4603 7.5 15.8334 7.8731 15.8334 8.33333V16.6667C15.8334 17.1269 15.4603 17.5 15 17.5C14.5398 17.5 14.1667 17.1269 14.1667 16.6667V8.33333C14.1667 7.8731 14.5398 7.5 15 7.5Z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M10 2.5C10.4603 2.5 10.8334 2.8731 10.8334 3.33333V16.6667C10.8334 17.1269 10.4603 17.5 10 17.5C9.5398 17.5 9.1667 17.1269 9.1667 16.6667V3.33333C9.1667 2.8731 9.5398 2.5 10 2.5Z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M5.00004 10.8333C5.46027 10.8333 5.83337 11.2064 5.83337 11.6667V16.6667C5.83337 17.1269 5.46027 17.5 5.00004 17.5C4.5398 17.5 4.1667 17.1269 4.1667 16.6667V11.6667C4.1667 11.2064 4.5398 10.8333 5.00004 10.8333Z" />
        </svg>
    )
}

export default BarChart
