import Translate from "components/utils/Translation"

const Text = (props) => {
    const {onClick, style, className, translationKey, fallbackText, children, alt} = props
    const classes = `font-notoSans tracking-wide ${className || ''}`

    return (
      <span onClick={onClick} style={style} className={classes} title={alt}>
        <Translate translationKey={translationKey}>{fallbackText || children}</Translate>
      </span>
    )
}

export default Text

export const Text12Regular = (props) => <Text {...props} className={`text-[12px] font-normal ${setFontColor(props.className || '')}`} />
export const Text12Medium = (props) => <Text {...props} className={`text-[12px] font-medium ${setFontColor(props.className || '')}`} />
export const Text12Light = (props) => <Text {...props} className={`text-[12px] font-light ${setFontColor(props.className || '')}`} />
export const Text12Bold = (props) => <Text {...props} className={`text-[12px] font-bold ${setFontColor(props.className || '')}`} />
export const Text12Black = (props) => <Text {...props} className={`text-[12px] font-black ${setFontColor(props.className || '')}`} />

export const Text14Regular = (props) => <Text {...props} className={`text-[14px] leading-[21px] font-normal ${setFontColor(props.className || '')}`} />
export const Text14Medium = (props) => <Text {...props} className={`text-[14px] leading-[21px] font-medium ${setFontColor(props.className || '')}`} />
export const Text14Light = (props) => <Text {...props} className={`text-[14px] leading-[21px] font-light ${setFontColor(props.className || '')}`} />
export const Text14Bold = (props) => <Text {...props} className={`text-[14px] leading-[21px] font-bold ${setFontColor(props.className || '')}`} />
export const Text14Black = (props) => <Text {...props} className={`text-[14px] leading-[21px] font-black ${setFontColor(props.className || '')}`} />

export const Text16Regular = (props) => <Text {...props} className={`text-[16px] font-normal ${setFontColor(props.className || '')}`} />
export const Text16Medium = (props) => <Text {...props} className={`text-[16px] font-medium ${setFontColor(props.className || '')}`} />
export const Text16Light = (props) => <Text {...props} className={`text-[16px] font-light ${setFontColor(props.className || '')}`} />
export const Text16Bold = (props) => <Text {...props} className={`text-[16px] font-bold ${setFontColor(props.className || '')}`} />
export const Text16Black = (props) => <Text {...props} className={`text-[16px] font-black ${setFontColor(props.className || '')}`} />

export const Text18Regular = (props) => <Text {...props} className={`text-[18px] leading-[26px] font-normal ${setFontColor(props.className || '')}`} />
export const Text18Medium = (props) => <Text {...props} className={`text-[18px] leading-[26px] font-medium ${setFontColor(props.className || '')}`} />
export const Text18Light = (props) => <Text {...props} className={`text-[18px] leading-[26px] font-light ${setFontColor(props.className || '')}`} />
export const Text18Bold = (props) => <Text {...props} className={`text-[18px] leading-[26px] font-bold ${setFontColor(props.className || '')}`} />
export const Text18Black = (props) => <Text {...props} className={`text-[18px] leading-[26px] font-black ${setFontColor(props.className || '')}`} />

export const Text24Regular = (props) => <Text {...props} className={`text-[24px] font-normal ${setFontColor(props.className || '')}`} />
export const Text24Medium = (props) => <Text {...props} className={`text-[24px] font-medium ${setFontColor(props.className || '')}`} />
export const Text24Light = (props) => <Text {...props} className={`text-[24px] font-light ${setFontColor(props.className || '')}`} />
export const Text24Bold = (props) => <Text {...props} className={`text-[24px] font-bold ${setFontColor(props.className || '')}`} />
export const Text24Black = (props) => <Text {...props} className={`text-[24px] font-black ${setFontColor(props.className || '')}`} />

export const Text26Regular = (props) => <Text {...props} className={`text-[26px] font-normal ${setFontColor(props.className || '')}`} />
export const Text26Medium = (props) => <Text {...props} className={`text-[26px] font-medium ${setFontColor(props.className || '')}`} />
export const Text26Light = (props) => <Text {...props} className={`text-[26px] font-light ${setFontColor(props.className || '')}`} />
export const Text26Bold = (props) => <Text {...props} className={`text-[26px] font-bold ${setFontColor(props.className || '')}`} />
export const Text26Black = (props) => <Text {...props} className={`text-[26px] font-black ${setFontColor(props.className || '')}`} />

export const Text36Regular = (props) => <Text {...props} className={`text-[36px] font-normal ${setFontColor(props.className || '')}`} />
export const Text36Medium = (props) => <Text {...props} className={`text-[36px] font-medium ${setFontColor(props.className || '')}`} />
export const Text36Light = (props) => <Text {...props} className={`text-[36px] font-light ${setFontColor(props.className || '')}`} />
export const Text36Bold = (props) => <Text {...props} className={`text-[36px] font-bold ${setFontColor(props.className || '')}`} />
export const Text36Black = (props) => <Text {...props} className={`text-[36px] font-black ${setFontColor(props.className || '')}`} />

export const Text48Regular = (props) => <Text {...props} className={`text-[48px] font-normal ${setFontColor(props.className || '')}`} />
export const Text48Medium = (props) => <Text {...props} className={`text-[48px] font-medium ${setFontColor(props.className || '')}`} />
export const Text48Light = (props) => <Text {...props} className={`text-[48px] font-light ${setFontColor(props.className || '')}`} />
export const Text48Bold = (props) => <Text {...props} className={`text-[48px] font-bold ${setFontColor(props.className || '')}`} />
export const Text48Black = (props) => <Text {...props} className={`text-[48px] font-black ${setFontColor(props.className || '')}`} />



const setFontColor = (classString = '') => classString.includes('text-') ? classString : `${classString} text-white`