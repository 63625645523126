// import React f
import { Drawer, Grid } from "@mui/material";
import { getNfts } from "api/apiCalls";
import { ALLOWED_CHAINS } from "common/constants";
import { CaptionWhite } from "components/elements/Caption";
import { CheckboxFilter, RangeFilter } from "components/elements/Filter";
import IconButton from "components/elements/IconButton";
import NFTCardNew from "components/elements/NFTCardNew";
import { NoDataFound } from "components/elements/NoDataFound";
import SearchField from "components/elements/SearchField";
import Select from "components/elements/Select";
import { Text18Bold } from "components/elements/Text";
import Close from "components/icons/Close";
import Filter from "components/icons/Filter";
import Sort from "components/icons/Sort";
import { $t } from "components/utils/Translation";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const commonEmptyFilter = {
  categories: [],
  chains: [],
  saleType: [],
  collection: "",
  sortBy: "",
  minPrice: 0,
  maxPrice: 0,
  createdBy: 0, // id
  ownedBy: 0, // id
  likedBy: 0, // id
};

const Explore = (props) => {
  const emptyFilter = {
    categories: [],
    chains: [],
    saleType: [],
    collection: "",
    sortBy: "",
    minPrice: 0,
    maxPrice: 0,
    createdBy: 0, // id
    ownedBy: 0, // id
    likedBy: 0, // id
  };
  const location = useLocation();
  const queryString = new URLSearchParams(location.search);
  const searchKeyword = queryString.get("searchKeyword");

  const [priceRangeFilterValue, setpriceRangeFilterValue] = useState([0, 50]);
  const [showMobileFilters, setshowMobileFilters] = useState(false);
  const [nfts, setnfts] = useState([]);
  const [filter, setfilter] = useState(emptyFilter);
  const [isLoading, setIsLoading] = useState(false);
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setshowMobileFilters(open);
  };

  const filterByPriceRange = (e) => {
    setpriceRangeFilterValue(e.target.value);
    let currentFilter = filter;
    currentFilter.minPrice = e.target.value[0];
    currentFilter.maxPrice = e.target.value[1];
    setfilter({ ...currentFilter });
  };

  const handleSearchResponse = (response) => {
    if (!response || !response.length) {
      setnfts([]);
      return;
    }
    setnfts(response);
  };

  const fetchNfts = async () => {
    if (!!searchKeyword) return;
    setIsLoading(true);
    const getNftsResponse = await getNfts(filter);
    if (getNftsResponse && getNftsResponse.data && getNftsResponse.data.list) {
      setnfts(getNftsResponse.data.list);
    }
    setIsLoading(false);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const updateChainsFilter = (option) => {
    const currentFilter = filter;
    currentFilter.chains = addOrRemoveFilter(
      currentFilter.chains,
      option.value
    );
    setfilter({ ...currentFilter });
  };

  const updateSortBy = (option) => {
    const currentFilter = filter;
    currentFilter.sortBy = option.value;
    setfilter({ ...currentFilter });
  };

  const updateSaleTypeFilter = (option) => {
    let currentFilter = filter;
    currentFilter.saleType = addOrRemoveFilter(
      currentFilter.saleType,
      option.value
    );
    setfilter({ ...currentFilter });
  };

  const addOrRemoveFilter = (filterArray, option) => {
    if (!filterArray.includes(option)) {
      filterArray.push(option);
      filterArray = [...new Set(filterArray)];
      return filterArray;
    }
    const saleTypeIndex = filterArray.findIndex(
      (saleType) => saleType == option
    );
    filterArray.splice(saleTypeIndex, 1);
    filterArray = [...new Set(filterArray)];
    return filterArray;
  };

  useEffect(() => {
    fetchNfts();
  }, [
    JSON.stringify(filter),
    filter.sortBy,
    filter.chains,
    filter.minPrice,
    filter.maxPrice,
    filter.saleType,
  ]);

  const FilterSection = (props) => (
    <div className={props.className}>
      <div className="flex items-center min-h-[48px] justify-between">
        <Text18Bold>{$t("_FILTER_BY_", "Filter By")}</Text18Bold>
        {props.closeIcon && (
          <div onClick={props.closeCallback}>{props.closeIcon}</div>
        )}
      </div>
      <RangeFilter
        caption={$t("_PRICE_RANGE_", "Price Range")}
        prefix="$"
        unit="USD"
        value={priceRangeFilterValue}
        onChange={filterByPriceRange}
      />
      <CheckboxFilter
        className="lg:min-h-[190px] max-h-[400px]"
        caption={$t("_CATEGORY_", "Category")}
        options={[
          `${$t("_TRENDING_", "trending")}`,
          `${$t("_TOP_", "top")}`,
          `${$t("_ART_", "art")}`,
          `${$t("_COLLECTIBLES_", "collectibles")}`,
          `${$t("_DOMAIN_NAMES_", "domain names")}`,
          `${$t("_MUSIC_", "music")}`,
          `${$t("_PHOTOGRAGHY_", "photography")}`,
          `${$t("_TRENDING_CARDS_", "trending cards")}`,
          `${$t("_SPORTS_", "Sports")}`,
          `${$t("_VIDEOS_", "videos")}`,
          `${$t("_POSTER_", "Poster")}`,
        ]}
      />
      <CheckboxFilter
        caption={$t("_BLOCKCHAIN_", "Blockchain")}
        onChange={(e) => updateChainsFilter(e.target)}
        selectedItems={filter.chains}
        options={Object.values(ALLOWED_CHAINS).map((chainInfo) => ({
          ...chainInfo,
          value: chainInfo.chainId,
        }))}
      />
      <CheckboxFilter
        className="lg:min-h-[190px] max-h-[270px]"
        caption="Sale Type"
        selectedItems={filter.saleType}
        onChange={(e) => updateSaleTypeFilter(e.target)}
        options={[
          {
            name: `${$t("_TIME_BASED_AUCTION_", "time based aution")}`,
            value: "FOR_SALE",
          },
          { name: `${$t("_HAS_OFFERS_", "has offers")}`, value: "HAS_OFFERS" },
        ]}
      />
    </div>
  );

  return (
    <>
      <div className="pt-[98px] flex flex-col w-full">
        <CaptionWhite className="mb-8 opacity-95 uppercase font-bold text-[26px]">
          {$t("_EXPLORE_COLLECTIONS_", "Explore collections")}
        </CaptionWhite>
        <div className="flex flex-1 mt-16 w-full gap-10 relative">
          <div
            id="filters"
            className="hidden lg:flex flex-col w-0 lg:w-1/4 gap-5"
          >
            <FilterSection className="flex flex-col gap-5" />
          </div>
          <div
            id="content"
            className="flex flex-col w-full lg:w-3/4 gap-[1.25rem]"
          >
            <div
              id="search-and-sort-fields"
              className="flex flex-row items-center justify-between"
            >
              <div className="flex flex-1 gap-4 lg:flex-[0.74] mb-8 lg:mb-0 items-center">
                <SearchField
                  placeholder="Search by NFT name / collection / address"
                  iconClass="fill-white2"
                  setIsLoading={setIsLoading}
                  className="flex-1"
                  onClick={(response) => handleSearchResponse(response)}
                />
                <IconButton
                  className="flex lg:hidden p-2 rounded-full bg-primary2 hover:bg-primary1 border-primary2 hover:border-primary1 min-h-[30px] min-w-[30px] items-center justify-center"
                  icon={<Filter className="fill-black" />}
                  onClick={toggleDrawer(true)}
                />
                <IconButton
                  className="flex lg:hidden p-2 rounded-full bg-primary2 hover:bg-primary1 border-primary2 hover:border-primary1 min-h-[30px] min-w-[30px] items-center justify-center"
                  icon={<Sort className="fill-black" />}
                />
              </div>
              <div className="hidden lg:flex">
                <Select
                  caption={$t("_SORT_BY_", "Sort By")}
                  onSelect={updateSortBy}
                  options={[
                    {
                      name: `${$t("_RECENTLY_ADDED_", "Recently Added")}`,
                      value: "RECENTLY_ADDED",
                    },
                    {
                      name: `${$t("_PRICE_LOW_TO_HIGH_", "Price Low to High")}`,
                      value: "LOW_PRICE_TO_HIGH_PRICE",
                    },
                    {
                      name: `${$t("_PRICE_HIGH_TO_LOW_", "Price High to Low")}`,
                      value: "HIGH_PRICE_TO_LOW_PRICE",
                    },
                    {
                      name: `${$t("_AUCTION_ENDING_", "Auction Ending")}`,
                      value: "AUCTION_ENDING",
                    },
                    {
                      name: `${$t("_VERIFIED_ONLY_", "Verified Only")}`,
                      value: "VERIFIED_ONLY",
                    },
                    {
                      name: `${$t("_SHOW_NSFW_", "Show NSFW")}`,
                      value: "NSFW",
                    },
                  ]}
                />
              </div>
            </div>

            <Grid
              container
              columnSpacing={4}
              className="overflow-y-scroll scrollable-container"
              // style={{ scrollSnapType: 'y mandatory', overscrollBehaviorY: 'contain', maxHeight: 1440 }} >
              style={{ maxHeight: 1440 }}
            >
              {isLoading ? (
                <>
                  {[1, 2, 3, 4, 5, 6, 7, 8]?.map((idx) => (
                    <Grid key={idx} item lg={4} xs={12}>
                      <NFTCardNew loading={true} />
                    </Grid>
                  ))}
                </>
              ) : (
                <>
                  {!nfts.length ? (
                    <div className="px-8">
                      <NoDataFound resultText={nfts.length} />
                    </div>
                  ) : (
                    nfts.map((item, idx) => (
                      <Grid key={idx} item lg={4} xs={12}>
                        <NFTCardNew nft={item} />
                      </Grid>
                    ))
                  )}
                </>
              )}
            </Grid>
          </div>
          {/* <ButtonPrimaryLarge onClick={toggleDrawer(true)} text="Filter" className="flex lg:hidden fixed left-8 right-8 top-[calc(100vh-50px)]"/> */}
        </div>
      </div>

      <Drawer
        anchor={"right"}
        open={showMobileFilters}
        onClose={toggleDrawer(false)}
        PaperProps={{
          style: { width: "100%", maxWidth: 500 },
          className: "!bg-baseDark",
        }}
      >
        <div className="px-8 py-10">
          <FilterSection
            className="flex flex-col gap-5"
            closeIcon={<Close className="fill-white3" />}
            closeCallback={toggleDrawer(false)}
          />
        </div>
      </Drawer>
    </>
  );
};

export default Explore;
