import React from "react";

export const AlertBox = () => {

    const ok = () => {
        document.getElementById('dialogbox').style.display = "none";
        document.getElementById('dialogoverlay').style.display = "none";
    }
    return (
        <div id="dialogoverlay">
            <div id="dialogbox">
                <div id="dialogboxbody" class="text-base font-notoSans text-left">Thank you for subscribing to our newsletter!</div>
                <div onClick={ok} id="dialogboxbutton" className="flex justify-center items-center cursor-pointer">Ok</div>
            </div>
        </div>
    )
}