const Bell = (props) => {
    const { style, className } = props

    return (
        <svg style={style} className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M5.8756 2.54204C6.96956 1.44808 8.45329 0.833496 10.0004 0.833496C11.5475 0.833496 13.0312 1.44808 14.1252 2.54204C15.2191 3.636 15.8337 5.11973 15.8337 6.66683C15.8337 9.46071 16.4313 11.1957 16.982 12.2053C17.2585 12.7122 17.5277 13.0447 17.7147 13.2421C17.8085 13.3411 17.8821 13.4067 17.9265 13.4436C17.9486 13.4621 17.9635 13.4735 17.9699 13.4782C17.9709 13.479 17.9717 13.4796 17.9723 13.48C18.2711 13.6852 18.4033 14.0606 18.298 14.4083C18.1916 14.7598 17.8676 15.0002 17.5004 15.0002H2.50039C2.13317 15.0002 1.80923 14.7598 1.70281 14.4083C1.59753 14.0606 1.72973 13.6852 2.02847 13.48C2.02905 13.4796 2.02985 13.479 2.03085 13.4782C2.03726 13.4735 2.05214 13.4621 2.07433 13.4436C2.11867 13.4067 2.19233 13.3411 2.28605 13.2421C2.47308 13.0447 2.74231 12.7122 3.01881 12.2053C3.5695 11.1957 4.16706 9.46071 4.16706 6.66683C4.16706 5.11973 4.78164 3.636 5.8756 2.54204ZM2.03689 13.4743C2.03695 13.4742 2.03702 13.4742 2.03709 13.4742C2.03708 13.4742 2.03708 13.4742 2.03707 13.4742L2.03689 13.4743ZM4.29149 13.3335H15.7093C15.6459 13.2296 15.5822 13.1196 15.5188 13.0034C14.8195 11.7213 14.1671 9.70628 14.1671 6.66683C14.1671 5.56176 13.7281 4.50195 12.9467 3.72055C12.1653 2.93915 11.1055 2.50016 10.0004 2.50016C8.89532 2.50016 7.83551 2.93915 7.05411 3.72055C6.27271 4.50195 5.83372 5.56176 5.83372 6.66683C5.83372 9.70628 5.18128 11.7213 4.48197 13.0034C4.41855 13.1196 4.3549 13.2296 4.29149 13.3335Z" fill="#E3E3E3"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M8.13994 16.7791C8.53804 16.5482 9.04798 16.6837 9.27891 17.0818C9.35217 17.2081 9.45731 17.3129 9.58382 17.3858C9.71033 17.4587 9.85375 17.497 9.99975 17.497C10.1457 17.497 10.2892 17.4587 10.4157 17.3858C10.5422 17.3129 10.6473 17.2081 10.7206 17.0818C10.9515 16.6837 11.4615 16.5482 11.8596 16.7791C12.2577 17.0101 12.3932 17.52 12.1622 17.9181C11.9425 18.297 11.6271 18.6114 11.2475 18.83C10.868 19.0486 10.4377 19.1637 9.99975 19.1637C9.56177 19.1637 9.13149 19.0486 8.75196 18.83C8.37244 18.6114 8.05701 18.297 7.83725 17.9181C7.60631 17.52 7.74183 17.0101 8.13994 16.7791Z" fill="#E3E3E3"/>
        </svg>
        
    )
}

export default Bell
