import { AppContext } from "contexts/AppContext";
import React, { useContext } from "react";
import { useMoralis } from "react-moralis";
import { Navigate } from "react-router-dom";

const PrivateRouter = ({ children, redirectUrl, role }) => {
  const { account } = useMoralis();
  const { userData } = useContext(AppContext);
  return account && userData?.role === role ? (
    children
  ) : (
    <Navigate to={redirectUrl} />
  );
};

export default PrivateRouter;
