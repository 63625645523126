import Verified from "components/icons/Verified";
import { Text12Regular } from "components/elements/Text";

const NFTCollectionCardNew = (props) => {
  const {
    collection,
    style,
    className,
    userIsVerified,
    collectionWrapClass,
    collectionItemClass,
  } = props;
  const nftImages = collection?.nfts?.map((item) =>
    !!item.secondaryMediaS3Key && item.secondaryMediaS3Key.signedUrl
      ? item.secondaryMediaS3Key.signedUrl
      : null
  );
  const collectionImagesForDisplay = [...nftImages]
    .sort(() => 0.5 - Math.random())
    .slice(0, 4);
  const defaultDescription =
    "Korea's most renowned virtual artist, APOKI. With her unique concept, music and performances that only she can pull off, APOKI is...";
  return !collection || !collection.id ? null : (
    <>
      <div
        className={`flex flex-col gap-2 w-full max-w-[350px] max-h-[479px] ${
          className || ""
        }`}
        style={style}
      >
        <div
          id="collection-background"
          className={`bg-[#26282B] py-3 px-[14px] w-full rounded-xl ${
            collectionWrapClass || ""
          }`}
        >
          <div id="collection-foreground" className="flex flex-wrap gap-1.5">
            {collectionImagesForDisplay.map((item, idx) => (
              <div
                key={idx}
                style={{ backgroundImage: `url(${item})` }}
                className={`rounded-[25px] bg-no-repeat bg-cover bg-center bg-baseDark ${
                  collectionItemClass ||
                  "w-[84px] h-[84px] xs2:w-[120px] xs2:h-[120px]"
                }`}
              />
            ))}
          </div>
          <div className="flex flex-col items-start justify-center mt-2">
            <div
              id="collection-info"
              className="flex items-center justify-center md:justify-start"
            >
              {!!collection.name && (
                <div>
                  <Text12Regular>
                    <span className="capitalize">{collection.name}</span>
                  </Text12Regular>
                </div>
              )}
              {!!userIsVerified && (
                <div>
                  <Verified />
                </div>
              )}
            </div>
            <div className="text-white text-sm font-notoSans font-normal pt-0.5 pb-2">
              {collection.description || defaultDescription}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NFTCollectionCardNew;
