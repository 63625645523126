import MUICheckbox from "@mui/material/Checkbox"; // MUICheckbox is a custom alias for the default export
import FormControlLabel from "@mui/material/FormControlLabel";
import { CheckboxCheckedIcon, CheckboxIcon } from "components/icons/Checkbox";

const Checkbox = (props) => {
  const { className, label, checked, onChange, item, selectedItems } = props;
  return (
    <FormControlLabel
      label={label}
      control={
        <MUICheckbox
          checked={selectedItems?.includes(item?.value) ? true : checked}
          onChange={onChange}
          value={item?.value}
          className={className || ""}
          icon={<CheckboxIcon />}
          checkedIcon={<CheckboxCheckedIcon />}
        />
      }
    />
  );
};

export default Checkbox;
