import { useEffect, useRef, useState } from "react";
import debounce from "lodash/debounce";
import { Text12Regular, Text18Bold, Text24Light, Text26Light } from "./Text";
import Verified from "components/icons/Verified";
import { $t } from "components/utils/Translation";
import { ButtonPrimaryLarge, ButtonSecondaryLarge } from "./Button";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PlaceBidModal from "components/blocks/placeBidModal/PlaceBidModal";
import { customToast, shortWalletAddress } from "components/utils/generic";

const AuctionCard = (props) => {
  // const second = 1000
  // const minute = second * 60
  // const hour = minute * 60
  // const day = hour * 24
  const initialTimeLeft = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const navigate = useNavigate();
  const { nftInfo, wrapperStyle, loading } = props;

  const nftImageRef = useRef(null);
  const [nftImageHeight, setnftImageHeight] = useState(0);
  const [showPlaceBidModal, setshowPlaceBidModal] = useState(false);
  const [timeLeft, setTimeLeft] = useState(initialTimeLeft);
  const collection = (!!nftInfo && nftInfo.collection?.name) || "";
  const nftImageUrl = !!nftInfo && nftInfo.secondaryMediaS3Key?.signedUrl;

  const ownerUserName = !!nftInfo && nftInfo.owner?.profileName;
  const ownerWalletAddress = !!nftInfo && nftInfo.owner?.walletAddress;
  const ownerIsVerified = !!nftInfo && !!nftInfo.owner?.verified;
  const ownerImage =
    !!nftInfo && !!nftInfo.owner?.profileImage
      ? nftInfo.owner.profileImage.signedUrl
      : "";

  const creatorUserName = !!nftInfo && nftInfo.creator?.profileName;
  const creatorWalletAddress = !!nftInfo && nftInfo.creator?.walletAddress;
  const creatorIsVerified = !!nftInfo && !!nftInfo.creator?.verified;
  const creatorImage =
    !!nftInfo && !!nftInfo.creator?.profileImage
      ? nftInfo.creator?.profileImage?.signedUrl
      : "";

  const name = !!nftInfo && nftInfo.name;
  const nftId = !!nftInfo && nftInfo.id;
  const totalBids = !!nftInfo && nftInfo.totalBids;
  const highestBid = !!nftInfo && nftInfo.highestBid;
  const ownerId = !!nftInfo && nftInfo.owner?.id;
  const creatorId = !!nftInfo && nftInfo.creator?.id;

  const sale =
    !!nftInfo &&
    nftInfo.sales.filter((item) => +new Date(item.expiry) > +new Date());
  const saleEndDate = sale.length ? new Date(sale[0].expiry) : null;
  const saleEndMonth = saleEndDate ? months[saleEndDate.getUTCMonth()] : null;
  const saleEndDay = saleEndDate ? saleEndDate.getUTCDate() : null;
  const saleEndYear = saleEndDate ? saleEndDate.getUTCFullYear() : null;
  const saleEndTime = saleEndDate
    ? `${saleEndDate.getUTCHours()}:${saleEndDate.getUTCMinutes()} UTC`
    : null;
  const saleEnd = saleEndDate
    ? `${saleEndMonth} ${saleEndDay}, ${saleEndYear} at ${saleEndTime}`
    : null;

  const goToNftDetails = () => {
    if (!nftId || isNaN(nftId))
      return customToast("Invalid/Test NFT", { toastId: "INVALID_NFTID" });
    navigate(`/nft/${nftId}`);
  };

  const goToOwnerDetails = () => {
    if (!ownerId || isNaN(ownerId))
      return customToast("Invalid/Test OWNER", { toastId: "INVALID_OWNERID" });
    navigate(`/profile/${ownerId}`);
  };

  const goToCreatorDetails = () => {
    if (!creatorId || isNaN(creatorId))
      return customToast("Invalid/Test OWNER", {
        toastId: "INVALID_CREATORID",
      });
    navigate(`/profile/${creatorId}`);
  };

  const calculateTimeLeft = () => {
    if (!sale.length) return initialTimeLeft;
    let difference = +new Date(sale[0].expiry) - +new Date();
    let timeLeft = {};
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      timeLeft = initialTimeLeft;
    }
    return timeLeft;
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const setSquareImageHeight = () =>
    nftImageRef &&
    nftImageRef.current &&
    setnftImageHeight(nftImageRef.current.offsetWidth);

  useEffect(setSquareImageHeight, [nftImageRef, nftImageUrl]);

  useEffect(() => {
    window.addEventListener("resize", debounce(setSquareImageHeight, 100));
    return () => {
      window.removeEventListener("resize", debounce(setSquareImageHeight, 100));
    };
  }, [nftImageRef]);

  if (loading) {
    return (
      <Grid
        container
        style={wrapperStyle}
        className="justify-between bg-lightbg md:bg-baseDark rounded-3xl md:rounded-none"
      >
        <Grid item xs={12} md={6}>
          <div
            id="nft-image"
            ref={nftImageRef}
            style={{
              height: nftImageHeight,
            }}
            className="relative rounded-3xl bg-gray-800 animate-pulse max-w-[456px]"
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <div
            id="nft-details"
            className="flex flex-col flex-1 scroll-container-with-scrollbar py-4 md:py-0 lg:py-4 justify-between items-center md:items-stretch gap-4 h-full"
          >
            <div className="flex flex-col md:flex-row justify-between gap-y-5 lg:gap-4">
              {/* Creator info */}
              <div id="creator-info" className="flex gap-3 mt-5 md:mt-0">
                <div className="bg-no-repeat bg-cover h-[50px] rounded-md w-[50px] bg-center animate-pulse bg-grey1" />
                <div className="flex flex-col">
                  <div className="bg-gray-800 animate-pulse h-2 w-12 rounded" />
                  <div className="bg-gray-800 animate-pulse h-4 w-28 rounded mt-2" />
                </div>
              </div>
              {/* Owner info */}
              <div id="owner-info" className="flex gap-3">
                <div className="bg-no-repeat bg-cover h-[50px] rounded-md w-[50px] bg-center animate-pulse bg-grey1" />
                <div className="flex flex-col">
                  <div className="bg-gray-800 animate-pulse h-2 w-12 rounded" />
                  <div className="bg-gray-800 animate-pulse h-4 w-28 rounded mt-2" />
                </div>
              </div>
            </div>

            <div className="flex flex-col mt-4 md:mt-0">
              <div className="flex items-center justify-center md:justify-start capitalize">
                <div className="bg-gray-800 animate-pulse h-2 w-14 rounded" />
              </div>
              <div className="flex mt-2">
                <div className="bg-gray-800 animate-pulse h-3 w-full rounded" />
              </div>
            </div>
            <div className="flex flex-col mt-4 md:mt-0">
              <div className="text-center md:text-left">
                <div className="bg-gray-800 animate-pulse h-2 w-24 rounded" />
              </div>
              <div className="flex gap-9 mt-2">
                <div className="flex flex-col items-center">
                  <div className="h-7 w-8 bg-gray-800 animate-pulse rounded-full" />
                </div>
                <div className="flex flex-col items-center">
                  <div className="h-7 w-6 bg-gray-800 animate-pulse rounded-full" />
                </div>
                <div className="flex flex-col items-center">
                  <div className="h-7 w-5 bg-gray-800 animate-pulse rounded-full" />
                </div>
                <div className="flex flex-col items-center">
                  <div className="h-7 w-10 bg-gray-800 animate-pulse rounded-full" />
                </div>
              </div>
            </div>
            <div className="flex gap-4 justify-center md:justify-start h-50" />
          </div>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      {/*  */}

      <Grid
        container
        style={wrapperStyle}
        className="justify-between bg-lightbg md:bg-baseDark rounded-3xl md:rounded-none"
      >
        <Grid item xs={12} md={6}>
          <div
            id="nft-image"
            ref={nftImageRef}
            style={{
              height: nftImageHeight,
              backgroundImage: `url(${nftImageUrl})`,
            }}
            className="relative rounded-3xl bg-no-repeat bg-cover bg-top max-w-[456px]"
          ></div>
        </Grid>
        <Grid item xs={12} md={5}>
          <div
            id="nft-details"
            className="flex flex-col flex-1 scroll-container-with-scrollbar py-4 md:py-0 lg:py-4 justify-between items-center md:items-stretch gap-4 h-full"
          >
            <div className="flex flex-col md:flex-row justify-between gap-y-5 lg:gap-4">
              {/* Creator info */}
              <div
                id="creator-info"
                className="flex gap-3 mt-5 md:mt-0"
                onClick={goToCreatorDetails}
              >
                <div
                  className="bg-no-repeat bg-cover bg-center bg-grey1"
                  style={{
                    backgroundImage: `url(${creatorImage})`,
                    width: 50,
                    height: 50,
                    borderRadius: 6,
                  }}
                />
                <div className="flex flex-col">
                  <div>
                    <Text12Regular>{$t("_CREATOR_", "Creator")}</Text12Regular>
                  </div>
                  <div className="flex">
                    <Text18Bold>
                      {creatorUserName ||
                        shortWalletAddress(creatorWalletAddress)}
                    </Text18Bold>
                    {!!creatorIsVerified && <Verified />}
                  </div>
                </div>
              </div>
              {/* Owner info */}
              <div
                id="owner-info"
                className="flex gap-3"
                onClick={goToOwnerDetails}
              >
                <div
                  className="bg-no-repeat bg-cover bg-center bg-grey1"
                  style={{
                    backgroundImage: `url(${ownerImage})`,
                    width: 50,
                    height: 50,
                    borderRadius: 6,
                  }}
                />
                <div className="flex flex-col">
                  <div>
                    <Text12Regular>{$t("_OWNER_", "Owner")}</Text12Regular>
                  </div>
                  <div className="flex">
                    <Text18Bold>
                      {ownerUserName || shortWalletAddress(ownerWalletAddress)}
                    </Text18Bold>
                    {!!ownerIsVerified && <Verified />}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col mt-4 md:mt-0">
              <div className="flex items-center justify-center md:justify-start capitalize">
                <Text12Regular>{collection}</Text12Regular>
              </div>
              <div className="flex">
                <Text18Bold>{name}</Text18Bold>
              </div>
            </div>

            <div className="flex mt-4 md:mt-0">
              {!!totalBids && !isNaN(totalBids) ? (
                <div className="w-full flex items-start gap-10">
                  <div className="flex flex-col">
                    <Text26Light>{totalBids}</Text26Light>
                    <Text12Regular className="opacity-80">
                      {$t("_NO._OF_BIDS_", "No. of bids")}
                    </Text12Regular>
                  </div>
                  <div className="flex flex-col">
                    <Text24Light>
                      {!!highestBid
                        ? `${highestBid.amountDisplay} ${highestBid.priceUnit}`
                        : ""}
                    </Text24Light>
                    <Text12Regular className="opacity-80">
                      {$t("_HIGHEST_BID_", "Highest bid")}
                    </Text12Regular>
                  </div>
                </div>
              ) : null}
            </div>

            {!!saleEndDate && timeLeft !== initialTimeLeft && (
              <div className="flex flex-col mt-4 md:mt-0">
                <div className="text-center md:text-left">
                  <Text12Regular className="opacity-80">
                    {$t("_AUCTION_ENDS_IN_", "Auction Ends In")}
                  </Text12Regular>
                </div>
                <div className="flex gap-9">
                  <div className="flex flex-col items-center">
                    {!isNaN(timeLeft.days) && (
                      <Text24Light>{timeLeft.days}</Text24Light>
                    )}
                    <Text12Regular className="opacity-80">
                      {$t("_DAYS_", "Days")}
                    </Text12Regular>
                  </div>
                  <div className="flex flex-col items-center">
                    {!isNaN(timeLeft.hours) && (
                      <Text24Light>{timeLeft.hours}</Text24Light>
                    )}
                    <Text12Regular className="opacity-80">
                      {$t("_HOURS_", "Hours")}
                    </Text12Regular>
                  </div>
                  <div className="flex flex-col items-center">
                    {!isNaN(timeLeft.minutes) && (
                      <Text24Light>{timeLeft.minutes}</Text24Light>
                    )}
                    <Text12Regular className="opacity-80">
                      {$t("_MIN_", "Min")}
                    </Text12Regular>
                  </div>
                  <div className="flex flex-col items-center">
                    {!isNaN(timeLeft.seconds) && (
                      <Text24Light>{timeLeft.seconds}</Text24Light>
                    )}
                    <Text12Regular className="opacity-80">
                      {$t("_SEC_", "Sec")}
                    </Text12Regular>
                  </div>
                </div>
              </div>
            )}

            <div className="flex gap-4 justify-center md:justify-start">
              <ButtonPrimaryLarge
                className="md:px-[16px] lg:px-[28px] px-[28px] whitespace-nowrap mt-2 md:mt-0 mb-5 md:mb-0"
                text={`${$t("_PLACE_A_BID_", "Place a Bid")}`}
                onClick={goToNftDetails}
              />
              <ButtonSecondaryLarge
                className="hidden md:flex md:px-[16px] lg:px-[28px] whitespace-nowrap"
                text={`${$t("_VIEW_DETAILS_", "View Details")}`}
                onClick={goToNftDetails}
              />
            </div>
          </div>
        </Grid>
      </Grid>
      <PlaceBidModal
        visible={showPlaceBidModal}
        toggle={(val) => setshowPlaceBidModal(val)}
      />
    </>
  );
};

export default AuctionCard;
