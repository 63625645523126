import ProfileDefault from "components/icons/ProfileDefault";
import Verified from "components/icons/Verified";
import { shortWalletAddress } from "components/utils/generic";
import { celebrityImages } from "components/utils/mockData";
import { useNavigate } from "react-router-dom";
import { Text12Regular, Text18Bold, Text24Black } from "./Text";

const ProfileCardNew = (props) => {
  const navigate = useNavigate();
  const {
    style,
    rank,
    walletBalance,
    chainUnit,
    widthClass,
    heightClass,
    profile,
  } = props;
  const imageUrl =
    !!profile && profile.profileImage ? profile.profileImage?.signedUrl : "";

  const coverImage =
    !!profile && profile.profileImage ? profile.profileImage?.signedUrl : "";

  const profileId = !!profile && profile.id ? profile.id : "";
  const userIsVerified = !!profile && !!profile.verfied;
  const userName = !!profile
    ? profile.profileName
      ? profile.profileName
      : shortWalletAddress(profile.walletAddress)
    : "";
  const description = `Korea's most renowned virtual artist, APOKI. With her unique concept, music and performances that only she can pull off, APOKI is establishing herself as an unrivaled iconic figure.`;
  return !profile || !profile.id ? null : (
    <>
      <div
        style={style}
        className={`flex relative bg-[#26282B] rounded-xl p-3    md:p-[26px] min-h-[294px] h-auto max-w-[550px]`}
      >
        <div
          className="absolute w-full h-[78px] top-0 left-0 object-contain bg-cover bg-no-repeat rounded-t-xl"
          style={{
            backgroundImage: `url(${imageUrl})`,
            // backgroundSize: 'contain',
            backgroundRepeat: "no-repeat",
          }}
        />
        <div
          className="absolute top-[56px] bg-no-repeat bg-cover bg-center bg-[#676F7D] flex justify-center items-center"
          style={{
            backgroundImage: `url(${imageUrl})`,
            width: 50,
            height: 50,
            borderRadius: 6,
          }}
        >
          {!imageUrl && <ProfileDefault />}
        </div>
        <div className="absolute top-6 right-6">
          {" "}
          <Text24Black>#{rank}</Text24Black>
        </div>
        {/* <div className="flex gap-2 md:gap-4"> */}

        <div className="flex flex-col mt-28 md:mt-24">
          <Text12Regular>
            {walletBalance} {chainUnit}
          </Text12Regular>
          <Text18Bold>{profile.profileName || "Courtney Henry"}</Text18Bold>
          <div
            className="flex cursor-pointer"
            onClick={() =>
              navigate(
                celebrityImages.includes(imageUrl)
                  ? ""
                  : `/profile/${profileId}`
              )
            }
          >
            {!!userIsVerified && <Verified />}
          </div>
          <p className="mt-4 text-[#F2F2F2] text-sm font-notoSans font-normal">
            {profile.description || description}
          </p>
        </div>
        {/* </div> */}
      </div>
    </>
  );
};

export default ProfileCardNew;
