export const LeftArrow = (props) => {
    const { className } = props
    return (
        <svg className={className} width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.00168968 3.99373C0.00136377 3.86044 0.0594587 3.73128 0.16589 3.62865L3.73547 0.206069C3.85665 0.0895778 4.03079 0.0163207 4.21956 0.00241374C4.40834 -0.0114932 4.5963 0.0350892 4.7421 0.131913C4.88789 0.228737 4.97957 0.367872 4.99698 0.518708C5.01438 0.669545 4.95608 0.819728 4.83491 0.93622L1.63656 3.99373L4.72068 7.05123C4.77998 7.10958 4.82427 7.17672 4.85099 7.24879C4.87771 7.32085 4.88635 7.39643 4.87639 7.47117C4.86644 7.54591 4.8381 7.61835 4.793 7.68431C4.7479 7.75027 4.68693 7.80847 4.61359 7.85554C4.54018 7.90778 4.45407 7.94734 4.36063 7.97175C4.2672 7.99616 4.16847 8.00489 4.07061 7.9974C3.97276 7.9899 3.8779 7.96634 3.79198 7.92818C3.70605 7.89003 3.63092 7.83811 3.57127 7.77568L0.123056 4.3531C0.0334476 4.24752 -0.00928383 4.12099 0.00168968 3.99373V3.99373Z" fill="#A6A6A6" />
        </svg>


    )
}
