import Translate from "components/utils/Translation"

const Caption = (props) => {
    const {onClick, style, className, translationKey, fallbackText, children} = props
  const classes = `font-notoSans leading-6 ${className || ''}`

    return (
      <span onClick={onClick} style={style} className={classes}>
        <Translate translationKey={translationKey}>{fallbackText || children}</Translate>
      </span>
    )
}

export default Caption

export const CaptionGreen = (props) => <Caption {...props} className={`text-primary1 ${setFontColor(props.className || '')}`} />

export const CaptionWhite = (props) => <Caption {...props} className={`text-white ${setFontColor(props.className || '')}`} />

const setFontColor = (classString = '') => classString.includes('text-') ? classString : `${classString}`