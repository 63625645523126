import React from "react";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { CardContainer } from "components/elements/Filter";
import { TickActive } from "components/icons/Tick";
import {
  Text12Medium,
  Text12Regular,
  Text16Bold,
  Text24Black,
} from "components/elements/Text";
import { $t } from "components/utils/Translation";

const ApprovalStatus = () => {
  const BorderLinearProgress = styled(LinearProgress)(() => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: `rgba(255, 255, 255, 0.07)`,
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: `#3ED791`,
    },
  }));
  return (
    <CardContainer className="border border-solid border-lightbg1 rounded-lg flex py-12 gap-4">
      <TickActive />
      <div className="flex flex-col flex-1 pr-14">
        <Text12Medium className="text-white3">STEP 5/5</Text12Medium>
        <Text16Bold>
          {$t("_AWAITING_APPROVAL_", "Awaiting Approval")}
        </Text16Bold>
        <Text12Regular className="text-white3 mt-5 mb-8">
          Your NFT is created successfully and is under Review.
        </Text12Regular>
        <Text12Regular>
          {$t("_TIME_REMAINING_", "Time Remaining")}
        </Text12Regular>
        <Text24Black className="my-2">2 {$t("_HOURS_", "Hours")}</Text24Black>
        <BorderLinearProgress variant="determinate" value={60} />
      </div>
    </CardContainer>
  );
};

export default ApprovalStatus;
