import BigNumber from "bignumber.js";
import BinanceIcon from "components/icons/BinanceIcon";
import PolygonIcon from "components/icons/PolygonIcon";
import AvaxIcon from "components/icons/AvaxIcon";

export const APP_ENV = process.env.REACT_APP_ENV;

export const DEFAULT_ERC721_COLLECTION_ID = APP_ENV === "testnet" ? 1 : 1;

export const dropDowncustomStyles = {
  menu: (provided, state) => ({
    ...provided,
    color: state.selectProps.menuColor,
    backgroundColor: "#1B1E21",
    border: "1px solid #26282B",
    zIndex: 9999999,
  }),
  control: () => ({
    backgroundColor: "#26282B",
    display: "flex",
    borderRadius: "7px",
    padding: "6px 0px",
    color: "white",
    focus: "outline-none",
    outline: "none",
  }),
  input: (styles) => ({ ...styles, color: "white", zIndex: 9999 }),
  placeholder: (styles) => ({ color: "white" }),

  singleValue: (styles) => ({
    ...styles,
    color: "white",
    fontSize: "14px",
    fontStyle: "normal",
  }),
};

export const findToken = (tokenAddress, chainId) => {
  if (!tokenAddress) return null;
  const tokens = ALLOWED_TOKENS[chainId];
  const tokenDetails = tokens.find(
    (token) => token.address.toLowerCase() === tokenAddress.toLowerCase()
  );
  return tokenDetails;
};

export const findExplorerLink = (chainId, tokenAddress) => {
  if (!tokenAddress) return null;
  const chain = ALLOWED_CHAINS[chainId];
  if (!chain) return null;
  return `${chain.explorerUrl}/address/${tokenAddress}`;
};

export const findTokenLink = (chainId, tokenAddress, tokenId) => {
  if (!tokenAddress) return null;
  const chain = ALLOWED_CHAINS[chainId];
  if (!chain) return null;
  const decimalTokenId = new BigNumber(tokenId).toString(10);
  return `${chain.explorerUrl}/token/${tokenAddress}?a=${decimalTokenId}`;
};

export const ALLOWED_CHAINS =
  APP_ENV === "testnet"
    ? {
        "0x13881": {
          decimals: 18,
          icon: <PolygonIcon />,
          symbol: "MATIC",
          name: "Polygon Testnet",
          bg: "#6E44BC",
          explorerUrl: "https://mumbai.polygonscan.com",
          chainId: "0x13881",
          key: "POLYGON",
        },
        "0x61": {
          decimals: 18,
          icon: <BinanceIcon className={"w-4"} />,
          symbol: "BSC",
          name: "BSC Testnet",
          bg: "#48474A",
          explorerUrl: "https://testnet.bscscan.com",
          chainId: "0x61",
          key: "BSC",
        },
      }
    : {
        "0x89": {
          decimals: 18,
          icon: <PolygonIcon />,
          symbol: "MATIC",
          name: "Polygon",
          bg: "#6E44BC",
          explorerUrl: "https://polygonscan.com",
          chainId: "0x89",
          key: "POLYGON",
        },
        "0x38": {
          decimals: 18,
          icon: <BinanceIcon className={"w-4"} />,
          symbol: "BSC",
          name: "Binance Smart Chain",
          bg: "#48474A",
          explorerUrl: "https://bscscan.com",
          chainId: "0x38",
          key: "BSC",
        },
        "0xa86a": {
          decimals: 18,
          icon: <AvaxIcon className={"w-4"} />,
          symbol: "AVAX",
          name: "Avalanche Network",
          bg: "#e84142",
          explorerUrl: "https://snowtrace.io",
          chainId: "0xa86a",
          key: "AVAX",
        },
      };

export const ALLOWED_TOKENS = {
  "0x89": [
    {
      name: "WMATIC",
      chainId: "0x89",
      address: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
      decimals: 18,
      icon: <PolygonIcon />,
      symbol: "WMATIC",
      selectedIcon: "",
      color: "#6E44BC",
      isNativeToken: false,
    },

    {
      name: "WETH",
      chainId: "0x89",
      address: "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
      decimals: 18,
      icon: <PolygonIcon />,
      symbol: "WETH",
      selectedIcon: "",
      color: "#6E44BC",
    },

    {
      name: "BNB",
      chainId: "0x89",
      address: "0x3BA4c387f786bFEE076A58914F5Bd38d668B42c3",
      decimals: 18,
      icon: <PolygonIcon />,
      symbol: "BNB",
      selectedIcon: "",
      color: "#6E44BC",
    },

    {
      name: "USDT",
      chainId: "0x89",
      address: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
      decimals: 6,
      icon: <PolygonIcon />,
      symbol: "USDT",
      selectedIcon: "",
      color: "#6E44BC",
    },

    {
      name: "USDC",
      chainId: "0x89",
      address: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
      decimals: 6,
      icon: <PolygonIcon />,
      symbol: "USDC",
      selectedIcon: "",
      color: "#6E44BC",
    },
    {
      name: "BUSD",
      chainId: "0x89",
      address: "0xdAb529f40E671A1D4bF91361c21bf9f0C9712ab7",
      decimals: 18,
      icon: <PolygonIcon />,
      symbol: "BUSD",
      selectedIcon: "",
      color: "#6E44BC",
    },
    {
      name: "DAI",
      chainId: "0x89",
      address: "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
      decimals: 18,
      icon: <PolygonIcon />,
      symbol: "DAI",
      selectedIcon: "",
      color: "#6E44BC",
    },
  ],
  "0x38": [
    {
      name: "USDT",
      chainId: "0x38",
      address: "0x55d398326f99059ff775485246999027b3197955",
      decimals: 18,
      icon: <BinanceIcon className={"w-4"} />,
      symbol: "USDT",
      selectedIcon: "",
      color: "#FBAD18",
      minAmount: "5000000000000000000",
    },
    {
      name: "USDC",
      chainId: "0x38",
      address: "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
      decimals: 18,
      icon: <BinanceIcon className={"w-4"} />,
      symbol: "USDC",
      selectedIcon: "",
      color: "#FBAD18",
      minAmount: "5000000000000000000",
    },
    {
      name: "BUSD",
      chainId: "0x38",
      address: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
      decimals: 18,
      icon: <BinanceIcon className={"w-4"} />,
      symbol: "BUSD",
      selectedIcon: "",
      color: "#FBAD18",
      minAmount: "5000000000000000000",
    },

    {
      name: "WBNB",
      chainId: "0x38",
      address: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
      decimals: 18,
      icon: <BinanceIcon className={"w-4"} />,
      symbol: "WBNB",
      selectedIcon: "",
      color: "#FBAD18",
    },

    {
      name: "BNB",
      chainId: "0x38",
      address: "0x0000000000000000000000000000000000000000",
      decimals: 18,
      icon: <BinanceIcon className={"w-4"} />,
      symbol: "BNB",
      selectedIcon: "",
      color: "#FBAD18",
      isNativeToken: true,
    },

    {
      name: "DAI",
      chainId: "0x38",
      address: "0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3",
      decimals: 18,
      icon: <BinanceIcon className={"w-4"} />,
      symbol: "DAI",
      selectedIcon: "",
      color: "#FBAD18",
    },
  ],
  "0x13881": [
    {
      name: "WMATIC",
      chainId: "0x13881",
      address: "0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889",
      decimals: 18,
      icon: <PolygonIcon />,
      symbol: "WMATIC",
      selectedIcon: "",
      color: "#6E44BC",
      isNativeToken: false,
    },

    {
      name: "USDT",
      chainId: "0x13881",
      address: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
      decimals: 6,
      icon: <PolygonIcon />,
      symbol: "USDT",
      selectedIcon: "",
      color: "#6E44BC",
    },
    {
      name: "USDC",
      chainId: "0x13881",
      address: "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
      decimals: 6,
      icon: <PolygonIcon />,
      symbol: "USDC",
      selectedIcon: "",
      color: "#6E44BC",
    },
    {
      name: "BUSD",
      chainId: "0x13881",
      address: "0xdab529f40e671a1d4bf91361c21bf9f0c9712ab7",
      decimals: 18,
      icon: <PolygonIcon />,
      symbol: "BUSD",
      selectedIcon: "",
      color: "#6E44BC",
    },
    {
      name: "DAI",
      chainId: "0x13881",
      address: "0x8f3cf7ad23cd3cadbd9735aff958023239c6a063",
      decimals: 18,
      icon: <PolygonIcon />,
      symbol: "DAI",
      selectedIcon: "",
      color: "#6E44BC",
    },
  ],
};

export const ERC721_ABI = [
  {
    inputs: [
      { internalType: "address", name: "_marketPlace", type: "address" },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "approved",
        type: "address",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "operator",
        type: "address",
      },
      { indexed: false, internalType: "bool", name: "approved", type: "bool" },
    ],
    name: "ApprovalForAll",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "bytes32", name: "role", type: "bytes32" },
      {
        indexed: true,
        internalType: "bytes32",
        name: "previousAdminRole",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "bytes32",
        name: "newAdminRole",
        type: "bytes32",
      },
    ],
    name: "RoleAdminChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "bytes32", name: "role", type: "bytes32" },
      {
        indexed: true,
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "sender",
        type: "address",
      },
    ],
    name: "RoleGranted",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "bytes32", name: "role", type: "bytes32" },
      {
        indexed: true,
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "sender",
        type: "address",
      },
    ],
    name: "RoleRevoked",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "from", type: "address" },
      { indexed: true, internalType: "address", name: "to", type: "address" },
      {
        indexed: true,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "Transfer",
    type: "event",
  },
  {
    inputs: [],
    name: "DEFAULT_ADMIN_ROLE",
    outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "OP_ROLE",
    outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "tokenId", type: "uint256" },
    ],
    name: "approve",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "owner", type: "address" }],
    name: "balanceOf",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "baseURI",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
    name: "burn",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
    name: "getApproved",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "bytes32", name: "role", type: "bytes32" }],
    name: "getRoleAdmin",
    outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes32", name: "role", type: "bytes32" },
      { internalType: "address", name: "account", type: "address" },
    ],
    name: "grantRole",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes32", name: "role", type: "bytes32" },
      { internalType: "address", name: "account", type: "address" },
    ],
    name: "hasRole",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "owner", type: "address" },
      { internalType: "address", name: "operator", type: "address" },
    ],
    name: "isApprovedForAll",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "marketPlace",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "name",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
    name: "ownerOf",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes32", name: "role", type: "bytes32" },
      { internalType: "address", name: "account", type: "address" },
    ],
    name: "renounceRole",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes32", name: "role", type: "bytes32" },
      { internalType: "address", name: "account", type: "address" },
    ],
    name: "revokeRole",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "string", name: "uri", type: "string" },
      { internalType: "bytes32[]", name: "nftExistsProof", type: "bytes32[]" },
      {
        components: [
          { internalType: "uint256", name: "batchId", type: "uint256" },
          { internalType: "enum NFTKind", name: "kind", type: "uint8" },
          { internalType: "address", name: "collection", type: "address" },
          { internalType: "uint256", name: "tokenId", type: "uint256" },
          { internalType: "address", name: "creator", type: "address" },
          { internalType: "uint256", name: "royaltyPercent", type: "uint256" },
        ],
        internalType: "struct NFT",
        name: "nft",
        type: "tuple",
      },
    ],
    name: "safeMint",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "from", type: "address" },
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "tokenId", type: "uint256" },
    ],
    name: "safeTransferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "from", type: "address" },
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "tokenId", type: "uint256" },
      { internalType: "bytes", name: "_data", type: "bytes" },
    ],
    name: "safeTransferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "operator", type: "address" },
      { internalType: "bool", name: "approved", type: "bool" },
    ],
    name: "setApprovalForAll",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "string", name: "uri", type: "string" }],
    name: "setBaseURI",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_marketPlace", type: "address" },
    ],
    name: "setMarketPlace",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "bytes4", name: "interfaceId", type: "bytes4" }],
    name: "supportsInterface",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "symbol",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
    name: "tokenURI",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "from", type: "address" },
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "tokenId", type: "uint256" },
    ],
    name: "transferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export const ERC20_ABI = [
  {
    constant: true,
    inputs: [],
    name: "name",
    outputs: [
      {
        name: "",
        type: "string",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        name: "_spender",
        type: "address",
      },
      {
        name: "_value",
        type: "uint256",
      },
    ],
    name: "approve",
    outputs: [
      {
        name: "",
        type: "bool",
      },
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "totalSupply",
    outputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        name: "_from",
        type: "address",
      },
      {
        name: "_to",
        type: "address",
      },
      {
        name: "_value",
        type: "uint256",
      },
    ],
    name: "transferFrom",
    outputs: [
      {
        name: "",
        type: "bool",
      },
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "decimals",
    outputs: [
      {
        name: "",
        type: "uint8",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "_owner",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        name: "balance",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "symbol",
    outputs: [
      {
        name: "",
        type: "string",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        name: "_to",
        type: "address",
      },
      {
        name: "_value",
        type: "uint256",
      },
    ],
    name: "transfer",
    outputs: [
      {
        name: "",
        type: "bool",
      },
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "_owner",
        type: "address",
      },
      {
        name: "_spender",
        type: "address",
      },
    ],
    name: "allowance",
    outputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    payable: true,
    stateMutability: "payable",
    type: "fallback",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        name: "spender",
        type: "address",
      },
      {
        indexed: false,
        name: "value",
        type: "uint256",
      },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: "from",
        type: "address",
      },
      {
        indexed: true,
        name: "to",
        type: "address",
      },
      {
        indexed: false,
        name: "value",
        type: "uint256",
      },
    ],
    name: "Transfer",
    type: "event",
  },
];

export const MARKETPLACE_ABI = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "previousAdmin",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "newAdmin",
        type: "address",
      },
    ],
    name: "AdminChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "beacon",
        type: "address",
      },
    ],
    name: "BeaconUpgraded",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "implementation",
        type: "address",
      },
    ],
    name: "Upgraded",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "collection",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
      {
        internalType: "bool",
        name: "isBlocked",
        type: "bool",
      },
    ],
    name: "blockNFT",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "enum AppType.AddressConfig",
        name: "addressConfig",
        type: "uint8",
      },
      {
        internalType: "enum AppType.UintConfig",
        name: "uintConfig",
        type: "uint8",
      },
      {
        internalType: "enum AppType.BoolConfig",
        name: "boolConfig",
        type: "uint8",
      },
      {
        internalType: "enum AppType.Bytes32Config",
        name: "bytes32Config",
        type: "uint8",
      },
      {
        internalType: "enum AppType.StringConfig",
        name: "stringConfig",
        type: "uint8",
      },
      {
        internalType: "address",
        name: "addressValue",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "uintValue",
        type: "uint256",
      },
      {
        internalType: "bool",
        name: "boolValue",
        type: "bool",
      },
      {
        internalType: "bytes32",
        name: "bytes32Value",
        type: "bytes32",
      },
      {
        internalType: "string",
        name: "stringValue",
        type: "string",
      },
    ],
    name: "changeConfig",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "changeMinSaleTokenAmount",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "root",
        type: "bytes32",
      },
    ],
    name: "createBatch",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "batchId",
        type: "uint256",
      },
      {
        internalType: "bool",
        name: "isDisabled",
        type: "bool",
      },
    ],
    name: "disableBatch",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "batchId",
            type: "uint256",
          },
          {
            internalType: "enum AppType.NFTKind",
            name: "kind",
            type: "uint8",
          },
          {
            internalType: "address",
            name: "collection",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "tokenId",
            type: "uint256",
          },
          {
            internalType: "address",
            name: "creator",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "royaltyPercent",
            type: "uint256",
          },
        ],
        internalType: "struct AppType.NFT",
        name: "nft",
        type: "tuple",
      },
      {
        internalType: "bool",
        name: "isExcluded",
        type: "bool",
      },
    ],
    name: "excludeLeaf",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32[]",
        name: "nftExistsProof",
        type: "bytes32[]",
      },
      {
        components: [
          {
            internalType: "uint256",
            name: "batchId",
            type: "uint256",
          },
          {
            internalType: "enum AppType.NFTKind",
            name: "kind",
            type: "uint8",
          },
          {
            internalType: "address",
            name: "collection",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "tokenId",
            type: "uint256",
          },
          {
            internalType: "address",
            name: "creator",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "royaltyPercent",
            type: "uint256",
          },
        ],
        internalType: "struct AppType.NFT",
        name: "nft",
        type: "tuple",
      },
      {
        components: [
          {
            internalType: "uint256",
            name: "nftAmount",
            type: "uint256",
          },
          {
            internalType: "address",
            name: "seller",
            type: "address",
          },
          {
            internalType: "address",
            name: "buyer",
            type: "address",
          },
          {
            internalType: "address",
            name: "saleToken",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "saleAmount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "bidExpiry",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "acceptExpiry",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "placedBidNonce",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "acceptBidNonce",
            type: "uint256",
          },
          {
            internalType: "bytes",
            name: "placedBidByBuyer",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "acceptedBidBySeller",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "opSig",
            type: "bytes",
          },
          {
            internalType: "uint256",
            name: "opTimestamp",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "opNonce",
            type: "uint256",
          },
        ],
        internalType: "struct AppType.Trade",
        name: "trade",
        type: "tuple",
      },
    ],
    name: "executeTrade",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "enum AppType.AddressConfig",
        name: "addressConfig",
        type: "uint8",
      },
      {
        internalType: "enum AppType.UintConfig",
        name: "uintConfig",
        type: "uint8",
      },
      {
        internalType: "enum AppType.BoolConfig",
        name: "boolConfig",
        type: "uint8",
      },
      {
        internalType: "enum AppType.Bytes32Config",
        name: "bytes32Config",
        type: "uint8",
      },
      {
        internalType: "enum AppType.StringConfig",
        name: "stringConfig",
        type: "uint8",
      },
    ],
    name: "getConfig",
    outputs: [
      {
        internalType: "address",
        name: "addressValue",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "uintValue",
        type: "uint256",
      },
      {
        internalType: "bool",
        name: "boolValue",
        type: "bool",
      },
      {
        internalType: "bytes32",
        name: "bytes32Value",
        type: "bytes32",
      },
      {
        internalType: "string",
        name: "stringValue",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "initialize",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "name",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "proxiableUUID",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "batchId",
        type: "uint256",
      },
    ],
    name: "readBatch",
    outputs: [
      {
        internalType: "bytes32",
        name: "root",
        type: "bytes32",
      },
      {
        internalType: "bool",
        name: "isDisabled",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "operator",
        type: "address",
      },
      {
        internalType: "bool",
        name: "isOperator",
        type: "bool",
      },
    ],
    name: "setOperator",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "batchId",
        type: "uint256",
      },
      {
        internalType: "bytes32",
        name: "root",
        type: "bytes32",
      },
    ],
    name: "updateBatch",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newImplementation",
        type: "address",
      },
    ],
    name: "upgradeTo",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newImplementation",
        type: "address",
      },
      {
        internalType: "bytes",
        name: "data",
        type: "bytes",
      },
    ],
    name: "upgradeToAndCall",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32[]",
        name: "nftExistsProof",
        type: "bytes32[]",
      },
      {
        components: [
          {
            internalType: "uint256",
            name: "batchId",
            type: "uint256",
          },
          {
            internalType: "enum AppType.NFTKind",
            name: "kind",
            type: "uint8",
          },
          {
            internalType: "address",
            name: "collection",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "tokenId",
            type: "uint256",
          },
          {
            internalType: "address",
            name: "creator",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "royaltyPercent",
            type: "uint256",
          },
        ],
        internalType: "struct AppType.NFT",
        name: "nft",
        type: "tuple",
      },
    ],
    name: "verifyNFT",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];
