
const Share = (props) => {
    const { style, className } = props

    return (
        <svg style={style} className={className} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M13.5 2.25C12.6716 2.25 12 2.92157 12 3.75C12 4.57843 12.6716 5.25 13.5 5.25C14.3284 5.25 15 4.57843 15 3.75C15 2.92157 14.3284 2.25 13.5 2.25ZM10.5 3.75C10.5 2.09315 11.8431 0.75 13.5 0.75C15.1569 0.75 16.5 2.09315 16.5 3.75C16.5 5.40685 15.1569 6.75 13.5 6.75C11.8431 6.75 10.5 5.40685 10.5 3.75Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M4.5 7.5C3.67157 7.5 3 8.17157 3 9C3 9.82843 3.67157 10.5 4.5 10.5C5.32843 10.5 6 9.82843 6 9C6 8.17157 5.32843 7.5 4.5 7.5ZM1.5 9C1.5 7.34315 2.84315 6 4.5 6C6.15685 6 7.5 7.34315 7.5 9C7.5 10.6569 6.15685 12 4.5 12C2.84315 12 1.5 10.6569 1.5 9Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M13.5 12.75C12.6716 12.75 12 13.4216 12 14.25C12 15.0784 12.6716 15.75 13.5 15.75C14.3284 15.75 15 15.0784 15 14.25C15 13.4216 14.3284 12.75 13.5 12.75ZM10.5 14.25C10.5 12.5931 11.8431 11.25 13.5 11.25C15.1569 11.25 16.5 12.5931 16.5 14.25C16.5 15.9069 15.1569 17.25 13.5 17.25C11.8431 17.25 10.5 15.9069 10.5 14.25Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M5.79548 9.75484C6.00402 9.39695 6.46321 9.27589 6.82109 9.48444L11.9436 12.4694C12.3015 12.678 12.4225 13.1372 12.214 13.4951C12.0054 13.8529 11.5463 13.974 11.1884 13.7655L6.06587 10.7805C5.70799 10.5719 5.58693 10.1127 5.79548 9.75484Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M12.2062 4.50442C12.415 4.86218 12.2943 5.32144 11.9365 5.53021L6.8215 8.51521C6.46375 8.72399 6.00449 8.60322 5.79572 8.24547C5.58694 7.88772 5.70771 7.42846 6.06546 7.21968L11.1805 4.23468C11.5382 4.02591 11.9975 4.14667 12.2062 4.50442Z" fill="white" />
        </svg>
    )
}

export default Share
