import {
  ButtonPrimarySmall,
  ButtonTertiaryGhost,
  ButtonTertiarySmall,
} from "components/elements/Button";
import { $t } from "components/utils/Translation";
import { AppContext, HttpHeaderParam } from "contexts/AppContext";
import { useContext, useEffect, useState } from "react";
import ProfileIcon from "components/icons/ProfileIcon";
import IconButton from "components/elements/IconButton";
import LanguageSelector from "components/elements/LanguageSelector";
import {
  ArtistProfileBanner,
  Banner,
  ProfileBanner,
} from "components/blocks/banner/Banner";
import "./Header.css";
import { Text14Bold } from "components/elements/Text";
import { useMoralis } from "react-moralis";
import { useLocation, useNavigate } from "react-router-dom";
import { Drawer } from "@mui/material";
import BarChart from "components/icons/BarChart";
import Close from "components/icons/Close";
import { userLogout } from "api/apiCalls";
import { customToast } from "components/utils/generic";
import logo from "assets/logo.svg";
import SearchMobile from "components/icons/SearchMobile";
import { TestnetLogo } from "components/elements/TestnetLogo";

const Header = () => {
  const { showBanner, showProfileBanner, showArtistProfileBanner } =
    useContext(AppContext);
  return (
    <header className="w-full">
      <HeaderMenu />
      {showBanner && <Banner />}
      {showProfileBanner && <ProfileBanner />}
      {showArtistProfileBanner && <ArtistProfileBanner />}
    </header>
  );
};

export default Header;

export const HeaderMenu = () => {
  const defaultConnectWalletText = $t("_CONNECT_WALLET_", "Connect Wallet");
  const navigate = useNavigate();
  const location = useLocation();
  const { account, logout, enableWeb3 } = useMoralis();
  const { headerMenuLinks, setShowProviderPopup, userData, setuserData } =
    useContext(AppContext);
  const [smallScreenMenu, setsmallScreenMenu] = useState(false);
  const [connectWalletElementText, setconnectWalletElementText] = useState(
    !!account ? account : defaultConnectWalletText
  );

  const triggerConnection = async () =>
    window && window.ethereum
      ? setShowProviderPopup(true)
      : await enableWeb3({ provider: "walletconnect" });

  const handleConnection = async () => {
    setsmallScreenMenu(false);
    if (!!account) {
      logout();
      userLogout(account);
      setuserData(null);
      localStorage.removeItem(HttpHeaderParam.BearerToken);
      return;
    }
    await triggerConnection();
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setsmallScreenMenu(open);
  };

  const goToCreateNFT = async () => {
    if (!account || !userData || !userData.id) {
      return customToast(
        `Please check if your wallet is connected and you're signed in.`,
        { toastId: "CREATE_NFT_VALIDATION_FAILURE" }
      );
    }
    navigate("/nft/create");
  };

  const goToProfile = () => {
    navigate(`/my-profile`);
  };

  useEffect(() => {
    setsmallScreenMenu(false);
  }, [location]);

  useEffect(() => {
    setconnectWalletElementText(
      !!account
        ? `${account.slice(0, 5)} • • • ${account.slice(-3)}`
        : defaultConnectWalletText
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  return (
    <>
      <div className="absolute flex flex-col bg-lightbg2 backdrop-blur-lg w-full min-h-[90px] z-20">
        <div className="flex justify-center">
          <div className="px-4 xs4:px-9 w-full min-h-[90px] max-w-[1440px] flex items-center justify-between">
            <div id="menu-links" className="flex items-center">
              <TestnetLogo logo={logo} />
              {headerMenuLinks.map((item) => (
                <ButtonTertiaryGhost
                  className="hidden xl:block"
                  textClass={
                    `/${location.hash}` === item.link ? "text-primary1" : ""
                  }
                  text={$t(item.label, item.fallBackText)}
                  key={item.label}
                  onClick={() => navigate(`${item?.link}`)}
                />
              ))}
            </div>
            <div
              id="user-elements"
              className="flex items-center justify-end gap-3 flex-1 "
            >
              <LanguageSelector className="hidden lg:block" />
              <ButtonTertiarySmall
                textClass="text-teritary2 hover:text-teritary1"
                onClick={goToCreateNFT}
                className="hidden lg:block"
                text={$t("_CREATE_NFT_", "Create NFT")}
              />
              <ButtonPrimarySmall
                className="hidden lg:block text-black hover:text-black"
                text={connectWalletElementText}
                onClick={handleConnection}
              />
              {!!account && userData?.id && (
                <IconButton
                  onClick={goToProfile}
                  className="hidden lg:block"
                  icon={<ProfileIcon className="fill-white" />}
                />
              )}
              <IconButton
                className="flex lg:hidden items-center justify-center"
                icon={<SearchMobile className="fill-primary2" />}
              />
              <IconButton
                className="block xl:hidden -rotate-90"
                icon={<BarChart className="fill-white w-8 h-8" />}
                onClick={toggleDrawer(true)}
              />
            </div>
          </div>
        </div>

        <Drawer
          anchor={"right"}
          open={smallScreenMenu}
          onClose={toggleDrawer(false)}
          PaperProps={{
            style: { width: "100%", maxWidth: 500 },
            className: "!bg-baseDark",
          }}
        >
          <div className="px-8 pt-10 pb-80 flex flex-col gap-8 relative scrollable-container overflow-y-scroll">
            <div className="flex justify-end" onClick={toggleDrawer(false)}>
              <Close className="fill-white3" />
            </div>
            {headerMenuLinks.map((item, idx) => (
              <div
                key={idx}
                onClick={() => navigate(`${item.link}`)}
                className="w-full cursor-pointer flex flex-col items-center"
              >
                <Text14Bold
                  className={
                    `/${location.hash}` === item.link ? "text-primary1" : ""
                  }
                >
                  {$t(item.label)}
                </Text14Bold>
              </div>
            ))}
            <ButtonPrimarySmall
              className="lg:hidden block small-screen-menu-user-item-2 text-black hover:text-black w-[140px] mx-auto whitespace-nowrap"
              text={connectWalletElementText}
              onClick={handleConnection}
            />
            <ButtonTertiarySmall
              onClick={goToCreateNFT}
              className="lg:hidden block small-screen-menu-user-item-1 w-[140px] mx-auto whitespace-nowrap"
              text={$t("_CREATE_NFT_", "Create NFT")}
            />
            {!!account && userData?.id && (
              <IconButton
                onClick={goToProfile}
                className=" flex justify-center items-center rounded-3xl lg:hidden p-2 min-h-[36px]"
                icon={<ProfileIcon className="fill-white" />}
              />
            )}
            <LanguageSelector className="lg:hidden block m-auto" />
          </div>
        </Drawer>
      </div>
    </>
  );
};
