import Translate from "components/utils/Translation"

const RouteNotFound404 = (props) => {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100%',
        }}>

            <h3>
                <Translate translationKey="_PAGE_NOT_FOUND_ERROR_MESSAGE_" fallbackText="Page not found." />
            </h3>
        </div>
    )
}

export default RouteNotFound404
