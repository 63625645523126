const PolygonIcon = (props) => {
  const { style, className } = props;
  return (
    <>
      <svg
        style={style}
        className={className}
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.5 17C13.1944 17 17 13.1944 17 8.5C17 3.80558 13.1944 0 8.5 0C3.80558 0 0 3.80558 0 8.5C0 13.1944 3.80558 17 8.5 17Z"
          fill="#8247E5"
        />
        <path
          d="M11.3131 6.68062C11.1092 6.56202 10.8442 6.56202 10.6199 6.68062L9.02968 7.58992L7.94915 8.18294L6.35894 9.09224C6.15505 9.21085 5.89003 9.21085 5.66576 9.09224L4.40173 8.38062C4.19786 8.26202 4.05516 8.04457 4.05516 7.80735V6.40387C4.05516 6.16667 4.17748 5.94922 4.40173 5.83062L5.64537 5.13875C5.84924 5.02015 6.11428 5.02015 6.33855 5.13875L7.58217 5.83062C7.78606 5.94922 7.92876 6.16667 7.92876 6.40387V7.31317L9.00929 6.70037V5.79107C9.00929 5.55387 8.88697 5.33642 8.66272 5.21782L6.35894 3.91317C6.15505 3.79457 5.89003 3.79457 5.66576 3.91317L3.3212 5.21782C3.09693 5.33642 2.97461 5.55387 2.97461 5.79107V8.42015C2.97461 8.65735 3.09693 8.8748 3.3212 8.9934L5.66576 10.298C5.86964 10.4167 6.13467 10.4167 6.35894 10.298L7.94915 9.40852L9.02968 8.79572L10.6199 7.9062C10.8238 7.78759 11.0888 7.78759 11.3131 7.9062L12.5567 8.59805C12.7606 8.71667 12.9033 8.9341 12.9033 9.17132V10.5748C12.9033 10.812 12.781 11.0294 12.5567 11.148L11.3131 11.8597C11.1092 11.9783 10.8442 11.9783 10.6199 11.8597L9.37627 11.1678C9.1724 11.0492 9.02968 10.8318 9.02968 10.5946V9.68527L7.94915 10.298V11.2074C7.94915 11.4446 8.07147 11.662 8.29574 11.7806L10.6403 13.0853C10.8442 13.2039 11.1092 13.2039 11.3335 13.0853L13.678 11.7806C13.8819 11.662 14.0246 11.4446 14.0246 11.2074V8.57829C14.0246 8.34107 13.9023 8.12364 13.678 8.00502L11.3131 6.68062Z"
          fill="white"
        />
      </svg>
    </>
  );
};

export default PolygonIcon;
