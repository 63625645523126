const SideNavProfileIcon = ({ style, className }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      style={style}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.72039 12.8874C4.50179 12.106 5.5616 11.667 6.66667 11.667H13.3333C14.4384 11.667 15.4982 12.106 16.2796 12.8874C17.061 13.6688 17.5 14.7286 17.5 15.8337V17.5003C17.5 17.9606 17.1269 18.3337 16.6667 18.3337C16.2064 18.3337 15.8333 17.9606 15.8333 17.5003V15.8337C15.8333 15.1706 15.5699 14.5347 15.1011 14.0659C14.6323 13.5971 13.9964 13.3337 13.3333 13.3337H6.66667C6.00363 13.3337 5.36774 13.5971 4.8989 14.0659C4.43006 14.5347 4.16667 15.1706 4.16667 15.8337V17.5003C4.16667 17.9606 3.79357 18.3337 3.33333 18.3337C2.8731 18.3337 2.5 17.9606 2.5 17.5003V15.8337C2.5 14.7286 2.93899 13.6688 3.72039 12.8874Z"
        fill="#E3E3E3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 17.4004C2.5 16.8481 2.94772 16.4004 3.5 16.4004H16.5C17.0523 16.4004 17.5 16.8481 17.5 17.4004C17.5 17.9527 17.0523 18.4004 16.5 18.4004H3.5C2.94772 18.4004 2.5 17.9527 2.5 17.4004Z"
        fill="#E3E3E3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0007 3.33366C8.61994 3.33366 7.50065 4.45295 7.50065 5.83366C7.50065 7.21437 8.61994 8.33366 10.0007 8.33366C11.3814 8.33366 12.5006 7.21437 12.5006 5.83366C12.5006 4.45295 11.3814 3.33366 10.0007 3.33366ZM5.83398 5.83366C5.83398 3.53247 7.69946 1.66699 10.0007 1.66699C12.3018 1.66699 14.1673 3.53247 14.1673 5.83366C14.1673 8.13485 12.3018 10.0003 10.0007 10.0003C7.69946 10.0003 5.83398 8.13485 5.83398 5.83366Z"
        fill="#E3E3E3"
      />
    </svg>
  );
};

export default SideNavProfileIcon;
