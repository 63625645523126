import React, { createContext, useEffect, useState } from "react";
import translationsEN from "modules/translation/EN_Translations";
import {
  getTranslation,
  getCollections,
  getTopBuyers,
  getTopSellers,
} from "api/apiCalls";
import { commonEmptyFilter } from "modules/explore/Explore";

export const LangCodes = {
  ENGLISH: "EN",
  KOREAN: "KO",
};

export const baseURI = process.env.REACT_APP_BACKEND_URL;
export const moralisAppId = process.env.REACT_APP_MORALIS_APP_ID;
export const moralisServerUrl = process.env.REACT_APP_MORALIS_SERVER;

export const HttpHeaderParam = {
  BearerToken: "token",
  XChainId: "x-chainid",
};

export const MarketPlaceAddress = process.env.REACT_APP_MARKET_PLACE_ADDRESS;

export const headerMenuItems = [
  // { label: "_HEADER_HOME_", link: "/" },
  { label: "_TRENDING_", link: "/#trending", fallBackText: "Trending" },
  // {
  //   label: "_TOP_SELLERS_",
  //   link: "/#top_seller",
  //   fallBackText: "Top Sellers",
  // },
  { label: "_HOT_BIDS_", link: "/#hot_bids", fallBackText: "Hot Bids" },
  // { label: "_HEADER_TOP_BUYERS_", link: "/#top_buyers" },
  {
    label: "_LIVE_AUCTIONS_",
    link: "/#live_auction",
    fallBackText: "Live Auctions",
  },
  { label: "_EXPLORE_", link: "/#explore", fallBackText: "Explore" },
];

export const AppContext = createContext();

export const AppProvider = (props) => {
  const storedLanguageCode = localStorage.getItem("languageCode");
  const defaultProfilePic = `https://valores-public-files.s3.ap-northeast-2.amazonaws.com/defaultProfilePic.svg`;
  const defaultProfileName = "Leone";
  const defaultName = "Turning Mecard R";
  const defaultPrice = "1.3";
  const defaultPriceUnit = "ETH";
  const defaultFrom = "0x123455";
  const defaultTo = "Leone";
  const defaultType = "Sale";
  const defaultTimeStamp = "an hour ago";
  const [langCode, setLangCode] = useState(
    storedLanguageCode ? storedLanguageCode : LangCodes.ENGLISH
  );
  const [translations, setTranslations] = useState(translationsEN);
  const [headerMenuLinks, setheaderMenuLinks] = useState(headerMenuItems);
  const [userData, setuserData] = useState(null);
  const [signatureTexts, setsignatureTexts] = useState(null);
  const [isLoginReinitiate, setIsLoginReInitiate] = useState({
    key: "",
    isSuccess: false,
  });

  const [showBanner, setShowBanner] = useState(false);
  const [bannerDetails, setbannerDetails] = useState(false);

  const [showProfileBanner, setshowProfileBanner] = useState(false);
  const [profileBannerDetails, setprofileBannerDetails] = useState(false);

  const [showArtistProfileBanner, setshowArtistProfileBanner] = useState(false);
  const [artistProfileBannerDetails, setartistProfileBannerDetails] =
    useState(false);

  const [showProviderPopup, setShowProviderPopup] = useState(false);

  const [activityData, setActivityData] = useState([]);

  const [activityDataLimit, setActivityDataLimit] = useState("");

  const [totalActivityData, setTotalActivityData] = useState("");

  const [collections, setcollections] = useState([]);
  const [isLoadingCollection, setIsLoadingCollection] = useState(true);

  const [topSellers, setTopSellers] = useState([]);

  const [topBuyers, setTopBuyers] = useState([]);

  const changeLanguage = async (lang) => {
    switch (lang) {
      case LangCodes.ENGLISH:
        setLangCode(LangCodes.ENGLISH);
        return;

      case LangCodes.KOREAN:
        setLangCode(LangCodes.KOREAN);
        return;

      default:
        return;
    }
  };

  // const fetchCollections = async () => {
  //   const getCollectionsResponse = await getCollections(emptyFilter);
  //   if (
  //     getCollectionsResponse &&
  //     getCollectionsResponse.data &&
  //     getCollectionsResponse.data.collections
  //   ) {
  //     setcollections(
  //       getCollectionsResponse.data.collections.length
  //         ? getCollectionsResponse.data.collections
  //         : []
  //     );
  //   }
  // };

  // const fetchTopSellers = async () => {
  //   const getTopSellersResponse = await getTopSellers();
  //   if (getTopSellersResponse && getTopSellersResponse.data) {
  //     setTopSellers(getTopSellersResponse.data);
  //   }
  // };

  const fetchCollections = async () => {
    try {
      setIsLoadingCollection(true);
      const getCollectionsResponse = await getCollections(commonEmptyFilter);
      setcollections(getCollectionsResponse);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoadingCollection(false);
    }
  };

  const fetchTopSellers = async () => {
    const getTopSellersResponse = await getTopSellers();
    if (getTopSellersResponse) {
      setTopSellers(getTopSellersResponse);
    }
  };
  const fetchTopBuyers = async () => {
    const getTopBuyersResponse = await getTopBuyers();
    if (getTopBuyersResponse && getTopBuyersResponse.data) {
      setTopBuyers(getTopBuyersResponse.data);
    }
  };

  useEffect(() => {
    fetchCollections();
    fetchTopSellers();
  }, []);

  const value = {
    totalActivityData,
    activityDataLimit,
    topSellers,
    topBuyers,
    collections,
    setcollections,
    LangCodes,
    langCode,
    setLangCode,
    translations,
    setTranslations,
    changeLanguage,
    headerMenuLinks,
    setheaderMenuLinks,
    showBanner,
    setShowBanner,
    showProviderPopup,
    setShowProviderPopup,
    showProfileBanner,
    setshowProfileBanner,
    showArtistProfileBanner,
    setshowArtistProfileBanner,
    userData,
    setuserData,
    signatureTexts,
    setsignatureTexts,
    defaultProfilePic,
    artistProfileBannerDetails,
    setartistProfileBannerDetails,
    profileBannerDetails,
    setprofileBannerDetails,
    bannerDetails,
    setbannerDetails,
    activityData,
    defaultProfileName,
    defaultName,
    defaultFrom,
    defaultPrice,
    defaultPriceUnit,
    defaultTimeStamp,
    defaultTo,
    defaultType,
    isLoadingCollection,
    setIsLoadingCollection,
    isLoginReinitiate,
    setIsLoginReInitiate,
  };
  // Checks for language code change and trigger getTranslationForLangCode method
  useEffect(() => {
    getTranslationForLangCode(langCode);
    localStorage.setItem("languageCode", langCode);
  }, [langCode]);

  // Parse backend response array into hashmap
  const parseTranslationIntoHashMap = (translations, langCode) => {
    const translationHashMap = {};
    translations.forEach((currentTranslation) => {
      translationHashMap[currentTranslation["key"]] =
        currentTranslation[langCode];
      return translationHashMap;
    });
    return translationHashMap;
  };

  // Get translation for selected language code if not stored in localstorage or use data from localstorage
  const getTranslationForLangCode = async (langCode) => {
    const languageCode = langCode.toString().toLowerCase();
    const storedTranslation = localStorage.getItem(languageCode);
    if (
      storedTranslation &&
      Object.keys(JSON.parse(storedTranslation)).length
    ) {
      return setTranslations(JSON.parse(storedTranslation));
    }

    const translationResponse = await getTranslation(languageCode);
    if (
      translationResponse &&
      translationResponse.data &&
      translationResponse.data.length
    ) {
      const parsedTranslation = parseTranslationIntoHashMap(
        translationResponse.data,
        languageCode
      );
      localStorage.setItem(languageCode, JSON.stringify(parsedTranslation));
      return setTranslations(parsedTranslation);
    }
    return setTranslations({});
  };

  return (
    <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
  );
};
