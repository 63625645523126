const { useEffect, useRef } = require("react");

export const LottieLoader = (props) => {
  const ref = useRef(null);

  useEffect(() => {
    import("@lottiefiles/lottie-player");
  }, []);

  return (
    <div className="fixed top-0  left-0 w-full h-full z-50 bg-black bg-opacity-75 flex justify-center items-center inset-0">
      <div
        className={`flex flex-col relative z-50 mx-2 shadow-proto-lg  border-[5px] border-primary2 bg-background rounded-lg
             w-min animate-fade-in-popup`}
        role="dialog"
        aria-modal="true"
      >
        <div className="flex flex-row justify-center items-center">
          <div className="md:px-35 p-[3px]  max-h-[90vh]">
            <div className="relative flex animate-fade-in-popup">
              <lottie-player
                id={props.url}
                ref={ref}
                autoplay
                loop
                background="#191b1e"
                mode="normal"
                src={props.url}
                style={{
                  width: "380px",
                  height: "380px",
                }}
              ></lottie-player>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
