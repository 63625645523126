import React from "react";
import { toast } from "react-toastify";

const toastStyle = {
  backgroundColor: "rgba(0,0,0,0.85)",
  border: "1px solid #E3E3E3",
  borderRadius: "1rem 0 1rem 0",
  width: "fit-content",
  color: "#00FF8B",
  fontWeight: 500,
  overflowWrap: "break-word",
  letterSpacing: 1.5,
  fontFamily: "Noto Sans KR",
};

export const customToast = (message, { toastId, type }) => {
  if (!type) return toast(message, { style: toastStyle, toastId });

  switch (type.toLowerCase()) {
    case "success":
      return toast.success(message, { style: toastStyle });
    case "warning":
      return toast.warning(message, { style: toastStyle });
    case "info":
      return toast.info(message, { style: toastStyle });
    case "error":
      return toast.error(message, { style: toastStyle });
    default:
      return toast(message, { style: toastStyle });
  }
};

export const newLineToBreakTag = (str) => {
  const newlineRegex = /(\r\n|\n\r|\r|\n)/g;

  if (!str) return "";
  if (typeof str === "number") {
    return str;
  } else if (typeof str !== "string") {
    return "";
  }

  return str.split(newlineRegex).map(function (line, index) {
    if (line.match(newlineRegex)) {
      return React.createElement("br", { key: index });
    }
    return line;
  });
};

export const isValidEmail = (email) => /\S+@\S+\.\S+/.test(email);

export const isNumber = (text) => /^\d+$/.test(text);

export const shortWalletAddress = (address) =>
  address ? address?.replace(address.substring(6, 38), "• • •") : "• • •";

export const shortName = (name) =>
  name.substring(0, 15) + (name.length > 15 ? "..." : "");

export const arrUniq = (arr, property) => [
  ...new Map(arr.map((v) => [v[property], v])).values(),
];

export const correctUrl = (url) => {
  var pattern = /^((http|https|ftp):\/\/)/;
  if (!pattern.test(url)) {
    url = "http://" + url;
  }
  return url;
};
