import React from 'react';
import {
    FacebookShareButton,
    TwitterShareButton,
} from "react-share";
import {
    FacebookIcon,
    TwitterIcon,
} from "react-share";
// import { AppContext } from "contexts/AppContext"
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ValoresShortIcon } from "components/icons/ValoresShortIcon"

const SocialShare = (props) => {
    // const { profileBannerDetails } = React.useContext(AppContext)
    const link = `${window.location.protocol}//${window.location.host}${props.copyLink}`
    return (
        <div className="w-[218px] h-[141px] rounded-xl bg-[#1B1E21] text-white capitalize cursor-pointer py-2 z-50">
            <div className="flex gap-3 items-center py-2.5 -mb-0.5 hover:bg-dark3 px-6 text-white">
                <CopyToClipboard text={link}>
                    <div className='flex gap-3 items-center text-white'><ValoresShortIcon /><div className='text-white font-notoSans font-normal text-sm'>Copy Link</div></div>
                </CopyToClipboard>
            </div>
            <div className={'hover:bg-dark3 py-2 -mb-5 text-white font-notoSans font-normal text-sm'}>
            <FacebookShareButton
                url={props.linkFB}
                    quote={props.quoteFB}
                // hashtag={"#hashtag"}
                description={"aiueo"}
                className={'flex gap-x-2 ml-6 hover:bg-dark3'}
            
            >
                <FacebookIcon size={25} round /> Share on Facebook
                </FacebookShareButton>
                </div>
            <br />
            <div className={'hover:bg-dark3 py-2 text-white font-notoSans font-normal text-sm'}>
            <TwitterShareButton
                url={props.socialLink}
                className={'flex gap-x-2 ml-6 hover:bg-dark3'}
            >
                <TwitterIcon size={25} round />
                Share to Twitter
                </TwitterShareButton>
                </div>

            
        </div>
    )
}

export default SocialShare