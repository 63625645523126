import React from "react";
import ReactPaginate from "react-paginate";

const Pagination = ({ children, loading, pagination, ...rest }) => {
  return (
    <section className="w-full mx-auto h-full" {...rest}>
      {children}
      <div className="flex items-center">
        {pagination?.totalPage > 1 && !loading && (
          <div className="my-4 flex justify-start pr-2 sm:pr-0">
            <ReactPaginate
              breakLabel={"..."}
              pageCount={pagination.totalPage}
              marginPagesDisplayed={1}
              pageRangeDisplayed={5}
              onPageChange={(e) => {
                pagination.handlePageClick(e);
                document.documentElement.scrollTop = 0;
              }}
              forcePage={pagination.currentpage - 1}
              containerClassName={`flex space-x-3 text-white ${pagination.containerClassName}`}
              disabledClassName=""
              pageClassName="w-7 h-7 text-xs text-white flex rounded-md cursor-pointer"
              pageLinkClassName="focus:outline-none w-full h-full flex items-center justify-center"
              activeClassName="bg-primary2 text-white font-medium"
              previousClassName={`${
                pagination.currentpage === 1 ? "hidden" : ""
              } rounded-md flex justify-center items center`}
              previousLinkClassName="mt-0.5 focus:outline-none"
              nextClassName={`${
                pagination.totalPage === pagination.currentpage ? "hidden" : ""
              } rounded-md flex justify-center items center`}
              nextLinkClassName="mt-0.5 focus:outline-none"
              previousLabel={`<`}
              nextLabel={`>`}
              breakClassName="w-7 h-7 flex mx-2 text-white rounded cursor-pointer"
              breakLinkClassName="focus:outline-none w-full h-full flex items-center justify-center"
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default Pagination;
