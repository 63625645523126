export const HeartUnfilled = (props) => {
    const { style, className } = props
    return (
        <svg style={style} className={className} width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.8855 4.79757C19.1483 6.03003 18.9542 7.3198 18.3502 8.39851C17.2726 10.2663 15.2151 12.3768 13.3199 14.0904C11.8798 15.3926 10.5883 16.4173 10.0096 16.8661C9.99482 16.8547 9.97957 16.8429 9.96384 16.8308C9.76521 16.6772 9.49241 16.4627 9.16476 16.1977C8.50903 15.6673 7.63732 14.9375 6.70268 14.091C4.8129 12.3794 2.75257 10.2675 1.64754 8.39445C1.04529 7.31656 0.852066 6.02848 1.11447 4.79757C1.37747 3.56389 2.07394 2.49881 3.04255 1.81151C4.00901 1.12572 5.17729 0.863637 6.30988 1.06723C7.44366 1.27104 8.47575 1.93011 9.18432 2.92917L10 4.07924L10.8157 2.92917C11.5242 1.93011 12.5563 1.27104 13.6901 1.06723C14.8227 0.863637 15.991 1.12572 16.9575 1.81151C17.9261 2.49881 18.6225 3.56389 18.8855 4.79757Z" stroke="#2DC79D" stroke-width="2" />
        </svg>


    )
}

export const HeartFilled = (props) => {
    const { style, className } = props
    return (
        <svg style={style} className={className} width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.3745 4.69332C19.6625 6.04402 19.4502 7.459 18.7849 8.64557C17.6657 10.5869 15.5558 12.7429 13.6553 14.4613C11.8167 16.1237 10.2188 17.3375 10.0128 17.4939C10.0127 17.494 10.0125 17.4942 10.0124 17.4943L10.009 17.492C9.88404 17.405 8.2581 16.1743 6.36704 14.4616C4.47079 12.7442 2.3594 10.5875 1.21395 8.64351C0.549523 7.45737 0.337683 6.04324 0.625461 4.69332C0.913537 3.342 1.67825 2.1665 2.7532 1.40374C3.82708 0.641732 5.1311 0.34732 6.39834 0.575118C7.66617 0.803024 8.81088 1.53834 9.59216 2.63992L10 3.21495L10.4078 2.63992C11.1891 1.53834 12.3338 0.803024 13.6017 0.575118C14.8689 0.34732 16.1729 0.641732 17.2468 1.40374C18.3218 2.1665 19.0865 3.342 19.3745 4.69332ZM10.0254 17.5038L10.0251 17.5037L10.0254 17.5038Z" fill="#2DC79D" stroke="#2DC79D" />
        </svg>


    )
}
