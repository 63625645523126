const ProfileIcon = (props) => {
  const { style, className } = props;

  return (
    <svg
      style={style}
      className={className}
      width="19"
      height="19"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.97631 10.3098C3.60143 9.68469 4.44928 9.3335 5.33333 9.3335H10.6667C11.5507 9.3335 12.3986 9.68469 13.0237 10.3098C13.6488 10.9349 14 11.7828 14 12.6668V14.0002C14 14.3684 13.7015 14.6668 13.3333 14.6668C12.9651 14.6668 12.6667 14.3684 12.6667 14.0002V12.6668C12.6667 12.1364 12.456 11.6277 12.0809 11.2526C11.7058 10.8775 11.1971 10.6668 10.6667 10.6668H5.33333C4.8029 10.6668 4.29419 10.8775 3.91912 11.2526C3.54405 11.6277 3.33333 12.1364 3.33333 12.6668V14.0002C3.33333 14.3684 3.03486 14.6668 2.66667 14.6668C2.29848 14.6668 2 14.3684 2 14.0002V12.6668C2 11.7828 2.35119 10.9349 2.97631 10.3098Z"
        fill="#E3E3E3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 13.9201C2 13.4783 2.35817 13.1201 2.8 13.1201H13.2C13.6418 13.1201 14 13.4783 14 13.9201C14 14.3619 13.6418 14.7201 13.2 14.7201H2.8C2.35817 14.7201 2 14.3619 2 13.9201Z"
        fill="#E3E3E3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00033 2.66683C6.89576 2.66683 6.00033 3.56226 6.00033 4.66683C6.00033 5.7714 6.89576 6.66683 8.00033 6.66683C9.10489 6.66683 10.0003 5.7714 10.0003 4.66683C10.0003 3.56226 9.10489 2.66683 8.00033 2.66683ZM4.66699 4.66683C4.66699 2.82588 6.15938 1.3335 8.00033 1.3335C9.84127 1.3335 11.3337 2.82588 11.3337 4.66683C11.3337 6.50778 9.84127 8.00016 8.00033 8.00016C6.15938 8.00016 4.66699 6.50778 4.66699 4.66683Z"
        fill="#E3E3E3"
      />
    </svg>
  );
};

export default ProfileIcon;
