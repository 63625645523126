
const Send = (props) => {
    const { style, className } = props

    return (
        <svg style={style} className={className} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M16.0849 0.915718C16.3615 1.19234 16.3615 1.64083 16.0849 1.91745L8.29318 9.70912C8.01656 9.98574 7.56807 9.98574 7.29145 9.70912C7.01483 9.4325 7.01483 8.98401 7.29145 8.70738L15.0831 0.915718C15.3597 0.639097 15.8082 0.639097 16.0849 0.915718Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M16.0849 0.915738C16.2773 1.10815 16.3424 1.39377 16.2526 1.6506L11.2942 15.8173C11.1983 16.0914 10.9447 16.2792 10.6545 16.291C10.3643 16.3029 10.0963 16.1364 9.97837 15.871L7.25568 9.74491L1.12964 7.02222C0.864241 6.90427 0.697738 6.63625 0.709577 6.34606C0.721416 6.05588 0.909198 5.80232 1.18332 5.70637L15.35 0.748039C15.6068 0.658147 15.8924 0.723328 16.0849 0.915738ZM3.33767 6.45328L8.08 8.56099C8.24025 8.63221 8.36838 8.76034 8.4396 8.92059L10.5473 13.6629L14.4294 2.57117L3.33767 6.45328Z" fill="white" />
        </svg>
    )
}

export default Send
