import React from "react";
import Loader from "../../assets/fullpageloader.gif";

const FullPageLoader = ({ className }) => {
  return (
    <div className="h-full min-h-screen flex justify-center items-center">
      <div className={className}>
        <img src={Loader} alt="loader" className="h-full w-full" />
      </div>
    </div>
  );
};

export default FullPageLoader;
