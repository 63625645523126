import React from "react";

const CommingSoon = () => {
  return (
    <div
      style={{ height: "50vh" }}
      className="flex justify-center items-center text-2xl text-white"
    >
      Comming Soon!
    </div>
  );
};

export default CommingSoon;
