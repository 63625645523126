
const Badge = (props) => {
    const { style, className } = props

    return (
        <svg style={style} className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_376_8356)">
        <path fillRule="evenodd" clipRule="evenodd" d="M9.99967 1.66667C7.23825 1.66667 4.99967 3.90524 4.99967 6.66667C4.99967 9.42809 7.23825 11.6667 9.99967 11.6667C12.7611 11.6667 14.9997 9.42809 14.9997 6.66667C14.9997 3.90524 12.7611 1.66667 9.99967 1.66667ZM3.33301 6.66667C3.33301 2.98477 6.31778 0 9.99967 0C13.6816 0 16.6663 2.98477 16.6663 6.66667C16.6663 10.3486 13.6816 13.3333 9.99967 13.3333C6.31778 13.3333 3.33301 10.3486 3.33301 6.66667Z" fill="#E3E3E3"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M13.0488 10.7407C13.505 10.6802 13.9239 11.001 13.9844 11.4572L14.9928 19.0572C15.0349 19.3744 14.8916 19.6877 14.6242 19.8634C14.3567 20.039 14.0123 20.046 13.7379 19.8814L10 17.6387L6.2621 19.8814C5.98771 20.046 5.64327 20.039 5.37582 19.8633C5.10837 19.6876 4.96514 19.3743 5.00727 19.0571L6.01561 11.4654C6.07621 11.0092 6.49518 10.6885 6.95141 10.7491C7.40764 10.8097 7.72836 11.2287 7.66777 11.6849L6.88706 17.5628L9.57127 15.9522C9.83518 15.7939 10.1649 15.7939 10.4288 15.9522L13.1133 17.5629L12.3323 11.6764C12.2717 11.2202 12.5925 10.8013 13.0488 10.7407Z" fill="#E3E3E3"/>
        </g>
        <defs>
        <clipPath id="clip0_376_8356">
        <rect width="20" height="20" fill="white"/>
        </clipPath>
        </defs>
        </svg>   
    )
}

export default Badge
