import { CircularProgress } from "@mui/material";
import DialogWrapper from "components/DialogWrapper";
import { Tick } from "components/icons/Tick";
import React from "react";

const ConfirmationModal = ({
  heading = "Confirmation",
  content = (
    <div className="text-base text-white">
      <span className="opacity-70">
        Are you sure you want to Publish this Batch
      </span>
      <span className="font-bold"> 0x8492849284...898989</span>
    </div>
  ),
  open,
  setOpen,
  primaryBtnText = "Confirm",
  onSuccess = () => {},
  secondaryBtnText = "Close",
  loading = false,
}) => {
  return (
    <DialogWrapper {...{ open, setOpen }}>
      <div className="max-w-[840px] font-notoSans w-full bg-[#1B1E21] px-10 py-6 text-white">
        <div className="flex items-center">
          <div className="w-10 h-10 min-w-[10px] bg-[#22C373] rounded-full flex justify-center items-center">
            <Tick className="fill-white" />
          </div>
          <div className="flex flex-col space-y-1 ml-6">
            <div className="text-xl font-black">{heading}</div>
            <div>{content}</div>
          </div>
        </div>
        <div className="pt-4 flex justify-end items-center space-x-2">
          <div
            disabled={loading}
            onClick={() => !loading && setOpen(false)}
            className={`text-[#2DC79D] transition-all ${
              loading ? "opacity-10 cursor-not-allowed" : "cursor-pointer"
            } transform duration-500 text-sm font-bold p-2 rounded-lg hover:bg-black hover:bg-opacity-20`}
          >
            {secondaryBtnText}
          </div>
          <div
            disabled={loading}
            onClick={() => !loading && onSuccess()}
            className={`text-[#2DC79D] flex items-center space-x-1 transition-all ${
              loading ? "opacity-10 cursor-not-allowed" : "cursor-pointer"
            } transform duration-500 text-sm font-bold p-2 rounded-lg hover:bg-black hover:bg-opacity-20`}
          >
            <div>{primaryBtnText} </div>
            <div>
              {loading && (
                <CircularProgress
                  size={16}
                  sx={{
                    color: "inherit",
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </DialogWrapper>
  );
};

export default ConfirmationModal;
