const Lock = ({ style, className }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.16602 9.99967C3.70578 9.99967 3.33268 10.3728 3.33268 10.833V16.6663C3.33268 17.1266 3.70578 17.4997 4.16602 17.4997H15.8327C16.2929 17.4997 16.666 17.1266 16.666 16.6663V10.833C16.666 10.3728 16.2929 9.99967 15.8327 9.99967H4.16602ZM1.66602 10.833C1.66602 9.4523 2.7853 8.33301 4.16602 8.33301H15.8327C17.2134 8.33301 18.3327 9.4523 18.3327 10.833V16.6663C18.3327 18.0471 17.2134 19.1663 15.8327 19.1663H4.16602C2.7853 19.1663 1.66602 18.0471 1.66602 16.6663V10.833Z"
        fill="#E3E3E3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2.49967C9.11594 2.49967 8.2681 2.85086 7.64298 3.47599C7.01786 4.10111 6.66667 4.94895 6.66667 5.83301V9.16634C6.66667 9.62658 6.29357 9.99967 5.83333 9.99967C5.3731 9.99967 5 9.62658 5 9.16634V5.83301C5 4.50693 5.52678 3.23516 6.46447 2.29747C7.40215 1.35979 8.67392 0.833008 10 0.833008C11.3261 0.833008 12.5979 1.35979 13.5355 2.29747C14.4732 3.23516 15 4.50693 15 5.83301V9.16634C15 9.62658 14.6269 9.99967 14.1667 9.99967C13.7064 9.99967 13.3333 9.62658 13.3333 9.16634V5.83301C13.3333 4.94895 12.9821 4.10111 12.357 3.47599C11.7319 2.85086 10.8841 2.49967 10 2.49967Z"
        fill="#E3E3E3"
      />
    </svg>
  );
};

export default Lock;
