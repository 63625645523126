const Telegram2 = (props) => {
    const { style, className } = props

    return (
        <svg style={style} className={className} width="25" height="22" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M1.71862 9.39452C8.42949 6.46898 12.9045 4.54029 15.1435 3.60844C21.5365 0.947817 22.8649 0.485639 23.7307 0.470378C23.9212 0.467021 24.347 0.514243 24.6228 0.738176C24.8557 0.927261 24.9197 1.18269 24.9504 1.36196C24.9811 1.54123 25.0192 1.94962 24.9889 2.26872C24.6424 5.91091 23.1434 14.7495 22.3808 18.8288C22.0581 20.555 21.4227 21.1337 20.8076 21.1903C19.4707 21.3134 18.4556 20.3064 17.1608 19.4571C15.1348 18.1282 13.9901 17.301 12.0235 16.0042C9.75066 14.5056 11.224 13.6819 12.5193 12.3358C12.8583 11.9835 18.7484 6.62284 18.8624 6.13654C18.8767 6.07572 18.8899 5.84902 18.7553 5.72931C18.6207 5.6096 18.422 5.65054 18.2787 5.68309C18.0754 5.72924 14.8388 7.86983 8.56858 12.1049C7.64986 12.7361 6.8177 13.0437 6.07212 13.0275C5.25018 13.0098 3.6691 12.5625 2.49372 12.1802C1.05207 11.7113 -0.0937162 11.4634 0.00605648 10.6671C0.0580243 10.2523 0.628878 9.82813 1.71862 9.39452Z" fill="white" />
        </svg>
    )
}

export default Telegram2
