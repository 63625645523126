const FastForward = () => {
    return (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.89713 2.90528C9.14015 2.78642 9.42966 2.81637 9.64321 2.98247L16.0182 7.9408C16.1907 8.075 16.2917 8.28134 16.2917 8.49992C16.2917 8.71851 16.1907 8.92485 16.0182 9.05905L9.64321 14.0174C9.42966 14.1835 9.14015 14.2134 8.89713 14.0946C8.6541 13.9757 8.5 13.7288 8.5 13.4583V3.54159C8.5 3.27105 8.6541 3.02414 8.89713 2.90528ZM9.91667 4.98988V12.01L14.4296 8.49992L9.91667 4.98988Z" fill="white" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.10513 2.90528C1.34816 2.78642 1.63767 2.81637 1.85122 2.98247L8.22622 7.9408C8.39876 8.075 8.49967 8.28134 8.49967 8.49992C8.49967 8.71851 8.39876 8.92485 8.22622 9.05905L1.85122 14.0174C1.63767 14.1835 1.34816 14.2134 1.10513 14.0946C0.862107 13.9757 0.708008 13.7288 0.708008 13.4583V3.54159C0.708008 3.27105 0.862107 3.02414 1.10513 2.90528ZM2.12467 4.98988V12.01L6.63759 8.49992L2.12467 4.98988Z" fill="white" />
        </svg>

    )
}

export default FastForward