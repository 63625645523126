import React, { Fragment, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import logo from "assets/admin-logo.svg";
import Layer from "../../components/icons/Layer";
import Bell from "components/icons/Bell";
import Lock from "components/icons/Lock";
import Setting from "components/icons/setting";
import { Dialog, Transition } from "@headlessui/react";
import Close from "components/icons/Close";
import BarChart from "components/icons/BarChart";
import SideNavProfileIcon from "components/icons/sideNavProfile";
import { Text14Bold } from "components/elements/Text";
import { useMoralis } from "react-moralis";
import { userLogout } from "api/apiCalls";
import ReportIcon from "components/icons/ReportIcon";
import { HttpHeaderParam } from "contexts/AppContext";

const sidePanelMenu = [
  {
    name: "Batches",
    icon: <Layer className="text-white h-full w-full" />,
    href: "/admin/batch",
  },
  {
    name: "Artists",
    icon: <SideNavProfileIcon className="fill-white  h-full w-full" />,
    href: "/admin/artist",
  },
  {
    name: "Customers",
    icon: <SideNavProfileIcon className="fill-white  h-full w-full" />,
    href: "/admin/customer",
  },
  {
    name: "Notifications",
    icon: <Bell className="fill-white h-full w-full" />,
    href: "/admin/notification",
  },
  {
    name: "Admins",
    icon: <Lock className="fill-white h-full w-full" />,
    href: "/admin/admin",
  },
  {
    name: "Reports",
    icon: <ReportIcon className="fill-white h-full w-full" />,
    href: "/admin/report",
  },
  {
    name: "Settings",
    icon: <Setting className="fill-white h-full w-full" />,
    href: "/admin/setting",
  },
];

const AdminLayout = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { account, logout } = useMoralis();

  const triggerLogout = () => {
    if (!!account) {
      logout();
      userLogout(account);
      localStorage.removeItem(HttpHeaderParam.BearerToken);
      return;
    }
  };
  return (
    <div className="bg-baseDark h-screen flex overflow-hidden font-notoSans">
      {/* Sidebar */}
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex lg:hidden"
          style={{ zIndex: 100 }}
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex flex-col w-full sm:w-2/5 pt-5 pb-4 bg-dark1">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <Close className="fill-white h-6 w-6" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-shrink-0 flex items-center px-4">
                <img
                  className="h-8 w-auto"
                  onClick={() => navigate("/")}
                  src={logo}
                  alt="Workflow"
                />
              </div>
              <div className="mt-5 flex-1 h-0 overflow-y-auto py-10">
                <nav className="px-2 space-y-1">
                  {sidePanelMenu?.map((menu, index) => (
                    <div className={`p-3`} key={index}>
                      <div
                        className={`flex py-3 hover:bg-secondary2 hover:rounded cursor-pointer ${
                          pathname === menu?.href ? "bg-secondary2 rounded" : ""
                        }`}
                        onClick={() => navigate(menu?.href)}
                      >
                        <div className="w-5 h-5 mx-3">
                          {menu.icon}
                          {/* <img src="" className="w-full h-full"/> */}
                        </div>
                        <div className="text-white1 text-sm">{menu?.name}</div>
                      </div>
                    </div>
                  ))}
                </nav>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>
      {/* Side nav section */}
      <div className="bg-dark1 hidden lg:flex lg:flex-shrink-0">
        <div className="flex flex-col max-w-[280px] px-9">
          <div className="flex flex-col flex-grow overflow-y-auto">
            {/* Logo Container */}
            <div className="py-9">
              <img src={logo} alt="company_logo" className="w-full h-full" />
            </div>
            {/* nav section */}
            <div className="w-full pb-5">
              {sidePanelMenu?.map((menu, index) => (
                <div className={`p-3`} key={index}>
                  <div
                    className={`flex py-3 hover:bg-secondary2 hover:rounded cursor-pointer ${
                      pathname === menu?.href ? "bg-secondary2 rounded" : ""
                    }`}
                    onClick={() => navigate(menu?.href)}
                  >
                    <div className="w-5 h-5 mx-3">
                      {menu.icon}
                      {/* <img src="" className="w-full h-full"/> */}
                    </div>
                    <div className="text-white1 text-sm">{menu?.name}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* Right side section */}
      <div className="flex flex-col  flex-1 overflow-hidden">
        {/* Mobile header */}
        <div className="relative z-10 lg:hidden flex-shrink-0 flex shadow p-5">
          <button
            type="button"
            className="pr-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <BarChart
              className="h-6 w-6 fill-white rotate-90"
              aria-hidden="true"
            />
          </button>
          <div className="flex-1 flex justify-between">
            <div className="ml-4 items-center w-full flex justify-end lg:ml-6">
              <div
                onClick={() => triggerLogout()}
                className="bg-secondary2 py-[14px] px-9 flex  rounded-full text-white cursor-pointer text-sm font-bold"
              >
                <Text14Bold className={"text-white"}>
                  {account?.slice(0, 5)} • • • {account?.slice(-3)}
                </Text14Bold>
              </div>
            </div>
          </div>
        </div>

        <Outlet />
      </div>
    </div>
  );
};

export default AdminLayout;
