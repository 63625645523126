import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useState } from "react";
import { Text14Bold } from "./Text";

const StyledTabs = styled((props) => (
    <Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />
))({
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
        maxWidth: 65,
        width: '100%',
        backgroundColor: '#00FF8B',
    },
    '& .MuiTabs-scrollButtons': {
        color: '#FFF'
    },
    '& .MuiTabs-scrollButtons.Mui-disabled': {
        opacity: 0.3,
    }
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    () => ({
        textTransform: 'none',
        marginRight: 30,
        color: '#87898A',
        '&.Mui-selected': {
            color: '#FFFFFF',
        },
        '&.Mui-focusVisible': {
            // backgroundColor: '',
        },
    }),
);

const VerticalStyledTab = styled((props) => <Tab disableRipple {...props} />)(
    () => ({
        textTransform: 'none',
        marginRight: 30,
        color: '#87898A',
        '&.Mui-selected': {
            color: '#00FF8B',
        },
        '&.Mui-focusVisible': {
            // backgroundColor: '',
        },
    }),
);


const TabSection = (props) => {
    const { captions, panels } = props
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return <>
        <StyledTabs
            variant="scrollable"
            scrollButtons="auto"
            className="mb-12 border-b-2 border-dark1 "
            value={value}
            onChange={handleChange}
            allowScrollButtonsMobile
            aria-label="styled tabs example">
            {
                captions.map((item, idx) => <StyledTab key={idx} label={<Text14Bold>{item}</Text14Bold>} />)
            }
        </StyledTabs>
        {
            panels.map((item, idx) => (value === idx) ? <div key={idx}>{item}</div> : null)
        }
    </>
}

export default TabSection

export const VerticalTabSection = (props) => {
    const { captions, panels, icons } = props
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return <div className="flex gap-4 flex-col lg:flex-row">
        <StyledTabs
            orientation="vertical"
            variant="scrollable"
            scrollButtons="auto"
            className="mb-12 border-b-2 border-dark1"
            value={value}
            onChange={handleChange}
            allowScrollButtonsMobile
            aria-label="styled tabs example"
            >
            {
                captions.map((item, idx) => <VerticalStyledTab key={idx} label={<div className="flex items-center self-start">
                    {icons && icons[idx] ? icons[idx] : null }
                    <Text14Bold className="text-inherit">{item}</Text14Bold>
                </div>
                } />)
            }
        </StyledTabs>
        {
            panels.map((item, idx) => (value === idx) ? <div className='flex-1 w-full' key={idx}>{item}</div> : null)
        }
    </div>
}