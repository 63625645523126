import { userLogout } from "api/apiCalls";
import { Text14Bold } from "components/elements/Text";
import { HttpHeaderParam } from "contexts/AppContext";
import React from "react";
import { useMoralis } from "react-moralis";

const AdminHeader = ({
  children,
  searchText,
  setSeachText = () => {},
  pageActions = null,
}) => {
  const { account, logout } = useMoralis();

  const triggerLogout = () => {
    if (!!account) {
      logout();
      userLogout(account);
      localStorage.removeItem(HttpHeaderParam.BearerToken);
      return;
    }
  };

  return (
    <div className="relative z-10 flex-shrink-0 flex flex-col sm:flex-row sm:items-center bg-baseDark p-5">
      {/* left section */}
      {children}
      {/* Action Section  */}
      <div className="flex flex-1 justify-between sm:justify-end space-x-4 sm:ml-4 items-center pt-4 sm:pt-0">
        {/* Search input */}
        <div className="max-w-screen-xs4 flex-1 flex w-full p-3 rounded-full bg-dark1">
          <input
            className="w-full bg-transparent outline-0 text-white placeholder-grey2 text-xs"
            placeholder="Search"
            value={searchText}
            name="input_search"
            onChange={(e) => setSeachText(e?.target?.value)}
          />
        </div>
        {pageActions}
        <div
          onClick={() => triggerLogout()}
          className="bg-secondary2 p-3 hidden lg:flex  rounded-full text-white cursor-pointer text-xs font-bold"
        >
          <Text14Bold className={"text-white"}>
            {account?.slice(0, 5)} • • • {account?.slice(-3)}
          </Text14Bold>
        </div>
      </div>
    </div>
  );
};

export default AdminHeader;
