const Verified = (props) => {
    const { style, className } = props

    return (
        <svg className={className || ''} style={style} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.7681 6.64549C13.0213 5.27945 14.9787 5.27945 15.2319 6.64549C15.4131 7.62267 16.6053 8.01006 17.3262 7.32598C18.334 6.36967 19.9176 7.52018 19.3195 8.77418C18.8917 9.6712 19.6285 10.6854 20.6139 10.5557C21.9913 10.3744 22.5962 12.236 21.3752 12.899C20.5019 13.3732 20.5019 14.6268 21.3752 15.101C22.5962 15.764 21.9913 17.6256 20.6139 17.4443C19.6285 17.3146 18.8917 18.3288 19.3195 19.2258C19.9176 20.4798 18.334 21.6303 17.3262 20.674C16.6053 19.9899 15.4131 20.3773 15.2319 21.3545C14.9787 22.7206 13.0213 22.7206 12.7681 21.3545C12.5869 20.3773 11.3947 19.9899 10.6738 20.674C9.66596 21.6303 8.08242 20.4798 8.68049 19.2258C9.10831 18.3288 8.37146 17.3146 7.38613 17.4443C6.00869 17.6256 5.40383 15.764 6.62477 15.101C7.49814 14.6268 7.49814 13.3732 6.62477 12.899C5.40383 12.236 6.00869 10.3744 7.38613 10.5557C8.37146 10.6854 9.10831 9.6712 8.68049 8.77418C8.08242 7.52018 9.66596 6.36967 10.6738 7.32598C11.3947 8.01006 12.5869 7.62267 12.7681 6.64549Z" fill="#3ECA76" />
            <path d="M10.8906 14.3884L13.224 16.3328L17.5017 11.6661" stroke="white" strokeWidth="1.5" />
        </svg>

    )
}

export default Verified
