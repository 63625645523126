import { Text14Regular, Text18Bold } from "components/elements/Text";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import Checkbox from "./Checkbox";
import { $t } from "components/utils/Translation";

export const CardContainer = (props) => {
  const { style, className, onClick, isDisable } = props;
  return (
    <>
      <div
        onClick={!isDisable ? onClick : null}
        style={style}
        className={`bg-dark1 border ${
          isDisable
            ? "cursor-not-allowed opacity-50 select-none grayscale"
            : "cursor-pointer opacity-100"
        } border-lightbg2 py-5 px-7 w-full h-auto overflow-y-scroll rounded-3xl ${
          className || ""
        }`}
      >
        {props.children}
      </div>
    </>
  );
};

export const RangeFilter = (props) => {
  const {
    value,
    onChange,
    label,
    unit,
    prefix,
    caption,
    className,
    captionClasses,
    sliderClasses,
  } = props;
  // for min-max kind of slider, supply an array of length to value. [min, max]

  const RangeSlider = styled(Slider)({
    // color: '#52af77',
    height: 8,
    "& .MuiSlider-track": {
      border: "1px solid rgba(255, 255, 255, 0.06)",
    },
    "& .MuiSlider-rail": {
      border: "1px solid rgba(255, 255, 255, 0.06)",
      backgroundColor: "#414B5D",
    },
    "& .MuiSlider-thumb": {
      height: 28,
      width: 28,
      backgroundColor: "#101316",
      border: "8px solid currentColor",
      "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
        boxShadow: "inherit",
      },
      "&:before": {
        display: "none",
      },
    },
    "& .MuiSlider-valueLabel": {
      lineHeight: 1.2,
      fontSize: 12,
      background: "unset",
      padding: 0,
      width: 32,
      height: 32,
      borderRadius: "50% 50% 50% 0",
      backgroundColor: "#52af77",
      transformOrigin: "bottom left",
      transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
      "&:before": { display: "none" },
      "&.MuiSlider-valueLabelOpen": {
        transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
      },
      "& > *": {
        transform: "rotate(45deg)",
      },
    },
  });
  return (
    <>
      <CardContainer
        className={`flex flex-col justify-between min-h-[190px] ${
          className || ""
        }`}
      >
        <Text18Bold className={captionClasses || ""}>
          {caption || ""}
        </Text18Bold>
        <RangeSlider
          className={`!text-primary1 ${sliderClasses || ""}`}
          valueLabelDisplay="off"
          getAriaLabel={() => label || ""}
          value={value}
          onChange={onChange}
          getAriaValueText={() => value}
        />
        <Text14Regular className="text-white1">{`${prefix || ""} ${value[0]} ${
          unit || ""
        } - ${value[1]} ${unit || ""}`}</Text14Regular>
      </CardContainer>
    </>
  );
};

export const CheckboxFilter = (props) => {
  const {
    options,
    onChange,
    caption,
    className,
    captionClasses,
    selectedItems,
  } = props;
  // options is an array of strings (label)
  if (!options || !options.length) return null;
  return (
    <>
      <CardContainer
        className={`flex flex-col overflow-hidden ${className || ""}`}
      >
        <Text18Bold className={`mb-6 ${captionClasses || ""}`}>
          {caption || ""}
        </Text18Bold>
        <div className="flex flex-col overflow-y-scroll scrollable-container-with-scrollbar">
          {caption === `${$t("_BLOCKCHAIN_", "Blockchain")}` ||
          caption === "Sale Type" ? (
            <div className="flex flex-col overflow-y-scroll scrollable-container-with-scrollbar">
              {options.map((item, idx) => (
                <Checkbox
                  key={idx}
                  onChange={onChange}
                  item={item}
                  selectedItems={selectedItems}
                  label={
                    <Text14Regular className="text-white capitalize flex gap-2 items-center">
                      {item.icon}
                      {item.name}
                    </Text14Regular>
                  }
                />
              ))}
            </div>
          ) : (
            <div className="flex flex-col overflow-y-scroll scrollable-container-with-scrollbar">
              {options.map((item, idx) => (
                <Checkbox
                  key={idx}
                  onChange={onChange}
                  label={
                    <Text14Regular className="text-white capitalize ">
                      {item}
                    </Text14Regular>
                  }
                />
              ))}
            </div>
          )}
        </div>
      </CardContainer>
    </>
  );
};
