const Sort = (props) => {
    const { style, className } = props

    return <>
        <svg style={style} className={className} width="18px" height="12px" viewBox="0 0 18 12" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Rounded" transform="translate(-341.000000, -1532.000000)">
                    <g id="Content" transform="translate(100.000000, 1428.000000)">
                        <g id="-Round-/-Content-/-sort" transform="translate(238.000000, 98.000000)">
                            <g>
                                <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                                <path d="M4,18 L8,18 C8.55,18 9,17.55 9,17 C9,16.45 8.55,16 8,16 L4,16 C3.45,16 3,16.45 3,17 C3,17.55 3.45,18 4,18 Z M3,7 C3,7.55 3.45,8 4,8 L20,8 C20.55,8 21,7.55 21,7 C21,6.45 20.55,6 20,6 L4,6 C3.45,6 3,6.45 3,7 Z M4,13 L14,13 C14.55,13 15,12.55 15,12 C15,11.45 14.55,11 14,11 L4,11 C3.45,11 3,11.45 3,12 C3,12.55 3.45,13 4,13 Z" id="🔹Icon-Color" fill="#1D1D1D"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    </>
}

export default Sort

