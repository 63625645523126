// call to Valores Backend api
import { baseURI,HttpHeaderParam } from "../contexts/AppContext";
export const callAPI = (
  path,
  method = "GET",
  params = { body: null, query: null }
) => {
  if (!path) {
    console.error(`Path is empty`);
    return `Path is empty`;
  }
  const requestBody = params.body;
  let endpoint = `/${path}`;
  if (params.query) {
    endpoint += "?" + serialize(params.query);
  }
  // endpoint = `https://valores-stage-api.herokuapp.com${endpoint}`;
  endpoint = `${baseURI}${endpoint}`;
  const authorizationToken = "Bearer " + localStorage.getItem(HttpHeaderParam.BearerToken)
  const authorizationXChain = localStorage.getItem(HttpHeaderParam.XChainId)
  const fetchOptions = {
    method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
       "Authorization":authorizationToken,
       "x-chainid":authorizationXChain
    },
    credentials: "include",
  };
  if (!["GET", "HEAD"].includes(method.trim().toUpperCase()) && requestBody) {
    fetchOptions.body = JSON.stringify(requestBody);
  }
  return fetch(endpoint, fetchOptions)
    .then((response) => {
      return response.json();
    })
    .then(async (responseJSON) => responseJSON)
    .catch((error) => {
      console.error(error);
      return error;
    });
};

// call to AWS
export const callAWS = (endpoint, method = "GET", params = { body: {} }) => {
  if (!endpoint) return;
  const fetchOptions = {
    method,
    header: {
      "Content-Type": params.body.type,
    },
  };
  if (!["GET", "HEAD"].includes(method.trim().toUpperCase())) {
    fetchOptions.body = params.body;
  }
  return fetch(endpoint, fetchOptions)
    .then((response) => response.json())
    .then((responseJSON) => responseJSON)
    .catch((error) => {
      console.error(error);
      return error;
    });
};

export const callCoingecko = (
  endpoint,
  method = "GET",
  params = { body: {} }
) => {
  // https://api.coingecko.com/api/v3/simple/price?ids=WETH&vs_currencies=USD
  if (!endpoint) return;
  const baseUrl = "https://api.coingecko.com/api/v3/";
  const fetchOptions = {
    method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  endpoint = baseUrl + endpoint;
  if (params.query) {
    endpoint += "?" + serialize(params.query);
  }
  if (!["GET", "HEAD"].includes(method.trim().toUpperCase())) {
    fetchOptions.body = JSON.stringify(params.body);
  }
  return fetch(endpoint, fetchOptions)
    .then((response) => {
      console.log(response);
      return response.json();
    })
    .then(async (responseJSON) => responseJSON)
    .catch((error) => {
      console.error(error);
      return error;
    });
};

export const serialize = (obj) => {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
};
