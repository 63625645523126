import ChevronDown from "components/icons/ChevronDown";
import { AppContext } from "contexts/AppContext";
import { useContext, useState } from "react";
import { ButtonTertiaryGhost } from "components/elements/Button";
import DropdownOptions from "components/elements/DropdownOptions";

export const LanguageSelector = (props) => {
  const { LangCodes, langCode, setLangCode } = useContext(AppContext);
  const { className, style } = props;
  const [showOptions, setShowOptions] = useState(false);
  const options = Object.values(LangCodes).map((item) => item.toLowerCase());

  const selectLang = (val) => {
    if (!val) return;
    const newlang = val.toUpperCase();
    setLangCode(newlang);
    setShowOptions(false);
  };

  return (
    <>
      <div className={`relative w-20 ${className}`} style={style}>
        <div
          className="absolute top-0"
          style={{ transform: "translateY(-50%)" }}
        >
          <ButtonTertiaryGhost
            onClick={() => setShowOptions(!showOptions)}
            className="inline-flex justify-center w-auto text-sm font-medium text-white1 focus:outline-none"
          >
            <span className="flex grow shrink basis-0 items-center gap-2">
              {langCode}{" "}
              <ChevronDown
                className={`fill-white1 w-2 h-2 ${
                  showOptions ? "rotate-180" : ""
                }`}
                aria-hidden="true"
              />
            </span>
          </ButtonTertiaryGhost>
        </div>
        {showOptions && (
          <DropdownOptions
            onClick={(val) => selectLang(val)}
            className="absolute top-10 min-w-[12rem] ml-4 lg:left-0"
            options={options}
          />
        )}
      </div>
    </>
  );
};

export default LanguageSelector;
