import { getReportList } from "api/adminApiCalls";
import AdminHeader from "components/layouts/AdminHeader";
import Table from "components/Table";
import React, { useEffect, useState } from "react";
import moment from "moment";
import useDebounce from "hooks/useDebounce";

const columns = [
  {
    name: "id",
    label: "ID",
    options: {
      customBodyRender: (value) => <div className="w-[50px]">{value}</div>,
    },
  },
  {
    name: "user",
    label: "User Address",
    options: {
      customBodyRender: (value) => (
        <div className="w-[150px]">
          {value?.walletAddress
            ? `${value?.walletAddress?.slice(
                0,
                5
              )}• • •${value?.walletAddress?.slice(-3)}`
            : "-"}
        </div>
      ),
    },
  },
  {
    name: "kind",
    label: "Reason",
    options: {
      customBodyRender: (value) => (
        <div className="w-[150px]">{value ?? "-"}</div>
      ),
    },
  },
  {
    name: "description",
    label: "Description",
    options: {
      customBodyRender: (value) => (
        <div className="text-white max-w-[300px] line-clamp-2 text-ellipsis overflow-hidden">
          {value ?? "-"}
        </div>
      ),
    },
  },
  {
    name: "createdOn",
    label: "Reported on",
    options: {
      customBodyRender: (value) => (
        <>
          {value ? (
            <div>
              <div>{moment(new Date(value)).format("MMM DD, yyyy")}</div>
              <div>{moment(new Date(value)).format("hh:mm A")}</div>
            </div>
          ) : (
            "-"
          )}
        </>
      ),
    },
  },
];

const Report = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [searchText, setSeachText] = useState("");
  const debounceValue = useDebounce(searchText, 800);

  async function fetchReportList(currentpage) {
    try {
      const response = await getReportList(
        currentpage,
        10,
        "ASC",
        debounceValue
      );
      setData(response?.data?.list?.length ? response?.data?.list : []);
      setTotalPage(Math.ceil(response?.data?.count / 10));
    } catch (e) {
      console.log(e);
      setData([]);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchReportList(currentPage);
    setData([]);
    setLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, debounceValue]);

  return (
    <>
      {/* Header */}
      <AdminHeader {...{ searchText, setSeachText }}>
        <div className="text-white">
          <div className="text-2xl font-black mb-2 uppercase">Reports</div>
        </div>
      </AdminHeader>
      <div className="overflow-auto px-5 mt-4 flex-1 relative overflow-y-auto focus:outline-none">
        <Table
          {...{
            columns,
            data,
            currentPage,
            setCurrentPage,
            loading,
            totalPage,
          }}
        />
      </div>
    </>
  );
};

export default Report;
