import React from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import Pagination from "components/Pagination";

const Table = ({
  columns,
  data = [],
  loading = true,
  currentPage,
  setCurrentPage = () => {},
  totalPage,
  onRowClick,
}) => {
  const getMuiTheme = () =>
    createTheme({
      components: {
        MuiPaper: {
          styleOverrides: {
            root: {
              boxShadow: "none",
            },
          },
        },
        MuiTable: {
          styleOverrides: {
            root: {
              height: "100%",
            },
          },
        },
        MUIDataTableHead: {
          styleOverrides: {
            responsiveStacked: {
              zIndex: 0,
              position: "relative",
              "@media (max-width: 899.95px)": {
                display: "none",
              },
            },
          },
        },
        MUIDataTableHeadCell: {
          styleOverrides: {
            root: {
              background: "#1B1E21",
              color: "rgba(255,255,255,0.8) !important",
              fontSize: "12px !important",
              fontWeight: "normal !important",
            },
            data: {
              fontFamily: "Noto Sans KR",
              fontWeight: "normal !important",
            },
          },
        },
        MUIDataTableToolbar: {
          styleOverrides: {
            root: {
              display: "none",
            },
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides: {
            stackedParent: {
              "@media (max-width: 899.95px)": {
                display: "inline-block !important",
              },
            },
            stackedHeader: {
              "@media (max-width: 899.95px)": {
                display: data?.length ? "inline-block" : "none !important",
              },
            },
            stackedCommon: {
              "@media (max-width: 899.95px)": {
                width: data?.length ? "calc(50%)" : "calc(100%)",
              },
            },
          },
        },
        MuiTableCell: {
          styleOverrides: {
            root: {
              fontFamily: "Noto Sans KR",
              background: "#1B1E21",
              color: "white",
              fontWeight: "bold",
              fontSize: "14px !important",
              borderBottom: "1px solid #101316",
              padding: 10,
            },
          },
        },
        MuiTableFooter: {
          styleOverrides: {
            root: {
              display: "none",
            },
          },
        },
        MUIDataTableBodyRow: {
          styleOverrides: {
            responsiveStacked: {
              ":hover": {
                cursor: onRowClick ? "pointer" : "default",
                opacity: 0.9,
              },
            },
          },
        },
      },
    });

  return (
    <Pagination
      pagination={{
        currentpage: currentPage,
        totalPage,
        handlePageClick: ({ selected }) => {
          setCurrentPage(selected + 1);
        },
      }}
    >
      <ThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          data={data}
          columns={columns}
          options={{
            selectableRows: "none",
            responsive: "vertical",
            sort: false,
            onRowClick,
            textLabels: {
              body: {
                noMatch: loading ? (
                  <div className="h-full w-full flex justify-center items-center">
                    <CircularProgress />
                  </div>
                ) : (
                  "Sorry, there is no data to display"
                ),
              },
            },
          }}
        />{" "}
      </ThemeProvider>
    </Pagination>
  );
};

export default Table;
