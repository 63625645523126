import Table from "components/Table";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AdminHeader from "../../components/layouts/AdminHeader";
import moment from "moment";
import {
  excludeToBatch,
  getNFTList,
  includeToBatch,
  generateMerkleRoot,
  publishBatch,
} from "api/adminApiCalls";
import useDebounce from "hooks/useDebounce";
import { Tick } from "components/icons/Tick";
import Cross from "components/icons/cross";
import { customToast } from "components/utils/generic";
import ConfirmationModal from "components/ConfirmationModal";
import DialogWrapper from "components/DialogWrapper";
import { useMoralis, useWeb3ExecuteFunction } from "react-moralis";
import { ALLOWED_CHAINS, MARKETPLACE_ABI } from "common/constants";
import { MarketPlaceAddress } from "contexts/AppContext";
const NFTListing = () => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [totalPage, setTotalPage] = useState(1);
  const [searchText, setSeachText] = useState("");
  const navigate = useNavigate();
  const debounceValue = useDebounce(searchText, 800);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [openNftMedia, setOpenNftMedia] = useState({
    open: false,
    imageSrc: "",
    name: "",
  });

  const {
    data: _web3Data,
    error: _web3Error,
    fetch: web3Fetch,
    isFetching: _web3IsFetching,
    isLoading: _web3IsLoading,
  } = useWeb3ExecuteFunction();

  const columns = [
    {
      name: "id",
      label: "ID",
    },
    {
      name: "secondaryMediaS3Key",
      label: "NFT Media",
      options: {
        customBodyRender: (value, tableData) => (
          <div
            className="w-14 h-14 cursor-pointer"
            onClick={() =>
              setOpenNftMedia({
                open: true,
                imageSrc: value?.signedUrl,
                name:
                  data?.nfts?.find((el) => el?.id === tableData?.rowData[0])
                    ?.name ?? "",
              })
            }
          >
            <img
              className="w-full h-full rounded-lg object-cover"
              src={value?.signedUrl}
              alt="nft images"
            />
          </div>
        ),
      },
    },
    {
      name: "trasactionId",
      label: "Transaction Id",
      options: {
        customBodyRender: (value) => (
          <>{value ? moment(new Date(value)).format("MM/DD/YYYY") : "-"}</>
        ),
      },
    },
    {
      name: "createdOn",
      label: "Requested At",
      options: {
        customBodyRender: (value) => (
          <>
            {value ? (
              <div className="flex flex-col text-sm">
                <div>{moment(new Date(value)).format("MMM dd, yyyy")}</div>
                <div className="opacity-90 font-normal">
                  {moment(new Date(value)).format("hh:mm A")}
                </div>
              </div>
            ) : (
              "-"
            )}
          </>
        ),
      },
    },
    {
      name: "creator",
      label: "From Address",
      options: {
        customBodyRender: (value) => (
          <div className="max-w-[100px] break-words">
            {value?.walletAddress
              ? `${value?.walletAddress.slice(
                  0,
                  5
                )} • • • ${value?.walletAddress.slice(-3)}`
              : "-"}
          </div>
        ),
      },
    },
    {
      name: "chainId",
      label: "Blockchain",
      options: {
        customBodyRender: (value) => (
          <div
            className="flex max-w-[120px] text-xs items-center py-1 px-2 rounded-full"
            style={{ background: ALLOWED_CHAINS[value]?.bg }}
          >
            <div>{ALLOWED_CHAINS[value]?.icon}</div>
            <div className="ml-2">{ALLOWED_CHAINS[value]?.name}</div>
          </div>
        ),
      },
    },
    {
      name: "isExcludedInBatch",
      label: "Status",
      options: {
        customBodyRender: (value) => (
          <div
            className={`py-1 px-4 max-w-[95px] w-full text-center rounded-full text-xs text-white ${
              value ? "bg-[#FF986B]" : "bg-[#6C8F69]"
            }`}
          >
            {value ? "Rejected" : "Approved"}
          </div>
        ),
      },
    },
    {
      name: "isExcludedInBatch",
      label: " ",
      options: {
        display: !data?.isPublishedAt,
        customBodyRender: (value, tableData) => (
          <>
            <div
              className={`w-[27px] h-[27px] cursor-pointer flex justify-center items-center rounded-full ${
                value ? "bg-[#6C8F69]" : "bg-[#FF986B]"
              }`}
              onClick={() =>
                PerformAction("INCLUDE/EXCLUDE", value, tableData?.rowData[0])
              }
            >
              {value ? (
                <Tick className="fill-white" />
              ) : (
                <Cross className="fill-white" h={12} w={12} />
              )}
            </div>
          </>
        ),
      },
    },
  ];

  async function performIncludeExclude(isInclude, nftId) {
    try {
      setModalData((modalData) => {
        return {
          ...modalData,
          loading: true,
        };
      });
      let result;
      if (isInclude) {
        result = await includeToBatch(id, nftId);
      } else {
        result = await excludeToBatch(id, nftId);
      }
      if (result?.data?.message) {
        customToast(result?.data?.message, { type: "success" });
        setModalData({});
        setOpenModal(false);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setData({});
      setLoading(true);
      fetchNFTList(id, currentPage);
    }
  }

  async function fetchNFTList(id, currentPage) {
    try {
      const response = await getNFTList(
        id,
        currentPage,
        10,
        "ASC",
        debounceValue
      );
      if (response?.data) {
        setData(response?.data);
      }
      setTotalPage(Math.ceil(response?.data?.count / 10));
    } catch (e) {
      console.log(e);
      setData({});
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (id) {
      setLoading(true);
      setData({});
      fetchNFTList(id, currentPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, currentPage, debounceValue]);

  const generateRoot = async () => {
    try {
      setModalData((modalData) => {
        return {
          ...modalData,
          loading: true,
        };
      });
      const payload = {
        status: "GENERATED_ROOT",
      };
      const result = await generateMerkleRoot(id, payload);
      if (result?.data?.message) {
        customToast(result?.data?.message, { type: "success" });
        setModalData({});
        setOpenModal(false);
      }
    } catch (e) {
      console.log(e);
    } finally {
      fetchNFTList(id, currentPage);
    }
  };

  const triggerPublish = async () => {
    try {
      setModalData((modalData) => {
        return {
          ...modalData,
          loading: true,
        };
      });
      web3Fetch({
        onError: (error) => {
          console.log("ERROR", error);
          customToast("Failed to create batch on chain", { type: "error" });
          setModalData({});
          setOpenModal(false);
        },
        onSuccess: async (params) => {
          console.log("Success Params", params);
          const result = await publishBatch(id, params.hash);
          if (result?.data?.message) {
            customToast(result?.data?.message, { type: "success" });
            setModalData({});
            setOpenModal(false);
          }
        },
        params: {
          contractAddress: MarketPlaceAddress,
          abi: MARKETPLACE_ABI,
          functionName: "createBatch",
          params: { root: `0x${data?.merkleRoot}` },
        },
      });
    } catch (e) {
      console.log(e);
    } finally {
      fetchNFTList(id, currentPage);
    }
  };

  const PerformAction = (action, value, nftId) => {
    setOpenModal(true);
    switch (action) {
      case "GENERATE_ROOT":
        setModalData({
          content: (
            <div className="text-base text-white">
              <div className="opacity-70">
                Are you sure you want to finalize this Batch
              </div>
              <div className="font-bold">
                {data?.merkleRoot?.slice(0, 5)} • • •
                {data?.merkleRoot?.slice(-3)}
              </div>
            </div>
          ),
          onSuccess: () => generateRoot(),
        });
        break;
      case "INCLUDE/EXCLUDE":
        setModalData({
          content: `Are you Sure you want to ${
            value ? "Include" : "Exclude"
          } this NFT Media`,
          onSuccess: () => performIncludeExclude(value, nftId),
        });
        break;
      case "PUBLISH":
        setModalData({
          content: (
            <div className="text-base text-white">
              <span className="opacity-70">
                Are you Sure you want to Publish this Batch
              </span>
              <span className="font-bold">
                {" "}
                {data?.merkleRoot?.slice(0, 5)} • • •
                {data?.merkleRoot?.slice(-3)}
              </span>
            </div>
          ),
          onSuccess: () => triggerPublish(),
        });
        break;
      default:
        break;
    }
  };

  return (
    <>
      {/* Header */}
      <AdminHeader
        {...{ searchText, setSeachText }}
        pageActions={
          <>
            {!loading && (
              <div
                onClick={() =>
                  !data?.merkleRoot &&
                  !data?.isPublishedAt &&
                  PerformAction("GENERATE_ROOT")
                }
                className={`bg-primary2 ${
                  data?.merkleRoot || data?.isPublishedAt
                    ? "opacity-50 cursor-not-allowed"
                    : "cursor-pointer"
                } p-3 flex rounded-full text-xs font-bold`}
              >
                GENERATE MERKLE ROOT
              </div>
            )}
          </>
        }
      >
        <div className="text-white">
          <div
            className="text-xl font-black mb-1 uppercase cursor-pointer"
            onClick={() => navigate("/admin/batch")}
          >
            Merkle root
          </div>
          <div className="text-xs">
            {data?.updatedAt
              ? `Last updated ${moment(new Date(data.updatedAt)).fromNow()} |
            ${moment(new Date(data.updatedAt)).format("hh:mm A")}`
              : ""}
          </div>
        </div>
      </AdminHeader>
      <div className="overflow-auto px-5">
        {data?.merkleRoot && !data?.isPublishedAt && !loading && (
          <div className="w-full px-4 py-5 bg-secondary2 flex flex-col sm:flex-row sm:items-center">
            <div className="text-white text-sm">
              <div className="mb-1 font-bold">
                {data?.merkleRoot?.slice(0, 8)}....{data?.merkleRoot?.slice(-8)}
              </div>
              <div>Generated merkel Root</div>
            </div>
            <div className="flex flex-1 justify-between sm:justify-end sm:items-center space-x-2 pt-4 sm:pt-0">
              <div
                onClick={() =>
                  data?.isRegenerateRequired && PerformAction("GENERATE_ROOT")
                }
                className={`py-[8px] px-9  text-white flex border-white border-[1px] rounded-full text-sm font-bold ${
                  data?.isRegenerateRequired
                    ? "cursor-pointer"
                    : "cursor-not-allowed opacity-30 select-none"
                }`}
              >
                Regenerate Merkle Root
              </div>
              <div
                onClick={() =>
                  !data?.isRegenerateRequired && PerformAction("PUBLISH")
                }
                className={`py-[8px] px-9 bg-primary2 flex rounded-full text-sm font-bold ${
                  !data?.isRegenerateRequired
                    ? "cursor-pointer"
                    : "cursor-not-allowed opacity-30 select-none"
                }`}
              >
                Publish
              </div>
            </div>
          </div>
        )}
        <div className="mt-4 flex-1 relative overflow-y-auto focus:outline-none">
          <Table
            data={data?.nfts?.length ? data?.nfts : []}
            {...{ columns, currentPage, setCurrentPage, loading, totalPage }}
          />
        </div>
      </div>
      <ConfirmationModal
        open={openModal}
        setOpen={setOpenModal}
        {...{ ...modalData }}
      />
      <DialogWrapper open={openNftMedia?.open}>
        <div className="max-w-[660px] flex">
          <div className="w-full font-notoSans bg-[#1B1E21] px-10 py-6 text-white">
            <div className="text-lg font-notoSans font-bold mb-2">
              NFT Media
            </div>
            <div className="text-sm">{openNftMedia?.name}</div>
            <div className="max-w-[500px] w-full rounded-lg my-5">
              <img
                className="w-full h-full rounded-lg"
                src={openNftMedia?.imageSrc}
                alt="nft"
              />
            </div>
            <div className="flex justify-end">
              <div
                disabled={loading}
                onClick={() => setOpenNftMedia({})}
                className={`text-[#2DC79D] transition-all cursor-pointer transform duration-500 text-sm font-bold p-2 rounded-lg hover:bg-black hover:bg-opacity-20`}
              >
                Close
              </div>
            </div>
          </div>
        </div>
      </DialogWrapper>
    </>
  );
};

export default NFTListing;
