import { getBatchList } from "api/adminApiCalls";
import AdminHeader from "components/layouts/AdminHeader";
import Table from "components/Table";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import useDebounce from "hooks/useDebounce";
import _ from "lodash";
import Select from "react-select";
import { ALLOWED_CHAINS, dropDowncustomStyles } from "common/constants";

const columns = [
  {
    name: "id",
    label: "ID",
  },
  {
    name: "merkleRoot",
    label: "Merkle Root",
    options: {
      customBodyRender: (value) => (
        <>{value ? `${value?.slice(0, 5)}• • •${value?.slice(-3)}` : "-"}</>
      ),
    },
  },
  {
    name: "isPublishedAt",
    label: "Published At",
    options: {
      customBodyRender: (value) => (
        <>
          {value
            ? `${moment(new Date(value)).fromNow()} | ${moment(
                new Date(value)
              ).format("hh:mm a")}`
            : "-"}
        </>
      ),
    },
  },
  {
    name: "totalNfts",
    label: "Total NFTS",
    options: {
      customBodyRender: (value) => (
        <div className="text-white uppercase">{value} nfts</div>
      ),
    },
  },
  {
    name: "updatedAt",
    label: "Updated At",
    options: {
      customBodyRender: (value) => (
        <>
          {value
            ? `${moment(new Date(value)).fromNow()} | ${moment(
                new Date(value)
              ).format("hh:mm a")}`
            : "-"}
        </>
      ),
    },
  },
  {
    name: "status",
    label: "Status",
    options: {
      customBodyRender: (value, tableData) => (
        <div
          className={`py-1 px-4 max-w-[150px] w-full text-center rounded-full text-xs text-white ${
            tableData?.rowData[2]
              ? "bg-secondary2"
              : value === "ACTIVE"
              ? "bg-[#6C8F69]"
              : "bg-[#FF986B]"
          }`}
        >
          {tableData?.rowData[2] ? "PUBLISHED" : _.startCase(value)}
        </div>
      ),
    },
  },
];

const Batch = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [searchText, setSeachText] = useState("");
  const navigate = useNavigate();
  const debounceValue = useDebounce(searchText, 800);
  const chainOptions = Object.keys(ALLOWED_CHAINS)?.map((chainId) => {
    return {
      value: chainId,
      label: ALLOWED_CHAINS[chainId].name,
      fullOption: ALLOWED_CHAINS[chainId],
    };
  });
  const [chainId, setChainId] = useState(chainOptions[0]?.value);

  async function fetchBachList(currentpage) {
    try {
      const response = await getBatchList(
        currentpage,
        10,
        "ASC",
        debounceValue,
        chainId
      );
      setData(response?.data?.list?.length ? response?.data?.list : []);
      setTotalPage(Math.ceil(response?.data?.count / 10));
    } catch (e) {
      console.log(e);
      setData([]);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (chainId && currentPage) {
      fetchBachList(currentPage);
      setData([]);
      setLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, debounceValue, chainId]);

  const formatOptionLabel = ({ value, label, fullOption }) => (
    <div className="flex items-center">
      <div className="w-7 h-7 mr-0.5 flex items-center">{fullOption?.icon}</div>
      <div>{label}</div>
    </div>
  );

  return (
    <>
      {/* Header */}
      <AdminHeader {...{ searchText, setSeachText }}>
        <div className="text-white">
          <div className="text-2xl font-black mb-2 uppercase">Batch</div>
        </div>
      </AdminHeader>
      <div className="overflow-auto px-5 mt-2 sm:mt-4 flex-1 relative overflow-y-auto focus:outline-none">
        <div className="flex flex-col mb-2 items-center sm:items-end">
          <div className="w-full sm:w-[280px]">
            <label className="text-white text-sm font-notoSans font-bold opacity-60 text-left mb-2">
              Chain
            </label>
            <Select
              menuColor="white"
              defaultValue={chainOptions[0]}
              options={chainOptions}
              onChange={({ value }) => setChainId(value)}
              styles={dropDowncustomStyles}
              formatOptionLabel={formatOptionLabel}
              menuPlacement="auto"
              maxMenuHeight={150}
              className={`bg-[#1B1E21] text-sm`}
              classNamePrefix={` text-sm font-notoSans font-normal`}
              theme={(theme) => ({
                ...theme,
                borderRadius: "7px",
                color: "white",
                colors: {
                  primary25: "#3ED791",
                  primary: "",
                  neutral0: "",
                },
              })}
            />
          </div>
        </div>
        <Table
          onRowClick={(data) => navigate(`/admin/batch/${data[0]}`)}
          {...{
            columns,
            data,
            currentPage,
            setCurrentPage,
            loading,
            totalPage,
          }}
        />
      </div>
    </>
  );
};

export default Batch;
