import { callAPI, callAWS, callCoingecko } from "./fetch";

export const getSignatureTexts = async () => {
  const signatureTexts = await callAPI(`helper/signatureTexts`);
  return signatureTexts;
};

export const fileUpload = async (file) => {
  if (!file) return null;
  const type = file.type;
  const s3Response = await callAPI(`helper/imageUploadUrl?mimeType=${type}`);
  if (!s3Response || !s3Response.data) return;

  const { key, signedUrl } = s3Response.data;
  const awsResponse = await callAWS(signedUrl, `PUT`, { body: file });
  return { key, awsResponse, type, file };
};

export const fileDownload = async (key) => {
  if (!key) return null;
  return callAPI(`helper/imageDownloadUrl?s3Key=${key}`);
};

export const registerUser = (walletAddress) => {
  if (!walletAddress) return null;
  return callAPI(`user`, `POST`, { body: { walletAddress } });
};

export const getLoginNonce = (walletAddress) => {
  if (!walletAddress) return null;
  return callAPI(`user/getLoginNonce/${walletAddress}`, `GET`);
};

export const userLogin = async (
  walletAddress,
  signatureFromClient,
  chainId,
  event = "login"
) => {
  if (!walletAddress) return null;
  return callAPI(`authentication/login`, `POST`, {
    body: { walletAddress, signatureFromClient, event, chainId },
  });
};

export const userLoginWithToken = async () => {
  return callAPI(`authentication/loginWithToken`, `POST`);
};

export const userLogout = async (walletAddress) => {
  return callAPI(`authentication/logout`, `POST`, { body: { walletAddress } });
};

export const createNft = async (nftObject) => {
  return callAPI(`nft`, `POST`, { body: { ...nftObject } });
};

export const searchByName = async (keyword) => {
  return callAPI(`nft/searchByName`, `GET`, { query: { keyword } });
};

export const searchByCollectionOrAddress = async (keyword) => {
  return callAPI(`nft/searchByCollectionOrAddress`, `GET`, {
    query: { keyword },
  });
};

export const getNftDetails = async (nftId) => {
  return callAPI(`nft/${nftId}`, `GET`);
};

export const getUsdFor1Weth = async () => {
  return callCoingecko(`simple/price`, `GET`, {
    query: { ids: "WETH", vs_currencies: "USD" },
  });
};

export const likeNft = async (nftId) => {
  return callAPI(`nft/${nftId}/like`, `POST`);
};

export const unlikeNft = async (nftId) => {
  return callAPI(`nft/${nftId}/unlike`, `POST`);
};

export const checkUserNameExistance = async (username) => {
  return callAPI(`user/checkUserNameExistance/${username}`, `GET`);
};

export const updateProfile = async (userData) => {
  return callAPI(`user/${userData.id}`, `PATCH`, { body: { ...userData } });
};

export const createOffer = async (offerObject) => {
  return callAPI(`offer`, `POST`, { body: { ...offerObject } });
};
export const postCreateOfferSign = async ({ offerId, signature }) => {
  return callAPI(`offer/${offerId}/place-bid`, `POST`, {
    body: { signature },
  });
};

export const getAcceptOfferSign = async ({ offerId, expiry }) => {
  return callAPI(
    `offer/${offerId}/get-accept-offer-signature/${expiry}`,
    `POST`
  );
};

export const acceptOffer = async ({ offerId, signature }) => {
  return callAPI(`offer/${offerId}/accept-offer`, `POST`, {
    body: { signature },
  });
};

export const getUserProfile = async (id) => {
  return callAPI(`user/findUserById/${id}`, `GET`);
};

export const findByTrend = async (page, perPage) => {
  page = !!page ? page : 1;
  perPage = !!perPage ? perPage : 20;
  return callAPI(`nft/findByTrend`, `GET`, { query: { page, perPage } });
};

export const getNfts = async (filters) => {
  return callAPI(`nft/filterNfts`, `POST`, { body: { filters } });
};

export const getNftActivity = async (nftId) => {
  return callAPI(`activity/findAllByNft/${nftId}`, `GET`);
};

export const getUserActivity = async (userId) => {
  return callAPI(`activity/findAllByUser/${userId}`, `GET`);
};

export const findByBids = async (page, perPage) => {
  page = !!page ? page : 1;
  perPage = !!perPage ? perPage : 20;
  return callAPI(`nft/findByBids`, `GET`, { query: { page, perPage } });
};

export const getTopSellers = async () => {
  const response = await callAPI(`user/getTopSellers`, `GET`);
  return response?.data?.list || [];
};

export const getTopBuyers = async () => {
  return callAPI(`user/getTopBuyers`, `GET`);
};

export const getCollections = async () => {
  const response = await callAPI(`collection`, `GET`);
  return response?.data?.list || [];
};

export const subscribe = async (email) => {
  return callAPI(`subscribe`, `POST`, { body: { email } });
};
export const getActivity = async (page, perPage, order) => {
  page = !!page ? page : 1;
  perPage = !!perPage ? perPage : 9;
  order = !!order ? order : "ASC";
  return callAPI("activity", "GET", { query: { page, perPage, order } });

  // return callAPI('activity', 'GET', { query: {page ?? 1, perPage ?? 9, order ?? 'ASC'} })
};
export const getTranslation = async (langCode) => {
  return callAPI(`translation/language/${langCode}`, `GET`);
};

export const getCollectionById = async (id) => {
  return callAPI(`collection/${id}`, "GET");
};

export const report = async (reportContent) => {
  console.log(reportContent);
  return callAPI(`report`, "POST", { body: { ...reportContent } });
};

export const sendEmailVerificationLink = async (email) => {
  return callAPI(`user/sendEmailVerificationLink`, "POST", { body: { email } });
}

export const verifyEmail = async (encryptedHash) => { 
  return callAPI(`user/verifyEmail/${encryptedHash}`, "GET")
}