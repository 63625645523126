import {
  fileUpload,
  updateProfile,
  fileDownload,
  sendEmailVerificationLink,
} from "api/apiCalls";
import {
  ButtonPrimarySmall,
  ButtonTertiaryGhost,
  ButtonTertiarySmall,
} from "components/elements/Button";
import { CaptionWhite } from "components/elements/Caption";
import Checkbox from "components/elements/Checkbox";
import { VerticalTabSection } from "components/elements/TabSection";
import {
  Text12Regular,
  Text14Bold,
  Text14Regular,
  Text18Bold,
} from "components/elements/Text";
import TextField from "components/elements/TextField";
import Badge from "components/icons/Badge";
import Bell from "components/icons/Bell";
import { Ether } from "components/icons/Blockchain";
import Copy from "components/icons/Copy";
import Headphone from "components/icons/Headphone";
import ProfileIcon from "components/icons/ProfileIcon";
import { customToast } from "components/utils/generic";
import { $t } from "components/utils/Translation";
import { AppContext } from "contexts/AppContext";
import { useContext, useEffect, useState } from "react";
import { useMoralis } from "react-moralis";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

const EditProfile = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { ProfileID } = useParams();
  const { provider, account } = useMoralis();
  const { userData, handleAuthentication, signatureTexts, triggerConnection } =
    useContext(AppContext);
  const [signature, setSignature] = useState(null);

  useEffect(() => {
    if (!userData || !userData.id || !account) {
      customToast(
        `Please check if your wallet is connected and you're signed in. You're being redirected to Homepage.`,
        { toastId: "EDIT_PROFILE_VALIDATION_FAILURE" }
      );
      return navigate(`/`);
    }
    if (!ProfileID || isNaN(ProfileID)) {
      customToast(`Invalid Profile ID.`, {
        toastId: "EDIT_PROFILE_VALIDATION_FAILURE",
      });
      return navigate(-1);
    }
    if (Number(ProfileID) !== Number(userData.id)) {
      customToast(
        `Profile can be edited only by the corresponding user. Please check if you're connected using correct wallet address`,
        { toastId: "EDIT_PROFILE_VALIDATION_FAILURE" }
      );
      return navigate(-1);
    }
  }, [userData, account, ProfileID]);

  return !userData ? null : (
    <div className="pt-[98px]">
      <VerticalTabSection
        orientation="vertical"
        captions={[
          `${$t("_PROFILE_", "Profile")}`,
          `${$t("_NOTIFICATIONS_", "Notifications")}`,
          `${$t("_OFFERS_", "Offers")}`,
          `${$t("_ACCOUNT_SUPPORT_", "Account Support")}`,
        ]}
        icons={[
          <ProfileIcon className="fill-current mr-2" />,
          <Bell className="fill-current mr-2" />,
          <Badge className="fill-current mr-2" />,
          <Headphone className="fill-current mr-2" />,
        ]}
        panels={[
          <ProfileSettings />,
          <NotificationSettings />,
          <Offers />,
          <AccountSupport />,
        ]}
      />
    </div>
  );
};

export default EditProfile;

const ProfileSettings = () => {
  const { userData, defaultProfilePic, setuserData } = useContext(AppContext);
  const [isUpdating, setIsUpdating] = useState(false);
  const [profilePic, setprofilePic] = useState(
    userData?.profileImage ?? defaultProfilePic
  );
  const [profileDetails, setprofileDetails] = useState(userData);

  const updateProfileDetails = (val) => {
    setprofileDetails({ ...profileDetails, ...val });
  };

  const handleFileChange = async (event) => {
    if (!event.target.files || !event.target.files.length) return;
    const file = event.target.files[0];
    const fileObjectResponse = await fileUpload(file);
    if (!fileObjectResponse.key) {
      setprofilePic(defaultProfilePic);
      return customToast(
        "Something went wrong with your file upload. A random image is placed on ui but not linked to your profile",
        { toastId: "PROFILE_PIC_CHANGE_FAILURE" }
      );
    }

    updateProfileDetails({ profileImage: fileObjectResponse.key });
    const imageResponse = await fileDownload(fileObjectResponse.key);
    setprofilePic(
      imageResponse && imageResponse.data && imageResponse.data.signedUrl
        ? imageResponse.data.signedUrl
        : defaultProfilePic
    );
    return customToast(
      `Your profile image change will become effective after you click 'Save changes'`,
      { toastId: "PROFILE_PIC_CHANGE_SUCCESS" }
    );
  };

  const handleCopyToClipboard = () => {
    const cb = navigator.clipboard;
    const walletAddress = document.getElementById("Wallet Address");
    cb.writeText(walletAddress.value).then(() =>
      customToast("Wallet Address copied", { toastId: "WALLET_COPY" })
    );
  };

  const removeProfilePic = () => {
    updateProfileDetails({ profileImage: null });
    setprofilePic(defaultProfilePic);
    return customToast(
      `Your profile image will become empty after you click 'Save changes'. 
        A random image is used a placeholer but that is not linked to your profile`,
      { toastId: "PROFILE_PIC_REMOVED" }
    );
  };

  const submitHandler = async () => {
    setIsUpdating(true);
    const userUpdateResponse = await updateProfile(profileDetails);
    if (
      userUpdateResponse &&
      userUpdateResponse.data &&
      userUpdateResponse.data.id
    ) {
      setuserData(userUpdateResponse.data);
      setIsUpdating(false);
      return customToast("Profile updated successfully", {
        toastId: "PROFILE_UPDATE_SUCCESS",
      });
    }
    setIsUpdating(false);
    return customToast(
      "Something went wrong. Please try again after sometime",
      { toastId: "PROFILE_UPDATE_FAILURE" }
    );
  };

  return (
    <div className="flex flex-col gap-8 lg:ml-8">
      <CaptionWhite className="text-[24px] md:text-[36px] uppercase">
        {$t("_PROFILE_SETTINGS_", "Profile settings")}
      </CaptionWhite>
      <Text14Regular className="uppercase">
        collaborate, create, celebrate
      </Text14Regular>
      <div className="flex flex-1 flex-col-reverse lg:flex-row">
        <div className="flex flex-col gap-8 lg:w-2/3">
          <TextField
            labelClass="text-white2 text-[14px] font-bold"
            label={$t("_FULL_NAME_", "Full Name")}
            placeholder="Author Shelby"
            onChange={(e) =>
              updateProfileDetails({ profileName: e.target.value })
            }
            value={
              profileDetails.profileName === null
                ? `unNamedUser-${profileDetails.id}`
                : profileDetails.profileName
            }
          />
          <TextField
            labelClass="text-white2 text-[14px] font-bold"
            label={$t("", "Unique username")}
            placeholder="userName"
            onChange={(e) => updateProfileDetails({ userName: e.target.value })}
            value={profileDetails.userName || ``}
          />
          <TextField
            labelClass="text-white2 text-[14px] font-bold"
            label={$t("_DESCRIPTION_", "Description")}
            placeholder={$t("_DESCRIPTION_", "Description")}
            type="textarea"
            onChange={(e) =>
              updateProfileDetails({ description: e.target.value })
            }
            value={profileDetails.description || ``}
          />
          <EmailInput
            updateProfileDetails={updateProfileDetails}
            profileDetails={profileDetails}
            userData={userData}
          />
          <TextField
            labelClass="text-white2 text-[14px] font-bold"
            label={$t("_TWITTER_", "Twitter")}
            placeholder={$t("_LINK_HERE_", "Link here")}
            onChange={(e) => updateProfileDetails({ twitter: e.target.value })}
            value={profileDetails.twitter || ``}
          />
          <TextField
            labelClass="text-white2 text-[14px] font-bold"
            label={$t("_INSTAGRAM_", "Instagram")}
            placeholder={$t("_LINK_HERE_", "Link here")}
            onChange={(e) =>
              updateProfileDetails({ instagram: e.target.value })
            }
            value={profileDetails.instagram || ``}
          />
          <TextField
            labelClass="text-white2 text-[14px] font-bold"
            label={$t("_FACEBOOK_", "Facebook")}
            placeholder={$t("_LINK_HERE_", "Link here")}
            onChange={(e) => updateProfileDetails({ facebook: e.target.value })}
            value={profileDetails.facebook || ``}
          />
          <TextField
            labelClass="text-white2 text-[14px] font-bold"
            label={$t("_DISCORD_", "Discord")}
            placeholder={$t("_LINK_HERE_", "Link here")}
            onChange={(e) => updateProfileDetails({ discord: e.target.value })}
            value={profileDetails.discord || ``}
          />
          <TextField
            labelClass="text-white2 text-[14px] font-bold"
            label={$t("_TELEGRAM_", "Telegram")}
            placeholder={$t("_LINK_HERE_", "Link here")}
            onChange={(e) => updateProfileDetails({ telegram: e.target.value })}
            value={profileDetails.telegram || ``}
          />
          <TextField
            labelClass="text-white2 text-[14px] font-bold"
            label={$t("_WALLET_ADDRESS_", "Wallet Address")}
            value={profileDetails.walletAddress}
            readOnly
            rightElement={<Copy className="cursor-pointer" />}
            rightElementClickHandler={handleCopyToClipboard}
          />
          <div className="flex flex-1 justify-end">
            <ButtonPrimarySmall
              isLoading={isUpdating}
              text={$t("_SAVE_CHANGES_", "Save Changes")}
              onClick={submitHandler}
            />
          </div>
        </div>
        <div className="flex flex-col lg:w-1/3 items-center gap-8 mb-8">
          <div
            style={{
              backgroundImage: `url(${profilePic})`,
              width: 207,
              height: 207,
              borderRadius: 6,
            }}
            className="cursor-pointer bg-no-repeat bg-cover bg-center"
          />
          {
            <div className="relative">
              <input
                type="file"
                name="file"
                onChange={handleFileChange}
                className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                accept="image/*"
              />
              <ButtonTertiarySmall
                className="cursor-pointer"
                text={$t("_UPLOAD_PHOTO_", "Upload Photo")}
              />
            </div>
          }
          <ButtonTertiaryGhost
            text={$t("_REMOVE_", "Remove")}
            className="cursor-pointer"
            onClick={removeProfilePic}
          />
        </div>
      </div>
    </div>
  );
};

export const EmailInput = (props) => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const invalidEmail =
    props.profileDetails.emailAddress &&
    !regex.test(props.profileDetails.emailAddress);

  const sendVerificationEmail = async () => {
    try {
      const sentEmailVerificationResp = await sendEmailVerificationLink(
        props.userData.emailAddress
      );

      if (sentEmailVerificationResp.statusCode !== 201) {
        throw new Error("Email verification link Not Sent");
      }
      // const messageKeyword = sentEmailVerificationResp?.data?.message;
      customToast("Email verification link sent", {
        toastId: "EMAIL_VERIFICATION_SENT",
      });
    } catch (e) {
      customToast("Email verification link Not Sent", {
        toastId: "EMAIL_NOT_SENT",
      });
    }
  };
  return (
    <div className="grid grid-flow-row sm:grid-flow-col gap-y-2 sm:gap-x-2 grid-cols-[minmax(100%,auto)] sm:grid-cols-[minmax(80%,_1fr)_auto] content-end">
      <TextField
        label={$t("_EMAIL_", "Email")}
        labelClass="text-white2 text-[14px] font-bold"
        placeholder={$t("_EMAIL_ID_HERE_", "Email ID here")}
        inputClass={`${invalidEmail ? "border border-red-600" : ""}`}
        onChange={(e) => {
          props.updateProfileDetails({ emailAddress: e.target.value });
        }}
        value={props.profileDetails.emailAddress || ``}
      />
      {props.userData.emailAddress &&
      props.userData.emailVerifiedAt === null &&
      props.profileDetails.emailAddress?.toLowerCase() ===
        props.userData.emailAddress?.toLowerCase() ? (
        <button
          onClick={() => sendVerificationEmail()}
          className="text-sm font-notoSans font-bold text-white3 bg-lightbg p-3 sm:mt-7 focus:outline-none"
        >
          {$t("_VERIFY_", "Verify")}
        </button>
      ) : null}
    </div>
  );
};

const NotificationSettings = () => {
  const { userData, setuserData } = useContext(AppContext);
  const defaultPreference = {
    onOwnItemSale: false,
    onBidActivityOverOwnItem: false,
    onOfferedItemPriceChange: false,
    onOwnAuctionExpiry: false,
    onOfferedBidIsOutbid: false,
    onOwnedItemUpdate: false,
    onSuccessfulPurchase: false,
    minimumThresholdForBidNotification: 0,
  };
  const [profileNotifications, setprofileNotifications] = useState(
    userData.notificationPreference || defaultPreference
  );

  const updateProfileNotification = async (val) => {
    const notificationSettings = { ...profileNotifications, ...val };
    setprofileNotifications(notificationSettings);
    const userUpdateResponse = await updateProfile({
      ...userData,
      notificationPreference: notificationSettings,
    });
    if (
      userUpdateResponse &&
      userUpdateResponse.data &&
      userUpdateResponse.data.id
    ) {
      setuserData(userUpdateResponse.data);
      return customToast("Profile updated successfully", {
        toastId: "PROFILE_UPDATE_SUCCESS",
      });
    }
    return customToast(
      "Something went wrong. Please try again after sometime",
      { toastId: "PROFILE_UPDATE_FAILURE" }
    );
  };

  return (
    <div className="flex flex-col gap-8 lg:ml-8">
      <CaptionWhite className="text-[24px] md:text-[26px] font-notoSans font-bold uppercase">
        notification settings
      </CaptionWhite>
      <Text14Regular>
        Select which notifications you would like to receive for 0x80d4...fe2f.
      </Text14Regular>
      <div className="flex flex-col gap-4 lg:w-2/3">
        <div className="flex flex-col">
          <Checkbox
            checked={profileNotifications.onOwnItemSale}
            onChange={(e) =>
              updateProfileNotification({ onOwnItemSale: e.target.checked })
            }
            label={
              <Text14Regular>{$t("_ITEM_SOLD_", "Item Sold")}</Text14Regular>
            }
          />
          <Text14Regular className="text-white2 ml-7">
            {$t(
              "_WHEN_SOMEONE_PURCHASED_ONE_OF_YOUR_ITEMS_",
              "When someone purchased one of your items"
            )}
          </Text14Regular>
        </div>
        <div style={{ border: "1px solid white", opacity: 0.06 }} />
        <div className="flex flex-col">
          <Checkbox
            checked={profileNotifications.onBidActivityOverOwnItem}
            onChange={(e) =>
              updateProfileNotification({
                onBidActivityOverOwnItem: e.target.checked,
              })
            }
            label={
              <Text14Regular>
                {$t("_BID_ACTIVITY_", "Bid Activity")}
              </Text14Regular>
            }
          />
          <Text14Regular className="text-white2 ml-7">
            {$t("", "When someone purchased one of your items")}
          </Text14Regular>
        </div>
        <div style={{ border: "1px solid white", opacity: 0.06 }} />
        <div className="flex flex-col">
          <Checkbox
            checked={profileNotifications.onOfferedItemPriceChange}
            onChange={(e) =>
              updateProfileNotification({
                onOfferedItemPriceChange: e.target.checked,
              })
            }
            label={
              <Text14Regular>
                {$t("_PRICE_CHANGE_", "Price Change")}
              </Text14Regular>
            }
          />
          <Text14Regular className="text-white2 ml-7">
            {$t(
              "_WHEN_AN_ITEM_YOU_MADE_AN_OFFER_ON_CHANGES_IN_PRICE_",
              "When an item you made an offer on changes in price"
            )}
          </Text14Regular>
        </div>
        <div style={{ border: "1px solid white", opacity: 0.06 }} />
        <div className="flex flex-col">
          <Checkbox
            checked={profileNotifications.onOwnAuctionExpiry}
            onChange={(e) =>
              updateProfileNotification({
                onOwnAuctionExpiry: e.target.checked,
              })
            }
            label={
              <Text14Regular>
                {$t("_AUCTION_EXPIRATION_", "Auction Expiration")}
              </Text14Regular>
            }
          />
          <Text14Regular className="text-white2 ml-7">
            {$t(
              "_WHEN_A_TIMED_AUCTION_YOU_CREATED_ENDS_",
              "When a timed auction you created ends"
            )}
            When a timed auction you created ends
          </Text14Regular>
        </div>
        <div style={{ border: "1px solid white", opacity: 0.06 }} />
        <div className="flex flex-col">
          <Checkbox
            checked={profileNotifications.onOfferedBidIsOutbid}
            onChange={(e) =>
              updateProfileNotification({
                onOfferedBidIsOutbid: e.target.checked,
              })
            }
            label={<Text14Regular>{$t("_OUTBID_", "Outbid")}</Text14Regular>}
          />
          <Text14Regular className="text-white2 ml-7">
            {$t(
              "_WHEN_AN_OFFER_YOU_PLACED_IS_EXCEEDED_BY_ANOTHER_USER_",
              " When an offer you placed is exceeded by another user"
            )}
          </Text14Regular>
        </div>
        <div style={{ border: "1px solid white", opacity: 0.06 }} />
        <div className="flex flex-col">
          <Checkbox
            checked={profileNotifications.onOwnedItemUpdate}
            onChange={(e) =>
              updateProfileNotification({ onOwnedItemUpdate: e.target.checked })
            }
            label={
              <Text14Regular>
                {$t("_OWNED_ITEM_UPDATES_", "Owned Item Updates")}
              </Text14Regular>
            }
          />
          <Text14Regular className="text-white2 ml-7">
            {$t(
              "_WHEN_A_SIGNIFICANT_UPDATE_OCCURS_FOR_ONE_OF_THE_ITEMS_YOU_HAVE_PURCHASED_",
              "When a significant update occurs for one of the items you have purchased"
            )}
          </Text14Regular>
        </div>
        <div style={{ border: "1px solid white", opacity: 0.06 }} />
        <div className="flex flex-col">
          <Checkbox
            checked={profileNotifications.onSuccessfulPurchase}
            onChange={(e) =>
              updateProfileNotification({
                onSuccessfulPurchase: e.target.checked,
              })
            }
            label={
              <Text14Regular>
                {$t("_SUCCESSFUL_PURCHASE_", "Successful Purchase")}
              </Text14Regular>
            }
          />
          <Text14Regular className="text-white2 ml-7">
            {$t(
              "_WHEN_YOU_SUCCESSFULLY_BUY_AN_ITEM_",
              "When you successfully buy an item"
            )}
          </Text14Regular>
        </div>
        <div style={{ border: "1px solid white", opacity: 0.06 }} />
        <Text14Regular>
          {$t("_MINIMUM_BID_THRESHOLD_", "Minimum Bid Threshold")}
        </Text14Regular>
        <Text14Regular className="text-white2">
          {$t(
            "_RECEIVE_NOTIFICATIONS_ONLY_WHEN_YOU_RECEIVE_OFFERS_WITH_A_VALUE_GREATER_THAN_OR_EQUAL_TO_THIS_AMOUNT_OF_ETH",
            "Receive notifications only when you receive offers with a value greater than or equal to this amount of ETH."
          )}
        </Text14Regular>
        <div className="flex bg-inputField rounded-lg flex-1 items-center">
          <Ether className="h-full w-6 ml-4" />
          <div className="flex gap-2 items-center p-5">
            <div className="flex flex-col">
              <Text12Regular>ETH</Text12Regular>
              <Text14Bold>Ethereum</Text14Bold>
            </div>
          </div>
          <div className="w-0 h-full border-r border-solid border-baseDark" />
          <div className="flex flex-col p-5">
            <Text14Bold>0.005</Text14Bold>
          </div>
        </div>
      </div>
    </div>
  );
};

const AccountSupport = () => {
  return (
    <div className="flex flex-col gap-8 lg:ml-8">
      <CaptionWhite className="text-[24px] md:text-[26px] font-notoSans font-bold uppercase">
        {$t("_ACCOUNT_SUPPORT_", "Account support")}
      </CaptionWhite>
      <Text14Regular>
        {$t(
          "_IF_YOU_NEED_HELP_RELATED_TO_YOUR_ACCOUNT:_WE_CAN_HELP_YOU._",
          "If you need help related to your account: we can help you."
        )}
      </Text14Regular>
      <Text18Bold>
        {$t("_CONTACT_VALORES_SUPPORT_", "Contact Valores Support")}
      </Text18Bold>
      <div style={{ border: "1px solid white", opacity: 0.06 }} />
      <Text14Regular>
        {$t(
          "_CAN’T_FIND_THE_ANSWERS_YOU’RE_LOOKING_FOR?_YOU_CAN_ ",
          "Can’t find the answers you’re looking for? You can "
        )}
        <span className="text-primary1 cursor-pointer">
          <a
            href="https://forms.gle/CeK8WRBsi6sL2t4N7"
            target="_blank"
            rel="noreferrer"
          >
            {$t(`_SUBMIT_A_REQUEST_`, "submit a request")}
          </a>
        </span>{" "}
        {$t(`_HERE._`, "here.")}
      </Text14Regular>
    </div>
  );
};

const Offers = () => {
  return (
    <div className="flex flex-col gap-8 lg:ml-8">
      <CaptionWhite className="text-[24px] md:text-[26px] font-notoSans font-bold uppercase">
        {" "}
        {$t("_OFFERS_", "Offers")}
      </CaptionWhite>
      <Text14Regular>Manage your offers here.</Text14Regular>
    </div>
  );
};
