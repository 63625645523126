const Twitter = (props) => {
    const { style, className } = props

    return (
        <svg style={style} className={className} width="25" height="21" viewBox="0 0 25 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.3747 5.1703C22.3899 5.38905 22.3899 5.6078 22.3899 5.82856C22.3899 12.5554 17.2689 20.3134 7.90499 20.3134V20.3094C5.13886 20.3134 2.43019 19.5211 0.101562 18.0271C0.50378 18.0755 0.908014 18.0997 1.31326 18.1007C3.60559 18.1028 5.83241 17.3336 7.63584 15.9173C5.45741 15.8759 3.54713 14.4556 2.87979 12.382C3.64289 12.5292 4.42918 12.4989 5.17817 12.2943C2.80318 11.8144 1.09451 9.72775 1.09451 7.30437C1.09451 7.28219 1.09451 7.26102 1.09451 7.23985C1.80217 7.63401 2.59451 7.85276 3.40499 7.87695C1.16809 6.38199 0.478579 3.40618 1.82938 1.07957C4.41406 4.26001 8.22757 6.19348 12.3213 6.39812C11.911 4.62997 12.4715 2.77715 13.7941 1.53421C15.8445 -0.393211 19.0693 -0.294421 20.9967 1.75497C22.1368 1.53018 23.2296 1.11183 24.2296 0.519087C23.8495 1.69751 23.0542 2.69852 21.9917 3.33461C23.0008 3.21566 23.9866 2.9455 24.9151 2.5332C24.2316 3.55739 23.3707 4.44953 22.3747 5.1703Z" />
        </svg>

    )
}

export default Twitter
