import { verifyEmail } from "api/apiCalls";
import { LottieLoader } from "components/elements/LottieLoader";
import { $t } from "components/utils/Translation";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

const VerifyEmail = () => {
    const { encryptedHash } = useParams();
    const navigate = useNavigate();
    const [lottieUrl, setLottieUrl] = useState("");
    const [msg, setMsg] = useState("");

    useEffect(() => {
        if (msg === '') {
            setLottieUrl(`https://assets3.lottiefiles.com/packages/lf20_sndjizpg.json`)
        } else {
            setLottieUrl(``)
            setTimeout(() => navigate('/'), 1500);
        }
    }, [msg])

    const sendHash = async (encryptedHash) => {
        const response = await verifyEmail(encryptedHash);
        setMsg(response?.data?.message)
    }
    useEffect(() => {
        sendHash(encryptedHash)
    },[encryptedHash])
    return (
        <div className="mt-[98px] flex items-center justify-center w-full h-28 text-primary2 font-medium text-lg ">
            {$t(msg, 'Your email has been verified successfully')}
            {lottieUrl && <LottieLoader url={lottieUrl} />}
        </div>
    );
};

export default VerifyEmail;