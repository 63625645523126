const Search = (props) => {
    const { style, className } = props

    return (
        <svg style={style} className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M7.33325 2.66659C4.75592 2.66659 2.66659 4.75592 2.66659 7.33325C2.66659 9.91058 4.75592 11.9999 7.33325 11.9999C9.91058 11.9999 11.9999 9.91058 11.9999 7.33325C11.9999 4.75592 9.91058 2.66659 7.33325 2.66659ZM1.33325 7.33325C1.33325 4.01954 4.01954 1.33325 7.33325 1.33325C10.647 1.33325 13.3333 4.01954 13.3333 7.33325C13.3333 10.647 10.647 13.3333 7.33325 13.3333C4.01954 13.3333 1.33325 10.647 1.33325 7.33325Z"   />
            <path fillRule="evenodd" clipRule="evenodd" d="M10.6286 10.6286C10.889 10.3683 11.3111 10.3683 11.5714 10.6286L14.4714 13.5286C14.7318 13.789 14.7318 14.2111 14.4714 14.4714C14.2111 14.7318 13.789 14.7318 13.5286 14.4714L10.6286 11.5714C10.3683 11.3111 10.3683 10.889 10.6286 10.6286Z"   />
        </svg>

    )
}

export default Search