import { Drawer, Grid } from "@mui/material";
import { ALLOWED_CHAINS, ALLOWED_TOKENS, findToken } from "common/constants";
import { textMask } from "common/function";
import { ButtonPrimaryLarge } from "components/elements/Button";
import {
  CardContainer,
  CheckboxFilter,
  RangeFilter,
} from "components/elements/Filter";
import IconButton from "components/elements/IconButton";
import NFTCard from "components/elements/NFTCard";
import SearchField from "components/elements/SearchField";
import Select from "components/elements/Select";
import {
  Text12Regular,
  Text14Bold,
  Text14Regular,
  Text18Bold,
  Text24Black,
} from "components/elements/Text";
import Close from "components/icons/Close";
import Filter from "components/icons/Filter";
import Search from "components/icons/Search";
import Sort from "components/icons/Sort";
import { activities, SampleUrls } from "components/utils/mockData";
import { useContext, useEffect, useState } from "react";
import TabSection from "components/elements/TabSection";
import shuffle from "lodash/shuffle";
import Smiley1 from "components/icons/Smiley1";
import { Ether } from "components/icons/Blockchain";
import Cart from "components/icons/Cart";
import { AppContext } from "contexts/AppContext";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import PolygonIcon from "components/icons/PolygonIcon";
import { getUserProfile, getActivity } from "api/apiCalls";
import { useMoralis } from "react-moralis";
import moment from "moment";
import { shortWalletAddress } from "components/utils/generic";
import { $t } from "components/utils/Translation";
import NFTCardNew from "components/elements/NFTCardNew";
import NFTBidCardNew from "components/elements/NFTBidCardNew";
import Pagination from "hooks/pagination";
import FastForward from "components/icons/FastForward";
import Bookmark from "components/icons/Bookmark";
import SendIcon from "components/icons/SendIcon";
import Settings from "components/icons/Settings";
import Send from "components/icons/Send";
import EthereumIcon from "components/icons/Ethereum";
import FullPageLoader from "../../components/elements/FullpageLoader";

const Profile = ({ type }) => {
  const { ProfileID } = useParams();
  const {
    setshowProfileBanner,
    setshowArtistProfileBanner,
    setprofileBannerDetails,
    setartistProfileBannerDetails,
    userData,
    activityData,
    activityDataLimit,
  } = useContext(AppContext);
  const [profileDetails, setprofileDetails] = useState(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const fetchUser = async (id) => {
    setIsLoading(true);
    const getUserProfileResponse = await getUserProfile(id);
    if (!getUserProfileResponse || !getUserProfileResponse.data)
      return setprofileDetails(null);

    setprofileDetails(getUserProfileResponse.data);
    if (!!getUserProfileResponse.data.isArtist) {
      setartistProfileBannerDetails(getUserProfileResponse.data);
      setshowArtistProfileBanner(!!getUserProfileResponse.data.isArtist);
    } else {
      setprofileBannerDetails(getUserProfileResponse.data);
      setshowProfileBanner(!getUserProfileResponse.data.isArtist);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (ProfileID) {
      fetchProfileDetail(ProfileID);
    }
    return () => {
      setshowArtistProfileBanner(false);
      setshowProfileBanner(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ProfileID]);

  const fetchProfileDetail = (ProfileID) => {
    setshowArtistProfileBanner(false);
    setshowProfileBanner(false);
    fetchUser(ProfileID);
  };

  useEffect(() => {
    if (type === "MY_PROFILE") {
      if (userData?.id) {
        fetchProfileDetail(userData?.id);
      } else {
        navigate("/");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, type]);

  const Collected = (props) => {
    const [showMobileFilters, setshowMobileFilters] = useState(false);
    const hasContent = !!profileDetails.ownedNfts.length;
    const toggleDrawer = (open) => (event) => {
      if (
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }
      setshowMobileFilters(open);
    };

    return (
      <>
        <div
          id="search-and-sort-fields"
          className="flex flex-row items-center justify-between mb-12"
        >
          <div className="flex flex-1 gap-4 lg:flex-[0.74] mb-8 lg:mb-0 items-center">
            <SearchField
              placeholder={$t(
                "_SEARCH_BY_COLLECTION_/_ADDRESS_",
                "Search by collection / address"
              )}
              iconClass="fill-white2"
              className="w-full"
            />
            {/* <ButtonPrimaryLarge text="Search" className="hidden lg:block w-[7.5rem]" /> */}
            <IconButton
              className="flex lg:hidden p-2 rounded-full bg-primary2 hover:bg-primary1 border-primary2 hover:border-primary1 min-h-[30px] min-w-[30px] items-center justify-center"
              icon={<Search className="fill-black" />}
            />
            <IconButton
              className="flex lg:hidden p-2 rounded-full bg-primary2 hover:bg-primary1 border-primary2 hover:border-primary1 min-h-[30px] min-w-[30px] items-center justify-center"
              icon={<Filter className="fill-black" />}
              onClick={toggleDrawer(true)}
            />
            <IconButton
              className="flex lg:hidden p-2 rounded-full bg-primary2 hover:bg-primary1 border-primary2 hover:border-primary1 min-h-[30px] min-w-[30px] items-center justify-center"
              icon={<Sort className="fill-black" />}
            />
          </div>
          <div className="hidden lg:flex">
            <Select
              caption={$t("_SORT_BY_", "Sort By")}
              options={[
                {
                  name: `${$t("_RECENTLY_ADDED_", "Recently Added")}`,
                  value: "RECENTLY_ADDED",
                },
                {
                  name: `${$t("_PRICE_LOW_TO_HIGH_", "Price Low to High")}`,
                  value: "LOW_PRICE_TO_HIGH_PRICE",
                },
                {
                  name: `${$t("_PRICE_HIGH_TO_LOW_", "Price High to Low")}`,
                  value: "HIGH_PRICE_TO_LOW_PRICE",
                },
                {
                  name: `${$t("_AUCTION_ENDING_", "Auction Ending")}`,
                  value: "AUCTION_ENDING",
                },
                {
                  name: `${$t("_VERIFIED_ONLY_", "Verified Only")}`,
                  value: "VERIFIED_ONLY",
                },
                {
                  name: `${$t("_SHOW_NSFW_", "Show NSFW")}`,
                  value: "NSFW",
                },
              ]}
            />
          </div>
        </div>
        {!!hasContent ? (
          <Grid
            container
            columnSpacing={4}
            rowSpacing={4}
            // style={{ scrollSnapType: 'y mandatory', overscrollBehaviorY: 'contain', maxHeight: 1440 }} >
            className="overflow-y-scroll scrollable-container"
            style={{ maxHeight: 1440 }}
          >
            {profileDetails.ownedNfts.map((item, idx) => (
              <Grid key={idx} item lg={4} xs={12}>
                <NFTCardNew nft={item} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <div className="flex flex-col gap-8 items-center">
            <Smiley1 />
            <Text24Black>
              You have not collected/purchased any NFTS yet
            </Text24Black>
          </div>
        )}

        <Drawer
          anchor={"right"}
          open={showMobileFilters}
          onClose={toggleDrawer(false)}
          PaperProps={{
            style: { width: "100%", maxWidth: 500 },
            className: "!bg-baseDark",
          }}
        >
          <div className="px-8 py-10">
            <FilterSection
              className="flex flex-col gap-5"
              closeIcon={<Close className="fill-white3" />}
              closeCallback={toggleDrawer(false)}
            />
          </div>
        </Drawer>
      </>
    );
  };
  const Created = (props) => {
    const [showMobileFilters, setshowMobileFilters] = useState(false);
    // const [hasContent, sethasContent] = useState(false)
    const hasContent = !!profileDetails.createdNfts.length;
    const toggleDrawer = (open) => (event) => {
      if (
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }
      setshowMobileFilters(open);
    };

    return (
      <>
        <div
          id="search-and-sort-fields"
          className="flex flex-row items-center justify-between mb-12"
        >
          <div className="flex flex-1 gap-4 lg:flex-[0.74] mb-8 lg:mb-0 items-center">
            <SearchField
              placeholder="Search by NFT name / collection / address"
              iconClass="fill-white2"
              className="w-full"
            />
            <IconButton
              className="flex lg:hidden p-2 rounded-full bg-primary2 hover:bg-primary1 border-primary2 hover:border-primary1 min-h-[30px] min-w-[30px] items-center justify-center"
              icon={<Search className="fill-black" />}
            />
            <IconButton
              className="flex lg:hidden p-2 rounded-full bg-primary2 hover:bg-primary1 border-primary2 hover:border-primary1 min-h-[30px] min-w-[30px] items-center justify-center"
              icon={<Filter className="fill-black" />}
              onClick={toggleDrawer(true)}
            />
            <IconButton
              className="flex lg:hidden p-2 rounded-full bg-primary2 hover:bg-primary1 border-primary2 hover:border-primary1 min-h-[30px] min-w-[30px] items-center justify-center"
              icon={<Sort className="fill-black" />}
            />
          </div>
          <div className="hidden lg:flex">
            <Select
              caption={$t("_SORT_BY_", "Sort By")}
              options={[
                {
                  name: `${$t("_RECENTLY_ADDED_", "Recently Added")}`,
                  value: "RECENTLY_ADDED",
                },
                {
                  name: `${$t("_PRICE_LOW_TO_HIGH_", "Price Low to High")}`,
                  value: "LOW_PRICE_TO_HIGH_PRICE",
                },
                {
                  name: `${$t("_PRICE_HIGH_TO_LOW_", "Price High to Low")}`,
                  value: "HIGH_PRICE_TO_LOW_PRICE",
                },
                {
                  name: `${$t("_AUCTION_ENDING_", "Auction Ending")}`,
                  value: "AUCTION_ENDING",
                },
                {
                  name: `${$t("_VERIFIED_ONLY_", "Verified Only")}`,
                  value: "VERIFIED_ONLY",
                },
                {
                  name: `${$t("_SHOW_NSFW_", "Show NSFW")}`,
                  value: "NSFW",
                },
              ]}
            />
          </div>
        </div>

        {hasContent ? (
          <Grid
            container
            columnSpacing={4}
            rowSpacing={4}
            className="overflow-y-scroll scrollable-container"
            // style={{ scrollSnapType: 'y mandatory', overscrollBehaviorY: 'contain', maxHeight: 1440 }} >
            style={{ maxHeight: 1440 }}
          >
            {profileDetails.createdNfts.map((item, idx) => (
              <Grid key={idx} item lg={4} xs={12}>
                <NFTCardNew nft={item} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <div className="flex flex-col gap-8 items-center">
            <Smiley1 />
            <Text24Black>You have not created any NFTS yet</Text24Black>
          </div>
        )}

        <Drawer
          anchor={"right"}
          open={showMobileFilters}
          onClose={toggleDrawer(false)}
          PaperProps={{
            style: { width: "100%", maxWidth: 500 },
            className: "!bg-baseDark",
          }}
        >
          <div className="px-8 py-10">
            <FilterSection
              className="flex flex-col gap-5"
              closeIcon={<Close className="fill-white3" />}
              closeCallback={toggleDrawer(false)}
            />
          </div>
        </Drawer>
      </>
    );
  };
  const Favorites = (props) => {
    const [showMobileFilters, setshowMobileFilters] = useState(false);
    const hasContent = !!profileDetails.likedNfts.length;
    const toggleDrawer = (open) => (event) => {
      if (
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }

      setshowMobileFilters(open);
    };

    return (
      <>
        <div
          id="search-and-sort-fields"
          className="flex flex-row items-center justify-between mb-12"
        >
          <div className="flex flex-1 gap-4 lg:flex-[0.74] mb-8 lg:mb-0 items-center">
            <SearchField
              placeholder="Search by NFT name / collection / address"
              iconClass="fill-white2"
              className="w-full"
            />
            <IconButton
              className="flex lg:hidden p-2 rounded-full bg-primary2 hover:bg-primary1 border-primary2 hover:border-primary1 min-h-[30px] min-w-[30px] items-center justify-center"
              icon={<Search className="fill-black" />}
            />
            <IconButton
              className="flex lg:hidden p-2 rounded-full bg-primary2 hover:bg-primary1 border-primary2 hover:border-primary1 min-h-[30px] min-w-[30px] items-center justify-center"
              icon={<Filter className="fill-black" />}
              onClick={toggleDrawer(true)}
            />
            <IconButton
              className="flex lg:hidden p-2 rounded-full bg-primary2 hover:bg-primary1 border-primary2 hover:border-primary1 min-h-[30px] min-w-[30px] items-center justify-center"
              icon={<Sort className="fill-black" />}
            />
          </div>
          <div className="hidden lg:flex">
            <Select
              caption={$t("_SORT_BY_", "Sort By")}
              options={[
                {
                  name: `${$t("_RECENTLY_ADDED_", "Recently Added")}`,
                  value: "RECENTLY_ADDED",
                },
                {
                  name: `${$t("_PRICE_LOW_TO_HIGH_", "Price Low to High")}`,
                  value: "LOW_PRICE_TO_HIGH_PRICE",
                },
                {
                  name: `${$t("_PRICE_HIGH_TO_LOW_", "Price High to Low")}`,
                  value: "HIGH_PRICE_TO_LOW_PRICE",
                },
                {
                  name: `${$t("_AUCTION_ENDING_", "Auction Ending")}`,
                  value: "AUCTION_ENDING",
                },
                {
                  name: `${$t("_VERIFIED_ONLY_", "Verified Only")}`,
                  value: "VERIFIED_ONLY",
                },
                {
                  name: `${$t("_SHOW_NSFW_", "Show NSFW")}`,
                  value: "NSFW",
                },
              ]}
            />
          </div>
        </div>
        {!!hasContent ? (
          <Grid
            container
            columnSpacing={4}
            rowSpacing={4}
            className="overflow-y-scroll scrollable-container"
            // style={{ scrollSnapType: 'y mandatory', overscrollBehaviorY: 'contain', maxHeight: 1440 }} >
            style={{ maxHeight: 1440 }}
          >
            {profileDetails.likedNfts.map((item, idx) => (
              <Grid key={idx} item lg={4} xs={12}>
                <NFTCardNew nft={item} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <div className="flex flex-col gap-8 items-center">
            <Smiley1 />
            <Text24Black>You have not 'liked' any NFTS yet</Text24Black>
          </div>
        )}

        <Drawer
          anchor={"right"}
          open={showMobileFilters}
          onClose={toggleDrawer(false)}
          PaperProps={{
            style: { width: "100%", maxWidth: 500 },
            className: "!bg-baseDark",
          }}
        >
          <div className="px-8 py-10">
            <FilterSection
              className="flex flex-col gap-5"
              closeIcon={<Close className="fill-white3" />}
              closeCallback={toggleDrawer(false)}
            />
          </div>
        </Drawer>
      </>
    );
  };

  const FilterSection = (props) => {
    const [priceRangeFilterValue, setpriceRangeFilterValue] = useState([
      20, 50,
    ]);
    const filterByPriceRange = (e) => {
      setpriceRangeFilterValue(e.target.value);
    };

    return (
      <>
        <div className={props.className}>
          <div className="flex items-center min-h-[48px] justify-between mb-8">
            <Text18Bold>{$t("_FILTER_BY_", "Filter By")}</Text18Bold>
            {props.closeIcon && (
              <div onClick={props.closeCallback}>{props.closeIcon}</div>
            )}
          </div>
          <RangeFilter
            caption={$t("_PRICE_RANGE_", "Price Range")}
            prefix="$"
            unit="USD"
            value={priceRangeFilterValue}
            onChange={filterByPriceRange}
          />
          <CheckboxFilter
            className="lg:min-h-[190px] max-h-[400px]"
            caption={$t("_CATEGORY_", "Category")}
            options={[
              `${$t("_TRENDING_", "trending")}`,
              `${$t("_TOP_", "top")}`,
              `${$t("_ART_", "art")}`,
              `${$t("_COLLECTIBLES_", "collectibles")}`,
              `${$t("_DOMAIN_NAMES_", "domain names")}`,
              `${$t("_MUSIC_", "music")}`,
              `${$t("_PHOTOGRAGHY_", "photography")}`,
              `${$t("_TRENDING_CARDS_", "trending cards")}`,
              `${$t("_SPORTS_", "Sports")}`,
              `${$t("_VIDEOS_", "videos")}`,
              `${$t("_POSTER_", "Poster")}`,
            ]}
          />
          <CheckboxFilter
            caption={$t("_BLOCKCHAIN_", "Blockchain")}
            options={Object.values(ALLOWED_CHAINS)}
          />
          <CheckboxFilter
            className="lg:min-h-[190px] max-h-[270px]"
            caption={$t("_SALE_TYPE_", "Sales Type")}
            options={[
              `${$t("_TIME_BASED_AUCTION_", "time based aution")}`,
              `${$t("_HAS_OFFERS_", "has offers")}`,
            ]}
          />
        </div>
      </>
    );
  };

  if (isLoading) {
    return <FullPageLoader className="h-60" />;
  }

  return !profileDetails ? null : (
    <>
      <div className="flex flex-col w-full">
        <div className="flex flex-1 w-full gap-10 relative">
          <div
            id="filters"
            className="hidden lg:flex flex-col w-0 lg:w-1/4 gap-5"
          >
            <FilterSection className="flex flex-col gap-5" />
          </div>
          <div
            id="content"
            className="flex flex-col w-full lg:w-3/4 gap-[1.25rem]"
          >
            <div id="tabs" className="">
              <TabSection
                captions={[
                  `${$t("_COLLECTED_", "Collected")}`,
                  `${$t("_CREATED_", "Created")}`,
                  `${$t("_FAVOURITES_", "Favourites")}`,
                  `${$t("_ACTIVITY_", "Activity")}`,
                ]}
                panels={[
                  <Collected />,
                  <Created />,
                  <Favorites />,
                  <Pagination RenderComponent={Activity} pageLimit={3} />,
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;

export const Activity = (props) => {
  // const eventType = [{ PLACED_ON_SALE: <Cart /> },
  //   { TRANSFER: <FastForward /> }, { NFT_CREATED: <Bookmark /> }, { Bid: <SendIcon /> }, { OFFER_CREATED: <Settings/>}]
  const { Moralis } = useMoralis();
  const { type, style, item } = props;
  const {
    defaultProfilePic,
    defaultProfileName,
    defaultName,
    defaultFrom,
    defaultPrice,
    defaultPriceUnit,
    defaultTimeStamp,
    defaultTo,
    defaultType,
  } = useContext(AppContext);
  const shortName = (name) =>
    name.substring(0, 10) + (name.length > 10 ? "..." : "");

  return (
    <div className="">
      <div class="min-w-full hidden xl:block">
        <div className="grid grid-cols-11 gap-2 border-b border-white border-opacity-10 items-center">
          <div className="text-sm text-white opacity-80 font-light pl-2 py-6 whitespace-nowrap col-span-2 -mr-4">
            <div className="flex items-center gap-1.5">
              {item?.action === "OFFER_CREATED" ? (
                <Settings />
              ) : item?.action === "PLACED_ON_SALE" ? (
                <Cart />
              ) : item?.action === "TRANSFER" ? (
                <FastForward />
              ) : item?.action === "NFT_CREATED" ? (
                <Bookmark />
              ) : item?.action === "BID" ? (
                <Send />
              ) : (
                ""
              )}
              <Text12Regular>{item?.action || defaultType}</Text12Regular>
            </div>
          </div>
          <div className="text-sm text-white opacity-80 font-light py-6 col-span-3 xl:ml-5">
            <div className="flex gap-2">
              <div
                className="bg-no-repeat bg-cover bg-center"
                style={{
                  backgroundImage: `url(${
                    item?.actor?.profileImage?.signedUrl || defaultProfilePic
                  })`,
                  width: 50,
                  height: 50,
                  borderRadius: 6,
                }}
              />
              <div className="flex flex-col">
                <Text12Regular>
                  <span className="capitalize">
                    {item?.meta?.nft?.collection?.name ||
                      item?.meta?.nft?.owner?.profileName ||
                      textMask(item?.meta?.nft?.owner?.walletAddress) ||
                      item?.meta?.nft?.creator?.profileName ||
                      textMask(item?.meta?.nft?.creator?.walletAddress) ||
                      textMask(item?.actor?.walletAddress) ||
                      defaultProfileName}
                  </span>
                </Text12Regular>
                <Text14Bold className="whitespace-nowrap">
                  {shortName(item?.meta?.nft?.name || defaultName)}
                </Text14Bold>
              </div>
            </div>
          </div>
          <div className="text-sm text-white opacity-80 font-light py-6 whitespace-nowrap xl:-ml-4">
            <div className="flex items-center gap-x-1">
              {item?.meta?.salesToken
                ? findToken(item?.meta?.salesToken, item?.meta?.nft?.chainId)
                    .icon
                : ""}
              <Text12Regular>
                {item?.meta?.salesAmount
                  ? `${Moralis.Units.FromWei(item?.meta?.salesAmount)}`
                  : `-`}
              </Text12Regular>
            </div>
          </div>
          <div className="text-sm text-white opacity-80 font-light py-6 whitespace-nowrap col-span-2 xl:-ml-4">
            <Text12Regular>
              {item?.meta?.bidder?.profileName ||
                item?.meta?.nft?.owner?.profileName ||
                textMask(item?.meta?.nft?.owner?.walletAddress) ||
                item?.meta?.nft?.creator?.profileName ||
                textMask(item?.meta?.nft?.creator?.walletAddress) ||
                textMask(item?.actor?.walletAddress) ||
                defaultTo}
            </Text12Regular>
          </div>
          <div className="text-sm text-white opacity-80 font-light py-6 whitespace-nowrap col-span-2 xl:-ml-4">
            <Text12Regular>
              {item?.meta?.nft?.owner?.profileName ||
                textMask(item?.meta?.nft?.owner?.walletAddress) ||
                item?.meta?.nft?.creator?.profileName ||
                textMask(item?.meta?.nft?.creator?.walletAddress) ||
                textMask(item?.actor?.walletAddress) ||
                defaultTo}
            </Text12Regular>
          </div>
          <div className="text-sm text-white opacity-80 font-light py-6 whitespace-nowrap xl:-ml-6">
            <Text12Regular>
              {moment(item?.createdOn || defaultTimeStamp).fromNow()}
            </Text12Regular>
          </div>
        </div>
      </div>
      <div className="xl:hidden ">
        {/* <Grid container columnSpacing={2} className="overflow-y-scroll scrollable-container"
        style={{ maxHeight: 1440, ...style }}>
        <Grid item xs={12} md={type === 'row' ? 12 : 6} xl2={type === 'row' ? 12 : 6} >
          <CardContainer className="mb-6"> */}
        <div className="border border-lightbg2 rounded-2xl p-6 bg-[#1B1E21]">
          <div
            className="flex flex-col justify-between w-full gap-7  "
            style={{ scrollSnapAlign: "start" }}
          >
            <div className="flex gap-4">
              <div
                className="bg-no-repeat bg-cover bg-center"
                style={{
                  backgroundImage: `url(${
                    item?.actor?.profileImage?.signedUrl || defaultProfilePic
                  })`,
                  width: 50,
                  height: 50,
                  borderRadius: 6,
                }}
              />
              <div className="flex flex-col">
                <Text12Regular>
                  {item?.meta?.bidder?.profileName || defaultProfileName}
                </Text12Regular>
                <Text14Bold>{item?.meta?.nft?.name || defaultName}</Text14Bold>
              </div>
            </div>

            <div className="flex gap-8">
              <div className="flex flex-col">
                <Text12Regular className="text-white3">Price</Text12Regular>
                <div className="flex">
                  <Ether className="mr-1.5" />
                  <Text12Regular>
                    {item?.meta?.price
                      ? `${Moralis.Units.FromWei(item?.meta?.price)}  ${
                          item?.meta?.priceUnit
                        }`
                      : `${defaultPrice} ${defaultPriceUnit}`}
                  </Text12Regular>
                </div>
              </div>

              <div className="flex flex-col">
                <Text12Regular className="text-white3">From</Text12Regular>
                <Text12Regular>
                  {textMask(item?.meta?.bidder?.walletAddress) ||
                    textMask(item?.actor?.walletAddress) ||
                    defaultFrom}
                </Text12Regular>
              </div>
              <div
                className={`flex flex-col ${
                  ["OFFER_CREATED", "OFFER_ACCEPTED"].includes(item?.action)
                    ? "hidden"
                    : "visible"
                }`}
              >
                <Text12Regular className="text-white3">To</Text12Regular>
                <Text12Regular>
                  {textMask(item?.meta?.bidder?.walletAddress) ||
                    textMask(item?.actor?.walletAddress) ||
                    defaultTo}
                </Text12Regular>
              </div>
            </div>

            <div className="border-t border-lightbg2 flex justify-between pt-4">
              <Text12Regular>
                {moment(item?.createdOn || defaultTimeStamp).fromNow()}
              </Text12Regular>
              <div className="flex justify-evenly gap-2">
                <Cart />
                <Text12Regular>{item?.action || defaultType}</Text12Regular>
              </div>
            </div>
          </div>
          {/* </CardContainer >
        </Grid >
      </Grid > */}
        </div>
      </div>
    </div>
  );
};
