import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import { useContext, useEffect, useState } from "react";
import { TickActive, TickInactive } from "components/icons/Tick";
import Loading from "components/icons/Loading";
import {
  ButtonPrimarySmall,
  ButtonTertiaryGhost,
} from "components/elements/Button";
import { useChain, useMoralis } from "react-moralis";
import { createNft } from "api/apiCalls";
import { AppContext } from "contexts/AppContext";
import { useNavigate } from "react-router-dom";
import InfoModal from "components/elements/InfoModal";
import { customToast } from "components/utils/generic";
import { $t } from "components/utils/Translation";
import SelectBlockchain from "./SelectBlockchain";
import SelectNumberOfCopies from "./SelectNumberOfCopies";
import NftForm from "./NftForm";
import ApprovalStatus from "./ApprovalStatus";
import { emptyNft, getPayload } from "./constant";
import { Text12Regular, Text14Bold } from "components/elements/Text";
import { scrollToELement } from "common/function";
import { LottieLoader } from "../../../components/elements/LottieLoader";

const StepForm = ({ activeStep, nftDetails, setnftDetails }) => {
  switch (activeStep) {
    case 0:
      return <SelectBlockchain {...{ nftDetails, setnftDetails }} />;
    case 1:
      return <SelectNumberOfCopies {...{ nftDetails, setnftDetails }} />;
    case 2:
      return <NftForm {...{ nftDetails, setnftDetails }} />;
    case 3:
      return <ApprovalStatus />;
    default:
      return null;
  }
};

const NftCreate = () => {
  const navigate = useNavigate();
  const { account, chainId } = useMoralis();
  const { switchNetwork } = useChain();
  const { userData, isLoginReinitiate, setIsLoginReInitiate } =
    useContext(AppContext);
  const [nftDetails, setnftDetails] = useState(
    JSON.parse(JSON.stringify(emptyNft))
  );
  const [activeStep, setActiveStep] = useState(0);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [infoContent, setinfoContent] = useState("");
  const infoCaption = "";
  const [loading, setLoading] = useState(false);
  const [lottieUrl, setLottieUrl] = useState("");
   const [windowSize, setWindowSize] = useState(window.innerWidth);

   useEffect(() => {
     function handleWindowResize() {
       setWindowSize(window.innerWidth);
     }

     window.addEventListener("resize", handleWindowResize);

     return () => {
       window.removeEventListener("resize", handleWindowResize);
     };
   }, []);

  useEffect(() => {
    if (!userData || !userData.id || !account) {
      customToast(
        `You're redirected to Homepage. Please check if your wallet is connected and you're signed in.`,
        { toastId: "CREATE_NFT_VALIDATION_FAILURE" }
      );
      return navigate(`/`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, account]);

  useEffect(() => {
    if (
      isLoginReinitiate?.isSuccess &&
      isLoginReinitiate?.key === "CREATE_NFT"
    ) {
      submitNft(nftDetails);
      setIsLoginReInitiate(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoginReinitiate]);

  const validateFormBeforeSubmit = async () => {
    if (!nftDetails?.formDetails || nftDetails?.formDetails === {}) {
      setinfoContent("Please enter all details for the new NFT");
      setShowInfoModal(true);
      return;
    }
    if (!nftDetails?.formDetails?.name) {
      setinfoContent("Please provide NFT name");
      setShowInfoModal(true);
      return;
    }
    if (!nftDetails?.formDetails?.nftImageS3) {
      setinfoContent("Please upload display image");
      setShowInfoModal(true);
      return;
    }
    if (nftDetails?.formDetails?.sale) {
      if (
        !nftDetails?.formDetails?.minPrice ||
        !nftDetails?.formDetails?.saleEnd
      ) {
        setinfoContent(
          "Please check sale details. Minimum Price and Sale-end date are mandatory"
        );
        setShowInfoModal(true);
        return;
      }
    }
    if (nftDetails?.chainId?.toLowerCase() === chainId.toLowerCase()) {
      submitNft(nftDetails);
      return true;
    }
    try {
      setLottieUrl(
        "https://assets9.lottiefiles.com/packages/lf20_enojckud.json"
      );
      await switchNetwork(nftDetails?.chainId);
      setIsLoginReInitiate((_) => ({
        isSuccess: false,
        key: "CREATE_NFT",
      }));
    } catch (e) {
      customToast(e?.message, { type: "error" });
      setLottieUrl("");
    }
  };

  const submitNft = async (nftDetails) => {
    try {
      setLottieUrl("");
      setLoading(true);
      const payload = getPayload(nftDetails);
      const res = await createNft(payload);
      console.log(res);
      customToast("NFT created successfully", {
        toastId: "CREATE_NFT_SUCCESS",
      });
      goToNextStep();
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleNext = () => {
    switch (activeStep) {
      case 0:
        goToNextStep();
        return;
      case 1:
        goToNextStep();
        return;
      case 2:
        validateFormBeforeSubmit();
        return;
      case 3:
        setnftDetails(JSON.parse(JSON.stringify(emptyNft)));
        setActiveStep(0);
        return;
      default:
        return;
    }
  };

  const goToNextStep = () => {
    setActiveStep((prevActiveStep) =>
      prevActiveStep + 1 < steps.length ? prevActiveStep + 1 : 0
    );
    scrollToELement("create_nft_form");
  };

  const handlePrevious = () => {
    setActiveStep((prevActiveStep) =>
      prevActiveStep > 0 ? prevActiveStep - 1 : null
    );
    scrollToELement("create_nft_form");
  };

  const Connector = () => (
    <div className="border-l-[3px] border-solid min-h-[24px] ml-1 xs4:ml-2 sm:ml-[19px] border-dark1" />
  );

  const steps = [
    {
      label: (
        <div className="flex flex-col ">
          <Text12Regular className="text-dark3 text-[6px] sm:text-sm  whitespace-nowrap">
            {$t("_STEP_1_", "Step 1")}
          </Text12Regular>
          <Text14Bold className="text-white3 text-[6px] xs4:text-[10px] sm:text-sm leading-[10px] xs4:leading-4 sm:leading-[21px]">
            {$t("_SELECT_BLOCKCHAIN_", "Select Blockchain")}
          </Text14Bold>
        </div>
      ),
    },
    {
      label: (
        <div className="flex flex-col">
          <Text12Regular className="text-dark3 text-[6px] sm:text-sm  whitespace-nowrap">
            {$t("_STEP_2_", "Step 2")}
          </Text12Regular>
          <Text14Bold className="text-white3 text-[6px] xs4:text-[10px] sm:text-sm leading-[8px] xs3:leading-[10px] xs4:leading-3 sm:leading-[21px]">
            {$t("_SINGLE_OR_MULTIPLE_", "Single or Multiple")}
          </Text14Bold>
        </div>
      ),
    },
    {
      label: (
        <div className="flex flex-col">
          <Text12Regular className="text-dark3 whitespace-nowrap text-[6px] sm:text-sm">
            {$t("_STEP_3_", "Step 3")}
          </Text12Regular>
          <Text14Bold className="text-white3 text-[6px] xs4:text-[10px] sm:text-sm leading-[10px] xs4:leading-4 sm:leading-[21px]">
            {$t("_NFT_DETAILS_", "NFT Details")}
          </Text14Bold>
        </div>
      ),
    },
    {
      label: (
        <div className="flex flex-col">
          <Text12Regular className="text-dark3 text-[6px] sm:text-sm whitespace-nowrap">
            {$t("_STEP_4_", "Step 4")}
          </Text12Regular>
          <Text14Bold className="text-white3 text-[6px] xs4:text-[10px] sm:text-sm leading-[10px] xs4:leading-4  sm:leading-[21px]">
            {$t("_AWAITING_APPROVAL_", "Awaiting Approval")}
          </Text14Bold>
        </div>
      ),
    },
  ];

  return (
    <div
      className="pt-[98px] flex flex-1 flex-col sm:flex-row relative"
      id="create_nft_form"
    >
      <div className="flex sm:w-1/3 xs3:ml-0 xs2:-ml-4">
        <Stepper
          orientation={windowSize > 425 ? "vertical" : "horizontal"}
          connector={<Connector />}
          className="relative"
        >
          {steps.map((step, index) => (
            <Step key={index}>
              <StepLabel
                StepIconComponent={
                  index >= 4 && activeStep >= 4
                    ? Loading
                    : index <= activeStep
                    ? TickActive
                    : TickInactive
                }
              >
                {step.label}
              </StepLabel>
              <StepContent>{step.content}</StepContent>
            </Step>
          ))}
        </Stepper>
      </div>
      <div className="flex flex-col sm:w-2/3  gap-8 py-6 sm:py-0">
        <StepForm {...{ activeStep, nftDetails, setnftDetails }} />
        {activeStep < steps.length && activeStep >= 0 && (
          <div className="flex justify-end ">
            {activeStep > 0 && activeStep < 3 && (
              <ButtonTertiaryGhost
                disabled={loading}
                text={$t("_PREVIOUS_", "Previous")}
                onClick={handlePrevious}
              />
            )}
            <ButtonPrimarySmall
              isLoading={loading}
              text={
                activeStep >= 2
                  ? activeStep === 3
                    ? `${$t("", "Start over")}`
                    : `${$t("_FINISH_", "Finish")}`
                  : `${$t("_NEXT_", "Next")}`
              }
              onClick={handleNext}
            />
          </div>
        )}
      </div>
      <InfoModal
        visible={showInfoModal}
        content={infoContent || "Please select an option"}
        caption={infoCaption}
        toggle={setShowInfoModal}
      />
      {lottieUrl && <LottieLoader url={lottieUrl} />}
    </div>
  );
};

export default NftCreate;
