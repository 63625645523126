import {
  Text14Bold,
  Text14Regular,
  Text24Bold,
  Text24Light,
} from "components/elements/Text";
import React from "react";
import { $t } from "components/utils/Translation";
import Checkbox from "components/elements/Checkbox";
import RemoveIcon from "components/icons/Remove";

const AttributeModal = (props) => {
  const {
    visible,
    toggle,
    updateForm,
    setformDetails,
    attributeList,
    formDetails,
  } = props;

  if (!visible) return null;

  const handleAttributeChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...attributeList];
    list[index][name] = value;
    setformDetails({ ...formDetails, attributeList: list });
  };

  const handleAttributeAdd = () => {
    setformDetails({
      ...formDetails,
      attributeList: [...attributeList, { label: "", attribute: "" }],
    });
  };

  const handleAttributeRemove = (index) => {
    const list = [...attributeList];
    list.splice(index, 1);
    setformDetails({ ...formDetails, attributeList: list });
  };

  const SaveAllAttributes = () => {
    return (
      <button
        disabled={!attributeList[0]?.label || !attributeList[0]?.attribute}
        className={`px-9 py-2 flex items-center space-x-2  bg-primary2 rounded-full disabled:bg-opacity-30 disabled:cursor-not-allowed`}
        onClick={() => {
          updateForm({
            attributeList: attributeList.filter(
              (attribute) => attribute?.label
            ),
          });
          toggle(!visible);
        }}
      >
        <Text14Bold className="text-black">
          {$t(`_ADD_ATTRIBUTES_`, "Add Attributes")}
        </Text14Bold>
      </button>
    );
  };

  const CloseButton = () => {
    return (
      <button
        className={`flex items-center justify-center border border-teritary2 hover:border-teritary1 hover:text-teritary1 text-teritary2 font-bold font-notoSans text-sm rounded-[25px] px-9 py-2 focus:outline-none cursor-pointer`}
      >
        <span onClick={() => toggle(!visible)}>
          <Text14Bold className="text-primary2">
            {$t(`_CLOSE_`, "Close")}
          </Text14Bold>
        </span>
      </button>
    );
  };

  const AddAttributeButton = () => {
    return (
      <div onClick={handleAttributeAdd} className="mt-6 cursor-pointer">
        <Text14Bold className="text-primary1">+ Add Attribute</Text14Bold>
      </div>
    );
  };

  const Notice = () => {
    return (
      <div className="flex flex-col mb-4">
        <Text24Bold>{$t("_Attributes_", "Attributes")}</Text24Bold>
        <Text24Light>Fill all the fields to create Attributes</Text24Light>
      </div>
    );
  };

  return (
    <div
      className={
        `fixed -translate-y-1/2 -translate-x-1/2 top-1/2 left-1/2 z-20  ` + // position
        `max-w-[620px] w-full ` + // dimensions
        `bg-[#1B1E21] shadow-['0px 4px 4px rgba(0, 0, 0, 0.25)'] ` + // colors
        `flex flex-col gap-1 overflow-hidden rounded-lg` // display
      }
    >
      <div className="w-full flex flex-1 flex-col font-notoSans px-10 py-6 text-white">
        <Notice />
        <RenderAttributeList
          attributeList={attributeList}
          handleAttributeChange={handleAttributeChange}
          handleAttributeRemove={handleAttributeRemove}
        />
        <AddAttributeButton />
        {/* <TermsAndConditions setValues={setValues} /> */}
        <div className="flex space-x-4 justify-end mt-7">
          <CloseButton />
          <SaveAllAttributes />
        </div>
      </div>
    </div>
  );
};

export default AttributeModal;

const RenderAttributeList = (props) => {
  const { attributeList, handleAttributeChange, handleAttributeRemove } = props;
  return attributeList.map((attribute, index) => (
    <>
      <AttributeItem
        key={`attributeitem-${index}`}
        attribute={attribute}
        index={index}
        handleAttributeChange={handleAttributeChange}
        handleAttributeRemove={handleAttributeRemove}
      />
    </>
  ));
};

const AttributeItem = ({
  attribute,
  index,
  handleAttributeChange,
  handleAttributeRemove,
}) => {
  return (
    <div className="flex flex-row flex-1 w-full items-center justify-start space-x-4 mt-2">
      <AttributeInput
        key={`label-${index}`}
        name="label"
        placeholder={$t(`_LABEL_`, "Label")}
        index={index}
        value={attribute.label}
        handleAttributeChange={handleAttributeChange}
      />
      <AttributeInput
        key={`attribute-${index}`}
        name="attribute"
        placeholder={$t(`_ATTRIBUTE_`, "Attribute")}
        index={index}
        value={attribute.attribute}
        handleAttributeChange={handleAttributeChange}
      />
      <RemoveButton
        index={index}
        handleAttributeRemove={handleAttributeRemove}
      />
    </div>
  );
};

const RemoveButton = (props) => {
  const { index, handleAttributeRemove } = props;
  return (
    <div
      className="flex justify-center items-center gap-2 relative mt-8 cursor-pointer "
      onClick={() => handleAttributeRemove(index)}
    >
      <Text14Bold className="text-white text-opacity-80">
        {$t(`_REMOVE_`, "Remove")}
      </Text14Bold>
      <RemoveIcon />
    </div>
  );
};

const AttributeInput = (props) => {
  console.log("input");
  return (
    <div key={props.index} className="flex flex-col">
      <label
        htmlFor={props.name}
        className="text-white text-sm font-notoSans font-bold opacity-60 text-left mb-2 capitalize"
      >
        {props.placeholder}
      </label>
      <input
        key={`attribute-input-${props.name}-${props.index}`}
        name={props.name}
        type="text"
        placeholder={props.placeholder}
        autoComplete="off"
        className={`h-[48px] w-full text-sm text-white bg-lightbg px-5 focus:outline-none rounded-md`}
        value={props.value}
        onChange={(e) => props.handleAttributeChange(e, props.index)}
        required
      />
    </div>
  );
};

function TermsAndConditions(props) {
  const { setValues } = props;
  return (
    <div className="mt-5">
      <Checkbox
        onChange={(e) => {
          console.log(e?.target?.checked);
          setValues(() => {
            return { acceptTerm: e?.target?.checked };
          });
        }}
        label={
          <div>
            <Text14Regular>
              {$t(
                `_BY_CHECKING_THIS_I_AM_AGREEING_TO_THE_ `,
                "By checking this I am agreeing to the "
              )}
            </Text14Regular>
            <a href="google.co" onClick={(e) => false}>
              <Text14Regular className="text-primary1">
                {$t(`_TERMS_AND_CONDITIONS_`, "terms and conditions")}
              </Text14Regular>
            </a>
          </div>
        }
      />
    </div>
  );
}
