import Trending from "components/blocks/trending/Trending";
import { AppContext } from "contexts/AppContext";
import { useContext, useEffect, useState } from "react";
import { findByTrend, getNfts } from "api/apiCalls";
import { findByBids } from "api/apiCalls";
import { useNavigate, useLocation } from "react-router-dom";
import { Text26Bold } from "components/elements/Text";
import SmoothScroll from "../../hooks/useSmoothScroll";
import { commonEmptyFilter } from "modules/explore/Explore";
import shuffle from "lodash/shuffle";
import FloatingSocial from "components/elements/FloatingSocial";
import { $t } from "components/utils/Translation";

const Home = (props) => {
  const {
    setShowBanner,
    collections,
    topBuyers,
    topSellers,
    isLoadingCollection,
  } = useContext(AppContext);
  const navigate = useNavigate();

  const [trendingItems, setTrendingItems] = useState([]);
  const [bids, setBids] = useState([]);
  const [nfts, setnfts] = useState([]);
  const [loader, setLoader] = useState({
    isnftLoading: true,
    isBidLoading: true,
    isTrendingLoading: true,
  });
  // const [collections, setcollections] = useState([]);
  // const [topSellers, setTopSellers] = useState([]);
  // const [topBuyers, setTopBuyers] = useState([]);
  const { hash } = useLocation();

  const fetchTrendingItems = async () => {
    try {
      setLoader((loader) => {
        return { ...loader, isTrendingLoading: true };
      });
      const response = await findByTrend();
      setTrendingItems(
        response && response.data && response.data.length ? response.data : []
      );
    } catch (e) {
      console.log(e);
    } finally {
      setLoader((loader) => {
        return { ...loader, isTrendingLoading: false };
      });
    }
  };

  const fetchBids = async () => {
    try {
      setLoader((loader) => {
        return { ...loader, isBidLoading: true };
      });
      const response = await findByBids();
      setBids(
        response && response.data && response.data.length ? response.data : []
      );
    } catch (e) {
      console.log(e);
    } finally {
      setLoader((loader) => {
        return { ...loader, isBidLoading: false };
      });
    }
  };

  const fetchNfts = async () => {
    try {
      setLoader((loader) => {
        return { ...loader, isnftLoading: true };
      });
      const getNftsResponse = await getNfts(commonEmptyFilter);
      if (
        getNftsResponse &&
        getNftsResponse.data &&
        getNftsResponse.data.list
      ) {
        setnfts(
          getNftsResponse.data.list.length ? getNftsResponse.data.list : []
        );
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoader((loader) => {
        return { ...loader, isnftLoading: false };
      });
    }
  };

  // const fetchCollections = async () => {
  //   const getCollectionsResponse = await getCollections(emptyFilter);
  //   if (
  //     getCollectionsResponse &&
  //     getCollectionsResponse.data &&
  //     getCollectionsResponse.data.collections
  //   ) {
  //     setcollections(
  //       getCollectionsResponse.data.collections.length
  //         ? getCollectionsResponse.data.collections
  //         : []
  //     );
  //   }
  // };

  // const fetchTopSellers = async () => {
  //   const getTopSellersResponse = await getTopSellers();
  //   if (getTopSellersResponse && getTopSellersResponse.data) {
  //     setTopSellers(getTopSellersResponse.data);
  //   }
  // };
  // const fetchCollections = async () => {
  //   const getCollectionsResponse = await getCollections(emptyFilter);
  //   setcollections(getCollectionsResponse)
  // };

  // const fetchTopSellers = async () => {
  //   const getTopSellersResponse = await getTopSellers();
  //   if (getTopSellersResponse) {
  //     setTopSellers(getTopSellersResponse);
  //   }
  // };

  // const fetchTopBuyers = async () => {
  //   const getTopBuyersResponse = await getTopBuyers();
  //   if (getTopBuyersResponse && getTopBuyersResponse.data) {
  //     setTopBuyers(getTopBuyersResponse.data);
  //   }
  // };

  useEffect(() => {
    setShowBanner(true);
    return () => setShowBanner(false);
  }, [setShowBanner]);

  useEffect(() => {
    fetchTrendingItems();
    fetchNfts();
    fetchBids();
    // fetchTopSellers();
    // fetchCollections();
  }, []);

  return (
    <>
      <div className="flex flex-col gap-[100px] -mt-16 mb-14 z-50 relative">
        <Trending
          id="trending"
          type="NFT"
          isLoading={loader?.isTrendingLoading}
          items={trendingItems}
          caption={
            <Text26Bold className="uppercase">
              {$t("_TRENDING_", "trending")}
            </Text26Bold>
          }
        />
        <Trending
          id="collection"
          type="COLLECTIONS"
          isLoading={isLoadingCollection}
          items={collections}
          caption={
            <Text26Bold className="uppercase">
              {$t("_HOT_COLLECTIONS_", "hot collections")}
            </Text26Bold>
          }
        />
        {/* <Trending
          id="top_seller"
          type="PROFILES"
          isArtist={true}
          isLoading={true}
          buyers={topBuyers}
          sellers={topSellers}
          caption={
            <Text26Bold className="uppercase">
              {$t("_TOP_SELLERS_", "Top sellers")}
            </Text26Bold>
          }
        /> */}
        <Trending
          id="hot_bids"
          type="BIDS"
          items={bids}
          isLoading={loader?.isBidLoading}
          caption={
            <Text26Bold className="uppercase">
              {$t("_HOT_BIDS_", "hot bids")}
            </Text26Bold>
          }
        />
        <Trending
          id="live_auction"
          type="LIVEAUCTION"
          items={shuffle(nfts)}
          isLoading={loader?.isnftLoading}
          caption={
            <Text26Bold className="uppercase">
              {$t("_LIVE_AUCTIONS_", "live auctions")}
            </Text26Bold>
          }
        />
        <Trending
          id="explore"
          type="NFT"
          items={nfts}
          isLoading={loader?.isnftLoading}
          caption={
            <Text26Bold className="uppercase">
              {$t("_EXPLORE_", "explore")}
            </Text26Bold>
          }
        />
      </div>
      <SmoothScroll />
      <FloatingSocial />
    </>
  );
};

export default Home;
