import { Routes, Route } from "react-router-dom";
import WalletConnect from "modules/example/WalletConnect";
import RouteNotFound404 from "components/layouts/404";
import Home from "modules/home/Home";
import Page from "components/layouts/Page";
import Explore from "modules/explore/Explore";
import Profile from "modules/profile/Profile";
import NftDetails from "modules/nft/ViewNft";
import NftCreate from "modules/nft/CreateNft";
import EditProfile from "modules/profile/EditProfile";
import Admin from "modules/Admin";
import AdminLayout from "components/layouts/AdminLayout";
import Batch from "modules/Batch";
import CommingSoon from "components/CommingSoon";
import NFTListing from "modules/NFTListing";
import PrivateRouter from "components/PrivateRouter";
import Collection from "modules/collection/Collection";
import TopSellers from "modules/topSellers/TopSellers";
import Report from "modules/Report";
import VerifyEmail from "modules/verifyEmail/VerifyEmail";

const ValoresRouter = (props) => {
  return (
    <Routes>
      <Route exact path="/admin" element={<Admin />} />
      <Route
        element={
          <PrivateRouter redirectUrl={"/admin"} role={"ADMIN"}>
            <AdminLayout />
          </PrivateRouter>
        }
      >
        <Route exact path="/admin/batch" element={<Batch />} />
        <Route exact path="/admin/batch/:id" element={<NFTListing />} />
        <Route exact path="/admin/artist" element={<CommingSoon />} />
        <Route exact path="/admin/customer" element={<CommingSoon />} />
        <Route exact path="/admin/notification" element={<CommingSoon />} />
        <Route exact path="/admin/admin" element={<CommingSoon />} />
        <Route exact path="/admin/report" element={<Report />} />
        <Route exact path="/admin/setting" element={<CommingSoon />} />
      </Route>

      <Route element={<Page />}>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/explore" element={<Explore />} />
        <Route
          exact
          path="/my-profile"
          element={<Profile type="MY_PROFILE" />}
        />
        <Route exact path="/profile/:ProfileID" element={<Profile />} />
        <Route
          exact
          path="/profile/:ProfileID/edit"
          element={<EditProfile />}
        />
        <Route exact path="/nft/create" element={<NftCreate />} />
        <Route exact path="/nft/:NFTID" element={<NftDetails />} />
        <Route exact path="/collection" element={<Collection />} />
        <Route exact path="/topSellers" element={<TopSellers />} />
        <Route
          exact
          path="example/wallet-connect-example"
          element={<WalletConnect />}
        />
        <Route exact path="/verify-email/:encryptedHash" element={<VerifyEmail />} />
        <Route path="*" element={<RouteNotFound404 />} />
      </Route>
    </Routes>
  );
};

export default ValoresRouter;
