import {
  Text16Bold,
  Text16Light,
  Text24Black,
  Text24Light,
} from "components/elements/Text";
import { AppContext } from "contexts/AppContext";
import { useContext } from "react";
import { useMoralis } from "react-moralis";

const WalletConnectionProviderModal = () => {
  let classNames = ` fixed -translate-y-1/2 -translate-x-1/2 top-1/2 left-1/2 z-50  `; // position
  classNames += ` w-[80%] h-[60%] sm:w-[500px] sm:h-[470px] `; // dimensions
  classNames += ` bg-black shadow-['0px 4px 4px rgba(0, 0, 0, 0.25)'] `; // colors
  classNames += ` flex flex-col gap-0.5 overflow-hidden rounded-xl `; // display
  const { showProviderPopup, setShowProviderPopup } = useContext(AppContext);

  const { enableWeb3 } = useMoralis();

  const triggerMetamask = async () => {
    await enableWeb3();
  };
  const triggerWalletConnect = async () => {
    await enableWeb3({ provider: "walletconnect" });
  };

  if (!showProviderPopup) return null;

  return (
    <>
      <div
        id="connect-wallet-overlay"
        className="fixed inset-0 bg-darkbg1 z-10"
        onClick={() => setShowProviderPopup(!showProviderPopup)}
      />
      <div id="provider-selection" className={classNames}>
        <div
          onClick={triggerMetamask}
          className="flex flex-col flex-[0.5] items-center justify-center text-center cursor-pointer  bg-dark1"
        >
          <img
            alt="Metamask Logo"
            className="sm:w-[80px] sm:h-[80px] w-[40px] h-[40px]"
            src="https://docs.metamask.io/metamask-fox.svg"
          ></img>
          <Text24Black className="hidden sm:block text-white sm:text-2xl">
            MetaMask
          </Text24Black>
          <Text16Bold className="sm:hidden text-white leading-tight mt-3">
            MetaMask
          </Text16Bold>

          <Text24Light className="hidden sm:block text-white">
            Connect to your MetaMask Wallet
          </Text24Light>
          <Text16Light className="sm:hidden text-white">
            Connect to your MetaMask Wallet
          </Text16Light>
        </div>
        <div
          onClick={triggerWalletConnect}
          className="flex flex-col flex-[0.5] items-center justify-center text-center cursor-pointer bg-dark1"
        >
          <img
            alt="WalletConnect Logo"
            className="sm:w-[80px] sm:h-[80px] w-[40px] h-[40px]"
            src="https://docs.walletconnect.com/img/walletconnect-logo.svg"
          ></img>
          <Text24Black className="hidden md:block text-sm text-white sm:text-2xl mb-1">
            WalletConnect
          </Text24Black>
          <Text16Bold className="md:hidden text-sm text-white sm:text-2xl mt-2 mb-1">
            WalletConnect
          </Text16Bold>
          <Text24Light className="hidden md:block text-sm text-white sm:text-2xl">
            Scan with WalletConnect to Connect
          </Text24Light>
          <Text16Light className="md:hidden text-sm text-white sm:text-2xl">
            Scan with WalletConnect to Connect
          </Text16Light>
        </div>
      </div>
    </>
  );
};

export default WalletConnectionProviderModal;
