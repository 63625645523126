const Layer = (props) => {
    const { style, className } = props

    return (
        <svg style={style} className={className} width="20" height="20" viewBox="0 0 20 20" fill="white" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.62729 0.921321C9.8619 0.804018 10.138 0.804018 10.3726 0.921321L18.706 5.08799C18.9883 5.22915 19.1666 5.5177 19.1666 5.83334C19.1666 6.14899 18.9883 6.43754 18.706 6.5787L10.3726 10.7454C10.138 10.8627 9.8619 10.8627 9.62729 10.7454L1.29396 6.5787C1.01164 6.43754 0.833303 6.14899 0.833303 5.83334C0.833303 5.5177 1.01164 5.22915 1.29396 5.08799L9.62729 0.921321ZM3.53003 5.83334L9.99997 9.06832L16.4699 5.83334L9.99997 2.59837L3.53003 5.83334Z" fill="#ffffff" />
            <path fillRule="evenodd" clipRule="evenodd" d="M0.921334 13.794C1.12716 13.3823 1.62772 13.2155 2.03937 13.4213L10 17.4016L17.9607 13.4213C18.3723 13.2155 18.8729 13.3823 19.0787 13.794C19.2845 14.2056 19.1177 14.7062 18.706 14.912L10.3727 19.0787C10.1381 19.196 9.86195 19.196 9.62734 19.0787L1.29401 14.912C0.882363 14.7062 0.715509 14.2056 0.921334 13.794Z" fill="#ffffff" />
            <path fillRule="evenodd" clipRule="evenodd" d="M0.921334 9.62733C1.12716 9.21568 1.62772 9.04882 2.03937 9.25465L10 13.235L17.9607 9.25465C18.3723 9.04882 18.8729 9.21568 19.0787 9.62733C19.2845 10.039 19.1177 10.5395 18.706 10.7454L10.3727 14.912C10.1381 15.0293 9.86195 15.0293 9.62734 14.912L1.29401 10.7454C0.882363 10.5395 0.715509 10.039 0.921334 9.62733Z" fill="#ffffff" />
        </svg>
    )
}

export default Layer
