import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useSmoothScroll = ({ delay = 800 }) => {
  const { hash } = useLocation();
  useEffect(() => {
    if (delay && hash) {
      setTimeout(() => {
        const elem = document.getElementById(hash.slice(1));
        if (elem) {
          elem.scrollIntoView({ behavior: "smooth" });
        }
      }, delay);
    }
  }, [hash, delay]);

  return null;
};

export default useSmoothScroll;
