export const Tick = (props) => {

    const { style, className } = props

    return <>
        <svg style={style} className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.6656 0.9375L6.33437 10.6031L2 6.27187L0 8.27188L6.66563 14.9375L16 2.9375L13.6656 0.9375Z" fill="white" />
        </svg>
    </>
}

export const TickActive = (props) => {

    const { style, className } = props

    return (
      <>
        <svg
          style={style}
          className={"w-5 sm:w-[38px] h-5 sm:h-[38px]"}
          viewBox="0 0 38 38"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {/* <rect width="38" height="38" fill="#464646" />
            <rect width="1440" height="1888" transform="translate(-160 -167)" fill="#101316" />
            <line x1="18.5" y1="12" x2="18.5" y2="214" stroke="#1B1E21" strokeWidth="3" />
            <circle cx="19" cy="19" r="24" fill="#101316" /> */}
          <circle cx="19" cy="19" r="19" fill="#22C373" />
          <path
            d="M24.6656 11.9375L17.3344 21.6031L13 17.2719L11 19.2719L17.6656 25.9375L27 13.9375L24.6656 11.9375Z"
            fill="white"
          />
        </svg>
      </>
    );
}

export const TickInactive = (props) => {

    const { style, className } = props

    return (
      <>
        <svg
          style={style}
          className={"w-5 sm:w-[38px] h-5 sm:h-[38px]"}
          viewBox="0 0 38 38"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="38" height="38" fill="#464646" />
          <rect
            width="1440"
            height="1888"
            transform="translate(-160 -260)"
            fill="#101316"
          />
          <line
            x1="18.5"
            y1="-81"
            x2="18.5"
            y2="121"
            stroke="#1B1E21"
            strokeWidth="3"
          />
          <circle cx="19" cy="19" r="19" fill="#1B1E21" />
          <path
            d="M24.6656 11.9375L17.3344 21.6031L13 17.2719L11 19.2719L17.6656 25.9375L27 13.9375L24.6656 11.9375Z"
            fill="#BCBDC4"
          />
        </svg>
      </>
    );
}