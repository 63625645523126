

const Discord = (props) => {
    const { style, className } = props
    
    return (
        <svg style={style} className={className} width="25" height="20" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_150_2817)">
            <path d="M21.1636 1.70239C19.5701 0.980647 17.8614 0.448899 16.0749 0.144344C16.0424 0.138466 16.0099 0.153155 15.9931 0.182534C15.7733 0.568366 15.5299 1.07172 15.3595 1.46735C13.4379 1.18336 11.5262 1.18336 9.64408 1.46735C9.47359 1.06292 9.22134 0.568366 9.0006 0.182534C8.98384 0.154135 8.95134 0.139446 8.9188 0.144344C7.13324 0.447925 5.42454 0.979674 3.83014 1.70239C3.81634 1.70826 3.80451 1.71807 3.79666 1.73079C0.555616 6.51069 -0.33224 11.1731 0.103313 15.7777C0.105283 15.8002 0.118094 15.8218 0.135831 15.8355C2.27418 17.3857 4.34553 18.3268 6.37842 18.9506C6.41095 18.9604 6.44542 18.9486 6.46613 18.9222C6.94701 18.2739 7.37567 17.5904 7.7432 16.8715C7.76489 16.8295 7.74419 16.7795 7.69986 16.7629C7.01993 16.5082 6.3725 16.1978 5.74972 15.8453C5.70046 15.8169 5.69651 15.7473 5.74183 15.714C5.87289 15.6171 6.00398 15.5162 6.12912 15.4144C6.15176 15.3958 6.18331 15.3918 6.20993 15.4036C10.3013 17.2476 14.7308 17.2476 18.7739 15.4036C18.8005 15.3909 18.8321 15.3948 18.8557 15.4134C18.9809 15.5152 19.1119 15.6171 19.244 15.714C19.2893 15.7473 19.2863 15.8169 19.2371 15.8453C18.6143 16.2047 17.9669 16.5082 17.2859 16.7619C17.2416 16.7785 17.2219 16.8295 17.2436 16.8715C17.619 17.5894 18.0477 18.2729 18.5197 18.9212C18.5394 18.9486 18.5749 18.9604 18.6074 18.9506C20.6501 18.3268 22.7215 17.3857 24.8598 15.8355C24.8786 15.8218 24.8904 15.8012 24.8924 15.7787C25.4136 10.4552 24.0193 5.83107 21.1961 1.73176C21.1892 1.71807 21.1774 1.70826 21.1636 1.70239ZM8.35419 12.974C7.12239 12.974 6.10743 11.8576 6.10743 10.4866C6.10743 9.11559 7.10271 7.99922 8.35419 7.99922C9.61549 7.99922 10.6206 9.12539 10.6009 10.4866C10.6009 11.8576 9.60563 12.974 8.35419 12.974ZM16.6612 12.974C15.4294 12.974 14.4145 11.8576 14.4145 10.4866C14.4145 9.11559 15.4097 7.99922 16.6612 7.99922C17.9225 7.99922 18.9276 9.12539 18.908 10.4866C18.908 11.8576 17.9225 12.974 16.6612 12.974Z"/>
            </g>
        </svg>
    )
}

export default Discord