const Telegram = (props) => {
    const { style, className } = props

    return (
        <svg style={style} className={className} width="25" height="21" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.958 2.45e-05C6.57834 0.0111297 4.29993 0.964242 2.62117 2.65087C0.942418 4.33749 -2.59118e-05 6.62033 5.34326e-10 9.00002C5.3433e-10 11.387 0.948211 13.6762 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18C11.3869 18 13.6761 17.0518 15.364 15.364C17.0518 13.6762 18 11.387 18 9.00002C18 6.61308 17.0518 4.32389 15.364 2.63606C13.6761 0.948236 11.3869 2.45e-05 9 2.45e-05C8.986 -8.16668e-06 8.972 -8.16668e-06 8.958 2.45e-05V2.45e-05ZM12.6795 5.41802C12.7545 5.41652 12.9203 5.43527 13.0283 5.52302C13.1 5.58535 13.1458 5.67233 13.1565 5.76677C13.1685 5.83652 13.1835 5.99627 13.1715 6.12077C13.0365 7.54427 12.45 10.9973 12.1515 12.591C12.0255 13.266 11.7772 13.4918 11.5365 13.5135C11.0145 13.5623 10.6178 13.1685 10.1115 12.837C9.3195 12.3173 8.87175 11.994 8.103 11.487C7.21425 10.902 7.79025 10.5795 8.2965 10.0545C8.42925 9.91652 10.7318 7.82177 10.7768 7.63202C10.782 7.60802 10.7872 7.51952 10.7347 7.47302C10.6822 7.42652 10.6043 7.44227 10.548 7.45502C10.4685 7.47302 9.20325 8.31002 6.75225 9.96377C6.39225 10.2113 6.0675 10.3313 5.77575 10.3238C5.45475 10.3178 4.83675 10.143 4.377 9.99377C3.813 9.81002 3.36525 9.71327 3.40425 9.40202C3.4245 9.24002 3.648 9.07427 4.074 8.90477C6.6975 7.76177 8.4465 7.00802 9.3225 6.64427C11.8215 5.60477 12.3413 5.42402 12.6795 5.41802V5.41802Z" />
        </svg>
    )
}

export default Telegram
