import Footer from "components/blocks/footer/Footer"
import Header from "components/blocks/header/Header"
import ScrollToTop from "components/elements/ScrollToTop"
import { Outlet } from "react-router-dom"
import "./Page.css"
import { AlertBox } from "components/elements/AlertBox"

const Page = (props) => {
  return (
    <div className="page-container bg-baseDark relative min-h-[100vh]">
      <AlertBox />
      <Header />
      <div className="px-6 xl:px-[150px] py-14 max-w-[1440px] mx-auto">
        <Outlet />
      </div>
      <Footer />
      <ScrollToTop />
    </div>
  )
}

export default Page