import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function DialogWrapper({ open, children, isCenter }) {
  return (
    <div className="z-10">
      <Dialog
        PaperProps={{
          sx: {
            position: "fixed",
            top: !isCenter ? "48px" : undefined,
            background: "none",
            width: "100%",
          },
        }}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        {children}
      </Dialog>
    </div>
  );
}
