import { callAPI } from "./fetch";

export const getBatchList = async (
  page,
  perPage,
  order,
  search = "",
  chainId
) => {
  return callAPI(`admin/batches`, `GET`, {
    query: { page, perPage, order, search, chainId },
  });
};

export const getReportList = async (page, perPage, order, search = "") => {
  return callAPI(`report`, `GET`, {
    query: { page, perPage, order, search },
  });
};

export const getNFTList = async (id, page, perPage, order, search = "") => {
  return callAPI(`admin/batches/${id}/nfts`, `GET`, {
    query: { page, perPage, order, search },
  });
};

export const generateMerkleRoot = async (id, data) => {
  return callAPI(`admin/batches/${id}/generate-merkle-root`, `PATCH`, {
    body: data,
  });
};

export const includeToBatch = async (id, nftId) => {
  return callAPI(`admin/batches/${id}/nfts/${nftId}/include`, `POST`, {
    body: {},
  });
};

export const excludeToBatch = async (id, nftId) => {
  return callAPI(`admin/batches/${id}/nfts/${nftId}/exclude`, `POST`, {
    body: {},
  });
};

export const publishBatch = async (id, txHash) => {
  return callAPI(`admin/batches/${id}/publish`, `PATCH`, {
    body: { txHash: txHash },
  });
};
