import React from "react";
import Binance from "assets/binance.svg";

const BinanceIcon = ({ className }) => {
  return (
    <div className={className}>
      <img src={Binance} className="h-full w-full" alt="binanceIcon" />
    </div>
  );
};

export default BinanceIcon;
