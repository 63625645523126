import React, { useContext, useEffect } from "react";
import logo from "assets/admin-logo.svg";
import { AppContext, HttpHeaderParam } from "contexts/AppContext";
import { useMoralis } from "react-moralis";
import { userLogout } from "api/apiCalls";
import { useNavigate } from "react-router-dom";
import { customToast } from "components/utils/generic";

const Admin = () => {
  const { account, logout, enableWeb3 } = useMoralis();
  const { setShowProviderPopup, userData } = useContext(AppContext);
  const navigate = useNavigate();

  const triggerConnection = async () =>
    window && window.ethereum
      ? setShowProviderPopup(true)
      : await enableWeb3({ provider: "walletconnect" });

  const handleConnection = async () => {
    await triggerConnection();
  };

  useEffect(() => {
    if (userData && account) {
      if (userData?.role === "ADMIN") {
        navigate("/admin/batch");
      } else {
        customToast(
          `Invalid User role, Please try to connect wallet with Admin address`,
          { type: "error" }
        );
        logout();
        userLogout(account);
        localStorage.removeItem(HttpHeaderParam.BearerToken);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  return (
    <div className="bg-baseDark absolute h-full w-full font-notoSans">
      <div className="p-[60px] w-full flex justify-center">
        <div className="w-[210px]">
          <img src={logo} alt="company _logo" className="w-full h-full" />
        </div>
      </div>
      <div
        className="h-full w-full flex flex-col items-center justify-center text-center text-white"
        style={{ height: "calc(100% - 150px)" }}
      >
        <div className="text-2xl font-black">Admin</div>
        <div className="mt-2 text-sm font-normal">
          Hey there connect your wallet to access admin
        </div>
        <div className="my-10">
          <button
            onClick={() => handleConnection()}
            className="text-sm bg-secondary2 font-bold border-0 ring-1 ring-secondary2 rounded-full w-44 py-3"
          >
            Connect Wallet
          </button>
        </div>
      </div>
    </div>
  );
};

export default Admin;
