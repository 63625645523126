import { searchByName, searchByCollectionOrAddress } from "api/apiCalls";
import Search from "components/icons/Search";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useDebounce from "hooks/useDebounce";

const SearchField = (props) => {
  const {
    onClick,
    style,
    className,
    placeholder,
    searchIconClickHandler,
    iconClass,
    iconStyle,
    skipSearch,
    setIsLoading,
  } = props;
  // const navigate = useNavigate()
  const location = useLocation();
  const queryString = new URLSearchParams(location.search);
  const keyword = queryString.get("searchKeyword");
  const [searchKeyword, setsearchKeyword] = useState("");

  const debounceValue = useDebounce(searchKeyword, 800);

  const performSearch = async () => {
    setIsLoading(true);
    const searchCallResponseByName = await searchByName(searchKeyword);
    const searchCallResponseByCollectionOrAddress =
      await searchByCollectionOrAddress(searchKeyword);
    const searchByNameData =
      searchCallResponseByName &&
      searchCallResponseByName.data &&
      searchCallResponseByName.data.length
        ? searchCallResponseByName.data
        : [];
    const searchCallResponseByCollectionOrAddressData =
      searchCallResponseByCollectionOrAddress &&
      searchCallResponseByCollectionOrAddress.data &&
      searchCallResponseByCollectionOrAddress.data
        ? searchCallResponseByCollectionOrAddress.data.list
        : [];
    const searchCallResponse = searchByNameData.concat(
      searchCallResponseByCollectionOrAddressData
    );

    const searchResult = [...new Set(searchCallResponse)];
    if (onClick) {
      onClick(!!skipSearch ? searchKeyword : searchResult);
    }
    setIsLoading(false);
  };

  const searchNfts = async () => {
    if (!searchKeyword) return;
    !!skipSearch ? !!onClick && onClick(searchKeyword) : performSearch();
  };
  const handleEnter = (target) => {
    if (target.charCode === 13) {
      searchNfts();
    }
  };

  useEffect(() => {
    setsearchKeyword(keyword);
  }, [keyword]);

  useEffect(() => {
    if (!skipSearch && debounceValue?.trim()) {
      searchNfts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceValue]);

  return (
    <div className={`flex ${className || ""}`}>
      <div style={style} className={`font-notoSans text-white flex-1`}>
        <div className="relative">
          <div
            onClick={searchIconClickHandler || searchNfts}
            className="absolute inset-y-0 right-0 flex items-center pr-2"
          >
            <button
              onClick={() => searchNfts()}
              type="submit"
              className="p-2 focus:outline-none focus:shadow-outline"
            >
              <Search
                className={`!fill-primary1 ${iconClass || ""}`}
                style={iconStyle}
              />
            </button>
          </div>
          <input
            onKeyPress={(e) => handleEnter(e)}
            onChange={(e) => setsearchKeyword(e.target.value)}
            value={searchKeyword || ""}
            type="search"
            name="q"
            className="h-[30px] lg:h-[48px] w-full pr-10 text-sm text-white bg-lightbg pl-5 focus:outline-none rounded-[25px] placeholder:text-[12px] "
            placeholder={placeholder}
            autoComplete="off"
          />
        </div>
      </div>
    </div>
  );
};

export default SearchField;
