
const Headphone = (props) => {
    const { style, className } = props

    return (
        <svg style={style} className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M10.0003 3.33317C8.23222 3.33317 6.53652 4.03555 5.28628 5.28579C4.03604 6.53603 3.33366 8.23173 3.33366 9.99984V14.9998C3.33366 15.4601 2.96056 15.8332 2.50033 15.8332C2.04009 15.8332 1.66699 15.4601 1.66699 14.9998V9.99984C1.66699 7.7897 2.54497 5.67008 4.10777 4.10728C5.67057 2.54448 7.79019 1.6665 10.0003 1.6665C12.2105 1.6665 14.3301 2.54448 15.8929 4.10728C17.4557 5.67008 18.3337 7.7897 18.3337 9.99984V14.9998C18.3337 15.4601 17.9606 15.8332 17.5003 15.8332C17.0401 15.8332 16.667 15.4601 16.667 14.9998V9.99984C16.667 8.23173 15.9646 6.53603 14.7144 5.28579C13.4641 4.03555 11.7684 3.33317 10.0003 3.33317Z" fill="#E3E3E3"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M1.66699 11.6668C1.66699 11.2066 2.04009 10.8335 2.50033 10.8335H5.00033C5.66337 10.8335 6.29925 11.0969 6.76809 11.5657C7.23693 12.0346 7.50033 12.6705 7.50033 13.3335V15.8335C7.50033 16.4965 7.23693 17.1324 6.76809 17.6013C6.29925 18.0701 5.66337 18.3335 5.00033 18.3335H4.16699C3.50395 18.3335 2.86807 18.0701 2.39923 17.6013C1.93038 17.1324 1.66699 16.4965 1.66699 15.8335V11.6668ZM3.33366 12.5002V15.8335C3.33366 16.0545 3.42146 16.2665 3.57774 16.4228C3.73402 16.579 3.94598 16.6668 4.16699 16.6668H5.00033C5.22134 16.6668 5.4333 16.579 5.58958 16.4228C5.74586 16.2665 5.83366 16.0545 5.83366 15.8335V13.3335C5.83366 13.1125 5.74586 12.9005 5.58958 12.7442C5.4333 12.588 5.22134 12.5002 5.00033 12.5002H3.33366ZM15.0003 12.5002C14.7793 12.5002 14.5674 12.588 14.4111 12.7442C14.2548 12.9005 14.167 13.1125 14.167 13.3335V15.8335C14.167 16.0545 14.2548 16.2665 14.4111 16.4228C14.5674 16.579 14.7793 16.6668 15.0003 16.6668H15.8337C16.0547 16.6668 16.2666 16.579 16.4229 16.4228C16.5792 16.2665 16.667 16.0545 16.667 15.8335V12.5002H15.0003ZM13.2326 11.5657C13.7014 11.0969 14.3373 10.8335 15.0003 10.8335H17.5003C17.9606 10.8335 18.3337 11.2066 18.3337 11.6668V15.8335C18.3337 16.4965 18.0703 17.1324 17.6014 17.6013C17.1326 18.0701 16.4967 18.3335 15.8337 18.3335H15.0003C14.3373 18.3335 13.7014 18.0701 13.2326 17.6013C12.7637 17.1324 12.5003 16.4965 12.5003 15.8335V13.3335C12.5003 12.6705 12.7637 12.0346 13.2326 11.5657Z" fill="#E3E3E3"/>
        </svg>
         
    )
}

export default Headphone