import { ButtonTertiaryGhost } from "components/elements/Button";
import DropdownOptions from "components/elements/DropdownOptions";
import ChevronDown from "components/icons/ChevronDown";
import { useState } from "react";

const Select = (props) => {
  const { className, style, options, onSelect, defaultValue, caption } = props;
  const [showOptions, setShowOptions] = useState(false);
  const [selectedValue, setselectedValue] = useState("");

  const selectOption = (selectedVal) => {
    setselectedValue(selectedVal?.name || caption);
    // if (!selectedVal) return
    if (onSelect) onSelect(selectedVal);
    setShowOptions(false);
  };

  // const listOfOptions = ['', ...options]

  return (
    <>
      <div className={`relative w-auto ${className || ""}`} style={style}>
        <ButtonTertiaryGhost
          onClick={() => setShowOptions(!showOptions)}
          className="flex items-center w-full text-sm font-medium text-white1 focus:outline-none"
        >
          <span className="flex grow shrink basis-0 items-center gap-2">
            {selectedValue || caption || defaultValue || ""}{" "}
            <ChevronDown
              className={`fill-white1 w-2 h-2 ${
                showOptions ? "rotate-180" : ""
              }`}
              aria-hidden="true"
            />
          </span>
        </ButtonTertiaryGhost>
        {showOptions && (
          <DropdownOptions
            onClick={(selectedVal) => selectOption(selectedVal)}
            className="absolute top-10 min-w-[12rem] ml-4 right-0 left-auto xl:right-auto xl:left-0"
            options={options}
          />
        )}
      </div>
    </>
  );
};

export default Select;
