
const IconButton = (props) => {
    const {onClick, style, className, icon} = props
    const classes = `cursor-pointer ${className || ''}`

    return (
      <div onClick={onClick} style={style} className={classes}>
        {icon}
      </div>
    )
}

export default IconButton
