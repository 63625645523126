import { CaptionWhite } from "components/elements/Caption";
import { CardContainer } from "components/elements/Filter";
import { Text14Bold, Text14Regular } from "components/elements/Text";
import { $t } from "components/utils/Translation";
import React from "react";
import BlackBox from "./Blockbox";
import { copyOption } from "./constant";

const SelectNumberOfCopies = (props) => {
  const { nftDetails, setnftDetails } = props;

  return (
    <div className="flex flex-col flex-1 text-center sm:text-left">
      <CaptionWhite className="text-[24px] md:text-[26px] font-notoSans font-bold uppercase">
        {$t("_SINGLE_OR_MULTIPLE_", "Single or Multiple")}
      </CaptionWhite>
      <Text14Regular className="my-5">
        How many copies of NFTs would you like to upload?
      </Text14Regular>
      <div className="flex flex-col sm:flex-row gap-6 sm:gap-4 ">
        {copyOption?.map((copy, index) => (
          <CardContainer
            isDisable={copy?.isDisable}
            key={index}
            onClick={() =>
              setnftDetails({ ...nftDetails, numberOfCopies: copy?.key })
            }
            className={`flex flex-col flex-[0.5] items-center max-w-[369px] w-full h-auto xl:h-[369px] justify-between py-12 px-12 ${
              nftDetails?.numberOfCopies === copy?.key
                ? "!border-primary2 !border-2"
                : ""
            }`}
          >
            <BlackBox className="translate-y-1/3 -translate-x-1/4" />
            <BlackBox className="-translate-y-1/3 translate-x-1/4" />
            <Text14Bold>{copy?.name}</Text14Bold>
          </CardContainer>
        ))}
      </div>
    </div>
  );
};

export default SelectNumberOfCopies;
