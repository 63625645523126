import { CircularProgress } from "@mui/material";
import { Text14Bold } from "./Text";

const Button = (props) => {
  const {
    style,
    className,
    children,
    onClick,
    text,
    textClass,
    isLoading,
    disabled,
  } = props;

  return (
    <button
      onClick={onClick}
      style={style}
      disabled={isLoading || disabled}
      className={`flex items-center min-w-[100px] transition-all duration-800 justify-center w-auto h-auto border rounded-[25px] px-4 disabled:opacity-50  disabled:cursor-not-allowed focus:outline-none ${className}`}
    >
      {isLoading ? (
        <div className="ml-0.5 flex">
          <CircularProgress
            size={16}
            sx={{
              color: "inherit",
            }}
          />
        </div>
      ) : (
        <Text14Bold className={textClass || "text-white3"}>
          {text || children}
        </Text14Bold>
      )}
    </button>
  );
};

export default Button;

export const ButtonPrimaryLarge = (props) => (
  <Button
    {...props}
    textClass={`text-black hover:text-black ${props.textClass || ""}`}
    className={`bg-primary2 hover:bg-primary1 border-primary2 hover:border-primary1 min-h-[48px] ${
      props.className || ""
    }`}
  />
);
export const ButtonPrimarySmall = (props) => (
  <Button
    {...props}
    textClass={`text-black ${props.textClass || ""}`}
    className={`bg-primary2 hover:bg-primary1 border-primary2 hover:border-primary1 min-h-[36px] ${
      props.className || ""
    }`}
  />
);
export const ButtonPrimaryGhost = (props) => (
  <Button
    {...props}
    textClass={`text-primary2 hover:text-primary1 ${props.textClass || ""}`}
    className={` border-none min-h-[36px] ${props.className || ""}`}
  />
);

export const ButtonSecondaryLarge = (props) => (
  <Button
    {...props}
    textClass={`text-white hover:text-white ${props.textClass || ""}`}
    className={`bg-secondary2 hover:bg-secondary1 border-grey1 hover:border-fontDark1 min-h-[48px] ${
      props.className || ""
    }`}
  />
);
export const ButtonSecondarySmall = (props) => (
  <Button
    {...props}
    textClass={`text-white ${props.textClass || ""}`}
    className={`bg-secondary2 hover:bg-secondary1 border-grey1 hover:border-fontDark1 min-h-[36px] ${
      props.className || ""
    }`}
  />
);
export const ButtonSecondaryGhost = (props) => (
  <Button
    {...props}
    textClass={`text-secondary2 hover:text-secondary1 ${props.textClass || ""}`}
    className={` border-none min-h-[36px] ${props.className || ""}`}
  />
);

export const ButtonTertiaryLarge = (props) => (
  <Button
    {...props}
    textClass={`text-white ${props.textClass || ""}`}
    className={`border-teritary2 hover:border-teritary1 min-h-[48px] ${
      props.className || ""
    }`}
  />
);
export const ButtonTertiarySmall = (props) => (
  <Button
    {...props}
    textClass={`text-primary2 hover:text-primary1 ${props.textClass || ""}`}
    className={`border-teritary2 hover:border-teritary1 min-h-[36px] ${
      props.className || ""
    }`}
  />
);
export const ButtonTertiaryGhost = (props) => (
  <Button
    {...props}
    textClass={`text-white hover:text-teritary1 ${props.textClass || ""}`}
    className={` border-none min-h-[36px] ${props.className || ""}`}
  />
);
