const ProfileDefaultIcon = () => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 14C17.866 14 21 10.866 21 7C21 3.13401 17.866 0 14 0C10.134 0 7 3.13401 7 7C7 10.866 10.134 14 14 14Z" fill="white" />
            <path d="M14 16.332C8.20367 16.3385 3.50645 21.0357 3.5 26.832C3.5 27.4764 4.02232 27.9987 4.66665 27.9987H23.3333C23.9776 27.9987 24.4999 27.4764 24.4999 26.832C24.4935 21.0357 19.7963 16.3384 14 16.332Z" fill="white" />
        </svg>

    )
}

export default ProfileDefaultIcon