export const NoDataFound = (props) => {
  return (
    <div className="flex flex-col py-2">
      <div className="font-bold text-xl text-white font-notoSans py-2">
        {props.resultText} Results found
      </div>
      <div className="font-normal text-sm text-white font-notoSans py-2">
        we can’t find any results for the given criteria
      </div>
    </div>
  );
};
