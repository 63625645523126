// import React f
import { Grid } from "@mui/material";
import { CaptionWhite } from "components/elements/Caption";
import SearchField from "components/elements/SearchField";
import { Text14Regular } from "components/elements/Text";
import { SampleUrls } from "components/utils/mockData";
import { $t } from "components/utils/Translation";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { AppContext } from "contexts/AppContext";
import { useContext } from "react";
import NFTCollectionCardNew from "components/elements/NFTCollectionCardNew";
import ProfileCard from "components/elements/ProfileCard";
import ProfileCardNew from "components/elements/ProfileCardNew";

export const emptyFilter = {
    categories: [],
    chains: [],
    saleType: [],
    collection: "",
    sortBy: "",
    minPrice: 0,
    maxPrice: 0,
    createdBy: 0, // id
    ownedBy: 0, // id
    likedBy: 0, // id
};

const TopSellers = (props) => {
    const { topSellers } = useContext(AppContext);
    const profilePool = [...topSellers]
    const [autoSearch, setautoSearch] = useState(true);
    const [activeDuration, setActiveDuration] = useState("Today")
    const timeSlot = ['Today', '7 days', '30 days']
    const setCount = 6
    console.log(topSellers)

    return (
        <>
            <div className="pt-[98px] flex flex-col w-full">
                <div className="flex flex-col md:flex-row gap-6 justify-center md:justify-between items-center mb-5 md:mb-3">
                    <CaptionWhite className="opacity-95 uppercase font-bold text-[26px] whitespace-nowrap">
                        {$t("_TOP_SELLERS_", "TOP SELLERS")}
                    </CaptionWhite>
                    <div className="hidden md:flex justify-start w-full whitespace-nowrap py-2 px-3">
                        {timeSlot.map((time, id) => {
                            return (
                                <div key={id} className={`text-sm font-notoSans font-bold px-4 py-2 ${time === activeDuration ? `bg-primary2 hover:bg-primary1 text-black rounded-2xl flex justify-center items-center` : 'text-primary2 hover:text-primary1'}`} onClick={() => setActiveDuration(time)}>
                                    {time}
                                </div>
                            )
                        })}
                    </div>
                    
                    <div className="hidden md:block w-full max-w-[350px]">
                        <SearchField
                            placeholder="Search by NFT name / collection / address"
                            iconClass="fill-white2"
                            auto={autoSearch}
                            className="flex-1"
                        // onClick={(response) => handleSearchResponse(response)}
                        />
                    </div>
                </div>
                <div className='flex justify-center md:justify-start'>
                    <Text14Regular className="uppercase">
                        {topSellers.length}{topSellers.length <= 1 ? ' Result' : ' Results'}
                    </Text14Regular>

                </div>
                <div className="md:hidden flex justify-center items-center w-full overflow-scroll py-2 mt-6">
                    {timeSlot.map((time) => {
                        return (
                            <div className={`flex text-sm font-notoSans font-bold px-4 py-2  whitespace-nowrap ${time === activeDuration ? `bg-primary2 text-black rounded-2xl flex justify-center items-center` : ' text-primary2'}`} onClick={() => setActiveDuration(time)}>
                                {time}
                            </div>
                        )
                    })} 
                </div>
               
                <div className="flex flex-1 mt-16 w-full gap-10 relative">
                    <div
                        id="content"
                        className="flex flex-col w-full lg:w-full gap-4 pl-8"
                    >
                        <Grid
                            container
                            columnSpacing={4}
                            className="overflow-y-scroll scrollable-container"
                            // style={{ backgroundColor: 'red' }}
                        >
                            {/* {topSellers.map((item, idx) => (*/}
                            {/* <div className="min-w-full flex flex-col md:flex-row lg:flex-wrap gap-3 content-start">  */}
                                <div className="min-w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 content-star"> 

                            {topSellers.map((element, index) => (
                                        <ProfileCardNew
                                            profile={element}
                                            key={index}
                                            // widthClass="min-w-[99%] sm:min-w-[49%] lg:min-w-[32%]"
                                            // heightClass="max-h-[103px] h-auto"
                                            style={{
                                                scrollSnapAlign: "start",
                                                // marginRight: "1%",
                                                // marginTop: "1%",
                                            }}
                                    rank={index + 1}
                                            userName="Imapoki "
                                            userIsVerified={true}
                                            imageUrl={element}
                                            walletBalance="1.23"
                                            chainUnit="ETH"
                                        />
                            ))}
                                </div>
                                {/* </div>
                            ))}; */}
                        </Grid>
                    </div>
                </div>
                <div className="md:flex justify-center mt-6 cursor-pointer"><Text14Regular className='text-primary2'>{$t('_LOAD_MORE_', 'Load More')}</Text14Regular></div>
            </div>
        </>
    );
};

export default TopSellers;
