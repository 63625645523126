const Close = (props) => {
    const { style, className, onClick } = props

    return (
        <svg onClick={onClick} style={style} className={className} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M11.5906 1.58934C11.916 1.2639 11.916 0.736263 11.5906 0.410826C11.2651 0.0853888 10.7375 0.0853888 10.412 0.410826L6.0013 4.82157L1.59056 0.410826C1.26512 0.0853888 0.737483 0.0853888 0.412046 0.410826C0.0866095 0.736263 0.0866095 1.2639 0.412046 1.58934L4.82279 6.00008L0.412046 10.4108C0.0866095 10.7363 0.0866095 11.2639 0.412046 11.5893C0.737483 11.9148 1.26512 11.9148 1.59056 11.5893L6.0013 7.17859L10.412 11.5893C10.7375 11.9148 11.2651 11.9148 11.5906 11.5893C11.916 11.2639 11.916 10.7363 11.5906 10.4108L7.17981 6.00008L11.5906 1.58934Z" fill="#87898A" />
        </svg>
    )
}

export default Close
