import { ButtonPrimaryLarge } from "components/elements/Button";
import Text, {
  Text12Regular,
  Text14Regular,
  Text18Bold,
} from "components/elements/Text";
import TextField from "components/elements/TextField";
import Discord from "components/icons/Discord";
import Facebook from "components/icons/Facebook";
import Instagram from "components/icons/Instagram";
import Telegram from "components/icons/Telegram";
import Twitter from "components/icons/Twitter";
import { $t } from "components/utils/Translation";
import { useRef, useState, useEffect, useContext } from "react";
import debounce from "lodash/debounce";
import "./Footer.css";
import { useMoralis } from "react-moralis";
import { AppContext, HttpHeaderParam } from "contexts/AppContext";
import { useLocation, useNavigate } from "react-router-dom";
import { subscribe, userLogout } from "api/apiCalls";
import { customToast } from "components/utils/generic";
import { signLogin } from "common/function";

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const defaultConnectWalletText = $t("_CONNECT_WALLET_", "Connect Wallet");
  const footerRef = useRef(null);
  const { account, logout, enableWeb3, provider } = useMoralis();
  const { setShowProviderPopup, signatureTexts, userData, setuserData } =
    useContext(AppContext);
  const [supportDivHeight, setsupportDivHeight] = useState(0);
  const [connectWalletElementText, setconnectWalletElementText] = useState(
    !!account ? account : defaultConnectWalletText
  );
  const changeSupportDivHeight = () =>
    footerRef &&
    footerRef.current &&
    setsupportDivHeight(footerRef.current.offsetHeight || 0);

  const handleConnection = () => {
    if (!!account) {
      logout();
      userLogout(account);
      setuserData(null);
      localStorage.removeItem(HttpHeaderParam.BearerToken);
      return;
    }
    triggerConnection();
  };

  const triggerConnection = () =>
    window && window.ethereum
      ? setShowProviderPopup(true)
      : enableWeb3({ provider: "walletconnect" });

  const goToCreateNFT = async () => {
    if (!account || !userData || !userData.id) {
      return customToast(
        `Please check if your wallet is connected and you're signed in.`,
        { toastId: "CREATE_NFT_VALIDATION_FAILURE" }
      );
    }
    // const signature = location.pathname.includes("/nft/create")
    //   ? { user: userData }
    //   : await signLogin(
    //       account,
    //       signatureTexts,
    //       provider,
    //       userData,
    //       "createNft"
    //     );
    // if (signature.user) {
    //As per discussion signature won't needed for create NFT
    navigate("/nft/create");
    // }
  };

  const goToProfile = async () => {
    if (!userData || !userData.id)
      return customToast("Please connect your wallet", {
        toastId: "WALLET_NOT_CONNECTED",
      });
    // const signature = location.pathname.includes("/profile")
    //   ? { user: userData }
    //   : await signLogin(
    //       account,
    //       signatureTexts,
    //       provider,
    //       userData,
    //       "profileUpdate"
    //     );
    // if (signature.user) {
    //As per discussion signature won't needed for profile update NFT
    navigate(`/my-profile`);
    // }
  };

  useEffect(changeSupportDivHeight, [footerRef]);

  useEffect(() => {
    window.addEventListener("resize", debounce(changeSupportDivHeight, 100));
    return () => {
      window.removeEventListener(
        "resize",
        debounce(changeSupportDivHeight, 100)
      );
    };
  }, [footerRef]);

  useEffect(() => {
    setconnectWalletElementText(
      !!account
        ? `${account.slice(0, 5)} • • • ${account.slice(-3)}`
        : defaultConnectWalletText
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  return (
    <>
      <div style={{ width: "100%", height: supportDivHeight || 541 }} />
      <footer
        ref={footerRef}
        className="bg-lightbg h-auto xl:min-h-[498px] min-w-full max-w-full flex flex-col absolute bottom-0 px-5 md:px-10 xl:px-[150px] xl:pt-[78px] pt-8 pb-10 py-14"
      >
        <div className="max-w-6xl mx-auto w-full">
          <div className="flex flex-1 flex-col xl:flex-row gap-8 items-center xl:justify-between border-b border-grey2 pb-8 xl:pb-6">
            <Text14Regular className="hidden md:block text-grey2 opacity-70 max-w-[490px] md:pl-8 text-center xl:text-left">
              Valores is an NFT marketplace where{" "}
              <span className=" text-primary3">we value what you value</span> -
              what you <span className=" text-primary3">celebrate</span>, what
              you <span className=" text-primary3">create</span>, and on what
              you <span className=" text-primary3">collaborate</span>.
            </Text14Regular>
            <Text12Regular className="md:hidden text-[11px] text-grey2 opacity-70 max-w-[490px] md:pl-8 text-center">
              Valores is an NFT marketplace where{" "}
              <span className=" text-primary3">we value what you value</span> -
              what you <span className=" text-primary3">celebrate</span>, what
              you <span className=" text-primary3">create</span>, and on what
              you <span className=" text-primary3">collaborate</span>.
            </Text12Regular>
            <div className="flex flex-row gap-5 md:gap-9 md:pr-8">
              <div>
                <a
                  target="_blank"
                  href="https://www.instagram.com/wearevalores/"
                >
                  <Instagram className="fill-grey2 opacity-70 w-9 h-9" />
                </a>
              </div>
              <div>
                <a target="_blank" href="https://discord.gg/2D3c7Ehjvk">
                  <Discord className="fill-grey2 opacity-70 w-9 h-9" />
                </a>
              </div>
              <div>
                <a target="_blank" href="https://twitter.com/wearevalores">
                  <Twitter className="fill-grey2 opacity-70 w-9 h-9" />
                </a>
              </div>
              <div>
                <a target="_blank" href="https://www.facebook.com/wearevalores">
                  <Facebook className="fill-grey2 opacity-70 w-9 h-9" />
                </a>
              </div>
              <div>
                <a target="_blank" href="https://t.me/wearevalores">
                  <Telegram className="fill-grey2 opacity-70 w-9 h-9" />
                </a>
              </div>
            </div>
          </div>
          <div className="border-b border-grey2 pb-8 text-center xl:text-left justify-between">
            <div className="flex flex-row flex-wrap xl:flex-nowrap 2xl:flex-wrap md:px-8 justify-center">
              <div className="flex flex-col gap-4 min-w-[50%] pt-10 xl:min-w-[22%]">
                <Text18Bold className="text-grey2 opacity-70">
                  {$t("_EXPLORE_", "Explore")}
                </Text18Bold>
                <ul className="flex flex-col gap-4">
                  <li className="cursor-pointer" onClick={() => navigate("/")}>
                    <Text14Regular className="text-grey2 opacity-70">
                      {$t("_HOME_", "Home")}
                    </Text14Regular>
                  </li>
                  <li>
                    <Text14Regular className="text-grey2 opacity-70">
                      {$t("_TRENDING_NFTS_", "Trending NFTs")}
                    </Text14Regular>
                  </li>
                  <li>
                    <Text14Regular className="text-grey2 opacity-70">
                      {$t("_TRENDING_COLLECTIONS_", "Trending Collections")}
                    </Text14Regular>
                  </li>
                  <li>
                    <Text14Regular className="text-grey2 opacity-70">
                      {$t("_HOT_BIDS_", "Hot Bids")}
                    </Text14Regular>
                  </li>
                  <li>
                    <Text14Regular className="text-grey2 opacity-70">
                      {$t("_LIVE_AUCTIONS_", "Live Auctions")}
                    </Text14Regular>
                  </li>
                  <li
                    className="cursor-pointer"
                    onClick={() => navigate("/explore")}
                  >
                    <Text14Regular className="text-grey2 opacity-70">
                      {$t("_EXPLORE_", "Explore")}
                    </Text14Regular>
                  </li>
                </ul>
              </div>
              <div className="flex flex-col gap-4 min-w-[50%] pt-10 xl:min-w-[22%]">
                <Text18Bold className="text-grey2 opacity-70">Users</Text18Bold>
                <ul className="flex flex-col gap-4">
                  {/* <li><Text14Regular className="text-grey2 opacity-70">Top Buyers</Text14Regular></li> */}
                  <li>
                    <Text14Regular className="text-grey2 opacity-70">
                      {$t("_TOP_SELLERS_", "Top Sellers")}
                    </Text14Regular>
                  </li>
                  <li className="cursor-pointer" onClick={goToProfile}>
                    <Text14Regular className="text-grey2 opacity-70">
                      {$t("_MY_PROFILE_", "My Profile")}
                    </Text14Regular>
                  </li>
                </ul>
              </div>
              <div className="flex flex-col gap-4 min-w-[50%] pt-10 xl:min-w-[22%]">
                <Text18Bold className="text-grey2 opacity-70">
                  {$t("_MORE_INFO_", "More info")}
                </Text18Bold>
                <ul className="flex flex-col gap-4">
                  <li>
                    <a
                      target="_blank"
                      href="https://valores.notion.site/Valores-5983229fdb9e4f05b753ed39a3f06c80"
                    >
                      <Text14Regular className="text-grey2 opacity-70">
                        {$t("_ANNOUNCEMENTS_", "Announcements")}
                      </Text14Regular>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://valores.notion.site/About-Us-5a183f86b8ed46b28bd53d792855fd23"
                    >
                      <Text14Regular className="text-grey2 opacity-70">
                        {$t("_ABOUT_VALORES_", "About Valores")}
                      </Text14Regular>
                    </a>
                  </li>
                  <li>
                    <Text14Regular className="text-grey2 opacity-70">
                      {$t("_TUTORIAL_", "Tutorial")}
                    </Text14Regular>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://forms.gle/R7uZjH4qBY7BFNYk7"
                    >
                      <Text14Regular className="text-grey2 opacity-70">
                        Apply for Partnership
                      </Text14Regular>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://forms.gle/pLAkCd99RfGv6shz9"
                    >
                      <Text14Regular className="text-grey2 opacity-70">
                        Support
                      </Text14Regular>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="flex flex-col justify-between min-w-[50%] pt-10 xl:min-w-[33%]">
                <div className="flex flex-col gap-4">
                  <Text18Bold className="text-grey2 opacity-70">
                    {$t("_TOOLS_", "Tools")}
                  </Text18Bold>
                  <ul className="flex flex-col gap-4">
                    <li className="cursor-pointer" onClick={goToCreateNFT}>
                      <Text14Regular className="text-grey2 opacity-70">
                        {$t("_CREATE_NFT_", "Create NFT")}
                      </Text14Regular>
                    </li>
                    <li className="cursor-pointer" onClick={handleConnection}>
                      <Text14Regular className="text-grey2 opacity-70">
                        {connectWalletElementText}
                      </Text14Regular>
                    </li>
                  </ul>
                </div>
                <SubscribeField className="hidden xl:block" />
              </div>
              <SubscribeField className="block xl:hidden sm:flex-1 w-full pt-12" />
            </div>
          </div>
          <div className="flex flex-col-reverse md:flex-row items-center justify-between pt-8 gap-4 px-8">
            <Text className="text-grey2 opacity-70 text-[12px] md:text-[18px] font-bold">
              Ⓒ2022 CYK NETWORK PTE. LTD.
            </Text>
            <div className="flex justify-evenly gap-8 md:mt-1">
              <a
                target="_blank"
                href="https://valores.notion.site/Privacy-Policy-4c3d7a9d745246bca54b2399ff66cbd6"
              >
                <Text14Regular className="text-grey2 opacity-70 whitespace-nowrap">
                  {$t("_PRIVACY_POLICY_", "Privacy Policy")}
                </Text14Regular>
              </a>
              <a
                target="_blank"
                href="https://valores.notion.site/Terms-Conditions-eff87273243349f1beef2d721670b92d"
              >
                <Text14Regular className="text-grey2 opacity-70 whitespace-nowrap">
                  {$t("_TERMS_OF_USE_", "Terms of Service")}
                </Text14Regular>
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;

export const SubscribeField = (props) => {
  const regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  const [validEmail, setValidEmail] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const emailValidation = () => {
    if (!emailValue || regex.test(emailValue) === false) {
      setValidEmail(false);
      setEmailError("Enter a valid email");
      return false;
    }
    setValidEmail(true);
    return true;
  };

  const handleSubscribe = async (props) => {
    const render = (dialog) => {
      var winH = window.innerHeight;
      var dialogoverlay = document.getElementById("dialogoverlay");
      var dialogbox = document.getElementById("dialogbox");
      dialogoverlay.style.display = "block";
      dialogoverlay.style.height = winH + "px";
      dialogbox.style.display = "block";
    };
    if (emailValidation() && emailValue !== "") {
      render("Thank you for subscribing to our newsletter!");
      await subscribe(emailValue);
      document.getElementById(
        "Subscribe to our newsletter for daily updates"
      ).value = "";
      // alert('Thank you for subscribing to our newsletter!')
    }
  };
  const { className } = props;
  return (
    <div className={className || ""}>
      <TextField
        label="Subscribe to our newsletter for daily updates"
        labelClass="text-grey2 opacity-70 !mb-4"
        placeholder="Your Email"
        inputClass=" rounded-[25px] border border-white1"
        rightElement={
          <ButtonPrimaryLarge
            className="min-w-[130px]"
            text={$t("_SUBSCRIBE_", "Subscribe")}
          />
        }
        rightElementClickHandler={() => handleSubscribe()}
        onChange={(e) => setEmailValue(e.target.value)}
        validEmail={validEmail}
        emailError={emailError}
      />
    </div>
  );
};
