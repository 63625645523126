import React from "react";
import Avax from "assets/avax.svg";

const AvaxIcon = ({ className }) => {
  return (
    <div className={className}>
      <img src={Avax} className="h-full w-full" alt="avaxIcon" />
    </div>
  );
};

export default AvaxIcon;
