import { useNavigate, useParams } from "react-router-dom";
import debounce from "lodash/debounce";
import { SampleUrls, nftInfo, graphData } from "components/utils/mockData";
import { useContext, useEffect, useRef, useState } from "react";
import { BigNumber } from "bignumber.js";
import {
  Text12Bold,
  Text12Regular,
  Text14Bold,
  Text18Bold,
  Text18Regular,
  Text24Light,
  Text24Regular,
} from "components/elements/Text";
import Verified from "components/icons/Verified";
import { HeartFilled, HeartUnfilled } from "components/icons/Heart";
import { $t } from "components/utils/Translation";
import { Ether } from "components/icons/Blockchain";
import {
  ButtonPrimaryGhost,
  ButtonPrimarySmall,
} from "components/elements/Button";
import Accordion from "components/elements/Accordion";
import { CardContainer } from "components/elements/Filter";
import TabSection from "components/elements/TabSection";
import Trending from "components/blocks/trending/Trending";
import { CaptionWhite } from "components/elements/Caption";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  linearGradient,
  Label,
} from "recharts";
import Select from "components/elements/Select";
import {
  createOffer,
  postCreateOfferSign,
  acceptOffer,
  getAcceptOfferSign,
  getNftActivity,
  getNftDetails,
  likeNft,
  unlikeNft,
  userLoginWithToken,
} from "api/apiCalls";
import { customToast, shortWalletAddress } from "components/utils/generic";
import { AppContext } from "contexts/AppContext";
import {
  useMoralis,
  useApiContract,
  useWeb3ExecuteFunction,
  useChain,
} from "react-moralis";
import { findByTrend } from "api/apiCalls";
import { Grid } from "@mui/material";
import Cart from "components/icons/Cart";
import OfferIcon from "components/icons/Offer";
import Layer from "components/icons/Layer";
import moment from "moment";
import { ButtonTertiaryGhost } from "components/elements/Button";
import SocialShare from "components/elements/SocialShare";
import Share from "components/icons/Share";
import { ReportDropDown } from "components/blocks/banner/Banner";
import File from "components/icons/File";
import PlaceBidModal from "components/PlaceBidModal";
import DialogWrapper from "components/DialogWrapper";
import {
  ALLOWED_CHAINS,
  ALLOWED_TOKENS,
  findExplorerLink,
  findToken,
  findTokenLink,
} from "common/constants";
import { textMask } from "common/function";
import { ERC20_ABI, ERC721_ABI } from "../../common/constants";
import { MarketPlaceAddress } from "../../contexts/AppContext";
import FullPageLoader from "components/elements/FullpageLoader";
import { LottieLoader } from "components/elements/LottieLoader";
import { toast } from "react-toastify";

const NftDetails = () => {
  const { NFTID } = useParams();
  const [nftDetails, setnftDetails] = useState(null);
  const [isLoading, setIsloading] = useState(true);
  const fetchNftDetails = async (NFTID) => {
    if (!NFTID) return;
    setIsloading(true);
    const response = await getNftDetails(NFTID);
    setnftDetails(
      response && response.data && response.data.id ? response.data : null
    );
    setIsloading(false);
  };
  const refreshNft = () => {
    if (!nftDetails || !nftDetails.id) return;
    fetchNftDetails(nftDetails.id);
  };

  useEffect(() => {
    fetchNftDetails(NFTID);
  }, [NFTID]);

  const [similarItems, setSimilarItems] = useState([]);

  const fetchSimilarItems = async () => {
    const response = await findByTrend();
    setSimilarItems(response.data);
  };

  useEffect(() => {
    fetchSimilarItems();
  }, []);

  if (isLoading) {
    return <FullPageLoader className="h-60" />;
  }

  return !nftDetails ? null : (
    <>
      <div className="pt-[98px] flex flex-col xl:flex-row w-full flex-1 gap-10">
        <div className="flex flex-[0.5] flex-col">
          <NFTImage
            imageUrl={
              nftDetails ? nftDetails.secondaryMediaS3Key?.signedUrl : null
            }
          />
          <div className="hidden xl:block w-full">
            <NFTActivity
              nftDetails={nftDetails}
              refreshNft={() => refreshNft()}
            />
          </div>
        </div>
        <div className="flex flex-[0.5] flex-col items-start">
          <NFTInfo
            refreshNft={() => refreshNft()}
            nftInfo={nftInfo}
            nftDetails={nftDetails}
            setnftDetails={(val) => setnftDetails(val)}
          />
          <div className="block xl:hidden w-full">
            <NFTActivity
              nftDetails={nftDetails}
              refreshNft={() => refreshNft()}
            />
          </div>
        </div>
      </div>
      <div className="w-full my-8" />
      <Trending
        type="NFT"
        items={similarItems}
        caption={
          <CaptionWhite className="text-[24px] md:text-[26px] uppercase font-notoSans font-bold">
            {$t("_SIMILAR_", "similar")}
          </CaptionWhite>
        }
      />
    </>
  );
};

export default NftDetails;

const NFTImage = (props) => {
  const { imageUrl, style } = props;
  const nftImageRef = useRef(null);
  const [nftImageHeight, setnftImageHeight] = useState(0);
  const [nftImageUrl, setnftImageUrl] = useState(imageUrl || "");
  const [imageEnlarged, setImageEnlarged] = useState(false);
  const setSquareImageHeight = () =>
    nftImageRef &&
    nftImageRef.current &&
    setnftImageHeight(nftImageRef.current.offsetWidth);

  useEffect(setSquareImageHeight, [nftImageRef, nftImageUrl, imageUrl]);

  useEffect(() => {
    window.addEventListener("resize", debounce(setSquareImageHeight, 100));
    return () => {
      window.removeEventListener("resize", debounce(setSquareImageHeight, 100));
    };
  }, [nftImageRef]);

  function toggleEnlargeImg() {
    setImageEnlarged(!imageEnlarged);
  }

  return !imageUrl ? null : (
    <div id="nftImage" className="relative">
      <div
        id="nftImg"
        onClick={toggleEnlargeImg}
        ref={nftImageRef}
        className={`w-full bg-no-repeat bg-center bg-cover z-0 rounded-3xl cursor-pointer`}
        style={{
          backgroundImage: `url(${imageUrl})`,
          height: nftImageHeight,
          ...style,
        }}
      />
      {imageEnlarged && (
        <div className="fixed top-0 left-0 w-full h-full z-30 flex justify-center items-center">
          <div
            onClick={toggleEnlargeImg}
            className="text-white text-xl font-bold z-10 cursor-pointer h-4 w-4 flex justify-center items-center absolute top-4 right-4"
          >
            x
          </div>
          <div
            ref={nftImageRef}
            className={`w-5/6 xl:w-2/3 h-full md:h-2/3 fixed bg-center object-contain bg-cover bg-no-repeat rounded-3xl cursor-pointer`}
            style={{
              backgroundImage: `url(${imageUrl})`,
              transition: "transform 0.25 ease",
              margin: "auto",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              zIndex: 99999,
            }}
          />
          <div className="fixed top-0 left-0 w-full h-full bg-[#1C1C1C] opacity-90 cursor-pointer" />
        </div>
      )}
      ;
    </div>
  );
};

const NFTInfo = (props) => {
  const [showSocialShare, setShowSocialShare] = useState(false);
  const handleSocialShare = () => {
    setShowSocialShare(!showSocialShare);
  };
  const [showReport, setShowReport] = useState(false);
  const [placingBid, setPlacingBid] = useState(false);
  const toggleReport = () => {
    setShowReport(!showReport);
  };
  const { account, provider, Moralis, enableWeb3, chainId } = useMoralis();
  const { switchNetwork } = useChain();
  const { nftInfo, nftDetails, setnftDetails, refreshNft } = props;
  const {
    defaultProfilePic,
    userData,
    setuserData,
    isLoginReinitiate,
    setIsLoginReInitiate,
    setShowProviderPopup,
  } = useContext(AppContext);

  const {
    data: _web3Data,
    error: _web3Error,
    fetch: web3Fetch,
    isFetching: _web3IsFetching,
    isLoading: _web3IsLoading,
  } = useWeb3ExecuteFunction();

  const { NFTID } = useParams();
  const navigate = useNavigate();

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const initialTimeLeft = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };
  const liked =
    userData && userData.id
      ? nftDetails.likedByUsers.filter((item) => item.id === userData.id).length
      : null;
  const offersSortedDesc =
    nftDetails.offers.length > 0
      ? nftDetails.offers.sort(
          (a, b) =>
            Moralis.Units.FromWei(b.salesAmount) -
            Moralis.Units.FromWei(a.salesAmount)
        )
      : [];

  // console.log("offersSortedDesc" ,offersSortedDesc)
  const highestBid = offersSortedDesc.length
    ? Number(
        Moralis.Units.FromWei(offersSortedDesc[0]?.salesAmount || "0")
      ).toFixed(2)
    : null;
  const highestBidUnit = offersSortedDesc.length
    ? findToken(offersSortedDesc[0]?.salesToken, nftDetails.chainId)?.symbol
    : null;

  const sale = nftDetails.sales.filter(
    (item) => +new Date(item.expiry) > +new Date()
  );
  const saleEndDate = sale.length ? new Date(sale[0].expiry) : null;
  const saleEndMonth = saleEndDate ? months[saleEndDate.getUTCMonth()] : null;
  const saleEndDay = saleEndDate ? saleEndDate.getUTCDate() : null;
  const saleEndYear = saleEndDate ? saleEndDate.getUTCFullYear() : null;
  const saleEndTime = saleEndDate
    ? `${saleEndDate.getUTCHours()}:${saleEndDate.getUTCMinutes()} UTC`
    : null;
  const saleEnd = saleEndDate
    ? `${saleEndMonth} ${saleEndDay}, ${saleEndYear} at ${saleEndTime}`
    : null;

  const [timeLeft, setTimeLeft] = useState(initialTimeLeft);
  const [newOffer, setNewOffer] = useState();

  const [bid, setBid] = useState(null);
  const [bidUSD, setBidUSD] = useState(0);
  const [openPlaceBidModal, setOpenPlaceBidModal] = useState(false);
  const [lottieUrl, setLottieUrl] = useState("");

  const expiry = new Date().getTime() + 604800000;

  const calculateTimeLeft = () => {
    if (!sale.length) return initialTimeLeft;
    let difference = +new Date(sale[0].expiry) - +new Date();
    let timeLeft = {};
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      timeLeft = initialTimeLeft;
    }
    return timeLeft;
  };

  // const convertWethToUsd = async () => {
  //   const currencyRate = await getUsdFor1Weth()
  //   if (!currencyRate || !currencyRate.weth || !currencyRate.weth.usd) {
  //     setBidUSD(0)
  //     return
  //   }
  //   setBidUSD((bid * currencyRate.weth.usd).toFixed(2))
  // }

  const performLikeUnlike = (isLikedByUser) => {
    if (!userData?.id) {
      return customToast(
        `Please check if your wallet is connected and you're signed in.`,
        {
          type: "error",
        }
      );
    }
    isLikedByUser ? unlike() : like();
  };

  const like = async () => {
    if (!userData || !userData.id) {
      const response = await userLoginWithToken();
      setuserData(response && response.data ? response.data : null);
    }
    const likedNft = await likeNft(nftDetails.id);
    const updatedNft = { ...nftDetails };
    updatedNft.likedByUsers = likedNft.data.likedByUsers;
    setnftDetails(updatedNft);
  };

  const unlike = async () => {
    if (!userData || !userData.id) {
      const response = await userLoginWithToken();
      setuserData(response && response.data ? response.data : null);
    }
    const unlikedNft = await unlikeNft(nftDetails.id);
    const updatedNft = { ...nftDetails };
    updatedNft.likedByUsers = unlikedNft.data.likedByUsers;
    setnftDetails(updatedNft);
  };

  const signCreateOffer = async (messageHash) => {
    if (!account) {
      return;
    }
    const signature = await provider.send("personal_sign", [
      messageHash,
      `${account}`,
    ]);
    return signature && signature.result ? signature.result : null;
  };

  const { runContractFunction: getERC20AllowanceToMarketplace } =
    useApiContract({
      functionName: "allowance",
      address: bid?.token?.address,
      abi: ERC20_ABI,
      chain: nftDetails.chainId,
      params: {
        _owner: account,
        _spender: MarketPlaceAddress,
      },
    });

  const unlockERC20Funds = () => {
    setLottieUrl("https://assets8.lottiefiles.com/packages/lf20_3htoxr0u.json");
    web3Fetch({
      onError: (error) => {
        console.log("ERROR", error);
        setLottieUrl("");
        customToast("Failed to unlock funds", { type: "error" });
      },
      onSuccess: async (params) => {
        console.log("Success Params", params);
        setLottieUrl("");
        placeBid();
      },
      params: {
        contractAddress: bid?.token?.address,
        abi: ERC20_ABI,
        functionName: "approve",
        params: {
          _spender: MarketPlaceAddress,
          _value: "1000000000000000000000000000000000000000000000000000",
        },
      },
    });
  };

  useEffect(() => {
    if (!bid?.token?.address) {
      return;
    }

    if (!account)
      return customToast("Please connect your wallet", {
        toastId: "NO_WALLET",
      });
    setLottieUrl("https://assets6.lottiefiles.com/packages/lf20_reg0bj9h.json");
    getERC20AllowanceToMarketplace({
      onSuccess: (allowance) => {
        console.log("allowance", allowance);
        setLottieUrl("");
        allowance >= bid?.amount ? placeBid() : unlockERC20Funds();
      },
      onError: (error) => {
        console.log(error);
        setLottieUrl("");
      },
    });
  }, [bid]);

  const placeBid = async () => {
    try {
      if (!bid)
        return customToast("Please input bid value", { toastId: "EMPTY_BID" });
      // if (Number(bid) <= Number(highestBid))
      //   return customToast(
      //     `A higher bid ${highestBid} ${highestBidUnit} already exists`,
      //     { toastId: 'LOW_BID' }
      //   )
      setPlacingBid(true);
      setLottieUrl(
        "https://assets10.lottiefiles.com/packages/lf20_7zpwdaki.json"
      );
      const offerObject = {
        salesToken: bid.token.address,
        salesAmount: Moralis.Units.Token(bid.amount, bid.decimals),
        bidExpiry: Number(
          (new Date().getTime() / 1000 + 24 * 60 * 60).toFixed(0)
        ),
        nftId: nftDetails.id,
      };

      const newOfferResp = await createOffer(offerObject);

      if (newOfferResp?.data?.offer?.id) {
        setNewOffer(newOfferResp.data);
      } else {
        setLottieUrl("");
        customToast(
          "Bid was not placed due to technical issue. Please try again after sometime",
          { toastId: "CREATE_OFFER_FAILURE" }
        );
        setPlacingBid(false);
      }

      const signature = await signCreateOffer(newOfferResp.data.messageHash);

      await submitPlaceBidSignature({
        offerId: newOfferResp.data.offer.id,
        signature,
      });
    } catch (error) {
      setLottieUrl("");
      if (error.message) {
        customToast(error.message.toString(), {
          type: "error",
        });
      }
      console.log("Metamask Error", error);
    }
  };

  const submitPlaceBidSignature = async (params) => {
    const createOfferSignResp = await postCreateOfferSign(params);
    if (createOfferSignResp.statusCode === 201) {
      customToast("Offer placed successfully", {
        toastId: "CREATE_OFFER_SUCCESS",
      });
      refreshNft();
      setLottieUrl("");
      setPlacingBid(false);
      setOpenPlaceBidModal(false);
    } else {
      setLottieUrl("");
      customToast(
        "Bid was not placed due to technical issue. Please try again after sometime",
        { toastId: "CREATE_OFFER_FAILURE" }
      );
      setPlacingBid(false);
    }
  };

  const triggerConnection = async () =>
    window && window.ethereum
      ? setShowProviderPopup(true)
      : await enableWeb3({ provider: "walletconnect" });

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  useEffect(() => {
    if (
      isLoginReinitiate?.isSuccess &&
      isLoginReinitiate?.key === "PLACE_BID"
    ) {
      setOpenPlaceBidModal(true);
      setIsLoginReInitiate(null);
      setLottieUrl("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoginReinitiate]);

  const triggerOpenPlaceBidModal = async () => {
    if (nftDetails?.chainId?.toLowerCase() === chainId.toLowerCase()) {
      setOpenPlaceBidModal(true);
      return true;
    }
    try {
      setLottieUrl(
        "https://assets9.lottiefiles.com/packages/lf20_enojckud.json"
      );
      await switchNetwork(nftDetails?.chainId);
      setIsLoginReInitiate((prev) => ({ isSuccess: false, key: "PLACE_BID" }));
    } catch (e) {
      customToast(e?.message, { type: "error" });
      setOpenPlaceBidModal(false);
      setLottieUrl("");
    }
  };

  return (
    <>
      <div className="flex flex-col w-full gap-8 justify-start">
        <div className="flex flex-1 items-center justify-between ">
          <div className="flex flex-col">
            {!!nftDetails.collection && (
              <div className="flex items-center">
                <Text12Regular className="font-notoSans">
                  <span className="capitalize">
                    {" "}
                    {nftDetails.collection.name || ""}
                  </span>
                </Text12Regular>
                <Verified />
                <ButtonTertiaryGhost>
                  <div
                    className="flex justify-evenly gap-2 xs2:gap-1 items-center relative xs2:-ml-5"
                    onClick={() => handleSocialShare()}
                  >
                    <div className="absolute top-10 z-20 ">
                      {showSocialShare && (
                        <SocialShare
                          copyLink={`/nft/${nftDetails.id}`}
                          quoteFB={`${
                            nftDetails.name + " - " + nftDetails.collection.name
                          } | Valores ${window.location.protocol}//${
                            window.location.host
                          }/nft/${nftDetails.id}`}
                          linkFB={`${window.location.protocol}//${window.location.host}/nft/${nftDetails.id}`}
                          socialLink={`${
                            nftDetails.name + " - " + nftDetails.collection.name
                          } | Valores ${window.location.protocol}//${
                            window.location.host
                          }/nft/${nftDetails.id}`}
                        />
                      )}
                    </div>
                    <Text12Regular className="font-notoSans">
                      {$t("_SHARE_", "Share")}
                    </Text12Regular>
                    <Share />
                  </div>
                </ButtonTertiaryGhost>
                <ButtonTertiaryGhost>
                  <div
                    className="flex justify-evenly gap-2 xs2:gap-1 items-center xs2:-ml-12"
                    onClick={toggleReport}
                  >
                    <Text12Regular className="font-notoSans">
                      {$t("_REPORT_", "Report")}
                    </Text12Regular>
                    <File />
                  </div>
                </ButtonTertiaryGhost>
                {showReport && (
                  <ReportDropDown
                    showReport={showReport}
                    setShowReport={setShowReport}
                    toggleReport={toggleReport}
                    id={nftDetails.id}
                  />
                )}
              </div>
            )}
            <Text18Bold>{nftDetails.name ? nftDetails.name : ""}</Text18Bold>
          </div>

          <div className="flex flex-col -mt-6">
            <div className="flex gap-2 xs2:gap-1 items-center xs2:-ml-5">
              <Text12Bold>{nftDetails.likedByUsers.length}</Text12Bold>
              <span onClick={() => performLikeUnlike(liked)}>
                {liked ? (
                  <HeartFilled className="cursor-pointer" />
                ) : (
                  <HeartUnfilled className="cursor-pointer" />
                )}
              </span>
            </div>
          </div>
        </div>

        <div className="flex flex-1">
          <div className="flex flex-col lg:flex-row justify-between gap-4">
            {/* Creator info */}
            <div id="creator-info" className="flex gap-3">
              <div
                className="bg-no-repeat bg-cover bg-center"
                style={{
                  backgroundImage: `url(${
                    nftDetails.creator?.profileImage?.signedUrl ||
                    defaultProfilePic
                  })`,
                  width: 50,
                  height: 50,
                  borderRadius: 6,
                }}
              />
              <div className="flex flex-col">
                <div>
                  <Text12Regular>{$t("_CREATOR_", "Creator")}</Text12Regular>
                </div>
                <div
                  className="flex"
                  onClick={() => navigate(`/profile/${nftDetails.creator.id}`)}
                >
                  <Text18Bold>
                    {nftDetails.creator.profileName ||
                      shortWalletAddress(nftDetails.creator.walletAddress)}
                  </Text18Bold>
                  {!!nftDetails.creator.verifiedOn && <Verified />}
                </div>
              </div>
            </div>
            {/* Owner info */}
            <div id="owner-info" className="flex gap-3">
              <div
                className="bg-no-repeat bg-cover bg-center"
                style={{
                  backgroundImage: `url(${
                    nftDetails?.owner?.profileImage?.signedUrl ||
                    defaultProfilePic
                  })`,
                  width: 50,
                  height: 50,
                  borderRadius: 6,
                }}
              />
              <div className="flex flex-col">
                <div>
                  <Text12Regular>{$t("_OWNER_", "Owner")}</Text12Regular>
                </div>
                <div
                  className="flex"
                  onClick={() => navigate(`/profile/${nftDetails?.owner?.id}`)}
                >
                  <Text18Bold>
                    {nftDetails?.owner?.profileName ||
                      shortWalletAddress(nftDetails?.owner?.walletAddress)}
                  </Text18Bold>
                  {!!nftDetails?.owner?.verifiedOn && <Verified />}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-1">
          {nftDetails.offers.length > 0 ? (
            <div className="w-full flex items-start gap-10">
              <div className="flex flex-col">
                <Text24Light>{nftDetails.offers.length}</Text24Light>
                <Text12Regular>
                  {$t("_NO._OF_BIDS_", "No. of bids")}
                </Text12Regular>
              </div>
              <div className="flex flex-col">
                {highestBid && highestBidUnit && (
                  <>
                    <div className="flex items-center gap-1">
                      {/* <div className="w-8"> */}
                      {ALLOWED_CHAINS[nftDetails?.chainId]?.icon}
                      {/* </div> */}
                      <Text24Light>
                        <span className="uppercase">{`${highestBid} ${highestBidUnit}`}</span>
                      </Text24Light>
                    </div>
                    <Text12Regular>
                      {$t("_HIGHEST_BID_", "Highest bid")}
                    </Text12Regular>
                  </>
                )}
              </div>
            </div>
          ) : null}
        </div>

        {!!saleEndDate && timeLeft !== initialTimeLeft && (
          <div className="flex flex-1 flex-col bg-dark2 p-5 rounded-lg">
            <div>
              <Text14Bold>Sale Ends on {saleEnd} </Text14Bold>
            </div>
            <div>
              <Text12Regular className="text-white2">
                {$t("_AUCTION_ENDS_IN_", "Auction Ends In")}
              </Text12Regular>
            </div>
            {timeLeft && (
              <div className="flex gap-9">
                <div className="flex flex-col items-center">
                  {!isNaN(timeLeft.days) && (
                    <Text24Regular>{timeLeft.days}</Text24Regular>
                  )}
                  <Text12Regular className="text-white2">
                    {$t("_DAYS_", "Days")}
                  </Text12Regular>
                </div>
                <div className="flex flex-col items-center">
                  {!isNaN(timeLeft.hours) && (
                    <Text24Regular>{timeLeft.hours}</Text24Regular>
                  )}
                  <Text12Regular className="text-white2">
                    {$t("_HOURS_", "Hours")}
                  </Text12Regular>
                </div>
                <div className="flex flex-col items-center">
                  {!isNaN(timeLeft.minutes) && (
                    <Text24Regular>{timeLeft.minutes}</Text24Regular>
                  )}
                  <Text12Regular className="text-white2">
                    {" "}
                    {$t("_MIN_", "Min")}
                  </Text12Regular>
                </div>
                <div className="flex flex-col items-center">
                  {!isNaN(timeLeft.seconds) && (
                    <Text24Regular>{timeLeft.seconds}</Text24Regular>
                  )}
                  <Text12Regular className="text-white2">
                    {$t("_SEC_", "Sec")}
                  </Text12Regular>
                </div>
              </div>
            )}
          </div>
        )}

        {account !== nftDetails?.owner?.walletAddress && (
          <div className="space-y-4 flex flex-col">
            <Text18Bold>{$t("_PLACE_A_BID_", "Place a Bid")}</Text18Bold>
            <div>
              <button
                className="py-[8px] px-9 bg-primary2 rounded-full text-sm font-bold w-full max-w-[200px]"
                onClick={() =>
                  account ? triggerOpenPlaceBidModal() : triggerConnection()
                }
              >
                {account && userData?.id
                  ? $t("_PLACE_BID_", "Place Bid")
                  : "Connect Wallet"}
              </button>
            </div>
          </div>
        )}

        <div className="flex flex-1">
          <Accordion
            panels={[
              {
                caption: <Text18Bold>{$t("_ABOUT_", "About")}</Text18Bold>,
                content: (
                  <>
                    <Text12Regular>
                      {nftDetails.description || ""}
                    </Text12Regular>
                    <div className="flex gap-2 my-4 items-center">
                      <Text12Regular className="text-white3">
                        Created by{" "}
                      </Text12Regular>
                      <Text12Regular className="text-primary1">
                        {nftDetails.creator.profileName ||
                          shortWalletAddress(nftDetails.creator.walletAddress)}
                      </Text12Regular>
                      <Verified />
                    </div>
                  </>
                ),
              },
              {
                caption: (
                  <Text18Bold>{$t("_PROPERTIES_", "Properties")}</Text18Bold>
                ),
                content: (
                  <div className="flex flex-wrap gap-3">
                    {nftDetails?.attributes?.map((attribute) => {
                      return (
                        <CardContainer className="w-[30%] !min-w-[120px] !rounded-lg !px-3 !py-3">
                          <div className="flex flex-col justify-between gap-4">
                            <Text12Regular>
                              {attribute.trait_type || attribute.label}
                            </Text12Regular>
                            <Text18Bold>
                              {attribute.value || attribute.attribute}
                            </Text18Bold>
                          </div>
                        </CardContainer>
                      );
                    })}
                  </div>
                ),
              },
              {
                caption: <Text18Bold>{$t("_DETAILS_", "DETAILS")}</Text18Bold>,
                content: (
                  <div className="flex flex-col gap-4">
                    <div className="flex justify-between">
                      <Text18Regular className="text-white3">
                        {$t("_CONTRACT_ADDRESS_", "Contract Address")}
                      </Text18Regular>
                      <Text18Regular>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={findExplorerLink(
                            nftDetails?.chainId,
                            nftDetails?.collection.contractAddress
                          )}
                        >
                          {" "}
                          {!!nftDetails.collection &&
                            textMask(nftDetails.collection.contractAddress, 7)}
                        </a>
                      </Text18Regular>
                    </div>
                    <div className="flex justify-between">
                      <Text18Regular className="text-white3">
                        {$t("_TOKEN_ID_", "Token ID")}
                      </Text18Regular>
                      <Text18Regular title={nftDetails.tokenId}>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={findTokenLink(
                            nftDetails?.chainId,
                            nftDetails?.collection.contractAddress,
                            nftDetails?.tokenId
                          )}
                        >
                          {textMask(nftDetails.tokenId, 7)}
                        </a>
                      </Text18Regular>
                    </div>
                    <div className="flex justify-between">
                      <Text18Regular className="text-white3">
                        {$t("_TOKEN_STANDARD_", "Token Standard")}
                      </Text18Regular>
                      <Text18Regular>ERC-721</Text18Regular>
                    </div>
                    <div className="flex justify-between">
                      <Text18Regular className="text-white3">
                        {$t("_BLOCKCHAIN_", "Blockchain")}
                      </Text18Regular>
                      <Text18Regular>
                        {ALLOWED_CHAINS[nftDetails?.chainId]?.name}
                      </Text18Regular>
                    </div>
                  </div>
                ),
              },
            ]}
          />
        </div>
        {openPlaceBidModal && (
          <DialogWrapper open={openPlaceBidModal} isCenter>
            <PlaceBidModal
              loading={placingBid}
              tokenOptions={ALLOWED_TOKENS[chainId]?.map((el) => {
                return {
                  label: el?.name,
                  value: el?.name,
                  fullOption: el,
                };
              })}
              onAction={(values) =>
                values ? setBid(values) : setOpenPlaceBidModal(false)
              }
            />
          </DialogWrapper>
        )}
        {lottieUrl && <LottieLoader url={lottieUrl} />}
      </div>
    </>
  );
};

const NFTActivity = (props) => {
  const { nftDetails, refreshNft } = props;
  return (
    <div className="py-4 w-full mt-5">
      <TabSection
        captions={[
          // `${$t("Price history", "Price history")}`,
          `${$t("_OFFERS_", "Offers")}`,
          `${$t("_ACTIVITY_", "Activity")}`,
        ]}
        panels={[
          // <img alt="graph" src="https://i.imgur.com/ks3rXrD.png" />,
          // <GraphBlock />,
          <Offers
            type="row"
            style={{ maxHeight: 600 }}
            refreshNft={refreshNft}
            nftDetails={nftDetails}
          />,
          <Activity
            nftDetails={nftDetails}
            type="row"
            style={{ maxHeight: 600 }}
          />,
        ]}
      />
    </div>
  );
};

const GraphBlock = (props) => {
  const [daysNumber, setdaysNumber] = useState(7);
  const [data, setdata] = useState(graphData.slice(-1 * daysNumber));
  const priceArray = data.map((item) => item.price);
  const totalPrice = priceArray.reduce((total, next) => total + next, 0);
  const average = totalPrice / priceArray.length;
  useEffect(
    () => setdata(graphData.slice(-1 * daysNumber)),
    [daysNumber, setdata]
  );
  const setDays = (str) => {
    const num = str.match(/\d+/).join("");
    setdaysNumber(Number(num));
  };
  return (
    <div className="flex flex-col items-center min-h-[400px]">
      <div className="flex items-center justify-evenly w-full mb-5">
        <Text14Bold>Avg. price: {average.toFixed(2)}</Text14Bold>
        <Text14Bold>Volume: {totalPrice.toFixed(2)}</Text14Bold>
        <Select
          onSelect={setDays}
          caption={`Last ${daysNumber}days`}
          options={[
            `${$t("_LAST_7_DAYS_", "Last 7 days")}`,
            `${$t("_LAST_14_DAYS_", "Last 14 days")}`,
            `${$t("_LAST_21_DAYS_", "Last 21 days")}`,
            `${$t("_LAST_28_DAYS_", "Last 28 days")}`,
          ]}
        />
      </div>
      <ResponsiveContainer width="100%" height="100%" aspect={16 / 9}>
        <AreaChart
          width="100%"
          height={350}
          data={data}
          margin={{ top: 10, right: 30, left: 0, bottom: 100 }}
        >
          <defs>
            {/* <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                        <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                    </linearGradient> */}
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#3ED791" stopOpacity={0.5} />
              <stop offset="95%" stopColor="#3ED791" stopOpacity={0} />
            </linearGradient>
          </defs>
          {/* <XAxis dataKey="day" /> */}
          <XAxis>
            <Label
              className="fill-white"
              value={`${$t("_DAYS_", "Days")}`}
              offset={-10}
              position="insideBottom"
            />
          </XAxis>
          <YAxis>
            <Label
              className="fill-white"
              value={`${$t("_PRICE_", "Price")}`}
              offset={10}
              angle={-90}
              position="insideLeft"
            />
          </YAxis>
          <CartesianGrid vertical={false} strokeWidth={0.5} stroke="#3ED791" />
          <Tooltip />
          {/* <Area type="monotone" dataKey="uv" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" /> */}
          <Area
            type="monotone"
            dataKey="price"
            stroke="#3ED791"
            fillOpacity={1}
            fill="url(#colorPv)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export const Offers = (props) => {
  const { type, style, nftDetails, refreshNft } = props;
  const { Moralis, account, provider, chainId } = useMoralis();
  const { triggerConnection, isLoginReinitiate, setIsLoginReInitiate } =
    useContext(AppContext);
  const [showAcceptButton, setShowAcceptButton] = useState(false);
  const [refresh, setRefresh] = useState("");
  const [lottieUrl, setLottieUrl] = useState("");
  const { switchNetwork } = useChain();

  const {
    data: _web3Data,
    error: _web3Error,
    fetch: web3Fetch,
    isFetching: _web3IsFetching,
    isLoading: _web3IsLoading,
  } = useWeb3ExecuteFunction();

  const signAcceptOffer = async (messageHash) => {
    if (!account) {
      await triggerConnection();
    }
    const signature = await provider.send("personal_sign", [
      messageHash,
      `${account}`,
    ]);
    return signature && signature.result ? signature.result : null;
  };

  const unlockERC721Funds = (doneCb) => {
    setLottieUrl("https://assets8.lottiefiles.com/packages/lf20_3htoxr0u.json");
    web3Fetch({
      onError: (error) => {
        console.log("ERROR", error);
        customToast("Failed to unlock funds", { type: "error" });
        setLottieUrl("");
      },
      onSuccess: doneCb,
      params: {
        contractAddress: nftDetails?.collection?.contractAddress,
        abi: ERC721_ABI,
        functionName: "setApprovalForAll",
        params: {
          operator: MarketPlaceAddress,
          approved: true,
        },
      },
    });
  };

  const { runContractFunction: getNFTAllowanceToMarketplace } = useApiContract({
    functionName: "isApprovedForAll",
    address: nftDetails?.collection?.contractAddress,
    abi: ERC721_ABI,
    chain: nftDetails.chainId,
    params: {
      owner: account,
      operator: MarketPlaceAddress,
    },
  });

  useEffect(() => {
    if (
      isLoginReinitiate?.isSuccess &&
      isLoginReinitiate?.key === "ACCEPT_OFFER"
    ) {
      unlockNFTAndAcceptOffer(isLoginReinitiate?.id);
      setIsLoginReInitiate(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoginReinitiate]);

  const triggerAcceptOffer = async (offerId) => {
    if (nftDetails?.chainId?.toLowerCase() === chainId.toLowerCase()) {
      unlockNFTAndAcceptOffer(offerId);
      return true;
    }
    try {
      setLottieUrl(
        "https://assets9.lottiefiles.com/packages/lf20_enojckud.json"
      );
      await switchNetwork(nftDetails?.chainId);
      setIsLoginReInitiate((_) => ({
        isSuccess: false,
        key: "ACCEPT_OFFER",
        id: offerId,
      }));
    } catch (e) {
      customToast(e?.message, { type: "error" });
      setLottieUrl("");
    }
  };

  const unlockNFTAndAcceptOffer = async (offerId) => {
    setLottieUrl("https://assets6.lottiefiles.com/packages/lf20_reg0bj9h.json");
    getNFTAllowanceToMarketplace({
      onSuccess: (allowance) => {
        setLottieUrl("");
        allowance
          ? acceptOfferHandler(offerId)
          : unlockERC721Funds(() => acceptOfferHandler(offerId));
      },
      onError: (error) => {
        console.log("ERROR", error);
        customToast("Failed to unlock funds", { type: "error" });
        setLottieUrl("");
      },
    });
  };

  const acceptOfferHandler = async (offerId) => {
    try {
      setLottieUrl(
        "https://assets7.lottiefiles.com/packages/lf20_opovqcto.json"
      );
      const expiry = (new Date().getTime() / 1000 + 2 * 60 * 60 * 24).toFixed(
        0
      );
      const offerSignatureResp = await getAcceptOfferSign({ offerId, expiry });
      if (offerSignatureResp.statusCode !== 201) {
        setLottieUrl("");
        return customToast(
          "Bid was not accepted due to technical issue. Please try again after sometime",
          { toastId: "ACCEPT_OFFER_FAILURE" }
        );
      }

      const signature = await signAcceptOffer(
        offerSignatureResp.data.messageHash
      );
      setLottieUrl(
        "https://assets6.lottiefiles.com/packages/lf20_zjoay9id.json"
      );
      const acceptOfferResponse = await acceptOffer({ offerId, signature });
      if (acceptOfferResponse.statusCode === 201) {
        setLottieUrl("");
        customToast("Offer Accepted successfully", {
          toastId: "ACCEPT_OFFER_SUCCESS",
        });
      } else {
        setLottieUrl("");
        return customToast(
          "Bid was not accepted due to technical issue. Please try again after sometime",
          { toastId: "ACCEPT_OFFER_FAILURE" }
        );
      }

      refreshNft();
      setRefresh(Date.now());
    } catch (error) {
      setLottieUrl("");
      if (error.message) {
        customToast(error.message.toString(), {
          type: "error",
        });
      }
      console.log("Metamask error", error);
    }
  };

  useEffect(() => {
    setShowAcceptButton(account === nftDetails?.owner?.walletAddress);
  }, [account, nftDetails?.owner]);

  const isOfferProcessing = (offers) => {
    return offers.some((offer) => offer.status === "processing");
  };

  const isOfferExpired = (expiry, offerId) => {
    return expiry * 1000 < Date.now();
  };

  return !nftDetails.offers.length ? (
    <div className="text-lg text-center font-medium text-white">
      Sorry, there is no offers to display!
    </div>
  ) : (
    <>
      <Grid
        container
        columnSpacing={2}
        className="overflow-y-scroll scrollable-container"
        style={{ ...style }}
      >
        {nftDetails.offers.map((item, idx) => (
          <Grid
            key={idx}
            item
            xs={12}
            md={type === "row" ? 12 : 6}
            xl2={type === "row" ? 12 : 4}
          >
            <CardContainer className="mb-6">
              <div className="flex gap-2 justify-between flex-1">
                <div className="flex gap-8 flex-1">
                  <div className="flex flex-col flex-[0.33]">
                    <Text12Regular className="text-white3">
                      {$t("_PRICE_", "Price")}
                    </Text12Regular>
                    <div className="flex gap-2 flex-wrap">
                      {ALLOWED_CHAINS[nftDetails?.chainId]?.icon}
                      <Text12Regular>
                        {Number(
                          Moralis.Units.FromWei(item.salesAmount)
                        ).toFixed(2)}
                      </Text12Regular>
                      <Text12Regular>
                        {findToken(item.salesToken, nftDetails.chainId)?.symbol}
                      </Text12Regular>
                    </div>
                  </div>

                  <div className="flex flex-col flex-[0.33]">
                    <Text12Regular className="text-white3">
                      {$t("_FROM_", "From")}
                    </Text12Regular>
                    <Text12Regular>
                      {shortWalletAddress(item.bidder.walletAddress)}
                    </Text12Regular>
                  </div>

                  <div className="flex flex-col flex-[0.33]">
                    <Text12Regular className="text-white3">
                      {$t("_EXPIRY_", "Expiry")}
                    </Text12Regular>
                    <Text12Regular>{`${new Date(
                      item.bidderParams?.bidExpiry * 1000
                    ).toDateString()}`}</Text12Regular>
                  </div>
                </div>
                {!!showAcceptButton && (
                  <ButtonPrimarySmall
                    text={
                      isOfferExpired(item.bidderParams?.bidExpiry, item?.id)
                        ? "Expired"
                        : item.status === "pending"
                        ? "accept"
                        : item.status
                    }
                    className="!min-h-0 max-h-9 max-w-[75px] capitalize"
                    onClick={() => triggerAcceptOffer(item.id)}
                    disabled={
                      item.status !== "pending" ||
                      isOfferProcessing(nftDetails.offers) ||
                      isOfferExpired(item.bidderParams?.bidExpiry, item?.id)
                    }
                  />
                )}
              </div>
            </CardContainer>
          </Grid>
        ))}
        {lottieUrl && <LottieLoader url={lottieUrl} />}
      </Grid>
    </>
  );
};

export const Activity = (props) => {
  const { type, style } = props;
  const { NFTID } = useParams();
  const { Moralis } = useMoralis();
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchNftActivities = async (id) => {
    try {
      setLoading(true);
      const nftActivities = await getNftActivity(id);
      if (nftActivities?.data?.list?.length) {
        return setActivities(nftActivities?.data?.list);
      }
      setActivities([]);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const icon = (type) => {
    if (!type) return null;

    const componentMap = {
      OFFER_CREATED: OfferIcon,
      OFFER_ACCEPTED: Cart,
      NFT_CREATED: Layer,
    };

    const Component = componentMap[type];
    return <Component className=" fill-white" />;
  };

  const activityText = (type) => {
    if (!type) return null;

    const componentMap = {
      OFFER_CREATED: "Offer",
      OFFER_ACCEPTED: "Sale",
      NFT_CREATED: "Mint",
    };

    return componentMap[type] || null;
  };

  useEffect(() => {
    fetchNftActivities(NFTID);
  }, [NFTID]);

  const getSeletedToken = (chainId, address) =>
    ALLOWED_TOKENS[chainId]?.find(
      (token) => token?.address.toLowerCase() === address.toLowerCase()
    );

  if (loading) {
    return (
      <Grid container columnSpacing={2}>
        {[1, 2]?.map((idx) => (
          <Grid
            key={idx}
            item
            xs={12}
            md={type === "row" ? 12 : 6}
            xl2={type === "row" ? 12 : 4}
          >
            <CardContainer className="mb-6">
              <div className="flex flex-col justify-between gap-7">
                <div className="flex gap-8 flex-1 justify-between">
                  <div className="flex flex-col">
                    <div className="bg-white3 animate-pulse h-2 w-16 rounded mb-2" />
                    <div className="flex bg-white3 animate-pulse h-2 w-24 rounded" />
                  </div>

                  <div className="flex flex-col">
                    <div className="bg-white3 animate-pulse h-2 w-16 rounded mb-2" />
                    <div className="flex bg-white3 animate-pulse h-2 w-24 rounded" />
                  </div>

                  <div className="flex flex-col">
                    <div className="bg-white3 animate-pulse h-2 w-16 rounded mb-2" />
                    <div className="flex bg-white3 animate-pulse h-2 w-24 rounded" />
                  </div>
                </div>

                <div className="border-t border-lightbg2 flex justify-between pt-4">
                  <div className="bg-white3 animate-pulse h-2 w-16 rounded" />
                  <div className="flex bg-white3 animate-pulse h-6 w-6 rounded-full" />
                </div>
              </div>
            </CardContainer>
          </Grid>
        ))}
      </Grid>
    );
  }

  return !activities.length ? null : (
    <>
      <Grid
        container
        columnSpacing={2}
        className="overflow-y-scroll scrollable-container"
        style={{ ...style }}
      >
        {activities?.map((item, idx) => (
          <Grid
            key={idx}
            item
            xs={12}
            md={type === "row" ? 12 : 6}
            xl2={type === "row" ? 12 : 4}
          >
            <CardContainer className="mb-6">
              <div className="flex flex-col justify-between gap-7">
                <div className="flex gap-8 flex-1 justify-between">
                  <div className="flex flex-col">
                    <Text12Regular className="text-white3">
                      {$t("_PRICE_", "Price")}
                    </Text12Regular>
                    <div className="flex">
                      {item?.meta?.salesAmount &&
                        getSeletedToken(
                          item?.meta?.nft?.chainId,
                          item.meta.salesToken
                        )?.icon}
                      <Text12Regular className="ml-1">
                        {item?.meta?.salesAmount
                          ? `${Moralis.Units.FromWei(item.meta.salesAmount)} ${
                              getSeletedToken(
                                item?.meta?.nft?.chainId,
                                item.meta.salesToken
                              )?.symbol
                            }`
                          : `• • •`}
                      </Text12Regular>
                    </div>
                  </div>

                  <div className="flex flex-col">
                    <Text12Regular className="text-white3">
                      {$t("_FROM_", "From")}
                    </Text12Regular>
                    <Text12Regular>
                      {shortWalletAddress(
                        item?.action === "NFT_CREATED"
                          ? "0x0000000000000000000000000000000000000000"
                          : item.actor.walletAddress || "• • •"
                      )}
                    </Text12Regular>
                  </div>

                  <div className="flex flex-col">
                    <Text12Regular className="text-white3">
                      {$t("_TO_", "To")}
                    </Text12Regular>
                    <Text12Regular>
                      {shortWalletAddress(
                        item?.action === "NFT_CREATED"
                          ? item?.actor?.walletAddress
                          : item?.meta?.sellerParams?.signature
                          ? item?.meta?.nft?.owner?.walletAddress
                          : ""
                      )}
                    </Text12Regular>
                  </div>
                </div>

                <div className="border-t border-lightbg2 flex justify-between pt-4">
                  <Text12Regular>
                    {moment(item.createdOn).fromNow()}
                  </Text12Regular>
                  <div className="flex justify-evenly gap-2">
                    {icon(item.action || null)}
                    <Text12Regular>{activityText(item.action)}</Text12Regular>
                  </div>
                </div>
              </div>
            </CardContainer>
          </Grid>
        ))}
      </Grid>
    </>
  );
};
