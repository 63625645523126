const ChevronLeft = ( props ) => {
    const { style, className } = props
    
    return (
        <svg style={style} className={className} width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M7.08929 0.410749C7.41473 0.736186 7.41473 1.26382 7.08929 1.58926L2.67855 6.00001L7.08929 10.4108C7.41473 10.7362 7.41473 11.2638 7.08929 11.5893C6.76386 11.9147 6.23622 11.9147 5.91078 11.5893L0.91078 6.58926C0.585343 6.26382 0.585343 5.73619 0.91078 5.41075L5.91078 0.410749C6.23622 0.0853125 6.76386 0.0853125 7.08929 0.410749Z"   />
        </svg>
    )
}

export default ChevronLeft
