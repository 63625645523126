export const profileHeaderImage = `https://i.imgur.com/ZykRibP.png`

export const SampleUrls = [
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/120454280_330298334897758_2087326335710834059_n.jpg',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/138738671_413234559914141_3557234474010235211_n.jpg',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/145874634_1610785292455280_6175445467474391882_n.jpg',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/151046575_246480303849368_5949110328718598396_n.jpg',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/182023951_306014101041249_88985842802414376_n.jpg',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/193859362_312386147223391_9088892241672460129_n.jpg',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/199055140_327340348884135_9019093451060810082_n.jpg',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/233692171_278734670681022_2426768397562657685_n.jpg',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/251133046_603632261082421_1416321385188036397_n.jpg',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/265103969_885502838779663_9164577920829603602_n.jpg',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/267111068_935318517391570_6250176848192227865_n.jpg',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/card-1.png',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/card-2.png',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/card-3.png',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/card-4.png',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/colxn-1.png',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/colxn-2.png',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/colxn-3.png',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/colxn-4.png',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/card-4.png',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/card-3.png',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/card-1.png',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/card-2.png',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/card-3.png',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/card-4.png',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/colxn-1.png',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/colxn-2.png',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/colxn-3.png',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/colxn-4.png',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/card-4.png',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/card-3.png',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/card-1.png',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/card-2.png',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/card-3.png',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/card-4.png',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/colxn-1.png',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/colxn-2.png',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/colxn-3.png',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/colxn-4.png',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/card-4.png',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/card-3.png',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/card-1.png',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/card-2.png',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/card-3.png',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/card-4.png',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/colxn-1.png',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/colxn-2.png',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/colxn-3.png',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/colxn-4.png',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/card-4.png',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/card-3.png',
    'https://i.imgur.com/VgJ0wmK.png',
    'https://i.imgur.com/WEDjEq0.png',
    'https://i.imgur.com/RiWEy3A.png',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/120454280_330298334897758_2087326335710834059_n.jpg',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/138738671_413234559914141_3557234474010235211_n.jpg',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/145874634_1610785292455280_6175445467474391882_n.jpg',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/151046575_246480303849368_5949110328718598396_n.jpg',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/182023951_306014101041249_88985842802414376_n.jpg',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/193859362_312386147223391_9088892241672460129_n.jpg',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/199055140_327340348884135_9019093451060810082_n.jpg',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/233692171_278734670681022_2426768397562657685_n.jpg',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/251133046_603632261082421_1416321385188036397_n.jpg',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/265103969_885502838779663_9164577920829603602_n.jpg',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/267111068_935318517391570_6250176848192227865_n.jpg',
]

export const celebrityImages = [
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/120454280_330298334897758_2087326335710834059_n.jpg',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/138738671_413234559914141_3557234474010235211_n.jpg',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/145874634_1610785292455280_6175445467474391882_n.jpg',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/151046575_246480303849368_5949110328718598396_n.jpg',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/182023951_306014101041249_88985842802414376_n.jpg',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/193859362_312386147223391_9088892241672460129_n.jpg',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/199055140_327340348884135_9019093451060810082_n.jpg',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/233692171_278734670681022_2426768397562657685_n.jpg',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/251133046_603632261082421_1416321385188036397_n.jpg',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/265103969_885502838779663_9164577920829603602_n.jpg',
    'https://web-dev-images-public.s3.ap-south-1.amazonaws.com/267111068_935318517391570_6250176848192227865_n.jpg',
]

export const auctionData = [
    {
        imageUrl: SampleUrls[1],
        name: 'Epizon Akatsuki Dance',
        bidStats: {
            count: '323',
            highestBid: '0.08',
            highestBidUnit: 'ETH'
        },
        auctionEndTimestamp: '1654972496',
        creator: {
            name: 'Doris Logue',
            imageUrl: SampleUrls[2],
            verified: true
        },
        owner: {
            name: 'James White',
            imageUrl: SampleUrls[3],
            verified: true
        },
        brand: {
            name: 'Imapoki',
            verified: true
        }
    },
    {
        imageUrl: SampleUrls[2],
        name: 'Epizon Akatsuki Dance',
        bidStats: {
            count: '121',
            highestBid: '1.01',
            highestBidUnit: 'ETH'
        },
        auctionEndTimestamp: '1674972496',
        creator: {
            name: 'Sean Walter',
            imageUrl: SampleUrls[3],
            verified: true
        },
        owner: {
            name: 'James Bond',
            imageUrl: SampleUrls[1],
            verified: true
        },
        brand: {
            name: 'Imnotapoki',
            verified: false
        }
    },
    {
        imageUrl: SampleUrls[3],
        name: 'Epizon Akatsuki Dance',
        bidStats: {
            count: '224',
            highestBid: '0.5',
            highestBidUnit: 'ETH'
        },
        auctionEndTimestamp: '1664972496',
        creator: {
            name: 'Michael Woods',
            imageUrl: SampleUrls[2],
            verified: true
        },
        owner: {
            name: 'Donald Duck',
            imageUrl: SampleUrls[1],
            verified: true
        },
        brand: {
            name: 'Imapoki',
            verified: true
        }
    }
]

export const activities = [
    { imageUrl: SampleUrls[2], name: 'Epizon Akatsuki Barbie', userName: 'Apoki', price: '1.3', priceUnit: 'ETH', from: '0x12345', to: 'username1', type: 'sale', timestamp: 'an hour ago' },
    { imageUrl: SampleUrls[3], name: 'Epizon Akatsuki Barbie', userName: 'Apoki', price: '1.3', priceUnit: 'ETH', from: '0x12345', to: 'username1', type: 'sale', timestamp: 'an hour ago' },
    { imageUrl: SampleUrls[4], name: 'Epizon Akatsuki Barbie', userName: 'Apoki', price: '1.3', priceUnit: 'ETH', from: '0x12345', to: 'username1', type: 'sale', timestamp: 'an hour ago' },
    { imageUrl: SampleUrls[5], name: 'Epizon Akatsuki Barbie', userName: 'Apoki', price: '1.3', priceUnit: 'ETH', from: '0x12345', to: 'username1', type: 'sale', timestamp: 'an hour ago' },
    { imageUrl: SampleUrls[2], name: 'Epizon Akatsuki Barbie', userName: 'Apoki', price: '1.3', priceUnit: 'ETH', from: '0x12345', to: 'username1', type: 'sale', timestamp: 'an hour ago' },
    { imageUrl: SampleUrls[3], name: 'Epizon Akatsuki Barbie', userName: 'Apoki', price: '1.3', priceUnit: 'ETH', from: '0x12345', to: 'username1', type: 'sale', timestamp: 'an hour ago' },
    { imageUrl: SampleUrls[4], name: 'Epizon Akatsuki Barbie', userName: 'Apoki', price: '1.3', priceUnit: 'ETH', from: '0x12345', to: 'username1', type: 'sale', timestamp: 'an hour ago' },
    { imageUrl: SampleUrls[2], name: 'Epizon Akatsuki Barbie', userName: 'Apoki', price: '1.3', priceUnit: 'ETH', from: '0x12345', to: 'username1', type: 'sale', timestamp: 'an hour ago' },
    { imageUrl: SampleUrls[3], name: 'Epizon Akatsuki Barbie', userName: 'Apoki', price: '1.3', priceUnit: 'ETH', from: '0x12345', to: 'username1', type: 'sale', timestamp: 'an hour ago' },
    { imageUrl: SampleUrls[5], name: 'Epizon Akatsuki Barbie', userName: 'Apoki', price: '1.3', priceUnit: 'ETH', from: '0x12345', to: 'username1', type: 'sale', timestamp: 'an hour ago' },
    { imageUrl: SampleUrls[2], name: 'Epizon Akatsuki Barbie', userName: 'Apoki', price: '1.3', priceUnit: 'ETH', from: '0x12345', to: 'username1', type: 'sale', timestamp: 'an hour ago' },
    { imageUrl: SampleUrls[3], name: 'Epizon Akatsuki Barbie', userName: 'Apoki', price: '1.3', priceUnit: 'ETH', from: '0x12345', to: 'username1', type: 'sale', timestamp: 'an hour ago' },
    { imageUrl: SampleUrls[4], name: 'Epizon Akatsuki Barbie', userName: 'Apoki', price: '1.3', priceUnit: 'ETH', from: '0x12345', to: 'username1', type: 'sale', timestamp: 'an hour ago' },
    { imageUrl: SampleUrls[1], name: 'Epizon Akatsuki Barbie', userName: 'Apoki', price: '1.3', priceUnit: 'ETH', from: '0x12345', to: 'username1', type: 'sale', timestamp: 'an hour ago' },
    { imageUrl: SampleUrls[4], name: 'Epizon Akatsuki Barbie', userName: 'Apoki', price: '1.3', priceUnit: 'ETH', from: '0x12345', to: 'username1', type: 'sale', timestamp: 'an hour ago' },
    { imageUrl: SampleUrls[2], name: 'Epizon Akatsuki Barbie', userName: 'Apoki', price: '1.3', priceUnit: 'ETH', from: '0x12345', to: 'username1', type: 'sale', timestamp: 'an hour ago' },
    { imageUrl: SampleUrls[5], name: 'Epizon Akatsuki Barbie', userName: 'Apoki', price: '1.3', priceUnit: 'ETH', from: '0x12345', to: 'username1', type: 'sale', timestamp: 'an hour ago' },
    { imageUrl: SampleUrls[2], name: 'Epizon Akatsuki Barbie', userName: 'Apoki', price: '1.3', priceUnit: 'ETH', from: '0x12345', to: 'username1', type: 'sale', timestamp: 'an hour ago' },
    { imageUrl: SampleUrls[3], name: 'Epizon Akatsuki Barbie', userName: 'Apoki', price: '1.3', priceUnit: 'ETH', from: '0x12345', to: 'username1', type: 'sale', timestamp: 'an hour ago' },
    { imageUrl: SampleUrls[5], name: 'Epizon Akatsuki Barbie', userName: 'Apoki', price: '1.3', priceUnit: 'ETH', from: '0x12345', to: 'username1', type: 'sale', timestamp: 'an hour ago' },
    { imageUrl: SampleUrls[1], name: 'Epizon Akatsuki Barbie', userName: 'Apoki', price: '1.3', priceUnit: 'ETH', from: '0x12345', to: 'username1', type: 'sale', timestamp: 'an hour ago' },
    { imageUrl: SampleUrls[2], name: 'Epizon Akatsuki Barbie', userName: 'Apoki', price: '1.3', priceUnit: 'ETH', from: '0x12345', to: 'username1', type: 'sale', timestamp: 'an hour ago' },
    { imageUrl: SampleUrls[3], name: 'Epizon Akatsuki Barbie', userName: 'Apoki', price: '1.3', priceUnit: 'ETH', from: '0x12345', to: 'username1', type: 'sale', timestamp: 'an hour ago' },
    { imageUrl: SampleUrls[4], name: 'Epizon Akatsuki Barbie', userName: 'Apoki', price: '1.3', priceUnit: 'ETH', from: '0x12345', to: 'username1', type: 'sale', timestamp: 'an hour ago' },
    { imageUrl: SampleUrls[2], name: 'Epizon Akatsuki Barbie', userName: 'Apoki', price: '1.3', priceUnit: 'ETH', from: '0x12345', to: 'username1', type: 'sale', timestamp: 'an hour ago' },
    { imageUrl: SampleUrls[1], name: 'Epizon Akatsuki Barbie', userName: 'Apoki', price: '1.3', priceUnit: 'ETH', from: '0x12345', to: 'username1', type: 'sale', timestamp: 'an hour ago' },
]

export const nftInfo = {
    imageUrl: SampleUrls[1],
    name: 'Epizon Akatsuki Dance',
    bidStats: {
        count: '323',
        highestBid: '0.08',
        highestBidUnit: 'ETH'
    },
    auctionEndTimestamp: '1654972496',
    saleEndTimestamp: '1664972496',
    creator: {
        name: 'Doris Logue',
        imageUrl: SampleUrls[2],
        verified: true
    },
    owner: {
        name: 'James White',
        imageUrl: SampleUrls[3],
        verified: true
    },
    brand: {
        name: 'Imapoki',
        verified: true
    }
}


export const graphData = [
    {
        "day": 1, // timestamp
        // "uv": 4000,
        "price": 0.5, //  price of nft - 
        // "amt": 2400
    },
    {
        "day": 2,
        "price": 1.1,
        "amt": 2210
    },
    {
        "day": 3,
        "price": 1.2,
        "amt": 2290
    },
    {
        "day": 4,
        "price": 1.5,
        "amt": 2000
    },
    {
        "day": 5,
        "price": 1.0,
        "amt": 2181
    },
    {
        "day": 6,
        "price": 1.2,
        "amt": 2500
    },
    {
        "day": 7,
        "price": 1.40,
        "amt": 2100
    },
    {
        "day": 8,
        // "uv": 4000,
        "price": 0.65,
        "amt": 2400
    },
    {
        "day": 9,
        "price": 0.21,
        "amt": 2210
    },
    {
        "day": 10,
        "price": 0.52,
        "amt": 2290
    },
    {
        "day": 11,
        "price": 1.1,
        "amt": 2000
    },
    {
        "day": 12,
        "price": 1.0,
        "amt": 2181
    },
    {
        "day": 13,
        "price": 1.2,
        "amt": 2500
    },
    {
        "day": 14,
        "price": 1.40,
        "amt": 2100
    },
    {
        "day": 15,
        // "uv": 4000,
        "price": 0.5,
        "amt": 2400
    },
    {
        "day": 16,
        "price": 1.1,
        "amt": 2210
    },
    {
        "day": 17,
        "price": 1.2,
        "amt": 2290
    },
    {
        "day": 18,
        "price": 1.5,
        "amt": 2000
    },
    {
        "day": 19,
        "price": 1.0,
        "amt": 2181
    },
    {
        "day": 20,
        "price": 1.2,
        "amt": 2500
    },
    {
        "day": 21,
        "price": 1.40,
        "amt": 2100
    },
    {
        "day": 22,
        // "uv": 4000,
        "price": 0.5,
        "amt": 2400
    },
    {
        "day": 23,
        "price": 1.1,
        "amt": 2210
    },
    {
        "day": 24,
        "price": 1.2,
        "amt": 2290
    },
    {
        "day": 25,
        "price": 1.5,
        "amt": 2000
    },
    {
        "day": 26,
        "price": 1.0,
        "amt": 2181
    },
    {
        "day": 27,
        "price": 1.2,
        "amt": 2500
    },
    {
        "day": 28,
        "price": 1.40,
        "amt": 2100
    },
]

export const graphDataObj = {
    volume: 123.45,
    history: [
        {
            "day": 1, // timestamp
            // "uv": 4000,
            "price": 0.5, //  price of nft - 
            // "amt": 2400
        },
        {
            "day": 2,
            "price": 1.1,
            "amt": 2210
        },
        {
            "day": 3,
            "price": 1.2,
            "amt": 2290
        },
        {
            "day": 4,
            "price": 1.5,
            "amt": 2000
        },
        {
            "day": 5,
            "price": 1.0,
            "amt": 2181
        },
        {
            "day": 6,
            "price": 1.2,
            "amt": 2500
        },
        {
            "day": 7,
            "price": 1.40,
            "amt": 2100
        },
        {
            "day": 8,
            // "uv": 4000,
            "price": 0.65,
            "amt": 2400
        },
        {
            "day": 9,
            "price": 0.21,
            "amt": 2210
        },
        {
            "day": 10,
            "price": 0.52,
            "amt": 2290
        },
        {
            "day": 11,
            "price": 1.1,
            "amt": 2000
        },
        {
            "day": 12,
            "price": 1.0,
            "amt": 2181
        },
        {
            "day": 13,
            "price": 1.2,
            "amt": 2500
        },
        {
            "day": 14,
            "price": 1.40,
            "amt": 2100
        },
        {
            "day": 15,
            // "uv": 4000,
            "price": 0.5,
            "amt": 2400
        },
        {
            "day": 16,
            "price": 1.1,
            "amt": 2210
        },
        {
            "day": 17,
            "price": 1.2,
            "amt": 2290
        },
        {
            "day": 18,
            "price": 1.5,
            "amt": 2000
        },
        {
            "day": 19,
            "price": 1.0,
            "amt": 2181
        },
        {
            "day": 20,
            "price": 1.2,
            "amt": 2500
        },
        {
            "day": 21,
            "price": 1.40,
            "amt": 2100
        },
        {
            "day": 22,
            // "uv": 4000,
            "price": 0.5,
            "amt": 2400
        },
        {
            "day": 23,
            "price": 1.1,
            "amt": 2210
        },
        {
            "day": 24,
            "price": 1.2,
            "amt": 2290
        },
        {
            "day": 25,
            "price": 1.5,
            "amt": 2000
        },
        {
            "day": 26,
            "price": 1.0,
            "amt": 2181
        },
        {
            "day": 27,
            "price": 1.2,
            "amt": 2500
        },
        {
            "day": 28,
            "price": 1.40,
            "amt": 2100
        },
    ]
}