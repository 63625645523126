import { ALLOWED_CHAINS, DEFAULT_ERC721_COLLECTION_ID } from "common/constants";
import Moralis from "moralis-v1";

const getChainId = (key) =>
  Object.values(ALLOWED_CHAINS)?.find((chainObj) => chainObj?.key === key)
    ?.chainId;

export const emptyNft = {
  chainId: getChainId("POLYGON"),
  creationType: null,
  numberOfCopies: "single",
  formDetails: {
    attributeList: [{ label: "", attribute: "" }],
    collection: "valores",
    collectionId: DEFAULT_ERC721_COLLECTION_ID,
  },
};

export const copyOption = [
  {
    name: "Multiple copies",
    key: "multiple",
    isDisable: true,
  },
  {
    name: "Single copy",
    key: "single",
    isDisable: false,
  },
];

export const chainIds = {
  binance: getChainId("BSC"),
  polygon: getChainId("POLYGON"),
};

export const getPayload = (nftDetails) => {
  const createNftPayload = {
    name: nftDetails.formDetails.name,
    royaltyPercent: (
      Number(nftDetails.formDetails.royalties || 0) * 100
    ).toFixed(0),
    description: nftDetails.formDetails.description,
    // collectionContractAddress: nftDetails.formDetails.collection,
    collectionId: nftDetails.formDetails?.collectionId,
    chainId: nftDetails.chainId,
    mediaS3Key: nftDetails.formDetails.nftFileS3,
    secondaryMediaS3Key: nftDetails.formDetails.nftImageS3,
    attributes: nftDetails?.formDetails.attributeList.map((attribute) => ({
      value: attribute.attribute,
      trait_type: attribute.label,
    })),
  };

  if (nftDetails?.formDetails.sale) {
    createNftPayload.sales = [
      {
        saleToken: nftDetails?.formDetails?.saleToken?.address,
        saleAmount: Moralis.Units.Token(
          nftDetails.formDetails.minPrice,
          nftDetails?.formDetails?.saleToken?.decimals
        ),
        expiry: nftDetails.formDetails.saleEnd,
      },
    ];
  }

  return createNftPayload;
};
