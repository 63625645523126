const Clock = () => {
    return (
        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.50033 1.6263C3.80794 1.6263 1.62533 3.80891 1.62533 6.5013C1.62533 9.19369 3.80794 11.3763 6.50033 11.3763C9.19271 11.3763 11.3753 9.19369 11.3753 6.5013C11.3753 3.80891 9.19271 1.6263 6.50033 1.6263ZM0.541992 6.5013C0.541992 3.2106 3.20963 0.542969 6.50033 0.542969C9.79102 0.542969 12.4587 3.2106 12.4587 6.5013C12.4587 9.792 9.79102 12.4596 6.50033 12.4596C3.20963 12.4596 0.541992 9.792 0.541992 6.5013Z" fill="#2DC79D" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.49967 2.70703C6.79883 2.70703 7.04134 2.94954 7.04134 3.2487V6.16393L8.90858 7.09755C9.17615 7.23134 9.28461 7.5567 9.15082 7.82427C9.01704 8.09184 8.69167 8.2003 8.4241 8.06651L6.25743 6.98318C6.07393 6.89143 5.95801 6.70387 5.95801 6.4987V3.2487C5.95801 2.94954 6.20052 2.70703 6.49967 2.70703Z" fill="#2DC79D" />
        </svg>

    )
}

export default Clock