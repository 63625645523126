export const Ether = (props) => {
  const { style, className } = props;

  return (
    <>
      <svg
        style={style}
        className={className}
        width="10"
        height="17"
        viewBox="0 0 10 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.10679 0.213867L5 0.593449V11.608L5.10679 11.7195L9.996 8.69729L5.10679 0.213867Z"
          fill="#DA3979"
        />
        <path
          d="M5.10796 0.213867L0.21875 8.69729L5.10796 11.7195V6.37339V0.213867Z"
          fill="#E781A9"
        />
        <path
          d="M5.10706 12.6877L5.04688 12.7644V16.688L5.10706 16.8718L9.99918 9.66699L5.10706 12.6877Z"
          fill="#DA3979"
        />
        <path
          d="M5.10796 16.8718V12.6877L0.21875 9.66699L5.10796 16.8718Z"
          fill="#E781A9"
        />
        <path
          d="M5.10547 11.7191L9.99459 8.69698L5.10547 6.37305V11.7191Z"
          fill="#671334"
        />
        <path
          d="M0.21875 8.69698L5.1079 11.7191V6.37305L0.21875 8.69698Z"
          fill="#DA3979"
        />
      </svg>
    </>
  );
};

export const EtherBlack = (props) => {
  const { style, className } = props;

  return (
    <>
      <svg
        style={style}
        className={className}
        width="85"
        height="138"
        viewBox="0 0 85 138"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_436_7840)">
          <path
            d="M42.4865 0L41.5586 3.14388V94.3721L42.4865 95.2955L84.9676 70.2642L42.4865 0Z"
            fill="#343434"
          />
          <path
            d="M42.4869 0L0.00585938 70.2642L42.4869 95.2958V51.0163V0Z"
            fill="#8C8C8C"
          />
          <path
            d="M42.4877 103.314L41.9648 103.949V136.447L42.4877 137.969L84.9944 78.2949L42.4877 103.314Z"
            fill="#3C3C3B"
          />
          <path
            d="M42.4869 137.969V103.313L0.00585938 78.2944L42.4869 137.969Z"
            fill="#8C8C8C"
          />
          <path
            d="M42.4863 95.2954L84.9668 70.2645L42.4863 51.0166V95.2954Z"
            fill="#141414"
          />
          <path
            d="M0.00585938 70.264L42.4863 95.2952V51.0161L0.00585938 70.264Z"
            fill="#393939"
          />
        </g>
        <defs>
          <clipPath id="clip0_436_7840">
            <rect width="85" height="138" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export const Polygon = (props) => {
  const { style, className } = props;

  return (
    <>
      <svg
        style={style}
        className={className}
        width="138"
        height="138"
        viewBox="0 0 138 138"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M69 138C107.108 138 138 107.108 138 69C138 30.8924 107.108 0 69 0C30.8924 0 0 30.8924 0 69C0 107.108 30.8924 138 69 138Z"
          fill="#8247E5"
        />
        <path
          d="M91.8391 54.237C90.1842 53.2743 88.0327 53.2743 86.2123 54.237L73.3033 61.6184L64.532 66.4324L51.6232 73.8138C49.9681 74.7766 47.8167 74.7766 45.9962 73.8138L35.7352 68.0371C34.0803 67.0743 32.9219 65.3091 32.9219 63.3835V51.9905C32.9219 50.065 33.9148 48.2998 35.7352 47.337L45.8307 41.7207C47.4856 40.7579 49.6371 40.7579 51.4577 41.7207L61.553 47.337C63.208 48.2998 64.3665 50.065 64.3665 51.9905V59.3719L73.1378 54.3974V47.016C73.1378 45.0905 72.1449 43.3253 70.3245 42.3626L51.6232 31.7719C49.9681 30.8091 47.8167 30.8091 45.9962 31.7719L26.9639 42.3626C25.1433 43.3253 24.1504 45.0905 24.1504 47.016V68.3579C24.1504 70.2835 25.1433 72.0486 26.9639 73.0114L45.9962 83.6021C47.6512 84.565 49.8026 84.565 51.6232 83.6021L64.532 76.3812L73.3033 71.4067L86.2123 64.1859C87.8672 63.2231 90.0187 63.2231 91.8391 64.1859L101.935 69.8021C103.589 70.765 104.748 72.53 104.748 74.4557V85.8486C104.748 87.7743 103.755 89.5393 101.935 90.5021L91.8391 96.279C90.1842 97.2417 88.0327 97.2417 86.2123 96.279L76.1168 90.6626C74.4619 89.6998 73.3033 87.9347 73.3033 86.0091V78.6278L64.532 83.6021V90.9835C64.532 92.9091 65.5249 94.6743 67.3455 95.6371L86.3777 106.228C88.0327 107.191 90.1842 107.191 92.0046 106.228L111.037 95.6371C112.692 94.6743 113.85 92.9091 113.85 90.9835V69.6417C113.85 67.716 112.857 65.951 111.037 64.9881L91.8391 54.237Z"
          fill="white"
        />
      </svg>
    </>
  );
};
