import Verified from "components/icons/Verified";
import { Text12Bold, Text12Regular } from "components/elements/Text";
import { HeartFilled, HeartUnfilled } from "components/icons/Heart";
import { useNavigate } from "react-router-dom";
import {
  customToast,
  shortName,
  shortWalletAddress,
} from "components/utils/generic";
import { likeNft, unlikeNft, userLoginWithToken } from "api/apiCalls";
import { useContext, useState } from "react";
import { AppContext } from "contexts/AppContext";
import { useEffect } from "react";
import { $t } from "components/utils/Translation";
import Clock from "components/icons/Clock";
import Moralis from "moralis-v1";
import moment from "moment";
import { ALLOWED_CHAINS, findToken } from "common/constants";

const NFTCardNewLoading = ({ wrapperStyle, style }) => {
  return (
    <div className="bg-dark1 animate-pulse w-full border border-t-0 border-lightbg2 rounded-xl pb-1.5 whitespace-nowrap mb-8">
      <div
        className="flex flex-col gap-2 relative overflow-hidden rounded-t-xl"
        style={wrapperStyle}
      >
        <div
          id="nft-image"
          style={{ ...style }}
          className={`relative w-full h-[250px] animate-pulse rounded-t-xl bg-no-repeat bg-cover scale-[1.15]  duration-[0.6s] ease-in-out hover:scale-[1.05] bg-top`}
        />
      </div>
      <div className="px-4">
        <div className="flex items-center mt-3 mb-1">
          <div className="bg-dark2 animate-pulse h-2 w-20 mb-1 rounded" />
        </div>
        <div className="flex items-center bg-dark2 animate-pulse h-3 w-full mb-1 rounded" />
        <div className="flex items-center justify-between pt-2.5 pb-2">
          <div className="flex items-center gap-2 bg-dark2 h-2 w-10 rounded" />
          <div className="flex items-center gap-2 bg-dark2 h-2 w-10 rounded" />
        </div>
        <div className="border-t border-1 border-lightbg2 -mx-4" />
        <div className="flex gap-2 pt-3 pb-2.5 items-center">
          <div className="w-4 h-4 rounded-full animate-pulse bg-dark2" />
          <div className="bg-dark2 animate-pulse h-2 w-12 rounded" />
        </div>
      </div>
    </div>
  );
};

const NFTCardNew = (props) => {
  const navigate = useNavigate();
  const { userData, setuserData } = useContext(AppContext);
  const { className, wrapperStyle, style, nft, loading } = props;
  const [nftDetails, setnftDetails] = useState(nft || null);
  useEffect(() => setnftDetails(nft), [nft]);
  if (loading) {
    return <NFTCardNewLoading {...{ wrapperStyle, style }} />;
  }

  const nftImageUrl = !!nftDetails && nftDetails.secondaryMediaS3Key.signedUrl;
  const likeCount = !!nftDetails && nftDetails.likedByUsers.length;
  const userName = !!nftDetails && nftDetails?.owner?.profileName;
  const creatorName = !!nftDetails && nftDetails?.creator?.profileName;

  const walletAddress = !!nftDetails && nftDetails?.owner?.walletAddress;
  const userIsVerified = !!nftDetails && !!nftDetails?.owner?.verified;
  const name = !!nftDetails && nftDetails.name;
  const collectionName = !!nftDetails && nftDetails.collection?.name;
  const price =
    !!nftDetails && nftDetails.sales && nftDetails.sales.length
      ? nftDetails.sales[0].salesAmount
      : "0";
  const salesToken =
    !!nftDetails && nftDetails.sales && nftDetails.sales.length
      ? nftDetails.sales[0].salesToken
      : ``;
  const nftId = !!nftDetails && nftDetails.id;
  const ownerId = !!nftDetails && nftDetails?.owner?.id;
  const isLikedByUser =
    !userData || !userData.id
      ? false
      : !!nftDetails
      ? !!nftDetails.likedByUsers.filter((user) => user.id === userData.id)
          .length
      : false;

  const goToNftDetails = () => {
    if (!nftId || isNaN(nftId))
      return customToast("Invalid/Test NFT", { toastId: "INVALID_NFTID" });
    navigate(`/nft/${nftId}`);
  };

  const goToOwnerDetails = () => {
    if (!ownerId || isNaN(ownerId))
      return customToast("Invalid/Test OWNER", { toastId: "INVALID_OWNERID" });
    navigate(`/profile/${ownerId}`);
  };

  const like = async (e) => {
    e.stopPropagation();
    if (!userData || !userData.id) {
      const response = await userLoginWithToken();
      setuserData(response && response.data ? response.data : null);
    }
    const likedNft = await likeNft(nftId);
    likedNft.data &&
      likedNft.data.id &&
      setnftDetails({
        ...nftDetails,
        likedByUsers: likedNft.data.likedByUsers,
      });
  };

  const unlike = async (e) => {
    e.stopPropagation();
    if (!userData || !userData?.id) {
      const response = await userLoginWithToken();
      setuserData(response && response.data ? response.data : null);
    }
    const unlikedNft = await unlikeNft(nftId);
    unlikedNft.data &&
      unlikedNft.data.id &&
      setnftDetails({
        ...nftDetails,
        likedByUsers: unlikedNft.data.likedByUsers,
      });
  };

  const performLikeUnlike = (e) => {
    if (!userData?.id) {
      return customToast(
        `Please check if your wallet is connected and you're signed in.`,
        {
          type: "error",
        }
      );
    }
    isLikedByUser ? unlike(e) : like(e);
  };

  return !nftDetails || !nftDetails.id ? null : (
    <>
      <div className="bg-dark1 w-full border border-t-0 border-lightbg2 rounded-xl pb-1.5 whitespace-nowrap mb-8">
        <div
          className="flex flex-col gap-2 relative overflow-hidden rounded-t-xl"
          style={wrapperStyle}
        >
          <div
            id="nft-image"
            onClick={goToNftDetails}
            style={{ ...style, backgroundImage: `url(${nftImageUrl})` }}
            className={`cursor-pointer relative w-full h-[250px] rounded-t-xl bg-no-repeat bg-cover scale-[1.15]  duration-[0.6s] ease-in-out hover:scale-[1.05] bg-top  ${
              className || ""
            }`}
          ></div>
          <div
            onClick={(e) => performLikeUnlike(e)}
            className="absolute bg-white border border-white1 rounded-md flex justify-between items-center py-2 px-3 top-4 right-[23px] h-[34px] max-w-[78px] min-w-[60px] w-auto gap-1 opacity-50 hover:opacity-90"
          >
            <Text12Regular className="text-baseDark ">
              {likeCount || ""}
            </Text12Regular>
            {!!isLikedByUser ? <HeartFilled /> : <HeartUnfilled />}
          </div>
        </div>
        <div className="px-4">
          <div className="flex items-center mt-3">
            <div onClick={goToOwnerDetails} className="cursor-pointer">
              <Text12Regular>
                <span className="capitalize">
                  {$t(collectionName, "valores")}
                </span>
              </Text12Regular>
            </div>
            {!!userIsVerified && (
              <div>
                <Verified />
              </div>
            )}
          </div>
          <div className="flex items-center">
            <Text12Bold alt={name}>{shortName(name) || ""}</Text12Bold>
          </div>
          <div className="flex items-center justify-between pt-2.5 pb-2">
            <div className="flex items-center gap-2">
              <Clock />
              <Text12Regular className="text-primary2">
                {moment(nftDetails.createdOn).from()}
              </Text12Regular>
            </div>
            <div className="flex items-center justify-center gap-1">
              <div className="flex items-center gap-1">
                {ALLOWED_CHAINS[nftDetails?.chainId]?.icon}
                <Text12Regular>
                  {(nftDetails?.chainId &&
                    salesToken &&
                    Number(
                      Moralis.Units.FromWei(price.toString()),
                      findToken(salesToken, nftDetails?.chainId)?.decimals
                    ).toFixed(2)) ||
                    ""}
                </Text12Regular>
              </div>
              <div>
                <Text12Regular>
                  {findToken(salesToken, nftDetails?.chainId)?.symbol || ""}
                </Text12Regular>
              </div>
            </div>
          </div>
          <div className="border-t border-1 border-lightbg2 -mx-4" />
          <div className="flex gap-2 pt-3 pb-2.5">
            <img src="owner.svg" alt="" />
            <Text12Regular className="flex justify-center items-center capitalize">
              {userName || creatorName || shortWalletAddress(walletAddress)}
            </Text12Regular>
          </div>
        </div>
      </div>
    </>
  );
};

export default NFTCardNew;
