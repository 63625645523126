
export const CheckboxCheckedIcon = (props) => {
    const {style, className} = props

    return (
        <svg style={style} className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="19" height="19" rx="1.5" fill="#101316" stroke="#00FF8B" />
            <rect x="6" y="6" width="8" height="8" rx="1" fill="#00FF8B" />
        </svg>
    )
}

export const CheckboxIcon = (props) => {
    const {style, className} = props

    return (
        <svg style={style} className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="19" height="19" rx="1.5" fill="#101316" stroke="#414B5D" />
        </svg>
    )
}


