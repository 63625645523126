import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import {
  AppProvider,
  moralisAppId,
  moralisServerUrl,
} from "contexts/AppContext";
import { MoralisProvider } from "react-moralis";
import App from "./App";
import "./global.css";
import "react-toastify/dist/ReactToastify.min.css";
import "tw-elements";
import { createTheme, ThemeProvider } from "@mui/material";

// import reportWebVitals from './reportWebVitals';
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 200,
      xs2: 300,
      xs4: 400,
      sm: 600,
      md: 768,
      lg: 1024,
      xl: 1200,
      xl2: 1366,
      xl3: 1536,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    {/* <MoralisProvider appId="Bs2zADVXj3USJSlJiqn5pIJNJ7Q1FcGm07NFeDsJ" serverUrl="https://89mqh1xkhg3d.usemoralis.com:2053/server"> */}
    <MoralisProvider appId={moralisAppId} serverUrl={moralisServerUrl}>
      <ThemeProvider theme={theme}>
        <AppProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </AppProvider>
      </ThemeProvider>
    </MoralisProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
