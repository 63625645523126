const Facebook = (props) => {
    const { style, className } = props

    return (
        <svg style={style} className={className} width="25" height="21" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 9.05469C18 4.08444 13.9703 0.0546875 9 0.0546875C4.02975 0.0546875 0 4.08444 0 9.05469C0 13.5472 3.291 17.2702 7.59375 17.9452V11.6564H5.3085V9.05394H7.59375V7.07244C7.59375 4.81719 8.93775 3.57069 10.9935 3.57069C11.9775 3.57069 13.008 3.74694 13.008 3.74694V5.96169H11.8725C10.7542 5.96169 10.4055 6.65544 10.4055 7.36719V9.05469H12.9015L12.5025 11.6572H10.4055V17.9459C14.709 17.2702 18 13.5464 18 9.05469Z"   />
        </svg>
    )
}

export default Facebook
