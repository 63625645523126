import { $t } from "components/utils/Translation";
import { AppContext } from "contexts/AppContext";
import { useContext, useEffect } from "react";
import { useMoralis } from "react-moralis";
import { toast } from "react-toastify";

const WalletConnectExample = () => {
  const { changeLanguage, LangCodes } = useContext(AppContext);
  const { enableWeb3, isWeb3Enabled, logout, account } = useMoralis();
  const handleConnect = () => {
    if (!isWeb3Enabled) connect();
    if (!!isWeb3Enabled) disconnect();
    return;
  };
  const disconnect = async () => await logout();

  const connect = async () => {
    try {
      await enableWeb3({ provider: "walletconnect" });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    !!isWeb3Enabled
      ? toast("Please connect your wallet")
      : toast("Wallet Connected");
  }, [isWeb3Enabled]);
  return (
    <>
      <div
        className="grid grid-cols-1"
        style={{
          padding: "2rem",
          display: "flex",
          flexDirection: "column",
          gap: 20,
        }}
      >
        <div
          className="grid grid-cols-2 gap-4"
          style={{ display: "flex", flexDirection: "row", gap: 20 }}
        >
          <button onClick={() => changeLanguage(LangCodes.ENGLISH)}>EN</button>
          <button onClick={() => changeLanguage(LangCodes.KOREAN)}>KO</button>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <button onClick={handleConnect}>
            {!!isWeb3Enabled
              ? $t(`_DISCONNECT_WALLET_`, `Disconnect Wallet`)
              : $t(`_CONNECT_WALLET_`, `Connect Walet`)}
          </button>
          {!!isWeb3Enabled && !!account ? (
            <h3 className="text-3xl font-bold underline">{account}</h3>
          ) : (
            <h3 className="text-3xl font-bold underline">
              {$t(`_NOT_CONNECTED_`, `Not Connected`)}
            </h3>
          )}
        </div>
      </div>
    </>
  );
};

export default WalletConnectExample;
