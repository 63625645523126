const Cross = (props) => {
  const { style, className, h = 12, w = 12 } = props;
  return (
    <svg
      style={style}
      className={className}
      width={h}
      height={w}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1889_16662)">
        <path
          d="M12.0297 1.03073L10.9687 -0.0302734L5.99925 4.93973L1.02975 -0.0302734L-0.03125 1.03073L4.93875 6.00023L-0.03125 10.9697L1.02975 12.0307L5.99925 7.06073L10.9687 12.0307L12.0297 10.9697L7.05975 6.00023L12.0297 1.03073Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1889_16662">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Cross;
