import { ALLOWED_TOKENS } from "common/constants";
import DialogWrapper from "components/DialogWrapper";
import AttributeModal from "components/elements/AttributeModal";
import { CaptionWhite } from "components/elements/Caption";
import FileUpload from "components/elements/FileUpload";
import { CardContainer } from "components/elements/Filter";
import Select from "components/elements/Select";
import Switch from "components/elements/Switch";
import {
  Text12Bold,
  Text12Medium,
  Text12Regular,
  Text14Bold,
  Text14Regular,
  Text16Bold,
  Text18Bold,
} from "components/elements/Text";
import TextField from "components/elements/TextField";
import BarChart from "components/icons/BarChart";
import { TickActive } from "components/icons/Tick";
import { ShortLogo } from "components/icons/Valores";
import { $t } from "components/utils/Translation";
import React, { useEffect, useState } from "react";
import CreateCollectionModal from "./CreateCollectionModal";

const NftForm = (props) => {
  const { nftDetails, setnftDetails } = props;
  const [nonImageNft, setnonImageNft] = useState(false);
  const [showCreateCollectionModal, setshowCreateCollectionModal] =
    useState(false);
  const [showAttributeModal, setShowAttributeModal] = useState(false);

  useEffect(() => {
    setnftDetails((nftDetails) => {
      return {
        ...nftDetails,
        formDetails: {
          ...nftDetails?.formDetails,
          saleToken: nftDetails?.formDetails?.saleToken?.name
            ? nftDetails?.formDetails?.saleToken
            : ALLOWED_TOKENS[nftDetails?.chainId]?.length
            ? ALLOWED_TOKENS[nftDetails?.chainId][0]
            : {},
        },
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateForm = (value) => {
    setnftDetails((nftDetails) => {
      return {
        ...nftDetails,
        formDetails: {
          ...nftDetails?.formDetails,
          ...value,
        },
      };
    });
  };

  const handleFileUpload = async (awsFile) => {
    if (awsFile.type && !/image/.test(awsFile.type.toLowerCase())) {
      updateForm({ nftFileS3: awsFile.key });
      setnonImageNft(true);
      return;
    }
    updateForm({ nftImageS3: awsFile.key, nftFileS3: awsFile.key });
  };

  const handleImageUpload = async (awsFile) => {
    updateForm({ nftImageS3: awsFile.key });
  };

  const imposeMinMax = (el) => {
    if (!el || !el.value) return "";

    if (parseInt(el.value) < parseInt(el.min)) return el.min;

    if (parseInt(el.value) > parseInt(el.max)) return el.max;

    return el.value;
  };

  const handleSaleStart = (inputStartDate) => {
    var currentDate = new Date().toISOString().slice(0, 10);
    const startDate =
      inputStartDate > currentDate ? inputStartDate : currentDate;
    updateForm({ saleStart: startDate });
  };

  const handleEndDate = (inputEndDate) => {
    const defaultDate = new Date();
    defaultDate.setHours(defaultDate.getHours() + 24);
    const endDate =
      inputEndDate > nftDetails?.formDetails?.saleStart
        ? inputEndDate
        : defaultDate.toISOString().slice(0, 10);
    updateForm({ saleEnd: endDate });
  };

  return (
    <div className="flex flex-col flex-1 sm:w-2/3 gap-6 ">
      <CaptionWhite className="text-[24px] md:text-[26px] font-notoSans font-bold uppercase text-center sm:text-left">
        {$t("_NFT_DETAILS_", "NFT Details")}
      </CaptionWhite>
      <Text14Regular className="my-5 text-white text-center sm:text-left">
        Enter the following details of your NFT.
      </Text14Regular>
      <TextField
        label={$t("_NAME_", "Name")}
        labelClass="text-white2 text-[14px] font-bold"
        placeholder="E.g “Gamer Barbie”"
        onChange={(e) => updateForm({ name: e.target.value })}
        value={nftDetails?.formDetails?.name || ""}
      />
      <TextField
        type="textarea"
        label={$t("_DESCRIPTION_", "Description")}
        labelClass="text-white2 text-[14px] font-bold"
        placeholder="E.g “After purchasing you will be able to get the original image”"
        value={nftDetails?.formDetails?.description || ""}
        onChange={(e) => updateForm({ description: e.target.value })}
      />

      {nftDetails.numberOfCopies === "multiple" && (
        <TextField
          label={$t("_NO._OF_COPIES_", "No. of copies")}
          labelClass="txt-white2 text-[14px] font-bold"
          placeholder="Number"
          onChange={(e) => updateForm({ copies: e.target.value })}
        />
      )}

      <FileUpload
        label={$t("_UPLOAD_FILE_", "Upload file")}
        onChange={(awsFile) => handleFileUpload(awsFile)}
        value={nftDetails?.formDetails?.nftFileS3}
      />

      {!!nonImageNft && (
        <FileUpload
          label="Upload a display image"
          onChange={(awsFile) => handleImageUpload(awsFile)}
          accept="image/*"
          placeholder="Display image"
          value={nftDetails?.formDetails?.nftFileS3}
        />
      )}

      <div className="flex flex-1 items-center">
        <Text18Bold>
          {$t("_PUT_ON_MARKETPLACE_", "Put on marketplace")}
        </Text18Bold>
        <Switch
          label={
            <Text18Bold>
              {$t("_PUT_ON_MARKETPLACE_", "Put on marketplace")}
            </Text18Bold>
          }
          checked={nftDetails?.formDetails?.sale}
          onChange={(e) => updateForm({ sale: e.target.checked })}
        />
      </div>
      {nftDetails?.formDetails?.sale && (
        <>
          <Text16Bold>Auction details</Text16Bold>
          <Text12Bold className="text-white3">Minimum price</Text12Bold>
          <div className="flex flex-1 items-center">
            <Select
              className="flex-[0.2]"
              defaultValue={nftDetails?.formDetails?.saleToken?.name}
              onSelect={(val) => {
                updateForm({
                  saleToken: ALLOWED_TOKENS[nftDetails?.chainId]?.find(
                    (el) => el?.name === val?.name
                  ),
                });
              }}
              options={ALLOWED_TOKENS[nftDetails?.chainId]?.map((el) => ({
                name: el?.name,
                value: el?.chainId,
              }))}
            />
            <TextField
              type="number"
              className="flex-[0.8]"
              labelClass="text-white2 text-[14px] font-bold"
              placeholder="Enter minimum price for one copy"
              onChange={(e) => updateForm({ minPrice: e.target.value })}
              value={nftDetails?.formDetails?.minPrice}
            />
          </div>
          <Text14Regular className="text-white3">
            {$t("_SERVICE_FEE_", "Service fee")}: 2.5%
          </Text14Regular>
          {nftDetails?.formDetails?.minPrice && (
            <Text14Regular className="text-white3">
              {$t("_YOU_WILL_RECEIVE_", "You will receive")}:{" "}
              {`${
                nftDetails?.formDetails?.minPrice -
                nftDetails?.formDetails?.minPrice * 0.025
              } ${
                nftDetails?.formDetails?.saleToken?.name ||
                ALLOWED_TOKENS[nftDetails?.chainId][0]?.name
              }`}
            </Text14Regular>
          )}
          <Text12Bold className="text-white3">
            {$t("_DURATION_", "Duration")}
          </Text12Bold>
          <div className="flex flex-col sm:flex-row flex-1 items-center gap-4">
            <div className="flex flex-1 sm:flex-[0.5] flex-col">
              <Text12Medium className="text-white3">Start</Text12Medium>
              <input
                placeholder="Start"
                className="bg-dark1 text-white1 p-1 focus:outline-none"
                type="date"
                onChange={(e) => handleSaleStart(e.target.value)}
                value={nftDetails?.formDetails?.saleStart}
              />
            </div>
            <div className="flex flex-1 sm:flex-[0.5] flex-col">
              <Text12Medium className="text-white3">End</Text12Medium>
              <input
                placeholder="End"
                className="bg-dark1 text-white1 p-1 focus:outline-none"
                type="date"
                onChange={(e) => handleEndDate(e.target.value)}
                value={nftDetails?.formDetails?.saleEnd}
              />
            </div>
          </div>
          <Text14Regular className="text-white3">
            If this is a link, ensure it will be a downloadable content for user
          </Text14Regular>
        </>
      )}

      <Text18Bold>{$t("_CHOOSE_COLLECTION_", "Choose collection")}</Text18Bold>
      <div className="flex flex-wrap gap-4">
        <CardContainer
          isDisable={true}
          onClick={() => setshowCreateCollectionModal(true)}
          className="flex flex-col gap-4 w-[175px] h-[175px] items-center justify-center"
        >
          <TickActive  />
          <div>
            <Text16Bold>Create</Text16Bold>
          </div>
          <div>
            <Text14Regular className="text-white3">ERC-721</Text14Regular>
          </div>
        </CardContainer>
        <CardContainer
          onClick={() => updateForm({ collection: "valores" })}
          className={`flex flex-col gap-4 w-[175px] h-[175px] items-center justify-center cursor-pointer ${
            nftDetails?.formDetails?.collection === "valores"
              ? "!border-primary2 !border-2"
              : ""
          }`}
        >
          <ShortLogo />
          <div>
            <Text16Bold>Valores</Text16Bold>
          </div>
          <div>
            <Text14Regular className="text-white3">ERC-721</Text14Regular>
          </div>
        </CardContainer>
      </div>
      <TextField
        type="number"
        label={$t("_ROYALTIES_", "Royalties")}
        labelClass="text-white2 text-[14px] font-bold"
        placeholder="E.g “2%”"
        value={nftDetails?.formDetails?.royalties || ""}
        onChange={(e) => {
          updateForm({ royalties: imposeMinMax(e.target) });
        }}
        min={0}
        max={5}
      />
      <Text14Regular className="text-white3">
        {$t("_SUGGESTED_:_MAXIMUM_5%_", "Suggested : maximum 5%")}
      </Text14Regular>
      <CreateCollectionModal
        visible={showCreateCollectionModal}
        toggle={(val) => setshowCreateCollectionModal(val)}
      />
      <div className="w-full bg-red-40">
        <div className="grid sm:grid-flow-col gap-3 items-center justify-between">
          <Text16Bold>Attributes</Text16Bold>
          <span onClick={() => setShowAttributeModal(!showAttributeModal)}>
            <Text14Bold className="text-primary2 cursor-pointer">
              Create attribute
            </Text14Bold>
          </span>
        </div>
        <div className="flex flex-wrap gap-4 mt-3">
          {nftDetails?.formDetails?.attributeList.map((attribute) => {
            if (attribute.label === "" || attribute.attribute === "")
              return (
                <div className="flex flex-row gap-3 items-center justify-center md:px-20 py-10 w-full">
                  <BarChart className="fill-white w-5 h-5" />
                  <Text12Regular>Create attributes</Text12Regular>
                </div>
              );
            return (
              <CardContainer className="w-[30%] !min-w-[120px] !rounded-lg !px-3 !py-3">
                <div className="flex flex-col justify-between gap-1">
                  <Text12Regular>{attribute.label}</Text12Regular>
                  <Text18Bold>{attribute.attribute}</Text18Bold>
                </div>
              </CardContainer>
            );
          })}
        </div>
      </div>
      {showAttributeModal && (
        <DialogWrapper open={showAttributeModal} isCenter>
          <AttributeModal
            formDetails={nftDetails?.formDetails}
            attributeList={nftDetails?.formDetails?.attributeList}
            setformDetails={(value) => updateForm(value)}
            updateForm={updateForm}
            visible={showAttributeModal}
            toggle={setShowAttributeModal}
          />
        </DialogWrapper>
      )}
    </div>
  );
};

export default NftForm;
