import React, { useState, useEffect, useContext } from "react";
import Verified from "components/icons/Verified";
import { Text12Bold, Text12Regular } from "components/elements/Text";
import { HeartFilled, HeartUnfilled } from "components/icons/Heart";
import { useNavigate } from "react-router-dom";
import { AppContext } from "contexts/AppContext";
import { likeNft, unlikeNft, userLoginWithToken } from "api/apiCalls";
import {
  customToast,
  shortName,
  shortWalletAddress,
} from "components/utils/generic";
import Clock from "components/icons/Clock";
import moment from "moment";
import { ALLOWED_CHAINS, findToken } from "common/constants";
import Moralis from "moralis-v1";

const NFTBidCardNewLoading = ({ wrapperStyle, style }) => {
  return (
    <div className="bg-dark1 animate-pulse w-full border border-t-0 border-lightbg2 rounded-xl pb-1.5 whitespace-nowrap mb-8">
      <div
        className="flex flex-col gap-2 relative overflow-hidden rounded-t-xl"
        style={wrapperStyle}
      >
        <div
          style={{ ...style }}
          className={`cursor-pointer animate-pulse relative w-full h-[250px] rounded-t-xl bg-no-repeat bg-cover scale-110  duration-[0.6s] ease-in-out hover:scale-100  bg-top`}
        />
      </div>
      <div className="px-4">
        <div className="flex items-center mt-3 mb-1">
          <div className="bg-dark2 animate-pulse h-2 w-20 mb-1 rounded" />
        </div>
        <div className="flex items-center bg-dark2 animate-pulse h-3 w-full mb-1 rounded" />
        <div className="flex items-center justify-between pt-2.5 pb-2">
          <div className="flex items-center gap-2 bg-dark2 h-2 w-10 rounded" />
          <div className="flex items-center gap-2 bg-dark2 h-2 w-10 rounded" />
        </div>
        <div className="border-t border-1 border-lightbg2 -mx-4" />
        <div className="flex gap-2 pt-3 pb-2.5 items-center">
          <div className="w-4 h-4 rounded-full animate-pulse bg-dark2" />
          <div className="bg-dark2 animate-pulse h-2 w-12 rounded" />
        </div>
      </div>
    </div>
  );
};

const NFTBidCardNew = (props) => {
  const navigate = useNavigate();
  const { userData, setuserData } = useContext(AppContext);
  const { className, wrapperStyle, style, bids, loading } = props;
  const [bidsDetails, setbidsDetails] = useState(bids || null);
  useEffect(() => setbidsDetails(bids), [bids]);

  if (loading) {
    return <NFTBidCardNewLoading {...{ style, wrapperStyle }} />;
  }

  const bidsImageUrl =
    !!bidsDetails && bidsDetails.secondaryMediaS3Key.signedUrl;
  const likeCount = !!bidsDetails && bidsDetails.likedByUsers.length;
  const userName = !!bidsDetails && bidsDetails.owner.profileName;
  const userIsVerified = !!bidsDetails && !!bidsDetails.owner.verified;

  // const bidsImageUrl = !!bidsDetails && bidsDetails.secondaryMediaS3Key?.signedUrl
  // const likeCount = !!bidsDetails && bidsDetails.likedByUsers?.length
  const isLikedByUser =
    userData && userData.id
      ? !!bidsDetails.likedByUsers.filter((item) => item.id === userData.id)
          .length
      : null;
  const walletAddress = !!bidsDetails && bidsDetails.owner?.walletAddress;
  // const userIsVerified = !!bidsDetails && !!bidsDetails.owner?.verified
  const name = !!bidsDetails && bidsDetails.name;
  const totalBids = !!bidsDetails && bidsDetails.totalBids;
  const highestBid = !!bidsDetails && bidsDetails.highestBid;
  const bidsId = !!bidsDetails && bidsDetails.id;
  const ownerId = !!bidsDetails && bidsDetails.owner?.id;
  const collectionName = !!bidsDetails && bidsDetails.collection?.name;

  const like = async (e) => {
    e.stopPropagation();
    if (!userData || !userData.id) {
      const response = await userLoginWithToken();
      setuserData(response && response.data ? response.data : null);
    }
    const likedNft = await likeNft(bidsId);
    likedNft.data &&
      likedNft.data.id &&
      setbidsDetails({
        ...bidsDetails,
        likedByUsers: likedNft.data.likedByUsers,
      });
  };

  const unlike = async (e) => {
    e.stopPropagation();
    if (!userData || !userData.id) {
      const response = await userLoginWithToken();
      setuserData(response && response.data ? response.data : null);
    }
    const unlikedNft = await unlikeNft(bidsId);
    unlikedNft.data &&
      unlikedNft.data.id &&
      setbidsDetails({
        ...bidsDetails,
        likedByUsers: unlikedNft.data.likedByUsers,
      });
  };

  const performLikeUnlike = (e) => {
    if (!userData?.id) {
      return customToast(
        `Please check if your wallet is connected and you're signed in.`,
        {
          type: "error",
        }
      );
    }
    isLikedByUser ? unlike(e) : like(e);
  };

  return (
    <>
      <div className="bg-dark1 w-full border border-t-0 border-lightbg2 rounded-xl pb-1.5 whitespace-nowrap mb-8">
        <div
          className="flex flex-col gap-2 relative overflow-hidden rounded-t-xl"
          style={wrapperStyle}
        >
          <div
            id="nft-image"
            onClick={() => navigate(`/nft/${bidsId}`)}
            style={{ ...style, backgroundImage: `url(${bidsImageUrl})` }}
            className={`cursor-pointer relative w-full h-[250px] rounded-t-xl bg-no-repeat bg-cover scale-110  duration-[0.6s] ease-in-out hover:scale-100  bg-top ${
              className || ""
            }`}
          ></div>
          <div
            onClick={(e) => performLikeUnlike(e)}
            className="absolute bg-white border border-white1 rounded-md flex justify-between items-center py-2 px-3 top-4 right-[23px] h-[34px] max-w-[78px] min-w-[60px] w-auto gap-1 opacity-50 hover:opacity-90"
          >
            <Text12Regular className="text-baseDark">
              {likeCount || ""}
            </Text12Regular>
            {!!isLikedByUser ? <HeartFilled /> : <HeartUnfilled />}
          </div>
        </div>
        <div className="px-4">
          <div className="flex items-center mt-3">
            <div
              onClick={() => navigate(`/nft/${bidsId}`)}
              className="cursor-pointer"
            >
              <Text12Regular>
                {" "}
                <span className="capitalize">{collectionName}</span>
              </Text12Regular>
            </div>
            {!!userIsVerified && (
              <div>
                <Verified />
              </div>
            )}
          </div>
          <div className="flex items-center">
            <Text12Bold alt={name}>{shortName(name) || ""}</Text12Bold>
          </div>
          <div className="flex items-center justify-between pt-2.5 pb-2">
            <div className="flex items-center gap-2">
              <Clock />
              <Text12Regular className="text-primary2">
                {moment(bidsDetails.createdOn).from()}
              </Text12Regular>
            </div>
            <div className="flex items-center justify-center gap-1">
              <div className="flex items-center gap-1">
                {ALLOWED_CHAINS[bidsDetails?.chainId]?.icon}
                <Text12Regular>
                  {" "}
                  {(highestBid?.amount &&
                    Number(
                      Moralis.Units.FromWei(highestBid?.amount.toString()),
                      findToken(highestBid.salesToken, bidsDetails.chainId)
                        ?.decimals
                    ).toFixed(2)) ||
                    ""}
                </Text12Regular>
              </div>
              <div>
                <Text12Regular>
                  <p className="uppercase m-0 p-0">
                    {(bidsDetails &&
                      findToken(highestBid.salesToken, bidsDetails.chainId)
                        ?.symbol) ||
                      ""}
                  </p>
                </Text12Regular>
              </div>
            </div>
          </div>
          <div className="border-t border-1 border-lightbg2 -mx-4" />
          <div className="flex gap-2 pt-3 pb-2.5">
            <img src="owner.svg" alt="" />
            <Text12Regular className="flex justify-center items-center capitalize">
              {userName ||
                bidsDetails.creatorName ||
                shortWalletAddress(walletAddress)}
            </Text12Regular>
          </div>
        </div>
      </div>
    </>
  );
};

export default NFTBidCardNew;

{
  /* <div className="cursor-pointer" onClick={() => navigate(celebrityImages.includes(bidsImageUrl) ? `/profile/aagbanjh` : !isArtist ? '/profile/normal' : '/profile/artist')}><Text12Regular>{ celebrityImages.includes(bidsImageUrl) ? 'aagbanjh' : userName || ''}</Text12Regular></div>                    <div className="cursor-pointer" onClick={() => navigate(celebrityImages.includes(bidsImageUrl) ? `/profile/aagbanjh` : !isArtist ? '/profile/normal' : '/profile/artist')}><Text12Regular>{celebrityImages.includes(bidsImageUrl) ? 'aagbanjh' : userName || ''}</Text12Regular></div> */
}
