const ChevronDown = ( props ) => {
    const { style, className } = props    
    
    return (
        <svg style={style} className={className} width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0.410772 0.910753C0.736209 0.585316 1.26385 0.585316 1.58928 0.910753L6.00003 5.3215L10.4108 0.910753C10.7362 0.585316 11.2638 0.585316 11.5893 0.910753C11.9147 1.23619 11.9147 1.76383 11.5893 2.08926L6.58928 7.08926C6.26385 7.4147 5.73621 7.4147 5.41077 7.08926L0.410772 2.08926C0.0853354 1.76383 0.0853354 1.23619 0.410772 0.910753Z"   />
        </svg>
    )
}

export default ChevronDown
