import { Text14Regular } from "./Text";

const DropdownOptions = (props) => {
  const { className, itemClass, options, onClick } = props;

  if (!options || !options.length) return null;

  return (
    <>
      <div
        style={{ boxShadow: `0px 4px 4px rgba(0, 0, 0, 0.25)` }}
        className={`w-auto py-2 rounded-xl bg-dark1 overflow-hidden z-10 ${className}`}
      >
        <ul>
          {options.map((item, idx) => (
            <li
              key={idx}
              onClick={() => onClick(item)}
              className={`hover:bg-dark3 text-white capitalize cursor-pointer px-6 py-4 ${
                itemClass || ""
              }`}
            >
              {item.name && <Text14Regular>{item.name}</Text14Regular>}
              {!item.name && <p className="uppercase">{item}</p>}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default DropdownOptions;
