import React from "react";

const ReportIcon = (props) => {
  const { style, className } = props;
  return (
    <svg
      style={style}
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.23223 1.56548C3.70107 1.09664 4.33696 0.833252 5 0.833252H11.6667C11.8877 0.833252 12.0996 0.921049 12.2559 1.07733L17.2559 6.07733C17.4122 6.23361 17.5 6.44557 17.5 6.66658V16.6666C17.5 17.3296 17.2366 17.9655 16.7678 18.4344C16.2989 18.9032 15.663 19.1666 15 19.1666H5C4.33696 19.1666 3.70107 18.9032 3.23223 18.4344C2.76339 17.9655 2.5 17.3296 2.5 16.6666V3.33325C2.5 2.67021 2.76339 2.03433 3.23223 1.56548ZM5 2.49992C4.77899 2.49992 4.56702 2.58772 4.41074 2.744C4.25446 2.90028 4.16667 3.11224 4.16667 3.33325V16.6666C4.16667 16.8876 4.25446 17.0996 4.41074 17.2558C4.56702 17.4121 4.77899 17.4999 5 17.4999H15C15.221 17.4999 15.433 17.4121 15.5893 17.2558C15.7455 17.0996 15.8333 16.8876 15.8333 16.6666V7.01176L11.3215 2.49992H5Z"
        fill="#E3E3E3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6673 0.833252C12.1276 0.833252 12.5007 1.20635 12.5007 1.66659V5.83325H16.6673C17.1276 5.83325 17.5007 6.20635 17.5007 6.66659C17.5007 7.12682 17.1276 7.49992 16.6673 7.49992H11.6673C11.2071 7.49992 10.834 7.12682 10.834 6.66659V1.66659C10.834 1.20635 11.2071 0.833252 11.6673 0.833252Z"
        fill="#E3E3E3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.83398 10.8333C5.83398 10.3731 6.20708 10 6.66732 10H13.334C13.7942 10 14.1673 10.3731 14.1673 10.8333C14.1673 11.2936 13.7942 11.6667 13.334 11.6667H6.66732C6.20708 11.6667 5.83398 11.2936 5.83398 10.8333Z"
        fill="#E3E3E3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.83398 14.1666C5.83398 13.7063 6.20708 13.3333 6.66732 13.3333H13.334C13.7942 13.3333 14.1673 13.7063 14.1673 14.1666C14.1673 14.6268 13.7942 14.9999 13.334 14.9999H6.66732C6.20708 14.9999 5.83398 14.6268 5.83398 14.1666Z"
        fill="#E3E3E3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.83398 7.50008C5.83398 7.03984 6.20708 6.66675 6.66732 6.66675H8.33399C8.79422 6.66675 9.16732 7.03984 9.16732 7.50008C9.16732 7.96032 8.79422 8.33341 8.33399 8.33341H6.66732C6.20708 8.33341 5.83398 7.96032 5.83398 7.50008Z"
        fill="#E3E3E3"
      />
    </svg>
  );
};

export default ReportIcon;
