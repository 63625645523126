import { fileDownload, fileUpload } from "api/apiCalls";
import { Text14Regular, Text18Bold } from "components/elements/Text";
import { $t } from "components/utils/Translation";
import { useEffect, useState } from "react";
import { ButtonPrimarySmall } from "./Button";

const FileUpload = (props) => {
  const { label, onChange, accept, placeholder, value } = props;

  // background generated using https://kovart.github.io/dashed-border-generator/
  const borderImage =
    "data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%233ED791FF' strokeWidth='4' stroke-dasharray='6%2c 12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e";

  const [file, setFile] = useState(null);
  const [bg, setBg] = useState(borderImage);

  useEffect(() => {
    if (value) {
      downloadUploadedImage(value);
    }
  }, [value]);

  const downloadUploadedImage = async (value) => {
    const getFile = await fileDownload(value);
    getFile &&
      getFile.data &&
      getFile.data.signedUrl &&
      setBg(getFile.data.signedUrl);
  };

  const bgStyle = {
    backgroundColor: !!bg && bg !== borderImage ? "none" : "transparent",
    backgroundImage:
      bg === borderImage ? `url("${borderImage}")` : `url(${bg})`,
    borderRadius: 6,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center",
  };

  const handleFileUpload = async (event) => {
    setBg(borderImage);
    const file = event.target.files[0];
    setFile(file);
    const awsFile = await fileUpload(file);
    onChange(awsFile);

    if (awsFile && awsFile.type && /image/.test(awsFile.type.toLowerCase())) {
      downloadUploadedImage(awsFile.key);
    }
  };

  return (
    <>
      <div className="flex flex-col gap-4">
        <Text18Bold>{label || ""}</Text18Bold>
        <div
          className="flex flex-col w-full lg:w-[98%] xl:w-[93%]  min-h-[190px] bg-lightbg4 rounded-md cursor-pointer items-center justify-center gap-4 relative "
          style={bgStyle}
        >
          <Text14Regular
            style={{ boxShadow: "inset 0px 0px 25px 2px black" }}
            className={`text-white text-center ${
              bg !== borderImage ? "absolute top-0 left-0 px-4 py-2" : ""
            }`}
          >
            {file || value
              ? file?.name || value
              : placeholder || "PNG, GIF, WEBP, MP4, or MP3. Max 100mb."}
          </Text14Regular>
          {file || value ? null : (
            <ButtonPrimarySmall text={$t("_CHOOSE_FILE_", "Choose file")} />
          )}
          <input
            onChange={handleFileUpload}
            type="file"
            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer "
            accept={accept || "image/*, audio/*, video/*, .pdf, .PDF"}
            multiple={false}
          />
        </div>
      </div>
    </>
  );
};

export default FileUpload;
