import { useContext, useState, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import gsap from "gsap";
import {
  Circ,
  Elastic,
  TweenLite,
  TweenMax,
  TimelineLite,
} from "gsap/gsap-core";
import { AppContext } from "contexts/AppContext";
import { CircularProgress, Grid, setRef } from "@mui/material";
import {
  ButtonPrimaryLarge,
  ButtonPrimarySmall,
  ButtonTertiaryGhost,
  ButtonTertiaryLarge,
  ButtonTertiarySmall,
} from "components/elements/Button";
import Text, {
  Text12Regular,
  Text14Bold,
  Text14Regular,
  Text18Bold,
} from "components/elements/Text";
import Discord from "components/icons/Discord";
import Facebook from "components/icons/Facebook";
import File from "components/icons/File";
import Instagram from "components/icons/Instagram";
import Share from "components/icons/Share";
import Telegram from "components/icons/Telegram";
import Twitter from "components/icons/Twitter";
import Verified from "components/icons/Verified";
import {
  celebrityImages,
  profileHeaderImage,
  SampleUrls,
} from "components/utils/mockData";
import Carousel from "components/blocks/carousel/Carousel";
import { correctUrl } from "components/utils/generic";
import SocialShare from "components/elements/SocialShare";
import SearchField from "components/elements/SearchField";
import { useMoralis } from "react-moralis";
import { CaptionWhite } from "components/elements/Caption";
import Telegram2 from "components/icons/Telegram2";
import { customToast } from "components/utils/generic";
import {
  getLoginNonce,
  getSignatureTexts,
  registerUser,
  report,
  userLogin,
} from "api/apiCalls";
import { $t } from "components/utils/Translation";
import ProfileDefault from "components/icons/ProfileDefault";
import Select from "react-select";
import { SocialDistance } from "@mui/icons-material";
import { APP_ENV } from "common/constants";

export const Banner = () => {
  const navigate = useNavigate();
  const handleSearchResponse = (keyword) =>
    navigate(`/explore?searchKeyword=${keyword}`);
  const images = [
    "https://d1dg8g269sqvrb.cloudfront.net/eyJidWNrZXQiOiJ2YWxvcmVzLW5mdC1maWxlcyIsImtleSI6IjEud2VicCIsImVkaXRzIjp7InJlc2l6ZSI6eyJ3aWR0aCI6NTAwLCJoZWlnaHQiOjUwMCwiZml0IjoiY292ZXIifX19",
    "https://d1dg8g269sqvrb.cloudfront.net/eyJidWNrZXQiOiJ2YWxvcmVzLW5mdC1maWxlcyIsImtleSI6IjIud2VicCIsImVkaXRzIjp7InJlc2l6ZSI6eyJ3aWR0aCI6NTAwLCJoZWlnaHQiOjUwMCwiZml0IjoiY292ZXIifX19",
    "https://d1dg8g269sqvrb.cloudfront.net/eyJidWNrZXQiOiJ2YWxvcmVzLW5mdC1maWxlcyIsImtleSI6IjMud2VicCIsImVkaXRzIjp7InJlc2l6ZSI6eyJ3aWR0aCI6NTAwLCJoZWlnaHQiOjUwMCwiZml0IjoiY292ZXIifX19",
    "https://d1dg8g269sqvrb.cloudfront.net/eyJidWNrZXQiOiJ2YWxvcmVzLW5mdC1maWxlcyIsImtleSI6IjQucG5nIiwiZWRpdHMiOnsicmVzaXplIjp7IndpZHRoIjo1MDAsImhlaWdodCI6NTAwLCJmaXQiOiJjb3ZlciJ9fX0=",
  ];

  const slides = images.map((item, idx) => (
    <div
      key={idx}
      className="min-w-full min-h-full bg-cover bg-no-repeat bg-center rounded-3xl"
      style={{ backgroundImage: `url(${item})` }}
    />
  ));

  const textAnimation = useCallback(() => {
    const vsOpts = {
      $slides: Array.from(document.getElementsByClassName(".v-slide")),
      $list: Array.from(document.getElementsByClassName(".v-slides")),
      duration: 4,
      lineHeight: 40,
    };

    const vSlide = gsap.timeline({
      paused: true,
      repeat: -1,
      duration: vsOpts.duration / vsOpts.$slides.length,
    });

    vsOpts.$slides.forEach(function (i) {
      vSlide.to(vsOpts.$list, {
        y: 10,
        ease: Elastic.easeOut.config(1, 0.4),
      });
    });
    vSlide.play();
  });

  const textAnimation2 = useCallback(() => {
    const line = document.getElementsByClassName("v-slide");
    const time = 0.9;
    const y = 50;
    const ease = Elastic.easeOut.config(1, 0.4);
    // const ease = Circ.easeInOut
    const tl = gsap.timeline({
      onComplete: () => tl.restart(),
      defaults: { ease: ease },
      // repeat: -1,
    });

    tl.add(
      TweenMax.staggerFromTo(
        line,
        time,
        { opacity: 0, y: y, ease: ease },
        { opacity: 1, y: 0, ease: ease },
        3
      )
    ).add(
      TweenMax.staggerTo(
        line,
        time,
        { delay: 0, opacity: 0, y: -y, ease: ease },
        3
      ),
      3
    );
  });

  useEffect(() => {
    textAnimation2();
    return () => textAnimation2();
  }, []);

  const blurredSlides = images.map((item, index) => (
    <div
      key={index}
      className="w-full min-w-full !bg-no-repeat !bg-center !bg-cover blur-3xl h-full"
      style={{
        background: `linear-gradient(0deg, #101316 10%, rgba(0, 0, 0, 0.57) 45%), url(${item})`,
      }}
    />
  ));

  return (
    <div>
      {APP_ENV == "testnet" && (
        <div
          id="marquee"
          className="bg-primary2 w-full text-black font-notoSans font-bold text-base absolute top-[90px] z-10 uppercase py-0.5 animation"
        >
          <div className="text-center">
            This is the testnet version of our NFT marketplace. Please do not
            send mainnet cryptocurrency to any address on this website.
          </div>
        </div>
      )}

      <div className="relative h-[800px] flex justify-center items-center">
        {/* <div className="w-full min-w-full !bg-no-repeat !bg-center !bg-cover blur-2xl h-full"
            style={{ background: `linear-gradient(0deg, #101316 0%, rgba(0, 0, 0, 0.57) 45%), url(${images[0]})` }}
        /> */}
        <Carousel
          slides={blurredSlides}
          type="fade"
          controls={false}
          id="blurredBg"
        />
        <div className="flex flex-col lg:flex-row absolute top-0 px-6 xs xs4:px-10 xl:px-36 gap-12 justify-end pb-40 sm:pb-20 lg:pb-0 lg:justify-between h-full w-full max-w-[1440px] items-center">
          <div className="flex flex-col">
            <Text className=" sm:text-left text-center text-[18px] sm:text-[24px] font-bold leading-0 text-white m-0">
              Valores is the NFT marketplace where
            </Text>

            <div className="relative lg:text-left text-center">
              {["value", "celebrate", "create", "collaborate"].map(
                (item, idx) => (
                  <Text
                    key={idx}
                    className="v-slide absolute w-full top-0 left-0 !leading-10 lg:text-left text-center text-[18px] sm:text-[24px] font-bold text-primary3"
                  >
                    {`we value what you ${item}!`}
                  </Text>
                )
              )}
            </div>

            <SearchField
              placeholder="Search by NFT name / collection / address"
              iconClass="fill-white2"
              className=" w-10/12 mt-14 lg:block hidden"
              skipSearch={true}
              onClick={(response) => handleSearchResponse(response)}
            />
          </div>
          <div className="flex justify-end w-full  xs4:w-[350px] h-[350px] sm:w-[456px] sm:h-[456px]">
            <Carousel slides={slides} controls={true} id="bannerImages" />
          </div>
        </div>
      </div>
    </div>
  );
};

export const ProfileBanner = () => {
  const navigate = useNavigate();
  const { profileBannerDetails, userData } = useContext(AppContext);
  const [showSocialShare, setShowSocialShare] = useState(false);
  const handleSocialShare = () => {
    setShowSocialShare(!showSocialShare);
  };
  const [showReport, setShowReport] = useState(false);
  const toggleReport = () => {
    setShowReport(!showReport);
  };
  const id = profileBannerDetails.id;
  return !profileBannerDetails || !profileBannerDetails.id ? null : (
    <>
      <div
        className="w-full bg-no-repeat bg-center bg-cover z-0"
        style={{ backgroundImage: `url(${profileHeaderImage})`, height: 300 }}
      />
      <div className="px-6 xl:px-[150px] max-w-[1440px] mx-auto">
        <Grid container columnSpacing={4}>
          <Grid item xs={12} lg={3} style={{ transform: `translateY(-66px)` }}>
            <div className="flex flex-col gap-6 items-center">
              {profileBannerDetails.profileImage ? (
                <div
                  className="w-full bg-no-repeat bg-center bg-cover z-0"
                  style={{
                    backgroundImage: `url(${profileBannerDetails.profileImage})`,
                    backgroundColor: "#000000",
                    height: 113,
                    width: 113,
                    borderRadius: 69,
                    border: `3px solid #FFF`,
                  }}
                />
              ) : (
                <div
                  className="w-full bg-no-repeat bg-center bg-cover z-0 flex justify-center items-center"
                  style={{
                    backgroundColor: "#676F7D",
                    height: 113,
                    width: 113,
                    borderRadius: 69,
                    border: `3px solid #FFF`,
                  }}
                >
                  <ProfileDefault />
                </div>
              )}

              <div className="flex flex-row items-center ">
                <Text18Bold>
                  {profileBannerDetails.profileName ||
                    `unNamedUser-${profileBannerDetails.id}`}
                </Text18Bold>
                <Verified />
              </div>
              <div>
                <Text14Regular className="text-white2">
                  {`${window.location.protocol}//${
                    window.location.host
                  }/profile/${
                    profileBannerDetails.username || profileBannerDetails.id
                  }`}
                </Text14Regular>
              </div>
              <div className="flex flex-row gap-6">
                <div>
                  {profileBannerDetails.discord && (
                    <a
                      rel="noreferrer"
                      target={"_blank"}
                      href={correctUrl(profileBannerDetails.discord)}
                    >
                      <Discord className="fill-grey2 opacity-70" />
                    </a>
                  )}
                </div>
                <div>
                  {profileBannerDetails.twitter && (
                    <a
                      rel="noreferrer"
                      target={"_blank"}
                      href={correctUrl(profileBannerDetails.twitter)}
                    >
                      <Twitter className="fill-grey2 opacity-70" />
                    </a>
                  )}
                </div>
                <div>
                  {profileBannerDetails.instagram && (
                    <a
                      rel="noreferrer"
                      target={"_blank"}
                      href={correctUrl(profileBannerDetails.instagram)}
                    >
                      <Instagram className="fill-grey2 opacity-70" />
                    </a>
                  )}
                </div>
                <div>
                  {profileBannerDetails.telegram && (
                    <a
                      rel="noreferrer"
                      target={"_blank"}
                      href={correctUrl(profileBannerDetails.telegram)}
                    >
                      <Telegram className="fill-grey2 opacity-70" />
                    </a>
                  )}
                </div>
                <div>
                  {profileBannerDetails.facebook && (
                    <a
                      rel="noreferrer"
                      target={"_blank"}
                      href={correctUrl(profileBannerDetails.facebook)}
                    >
                      <Facebook className="fill-grey2 opacity-70" />
                    </a>
                  )}
                </div>
              </div>
              <div className="flex flex-1 w-full">
                <div className=" flex-[0.5]">
                  <div className="flex flex-col border border-dark2 rounded-tl-2xl rounded-bl-2xl px-8 py-6 items-center">
                    <Text14Bold>
                      {profileBannerDetails.followers.length}
                    </Text14Bold>
                    <Text12Regular>
                      {$t("_FOLLOWERS_", "Followers")}
                    </Text12Regular>
                  </div>
                  {/* <div className="flex flex-col border border-dark2 rounded-bl-2xl px-6 py-6 items-center whitespace-nowrap">
                    <Text14Bold>
                      {profileBannerDetails.ownedNfts.length}
                    </Text14Bold>
                    <Text12Regular>
                      {$t("_OWNED_NFTS_", "Owned NFTS")}
                    </Text12Regular>
                  </div> */}
                </div>
                <div className=" flex-[0.5]">
                  <div className="flex flex-col border border-dark2 rounded-tr-2xl rounded-br-2xl  px-8 py-6 items-center">
                    <Text14Bold>
                      {profileBannerDetails.followees.length}
                    </Text14Bold>
                    <Text12Regular>
                      {$t("_FOLLOWING_", "Following")}
                    </Text12Regular>
                  </div>
                </div>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} lg={9}>
            <div className="flex flex-col pt-5 ml-8">
              <div className="flex gap-4 items-center xl:justify-end justify-center flex-wrap">
                {!!userData &&
                  userData.walletAddress ===
                    profileBannerDetails.walletAddress && (
                    <ButtonTertiarySmall
                      onClick={() =>
                        navigate(`/profile/${profileBannerDetails.id}/edit`)
                      }
                      text={$t("_EDIT_PROFILE_", "Edit Profile")}
                    />
                  )}
                <ButtonPrimarySmall text={$t("_FOLLOWING_", "Following")} />
                <ButtonTertiaryGhost>
                  <div
                    className="flex justify-evenly gap-2 items-center relative"
                    onClick={() => handleSocialShare()}
                  >
                    <div className="absolute top-10 z-20">
                      {showSocialShare && (
                        <SocialShare
                          quoteFB={`Check out this account on Valores ${window.location.protocol}//${window.location.host}/profile/${profileBannerDetails.id} via @wearevalores`}
                          linkFB={`${window.location.protocol}//${window.location.host}/profile/${profileBannerDetails.id} via @wearevalores}`}
                          copyLink={`/profile/${profileBannerDetails.id}`}
                          socialLink={`Check out this account on Valores ${window.location.protocol}//${window.location.host}/profile/${profileBannerDetails.id} via @wearevalores`}
                        />
                      )}
                    </div>
                    <Text14Bold>{$t("_SHARE_", "Share")}</Text14Bold>
                    <Share />
                  </div>
                </ButtonTertiaryGhost>
                <ButtonTertiaryGhost>
                  <div
                    className="flex justify-evenly gap-2 items-center"
                    onClick={toggleReport}
                  >
                    <Text14Bold>{$t("_REPORT_", "Report")}</Text14Bold>
                    <File />
                  </div>
                </ButtonTertiaryGhost>
                {showReport && (
                  <ReportDropDown
                    showReport={showReport}
                    setShowReport={setShowReport}
                    toggleReport={toggleReport}
                    id={id}
                  />
                )}
              </div>
              <div className="flex flex-col gap-8 mt-4 mb-4">
                <Text18Bold>{$t("_DESCRIPTION_", "Description")}</Text18Bold>
                <Text14Regular className="text-grey2">
                  {profileBannerDetails.description}
                </Text14Regular>
              </div>
              {/* <div className="flex rounded-xl bg-dark2 backdrop-blur-lg w-[470px] max-w-full justify-between px-1">
                <div className="flex flex-col items-center w-1/3 p-2 lg:p-7">
                  <Text14Bold>369</Text14Bold>
                  <Text12Regular className="text-white2">
                    {$t("_ITEMS_", "Items")}
                  </Text12Regular>
                </div>
                <div className="flex flex-col items-center w-1/3 p-2 lg:p-7">
                  <Text14Bold>0.1 ETH</Text14Bold>
                  <Text12Regular className="text-white2">
                    {$t("_FLOOR_PRICE_", "Floor price")}
                  </Text12Regular>
                </div>
                <div className="flex flex-col items-center w-1/3 p-2 lg:p-7">
                  <Text14Bold>1.24 ETH</Text14Bold>
                  <Text12Regular className="text-white2">
                    {$t("_VOLUME_TRADED_", "Volume traded")}
                  </Text12Regular>
                </div>
              </div> */}
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export const ReportDropDown = (props) => {
  const [selectedReason, setSelectedReason] = useState("Select a reason");
  const [description, setDescription] = useState("");
  const [errorMessageDescription, setErrorMessageDescription] = useState("");
  const [errorMessageOptions, setErrorMessageOptions] = useState("");
  const inputRef = useRef("");

  const options = [
    { label: "Select a reason", value: "Select a reason" },
    { label: "Spam", value: "Spam" },
    { label: "Has nudity", value: "Has nudity" },
    {
      label: "Belongs to someone you know",
      value: "Belongs to someone you know",
    },
    { label: "Stolen", value: "Stolen" },
    { label: "Others", value: "Others" },
  ];
  const reportHandler = async () => {
    if (description === "") {
      setErrorMessageDescription("Enter description");
    }
    if (selectedReason === "Select a reason") {
      setErrorMessageOptions("Enter an valid option");
    } else {
      setErrorMessageOptions("");
    }
    setDescription(inputRef.current.value);
    const reportObject = {
      entityId: props.id,
      kind: selectedReason,
      entity: "nft",
      description: description,
    };
    if (description !== "" && selectedReason !== "Select a reason") {
      props.toggleReport();
      customToast(
        "Thank you for reporting the content. We'll ensure this is reviewed",
        { toastId: "REPORT" }
      );
      await report(reportObject);
    }
  };

  const handleDescriptionChange = (e) => {
    setErrorMessageDescription("");
    setDescription(inputRef.current.value);
  };

  const handleReportTypeChange = (value) => {
    setErrorMessageOptions("");
    setSelectedReason(value.label);
  };
  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      color: state.selectProps.menuColor,
      backgroundColor: "#1B1E21",
      border: "1px solid #26282B",
    }),
    control: () => ({
      backgroundColor: "#26282B",
      display: "flex",
      borderRadius: "7px",
      padding: "6px 0px",
      color: "#87898A",
      focus: "outline-none",
      outline: "none",
    }),
    input: (styles) => ({ ...styles, color: "#87898A", zIndex: 9999 }),
    placeholder: (styles) => ({ color: "#87898A" }),

    singleValue: (styles) => ({
      ...styles,
      color: "#87898A",
      fontSize: "14px",
      fontStyle: "normal",
    }),
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full z-50 flex justify-center items-center">
      <div
        className={`max-w-[300px] sm:max-w-[500px] md:max-w-[600px] w-full h-[480px] bg-[#1B1E21] z-50 px-5 sm:px-12 py-8 relative rounded-lg border border-[#26282B] `}
      >
        <div className="text-white text-xl sm:text-2xl font-notoSans font-black text-left">
          Report this item
        </div>
        <div className="text-white text-xl sm:text-2xl font-notoSans font-light text-left mt-2">
          I think this item is
        </div>
        <div className="flex flex-col justify-center mt-7">
          <label className="text-white text-sm font-notoSans font-bold opacity-60 text-left mb-2">
            Select
          </label>

          <Select
            menuColor="white"
            defaultValue={options[0]}
            options={options}
            onChange={(value) => handleReportTypeChange(value)}
            styles={customStyles}
            className={`bg-[#1B1E21] text-sm`}
            classNamePrefix={` text-sm font-notoSans bg-[#1B1E21]font-normal`}
            theme={(theme) => ({
              ...theme,
              borderRadius: "7px",
              color: "white",
              colors: {
                primary25: "#3ED791",
                primary: "",
                neutral0: "",
              },
            })}
          />
        </div>
        {errorMessageOptions && (
          <div className="text-red-600">{errorMessageOptions}</div>
        )}
        <div
          className={`flex flex-col justify-center ${
            !errorMessageDescription ? "mt-5" : ""
          }`}
        >
          <label className="text-white text-sm font-notoSans font-bold opacity-60 text-left">
            Description
          </label>
          <textarea
            id="reportDescription"
            ref={inputRef}
            className={`required flex justify-between items-center mt-2 px-3 py-3 pb-10 text-gray-400 text-left font-notoSans text-sm font-normal
        bg-[#26282B] rounded-md focus:outline-none `}
            placeholder="Text here"
            value={description}
            onChange={handleDescriptionChange}
          ></textarea>
          {errorMessageDescription && (
            <div className="text-red-600">{errorMessageDescription}</div>
          )}
        </div>
        <div className="flex gap-[5%] absolute bottom-14 sm:right-[65px]">
          <button
            onClick={props.toggleReport}
            style={props.style}
            className={`flex items-center justify-center w-auto h-auto border border-teritary2 hover:border-teritary1 hover:text-teritary1 text-teritary2 font-bold font-notoSans text-sm rounded-[25px] px-9 focus:outline-none`}
          >
            Close
          </button>
          <button
            className={`flex items-center justify-center w-auto h-auto border border-teritary2 hover:border-teritary1 hover:text-teritary1 text-teritary2 font-bold font-notoSans text-sm rounded-[25px] px-9 min-h-[49px] focus:outline-none ${
              description !== "" && selectedReason !== ""
                ? "hover:cursor-pointer"
                : "hover:cursor-not-allowed"
            }`}
            onClick={() => reportHandler()}
          >
            Report
          </button>
        </div>
      </div>
      <div
        className="fixed top-0 left-0 w-full h-full bg-[#0C0C0C] opacity-60 cursor-pointer"
        onClick={props.toggleReport}
      />
    </div>
  );
};
export const ArtistProfileBanner = () => {
  const [showReport, setShowReport] = useState(false);
  const toggleReport = () => {
    setShowReport(!showReport);
  };
  return (
    <>
      <div
        className="w-full bg-no-repeat bg-bottom bg-cover z-0 flex flex-col justify-end"
        style={{
          backgroundImage: `url(https://i.imgur.com/99OVkgy.png)`,
          height: "50vw", // 854 - 192, // 854 = banner height and it is moved up 192 px in design
        }}
      >
        <div
          className=""
          style={{
            minHeight: "40vw", //470,
            background:
              "linear-gradient(180deg, rgba(16, 19, 22, 0) 0%, #101316 100%)",
          }}
        ></div>
      </div>
      <div className="flex flex-col gap-6 items-center mt-[-33%] max-w-full px-2 relative">
        <div
          className="bg-no-repeat bg-center bg-cover z-0 w-28 h-28 rounded-full border-[3px] border-white border-solid"
          style={{ backgroundImage: `url(${SampleUrls[8]})` }}
        />
        <div className="relative xl:absolute top-0 flex items-center gap-4 right-0 xl:right-[15vw]">
          <ButtonPrimarySmall text="Follow" />
          <ButtonTertiaryGhost>
            <div className="flex justify-evenly gap-2 items-center">
              <Text14Bold>{$t("_SHARE_", "Share")}</Text14Bold>
              <Share />
            </div>
          </ButtonTertiaryGhost>
          <ButtonTertiaryGhost>
            <div className="flex justify-evenly gap-2 items-center">
              <Text14Bold>{$t("_REPORT_", "Report")}</Text14Bold>
              <File />
            </div>
          </ButtonTertiaryGhost>
          {showReport && (
            <ReportDropDown
              showReport={showReport}
              setShowReport={setShowReport}
              toggleReport={toggleReport}
            />
          )}
        </div>
        <div className="flex flex-row items-center">
          <Text18Bold>Ahn Jae Hyun</Text18Bold>
          <Verified />
        </div>
        <div>
          <Text14Regular className="text-white2">
            https://Valores/aagbanjh
          </Text14Regular>
        </div>
        <div className="flex flex-row gap-6 max-w-full">
          <div>
            <Discord className="fill-white opacity-70" />
          </div>
          <div>
            <Twitter className="fill-white opacity-70" />
          </div>
          <div>
            <Instagram className="fill-white opacity-70" />
          </div>
          <div>
            <Telegram className="fill-white opacity-70" />
          </div>
          <div>
            <Facebook className="fill-white opacity-70" />
          </div>
        </div>
        <div className="flex rounded-xl bg-lightbg1 backdrop-blur-lg w-[470px] max-w-full justify-between px-1">
          <div className="w-0 h-auto border border-lightbg3" />

          <div className="w-0 h-auto border border-lightbg3" />
          <div className="flex flex-col items-center w-1/4 p-2 lg:p-4 justify-center">
            <Text14Bold>12k</Text14Bold>
            <Text12Regular className="text-white2">
              {$t("_FOLLOWERS_", "Followers")}
            </Text12Regular>
          </div>
          <div className="w-0 h-auto border border-lightbg3" />
          <div className="flex flex-col items-center w-1/4 p-2 lg:p-4 justify-center">
            <Text14Bold>200</Text14Bold>
            <Text12Regular className="text-white2">
              {$t("_FOLLOWING_", "Following")}
            </Text12Regular>
          </div>
        </div>
        <div
          className="flex flex-col gap-4 text-center w-[470px] max-w-full my-6"
          style={{ transform: `translateY(16px)` }}
        >
          <Text14Regular className="text-grey2">
            모델 시절에도 훈훈한 외모로 팬이 상당히 많았다. 2011년 JTBC의 예능
            프로그램인 이수근 김병만의 상류사회에서 택배맨으로 출연한 것을
            계기로 일반 시청자들에게 얼굴을 알리기 시작했다. 그의 역할은 각자의
            방 안에 갇힌 MC들에게 등짝에 '택배 왔습니다' 라는 글귀를 붙이고
            택배를 배달해주는 것. 방송을 보면 알겠지만 상류사회 한 편당 안재현이
            등장하는 분량은 몇 분도 되지 않는다. 택배 왔습니다와 OO씨 맞으세요?
            가 거의 대사의 전부다. 그럼에도 안재현은 이 방송을 통해 젊은 여성들
            사이에서 인지도를 쌓는 데 성공한다. 이 프로그램의 포맷상 MC이수근과
            김병만은 타잔 옷 빼고 다 헐벗은 보기 그런 형상을 하고 있었던 탓에
            택배맨의 훈훈한 외모가 여성 시청자들의 관심을 산 것 같다. 모델 다운
            큰 키와 저음의 목소리 덕에 미미한 분량에도 불구하고 인기가 꽤
            높았다.
          </Text14Regular>
          <Text14Regular className="text-grey2">
            또 케이윌의 '이러지마 제발' 뮤직비디오에서 서인국의 친구이자
            사랑하는 상대로 출연, 마성의 남자로 주목받았다. 서술트릭을 쓴 뮤비로
            해외 케이팝 팬들 사이에서 나름 인기 영상이다.
          </Text14Regular>
        </div>
        <div className="flex rounded-xl bg-dark2 backdrop-blur-lg w-[470px] max-w-full justify-between px-1">
          <div className="flex flex-col items-center w-1/3 p-2 lg:p-7">
            <Text14Bold>369</Text14Bold>
            <Text12Regular className="text-white2">
              {$t("_OWNED_NFTS_", "Owned NFTS")}
            </Text12Regular>
          </div>
          <div className="flex flex-col items-center w-1/3 p-2 lg:p-7">
            <Text14Bold>0.1 ETH</Text14Bold>
            <Text12Regular className="text-white2">
              {$t("_TOTAL_FUNDS_", "Total Funds")}
            </Text12Regular>
          </div>
          <div className="flex flex-col items-center w-1/3 p-2 lg:p-7">
            <Text14Bold>1.24</Text14Bold>
            <Text12Regular className="text-white2">
              Followers{$t("_FOLLOWERS_", "Followers")}
            </Text12Regular>
          </div>
        </div>
        <div className="flex justify-between gap-1 max-w-full overflow-x-scroll scrollable-container">
          {[celebrityImages[4], celebrityImages[7], celebrityImages[9]].map(
            (item) => (
              <div
                style={{
                  backgroundImage: `url(${item})`,
                  minWidth: 370,
                  height: 597,
                }}
                className="bg-no-repeat bg-cover bg-top "
              />
            )
          )}
        </div>
      </div>
    </>
  );
};
