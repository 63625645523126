import MuiAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ChevronDown from 'components/icons/ChevronDown';

const Accordion = (props) => {
    const {panels} = props
    return <div className="flex flex-col flex-1 max-w-full">
        {
            panels.map((item, idx) => <MuiAccordion key={idx} disableGutters className='!bg-baseDark !text-white'>
                <AccordionSummary 
                    className='!px-0 !border-lightbg2 !py-2' 
                    expandIcon={<ChevronDown className="fill-white" />} 
                    aria-controls={`panel${idx}a-content`} 
                    id={`panel${idx}a-header`}
                    style={{border: `none`, borderBottomWidth: 2, borderBottomStyle: `solid`}}
                    >
                    {item.caption}
                </AccordionSummary>
                <AccordionDetails className='!px-0'>
                    <div className='!py-2'>{item.content}</div>
                </AccordionDetails>
            </MuiAccordion>)
        }
    </div>
}

export default Accordion