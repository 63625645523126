import { Text18Bold } from "components/elements/Text";
import React, { useState } from "react";
import { $t } from "components/utils/Translation";
import { dropDowncustomStyles } from "common/constants";
import Select from "react-select";
import Checkbox from "components/elements/Checkbox";
import { CircularProgress } from "@mui/material";
import { ButtonPrimaryLarge } from "components/elements/Button";
import { useMoralis } from "react-moralis";
import { customToast } from "components/utils/generic";

const PlaceBidModal = ({
  tokenOptions = [],
  onAction = () => {},
  loading = false,
}) => {
  let btnClass =
    "flex items-center justify-center border border-teritary2 hover:border-teritary1 hover:text-teritary1 text-teritary2 font-bold font-notoSans text-sm rounded-[25px] px-9 py-2 focus:outline-none";
  const regEx = /^[0-9.]+$/;
  const [values, setValues] = useState({
    token: tokenOptions?.length ? tokenOptions[0]?.fullOption : "",
    amount: "",
    acceptTerm: "",
  });
  const { provider } = useMoralis();

  const formatOptionLabel = ({ value, label, fullOption }) => (
    <div className="flex items-center">
      <div className="w-7 h-7 mr-0.5 flex items-center">{fullOption?.icon}</div>
      <div>{label}</div>
    </div>
  );

  /**
   * @description Function to add the token if its not there
   */
  const importTokenAsset = async () => {
    const selectedToken = tokenOptions?.find(
      (tokenObj) => tokenObj?.fullOption?.address === values?.token?.address
    )?.fullOption;
    provider.sendAsync(
      {
        method: "metamask_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: selectedToken?.address,
            symbol: selectedToken?.symbol,
            decimals: selectedToken?.decimals,
          },
        },
        id: Math.round(Math.random() * 100000),
      },
      (err, added) => {
        console.log("provider returned", err, added);
        if (err || "error" in added) {
          customToast("There was a problem adding the token.", {
            type: "error",
          });
          return;
        }
      }
    );
  };

  return (
    <div className="max-w-[563px] bg-[#1B1E21]">
      <div className="w-full flex flex-1 flex-col font-notoSans px-10 py-6 text-white">
        <div>
          <Text18Bold>{$t("_PLACE_A_BID_", "Place a Bid")}</Text18Bold>
        </div>
        <div className="flex flex-col flex-1 w-full space-y-4 mt-7">
          <div className="flex flex-1 space-x-2">
            <div className="flex flex-1 flex-col">
              <label className="text-white text-sm font-notoSans font-bold opacity-60 text-left mb-2">
                Select Token
              </label>
              <Select
                menuColor="white"
                defaultValue={tokenOptions[0]}
                options={tokenOptions}
                onChange={(value) =>
                  setValues((values) => {
                    return { ...values, token: value?.fullOption };
                  })
                }
                styles={dropDowncustomStyles}
                formatOptionLabel={formatOptionLabel}
                menuPlacement="auto"
                maxMenuHeight={150}
                className={`bg-[#1B1E21] text-sm`}
                classNamePrefix={` text-sm font-notoSans font-normal`}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: "7px",
                  color: "white",
                  colors: {
                    primary25: "#3ED791",
                    primary: "",
                    neutral0: "",
                  },
                })}
              />
            </div>
            <div className="flex flex-1 items-end justify-end">
              <ButtonPrimaryLarge onClick={importTokenAsset}>
                Watch in wallet
              </ButtonPrimaryLarge>
            </div>
          </div>
          <div>
            <div
              className={`mb-2 text-white text-sm font-notoSans font-bold opacity-60 text-left`}
            >
              <label htmlFor={"Enter Amount"}>{"Enter Amount"}</label>
            </div>
            <input
              value={values?.amount ?? ""}
              onKeyPress={(e) => {
                if (!regEx.test(e.key)) {
                  e.preventDefault();
                }
              }}
              onChange={(e) => {
                setValues((values) => {
                  return { ...values, amount: e?.target?.value };
                });
              }}
              name="amount"
              id={"amount"}
              type={"number"}
              className={`h-[48px] w-full text-sm text-white bg-lightbg px-5 focus:outline-none rounded-md`}
              placeholder={"Enter Amount"}
              autoComplete="off"
            />
          </div>
        </div>
        <div className="mt-5">
          <Checkbox
            onChange={(e) => {
              setValues((values) => {
                return { ...values, acceptTerm: e?.target?.checked };
              });
            }}
            label={
              <div>
                By placing this bid, I agree to the{" "}
                <a
                  target={"_blank"}
                  href="https://valores.notion.site/Terms-Conditions-eff87273243349f1beef2d721670b92d"
                  className="text-primary1"
                  onClick={(e) => false}
                  rel="noreferrer"
                >
                  Terms of Service
                </a>
              </div>
            }
          />
        </div>
        <div className="flex space-x-4 justify-end mt-7">
          <button
            onClick={() => {
              !loading && onAction();
            }}
            className={`${btnClass} ${
              loading ? "opacity-10 cursor-not-allowed" : "cursor-pointer"
            }`}
          >
            Close
          </button>
          <button
            disabled={
              !values?.acceptTerm ||
              !values?.token?.address ||
              !values?.amount ||
              loading
            }
            className={`px-9 py-2 flex items-center space-x-2  bg-primary2 text-black rounded-full text-sm font-bold disabled:bg-opacity-30 disabled:cursor-not-allowed`}
            onClick={() => {
              onAction(values);
            }}
          >
            <div>Place bid </div>
            <div className="mt-0.5">
              {loading && (
                <CircularProgress
                  size={16}
                  sx={{
                    color: "inherit",
                  }}
                />
              )}
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PlaceBidModal;
