import { AppContext } from "contexts/AppContext";
import { useContext } from "react";
import ReactDOMServer from "react-dom/server";

const Translate = (props) => {
  const { translations } = useContext(AppContext);

  try {
    if (!props.translationKey)
      return props.children || !isNaN(props.children)
        ? props.children
        : `missing TRANSLATION_KEY & FALLBACK_TEXT`;
    const textKey = props.translationKey;
    const translatedText =
      textKey && translations && translations[textKey]
        ? translations[textKey]
        : props.children || `MISSING_FALLBACK_TEXT for key : ${textKey}`;

    return translatedText;
  } catch (error) {
    console.error(error);
  }
};

export const $t = (str, fallback) => {
  try {
    const ret = ReactDOMServer.renderToStaticMarkup(
      Translate({ translationKey: str, children: fallback })
    );
    return ret;
  } catch (error) {
    console.error(error);
  }
};

export default Translate;

// For future use when building profile page where we can change language
/*
export const changeLanguage = async (lang) => {

}
*/
