import { getLoginNonce, userLogin } from "api/apiCalls";
import { HttpHeaderParam } from "contexts/AppContext";

export const signLogin = async (
  account,
  signatureTexts,
  provider,
  userData,
  event = "login"
) => {
  if (!account) {
    return;
  }
  const chainId = localStorage.getItem(HttpHeaderParam.XChainId);
  const loginNonceResponse =
    event === "login" ? await getLoginNonce(account) : userData.loginNonce;
  const loginNonce =
    event === "login" ? loginNonceResponse.data : userData.loginNonce;
  const signText = signatureTexts[event]
    ? signatureTexts[event]
        .replace("[%nonce%]", loginNonce)
        .replace("[%walletAddress%]", account)
    : "Valores Marketplace signature";
  const authenticationSignature = await provider.send("personal_sign", [
    signText,
    `${account}`,
  ]);
  return event === "login"
    ? await userLogin(account, authenticationSignature.result, chainId)
    : { user: userData };
};

export const textMask = (value, chartToDisplay = 3) =>
  value
    ? `${value.slice(0, chartToDisplay)} • • • ${value.slice(-chartToDisplay)}`
    : "";

export const scrollToELement = (id) => {
  setTimeout(() => {
    const elm = document.getElementById(id);
    elm.scrollIntoView({ behavior: "smooth" });
  }, 200);
};
