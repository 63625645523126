import { useCallback, useEffect } from "react"

const Carousel = (props) => {
    const { slides, style, type, controls, id } = props
    if (!slides || !slides.length) return null

    const animClass = type === 'fade' ? 'carousel-fade' : ''
    const domNodeId = `carouselIndicators-${id || ''}`
    // IDs
    // blurredBg
    // bannerImages

    const syncCarousels = (idx) => {
        const id = `carouselIndicators-blurredBg-${idx || ''}-indicator`
        const blurredCarouselButton = document.getElementById(id)
        if (!blurredCarouselButton) return

        blurredCarouselButton.click()
    }

    return <>
        <div id={domNodeId} style={style} className={`w-full h-full carousel ${animClass} slide relative min`} data-bs-ride="carousel" data-bs-interval="4000">
            <div className={`carousel-indicators absolute right-0 bottom-0 left-0 flex justify-center p-0 -mb-8 h-4 ${!controls && 'hidden'}`}>
                {
                    slides.map((item, index) => {
                        return <button
                            id={`${domNodeId}-${index}-indicator`}
                            onClick={() => syncCarousels(`${index}`)}
                            key={index}
                            type="button"
                            data-bs-target={`#${domNodeId}`}
                            data-bs-slide-to={index}
                            className={index === 0 ? 'active' : ''}
                            aria-current="true"
                            aria-label={`Slide ${index + 1}`}
                        />
                    })
                }
            </div>
            <div className="carousel-inner relative w-full h-full overflow-hidden">
                {
                    slides.map((item, index) => <div key={index} className={`carousel-item float-left w-full h-full ${index === 0 ? 'active' : ''}`}>
                        {item}
                    </div>)
                }
            </div>
        </div>
    </>
}

export default Carousel