import React from "react";

const BlackBox = (props) => (
  <div
    className={`w-28 h-28 bg-baseDark rounded-lg overflow-hidden border border-solid border-lightbg2 ${
      props.className || ""
    }`}
  />
);

export default BlackBox;
