const Smiley1 = (props) => {
    const { style, className } = props

    return (
        <svg style={style} className={className} width="138" height="138" viewBox="0 0 138 138" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="69" cy="69" r="69" fill="#1B1E21" />
            <path d="M63.5 70C63.5 81.598 53.598 83 42 83C30.402 83 21 73.598 21 62C21 50.402 30.402 41 42 41C53.598 41 63.5 58.402 63.5 70Z" fill="white" fillOpacity="0.11" />
            <path d="M74 70C74 81.598 83.902 83 95.5 83C107.098 83 116.5 73.598 116.5 62C116.5 50.402 107.098 41 95.5 41C83.902 41 74 58.402 74 70Z" fill="white" fillOpacity="0.11" />
            <circle cx="42" cy="62" r="11" fill="#101316" />
            <circle cx="42" cy="58" r="5" fill="#343739" />
            <circle cx="95" cy="62" r="11" fill="#101316" />
            <circle cx="95" cy="58" r="5" fill="#343739" />
            <path d="M91 105.741C73 113.5 65 114 47 105.741" stroke="#343739" strokeWidth="2" />
        </svg>
    )
}

export default Smiley1