import { APP_ENV } from "common/constants";
import { useNavigate } from "react-router-dom";

export const TestnetLogo = (props) => {
  const navigate = useNavigate();
  return (
    <div className="cursor-pointer xs2:mr-24" onClick={() => navigate("/")}>
      <img src={props.logo} alt="company_logo" />
      {APP_ENV == "testnet" && (
        <p className="text-white font-notoSans font-bold text-sm text-right">
          TESTNET
        </p>
      )}
    </div>
  );
};
