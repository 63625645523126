const translations = {
    "_HOME_PAGE_" : "Home page",
    "_CONNECT_WALLET_" : "Connect Wallet",
    "_DISCONNECT_WALLET_" : "Connect Wallet",
    "_PAGE_NOT_FOUND_ERROR_MESSAGE_" : "Page not found.",
    "_NOT_CONNECTED_" : "Not connected",
    "_HEADER_HOME_" : "Home",
    "_HEADER_TRENDING_" : "Trending",
    "_HEADER_TOP_SELLERS_" : "Top Sellers",
    "_HEADER_HOT_BIDS_" : "Hot Bids",
    "_HEADER_TOP_BUYERS_" : "Top Buyers",
    "_HEADER_LIVE_AUCTIONS_" : "Live Auctions",
    "_HEADER_EXPLORE_" : "Explore",
}

export default translations