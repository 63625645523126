const Cart = ( props ) => {
    const { style, className } = props    
    
    return (
        <svg style={style} className={className} width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_953_14033)">
        <path fillRule="evenodd" clipRule="evenodd" d="M4.95703 14.8749C4.95703 14.0925 5.59129 13.4583 6.3737 13.4583C7.1561 13.4583 7.79037 14.0925 7.79037 14.8749C7.79037 15.6573 7.1561 16.2916 6.3737 16.2916C5.59129 16.2916 4.95703 15.6573 4.95703 14.8749Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M12.75 14.8749C12.75 14.0925 13.3843 13.4583 14.1667 13.4583C14.9491 13.4583 15.5833 14.0925 15.5833 14.8749C15.5833 15.6573 14.9491 16.2916 14.1667 16.2916C13.3843 16.2916 12.75 15.6573 12.75 14.8749Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M0 0.708333C0 0.317132 0.317132 0 0.708333 0H3.54167C3.87928 0 4.16997 0.238273 4.23623 0.569318L4.83114 3.54167H16.2917C16.5028 3.54167 16.7029 3.63582 16.8374 3.79848C16.972 3.96113 17.027 4.17533 16.9875 4.38269L15.8531 10.3309C15.756 10.8201 15.4898 11.2595 15.1013 11.5722C14.7148 11.8834 14.2316 12.0494 13.7355 12.0417H6.86279C6.36677 12.0494 5.88356 11.8834 5.49701 11.5722C5.10868 11.2596 4.84262 10.8204 4.74536 10.3315C4.74532 10.3313 4.7454 10.3317 4.74536 10.3315L3.56206 4.41943C3.55726 4.39988 3.55327 4.38 3.55013 4.35985L2.96106 1.41667H0.708333C0.317132 1.41667 0 1.09954 0 0.708333ZM5.11468 4.95833L6.13476 10.0549C6.16715 10.218 6.25585 10.3644 6.38535 10.4687C6.51485 10.5729 6.67688 10.6283 6.8431 10.6251L6.85667 10.625H13.7417L13.7552 10.6251C13.9215 10.6283 14.0835 10.5729 14.213 10.4687C14.3419 10.3649 14.4304 10.2193 14.4631 10.0572L15.4355 4.95833H5.11468Z" fill="white"/>
        </g>
        <defs>
        <clipPath id="clip0_953_14033">
        <rect width="17" height="17" fill="white"/>
        </clipPath>
        </defs>
        </svg>
    )
}

export default Cart
