import React from "react";
import { Helmet } from "react-helmet";

const MetaTags = ({ language }) => {
  return (
    <div>
      {language === "EN" ? (
        <Helmet>
          <title>Valores NFT Marketplace</title>
          <meta name="robots" content="index,follow" />
          <meta
            name="description"
            content="At Valores, we want to give brands, IPs, artists and fans the opportunity to take control  of their content like never before - to create, communicate, collaborate, monetize, and  categorically own their interests. We want to offer an organic experience at the core of our endeavor - for artists to  have no limitations, for creators to have no obstructions, and for fans to have no  inhibitions.   Valores is the world's first NFT marketplace where you're in complete control -  we don't just lock in your NFTs, we also unlock experiences!"
          />
          <meta property="og:title" content="Valores NFT Marketplace" />
          <meta
            property="og:description"
            content="At Valores, we want to give brands, IPs, artists and fans the opportunity to take control  of their content like never before - to create, communicate, collaborate, monetize, and  categorically own their interests. We want to offer an organic experience at the core of our endeavor - for artists to  have no limitations, for creators to have no obstructions, and for fans to have no  inhibitions.   Valores is the world's first NFT marketplace where you're in complete control -  we don't just lock in your NFTs, we also unlock experiences!"
          />
          <meta property="og:url" content="https://valores.cc" />
          <link rel="canonical" href="https://valores.cc" />
          <meta
            name="naver-site-verification"
            content="9051e39a54eeb89470246808ddd95ff8d065cff2"
          />
          <meta name="og:type" content="website" />
          <meta
            name="og:description"
            content="At Valores, we want to give brands, IPs, artists and fans the opportunity to take control  of their content like never before - to create, communicate, collaborate, monetize, and  categorically own their interests. We want to offer an organic experience at the core of our endeavor - for artists to  have no limitations, for creators to have no obstructions, and for fans to have no  inhibitions.   Valores is the world's first NFT marketplace where you're in complete control -  we don't just lock in your NFTs, we also unlock experiences!"
          />
        </Helmet>
      ) : (
        <Helmet>
          <title>Valores NFT Marketplace</title>
          <meta name="robots" content="index,follow" />
          <meta property="og:title" content="Valores NFT Marketplace" />
          <meta property="og:url" content="https://valores.cc" />
          <link rel="canonical" href="https://valores.cc" />
          <meta
            name="naver-site-verification"
            content="9051e39a54eeb89470246808ddd95ff8d065cff2"
          />
          <meta name="og:type" content="website" />
          <meta
            name="description"
            content="Valores 에서는 브랜드들, 각각의 IP 소유자들, 아티스트들 그리고 팬들에게, 그들이 지닌 콘텐츠에새롭고 선구적인 자율성을 선사합니다. Valores에서는 유기적인 경험을 제공합니다. 아티스트들과 크리에이터들에게 방해와 제약 없는 자율적인 창작 환경을 조성하고, 팬들과 아티스트가 거리낌 없이 소통할 수 있는 환경 조성을 위해노력합니다."
          />
          <meta
            property="og:description"
            content="Valores 에서는 브랜드들, 각각의 IP 소유자들, 아티스트들 그리고 팬들에게, 그들이 지닌 콘텐츠에새롭고 선구적인 자율성을 선사합니다. Valores에서는 유기적인 경험을 제공합니다. 아티스트들과 크리에이터들에게 방해와 제약 없는 자율적인 창작 환경을 조성하고, 팬들과 아티스트가 거리낌 없이 소통할 수 있는 환경 조성을 위해노력합니다."
          />
          <meta
            name="og:description"
            content="Valores 에서는 브랜드들, 각각의 IP 소유자들, 아티스트들 그리고 팬들에게, 그들이 지닌 콘텐츠에새롭고 선구적인 자율성을 선사합니다. Valores에서는 유기적인 경험을 제공합니다. 아티스트들과 크리에이터들에게 방해와 제약 없는 자율적인 창작 환경을 조성하고, 팬들과 아티스트가 거리낌 없이 소통할 수 있는 환경 조성을 위해노력합니다."
          />
        </Helmet>
      )}
    </div>
  );
};

export default MetaTags;
