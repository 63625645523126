import React from "react";
import { CaptionWhite } from "components/elements/Caption";
import { CardContainer } from "components/elements/Filter";
import { Text14Bold, Text14Regular } from "components/elements/Text";
import { Polygon } from "components/icons/Blockchain";
import BinanceIcon from "components/icons/BinanceIcon";
import { $t } from "components/utils/Translation";
import { chainIds } from "./constant";
import AvaxIcon from "components/icons/AvaxIcon";

const SelectBlockchain = (props) => {
  const { nftDetails, setnftDetails } = props;

  return (
    <div className="flex flex-col flex-1 text-center sm:text-left">
      <CaptionWhite className="text-[24px] md:text-[26px] font-notoSans font-bold uppercase">
        {$t("_CHOOSE_BLOCKCHAIN_", "choose blockchain")}
      </CaptionWhite>
      <Text14Regular className="my-5">
        Choose the most suitable blockchain for your needs.
        <br />
        {$t(
          "_YOU_NEED_TO_SIGN_IN_FOR_CREATION_",
          "You need to sign in for creation"
        )}
      </Text14Regular>
      <div className="flex flex-col lg:flex-row gap-6">
        <CardContainer
          onClick={() =>
            setnftDetails({ ...nftDetails, chainId: chainIds.polygon })
          }
          className={`flex flex-col flex-[0.5] items-center max-w-[369px] w-full h-auto xl:h-[369px] justify-evenly cursor-pointer 
                      ${
                        nftDetails && nftDetails.chainId === chainIds.polygon
                          ? "!border-primary2 !border-2"
                          : ""
                      }`}
        >
          <Polygon />
          <Text14Bold>Polygon</Text14Bold>
        </CardContainer>

        <CardContainer
          onClick={() =>
            setnftDetails({ ...nftDetails, chainId: chainIds.binance })
          }
          className={`flex flex-col flex-[0.5] items-center max-w-[369px] w-full h-auto xl:h-[369px] justify-evenly 
                      ${
                        nftDetails && nftDetails.chainId === chainIds.binance
                          ? "!border-primary2 !border-2"
                          : ""
                      }`}
          isDisable={true}
        >
          <BinanceIcon className={"h-[138px]"} />
          <Text14Bold>Binance</Text14Bold>
        </CardContainer>
        <CardContainer
          onClick={() =>
            setnftDetails({ ...nftDetails, chainId: chainIds.avax })
          }
          className={`flex flex-col flex-[0.5] items-center max-w-[369px] w-full h-auto xl:h-[369px] justify-evenly 
                      ${
                        nftDetails && nftDetails.chainId === chainIds.avax
                          ? "!border-primary2 !border-2"
                          : ""
                      }`}
          isDisable={true}
        >
          <AvaxIcon className={"h-[138px]"} />
          <Text14Bold>Avalanche Network</Text14Bold>
        </CardContainer>
      </div>
    </div>
  );
};

export default SelectBlockchain;
