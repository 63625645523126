import React from "react";
import Close from "components/icons/Close";
import { Text18Light } from "components/elements/Text";

const CreateCollectionModal = (props) => {
  const { visible, toggle } = props;
  let classNames = ` fixed -translate-y-1/2 -translate-x-1/2 top-1/2 left-1/2 z-20 `; // position
  classNames += ` w-[80%] h-[60%] sm:w-[600px] sm:h-[225px] `; // dimensions
  classNames += ` bg-black shadow-['0px 4px 4px rgba(0, 0, 0, 0.25)'] `; // colors
  classNames += ` flex flex-col gap-1 overflow-hidden rounded-xl `; // display
  classNames += ` items-center justify-center p-8 `; // extras

  return !visible ? null : (
    <>
      <div
        id="create-collection-overlay"
        className="fixed inset-0 bg-darkbg1 z-10"
        onClick={() => toggle(!visible)}
      />
      <div id="collection-fields" className={classNames}>
        <Close
          className="fill-white cursor-pointer absolute top-4 right-4 "
          onClick={() => toggle(!visible)}
        />
        <Text18Light className="text-white2">
          This feature is not available currently but our team is actively
          working on it.
        </Text18Light>
      </div>
    </>
  );
};

export default CreateCollectionModal;
